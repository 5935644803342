import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-dropdown-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import {InsightExternalUrls} from '../../util/insight-external-urls.js';

/* @imgex - desktop/help-menu/4TeoaUzSksdc2JhmpDZEFjZnt.jpeg */
class HelpMenu extends InsightElement {
  static get properties() {
    return {
      modalsEl: {type: HTMLElement}
    };
  }
  /****************************************************  Public Api  ****************************************************/

  /**************************************************  Private Methods  *************************************************/

  firstUpdated() {
    this._afterRender(() => {
      this.__configureHelpMenu();
    });
  }

  __configureHelpMenu() {
    const dd = this._getElement('#help-menu');
    const data = [
      {key: 'help', label: this._i18n('appshell:helpmenu.helpLbl')},
      {key: 'features', label: this._i18n('appshell:helpmenu.featuresLbl')},
      {key: 'blog', label: this._i18n('appshell:helpmenu.blogLbl')},
      {key: 'support', label: this._i18n('appshell:helpmenu.supportLbl') + ': 1-855-367-4967', divider: 'before'}
    ];
    if (this._hasAccess('MISC:SENDFEEDBACK:ALL')) data.splice(2, 0, {key: 'askquestion', label: this._i18n('appshell:helpmenu.askquestionLbl')});
    dd.data = data;
  }

  __handleHelpAction({detail}) {
    switch (detail.key) {
      case 'help':
        window.open(InsightExternalUrls.knowledgeBase, '_blank');
        break;
      case 'blog':
        window.open(InsightExternalUrls.blog, '_blank');
        break;
      case 'features':
        this._dispatchEvent('toggle-drawer');
        setTimeout(() => this.modalsEl.showFeatureHistory(), 300);
        break;
      case 'askquestion':
        this._dispatchEvent('toggle-drawer');
        setTimeout(() => this.modalsEl.showAskAQuestion(), 300);
        break;
      case 'support':
        window.location.href = 'tel:1-855-367-4967';
        break;
    }
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dropdown-button
        id="help-menu"
        @change=${this.__handleHelpAction}
        horizontal-offset=${this._mobileRes ? '-10' : '40'}
        horizontal-align=${this._mobileRes ? 'right' : 'left'}
      >
        <insight-icon-button icon-name="help" slot="dropdown-button"></insight-icon-button>
      </insight-dropdown-button>
    `;
  }

  get __css() {
    return `
      <style>
        :host #help-menu {
          position: absolute;
          bottom: 48px;
          padding: 0 4px 4px;
          outline: none;
          cursor: pointer;
          background-color: var(--mdc-theme-dark-bg-8, var(--mdc-theme-primary));
          z-index: 1;
          --icon-color: #fff;
        }
        :host([mobile-res]) #help-menu {
          bottom: unset;
          top: -4px;
          right: 30px;
          background-color: unset;
          z-index: unset;
        }
      </style>
    `;
  }
}
window.customElements.define('help-menu', HelpMenu);
