class InsightPublicAPIUtils {
  static fixUnderscores(obj) {
    if (Array.isArray(obj)) {
      for (let i = obj.length - 1; i >= 0; i--) obj[i] = InsightPublicAPIUtils.fixUnderscores(obj[i]);
      return obj;
    } else if (typeof obj === 'object' && obj !== null) {
      const keyValues = Object.keys(obj).map(key => {
        return {
          [key.replace(/([_][a-z])/gi, $1 => $1.toUpperCase().replace('_', ''))]: InsightPublicAPIUtils.fixUnderscores(obj[key])
        };
      });
      return Object.assign({}, ...keyValues);
    }
    return obj;
  }
}

export default InsightPublicAPIUtils;
