import {InsightComponent, html} from '../../insight-component.js';
import {ifDefined} from 'lit/directives/if-defined.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '../../button/insight-button.js';
import '../../button/insight-icon-button.js';
import '../../form/insight-dropdown-menu.js';
import '../../form/insight-form.js';
import '../../form/insight-input.js';

class InsightPaymentAddToken extends InsightComponent {
  static get properties() {
    return {
      noPayment: {type: Boolean, attribute: 'no-payment'},
      tokenDetails: {type: Object},
      options: {type: Object}
    };
  }

  /****************************************************  Public Api  ****************************************************/

  constructor() {
    super();
    this.tokenDetails = {};
  }

  getFocusEl() {
    return this._getElement('#ipt-cust-id');
  }

  /**************************************************  Private Methods  *************************************************/

  updated(changedProperties) {
    if (changedProperties.has('options') && this.options.token) {
      this.tokenDetails.source = this.options.token.source;
      this.tokenDetails.customerId = this.options.token.customerId;
      this.tokenDetails.paymentId = this.options.token.paymentId;
      this.requestUpdate();
    }
  }

  firstUpdated() {
    this._afterRender(() => {
      this.__setupTokenType();
      setTimeout(() => {
        this._getElement('#ipt-cust-id').setFocus(true);
      }, 200);
    });
  }

  __setupTokenType() {
    const dd = this._getElement('#dd-token-type');
    const data = [{value: 'CIM', label: this._i18nComp('payment', 'cim', 'Authorize.Net (CIM)')}];
    if (this.tokenDetails.source) {
      data.find(d => d.value === this.tokenDetails.source).selected = true;
    } else {
      data[0].selected = true;
      this.tokenDetails.source = data[0].value;
    }
    dd.data = data;
  }

  __updateToken(e) {
    if (this.__formEl.validate(false, true)) {
      this.options.paymentType = 'insight-token';
      this.options.token = {};
      this.options.token.source = this.tokenDetails.source;
      this.options.token.customerId = this.tokenDetails.customerId;
      this.options.token.paymentId = this.tokenDetails.paymentId;
      if (this.noPayment) this._dispatchEvent('finish');
      else this._dispatchEvent('change-view', 0);
    }
  }

  __backToCC() {
    this._dispatchEvent('change-view', 1);
  }

  __getTitle(item) {
    if (!!item && !!item.token) {
      if (!this.tokenDetails.customerId) return this._i18nComp('payment', 'changeToken', 'Change Token');
      else return this._i18nComp('payment', 'editToken', 'Edit Token');
    }
    if (!!this.options && this.options.isCharge) return this._i18nComp('payment', 'payToken', 'Pay with Token');
    return this._i18nComp('payment', 'addToken', 'Add Token');
  }

  get __formEl() {
    return this._getElement('insight-form');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div class="flex-layout-vertical addtoken-container">
        <div class="flex-layout-horizontal flex-layout-center">
          ${!this.noPayment
            ? html`
                <insight-icon-button
                  id="btnBack"
                  icon-name="arrow_back"
                  class="addtoken-back"
                  @click=${() => this._dispatchEvent('change-view', 0)}
                ></insight-icon-button>
              `
            : ''}
          <div class="typo-subhead addtoken-title">
            <b>${this.__getTitle(this.options.editItem)}</b>
          </div>
        </div>
        <insight-form id="addtoken-form" class="flex-layout-flex">
          <insight-dropdown-menu
            dense
            class="flex-layout-flex"
            id="dd-token-type"
            label=${this._i18nComp(null, 'tknSrc', 'Token Provider')}
            required
            error-message=${this._i18nComp('common', 'requiredLbl', 'Required')}
            fixed-width
            @change=${e => (this.tokenDetails.source = e.detail.value)}
          ></insight-dropdown-menu>
          <insight-input
            dense
            class="flex-layout-flex"
            id="ipt-cust-id"
            label=${this._i18nComp(null, 'tknCust', 'Customer ID')}
            required
            error-message=${this._i18nComp('common', 'requiredLbl', 'Required')}
            initial-value=${ifDefined(this.tokenDetails.customerId)}
            @change=${e => (this.tokenDetails.customerId = e.detail)}
          ></insight-input>
          <insight-input
            dense
            class="flex-layout-flex"
            id="ipt-pymt-id"
            label=${this._i18nComp(null, 'tknProf', 'Payment ID')}
            required
            error-message=${this._i18nComp('common', 'requiredLbl', 'Required')}
            initial-value=${ifDefined(this.tokenDetails.paymentId)}
            @change=${e => (this.tokenDetails.paymentId = e.detail)}
          ></insight-input>
        </insight-form>
        <div class="flex-layout-horizontal flex-layout-end-justified addtoken-footer">
          ${(!this.options || !this.options.preventCancel) && this.noPayment
            ? html`
                <insight-button class="flex-layout-flex" outlined @click=${() => this._dispatchEvent('close-dialog')}>
                  ${this.options && this.options.cancelButtonLabel
                    ? this._i18nDirect(this.options.cancelButtonLabel)
                    : this._i18nComp(null, 'cancel', 'Cancel')}
                </insight-button>
              `
            : ''}
          ${!!this.options && this.options.acceptACH
            ? html`
                <insight-button id="btnACH" outlined @click=${() => this._dispatchEvent('change-view', 7)}>
                  ${this._i18nComp('payment', 'useAch', 'Use ACH')}
                </insight-button>
              `
            : ''}
          ${!!this.options && !!this.options.networks && this.options.networks.length
            ? html`
                <insight-button id="btnCard" outlined @click=${this.__backToCC}>${this._i18nComp('payment', 'useCard', 'Use Card')}</insight-button>
              `
            : ''}
          <insight-button id="btnUpdate" contained @click=${this.__updateToken}>
            ${!!this.options && this.options.isCharge ? this._i18nComp(null, 'pay', 'Pay') : this._i18nComp('payment', 'done', 'Done')}
          </insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host .addtoken-container {
          height: 100%;
        }
        :host .addtoken-back {
          margin-left: -16px;
          margin-top: -12px;
        }
        :host .addtoken-title {
          margin-bottom: 12px;
        }
        :host #addtoken-form {
          margin-top: 8px;
        }
        :host .form-row > *:not(:last-child) {
          margin-right: 16px;
        }
        :host .addtoken-footer {
          padding: 8px 0;
          row-gap: 8px;
          column-gap: 8px;
        }
        :host .addtoken-footer insight-button {
          --button-min-width: 112px;
        }
        @media only screen and (max-width: 500px) {
          :host .addtoken-footer insight-button {
            --button-min-width: 80px;
          }
        }
        @media only screen and (max-width: 620px) {
          :host .form-row,
          :host .addtoken-footer {
            flex-wrap: wrap;
          }
          :host .form-row > * {
            min-width: 100% !important;
            max-width: 100% !important;
            margin: 0 !important;
          }
        }
      </style>
    `;
  }
}
window.customElements.define('insight-payment-add-token', InsightPaymentAddToken);
