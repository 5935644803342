const i18nMoney = {
  format: (val, currency, i18n, wholeNumbers, allowFree) => {
    val = parseFloat(val);
    const fmt = n => {
      const isNeg = n < 0;
      const str = Math.abs(n).toLocaleString(window.navigator.language, {
        style: 'currency',
        currency: currency || 'USD',
        maximumFractionDigits: wholeNumbers ? 0 : undefined
      });
      return isNeg ? '(' + str + ')' : str;
    };
    if (val && val.toLocaleString) return fmt(val);
    if (allowFree) return i18n ? i18n('common:free') : fmt(0);
    return i18n ? '' : fmt(0);
  },
  getCurrencyIcon: currencyCode => {
    switch (currencyCode) {
      //TODO add more as we support other currencies.  USD and CAD both use the $ symbol
      default:
        return 'attach_money';
    }
  }
};

export {i18nMoney};
