import ky from 'ky';
import InsightPublicAPIUtils from '../../common/public-api-utils.js';

class PricingQuoteServerAPI {
  async getQuote(prospectId) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await ky.get(`${Insight.configs.insightApiUrl}/pricing-quotes/${prospectId}/view`);
        resolve(InsightPublicAPIUtils.fixUnderscores(await result.json()));
      } catch (e) {
        let error;
        if (!!e.response && e.response.status === 400) error = (await e.response.json()).error_code;
        else error = 'unexpected_error';
        reject(error);
      }
    });
  }
}

export default PricingQuoteServerAPI;
