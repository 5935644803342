import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {i18nMoney} from '@insight/insight-common/i18n/i18n-money.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/container/insight-dialog.js';
import '@insight/insight-common/components/container/insight-panel.js';
import '@insight/insight-common/components/form/insight-form.js';
import '@insight/insight-common/components/form/insight-radio-group.js';
import '../../components/common/insight-numpad-input.js';

/* @imgex - none */
class PosDiscountDialog extends InsightElement {
  static get properties() {
    return {
      __amount: {type: String}
    };
  }

  open() {
    this.__amount = '';
    this.__loadReasons();
    this.__dialogEl.open();
    this._afterRender(() => {
      setTimeout(() => this.__reasonEl.focus(), 250);
    });
  }

  close() {
    this.__dialogEl.close();
  }

  __dialogClosed() {
    this.__formEl.clear();
    this.__numpadEl.clear();
    this.__reasonEl.selectedIndex = 0;
  }

  __loadReasons() {
    const data = [
      {label: 'Damaged Item', type: 1, checked: true},
      {label: 'Valued Customer', type: 2},
      {label: 'Bulk Discount', type: 3},
      {label: 'Item Substitution', type: 4},
      {label: 'Price Matching', type: 5}
    ];
    this.__reasonEl.data = data;
  }

  __submit() {
    if (this.__formEl.validate(false, true)) {
      if (!this.__amount) {
        this.__numpadEl.setInvalid();
        return;
      }
      this._dispatchEvent('add-discount', {reason: this.__reasonEl.selected, amount: Number(this.__amount)});
      this.close();
    }
  }

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  get __formEl() {
    return this._getElement('insight-form');
  }

  get __reasonEl() {
    return this._getElement('insight-radio-group');
  }

  get __numpadEl() {
    return this._getElement('insight-numpad-input');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dialog id="discount-dialog" modal can-dismiss @closed=${this.__dialogClosed}>
        <div slot="modalHeader"><div>${this._i18n('account:addSale.addDiscTtl')}</div></div>
        <div id="modal-body" slot="modalBody">
          <insight-form>
            <div class="flex-layout-horizontal flex-layout-center-justified flex-layout-wrap frm-div">
              <insight-panel secondary border-thin panel-title=${this._i18n('common:reason')}>
                <insight-radio-group id="rdo-reason"></insight-radio-group>
              </insight-panel>
              <insight-numpad-input
                prefix-icon=${i18nMoney.getCurrencyIcon('USD')}
                field-label=${this._i18n('common:amount')}
                @value-change=${e => (this.__amount = e.detail)}
              >
              </insight-numpad-input>
            </div>
          </insight-form>
        </div>
        <span slot="modalFooter" class="buttons modal-footer">
          <insight-button id="btn-save" contained success tabindex="0" @click=${this.__submit} ?disabled=${!this.__amount || !Number(this.__amount)}
            >${this._i18n('account:addSale.addDiscount')}</insight-button
          >
        </span>
      </insight-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host insight-dialog {
          --icon-width: 32px;
          --dialog-padding-body: 16px 24px 0;
          --dialog-overflow-x: hidden;
        }
        :host .frm-div {
          column-gap: 24px;
          row-gap: 24px;
        }
        :host insight-panel {
          --panel-margin: 0;
          --panel-height: 100%;
          --panel-title-weight: 400;
          --mdc-typography-body2-font-size: 18px;
        }
        :host #rdo-reason {
          display: block;
          width: 240px;
        }
        :host .modal-footer {
          width: 100%;
          text-align: center;
        }
      </style>
  `;
  }
}
window.customElements.define('pos-discount-dialog', PosDiscountDialog);
