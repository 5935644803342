import ky from 'ky';
import InsightPublicAPIUtils from '../../common/public-api-utils.js';

class PricingQuoteMockAPI {
  async getQuote() {
    return new Promise(async (resolve, reject) => {
      if (!!new URLSearchParams(location.search).get('no-quote')) reject('quote_not_found');
      const url = '/mockdata/quotes/new/quote-new.json';
      resolve(InsightPublicAPIUtils.fixUnderscores(await ky.get(url).json()));
    });
  }
}

export default PricingQuoteMockAPI;
