import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@insight/insight-common/components/ui/insight-icon.js';

/* @imgex - desktop/activity-panel/LetoPFK46bPs5bErDVtiLuKsuFcVPj.jpeg */
class MemberPhoto extends InsightElement {
  static get properties() {
    return {
      acctId: {type: String, attribute: 'account-id'},
      imageUrl: {type: String, attribute: 'image-url'},
      noPopup: {type: Boolean, attribute: 'no-popup'},
      isPrimaryMember: {type: Boolean, attribute: 'primary-member'}
    };
  }

  toggleSummary() {
    if (this.noPopup || !this.__checkAccountId(this.acctId)) return;
    const br = this.getBoundingClientRect();
    this._dispatchEvent('toggle-acct-summary', {id: this.acctId, x: br.left, y: br.top});
  }

  updated(changedProperties) {
    if (changedProperties.has('imageUrl')) this.__updateImage();
  }

  __updateImage() {
    this._afterRender(() => {
      const img = this._getElement('img');
      if (this.imageUrl && this.imageUrl !== 'undefined') img.src = this.imageUrl;
      else img.src = window._photoPlaceholder;
    });
  }

  __checkAccountId(acctId) {
    return !!acctId && acctId !== 'null' && acctId !== 'undefined';
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      ${this.isPrimaryMember ? html` <insight-icon icon-name="star" class="member-star" title=${this._i18n('common:isPrimary')}></insight-icon> ` : ''}
      <img
        src="${window._photoPlaceholder}"
        onerror="this.src='${window._photoPlaceholder}';"
        @click=${this.toggleSummary}
        ?data-can-click=${this.__checkAccountId(this.acctId)}
      />
      <div class="status-ball"></div>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          position: relative;
          display: block;
          line-height: unset !important;
          overflow: visible !important;
        }
        :host {
          width: var(--photo-width, 64px);
          height: var(--photo-height, 48px);
        }
        :host img {
          box-sizing: border-box;
          width: var(--photo-width, 64px);
          height: var(--photo-height, 48px);
          border-radius: var(--photo-border-radius, 4px);
          border-color: var(--photo-border-color, var(--mdc-theme-divider-color));
          border-width: var(--photo-border-width, 1px);
          border-style: solid;
          object-fit: var(--photo-fit, initial);
        }
        :host([no-bottom-border]) img {
          border-bottom: 0;
        }
        :host img[data-can-click] {
          cursor: context-menu;
        }
        :host .status-ball {
          position: absolute;
          right: -4px;
          top: -4px;
          border: 2px solid var(--mdc-theme-dark-bg-8, var(--mdc-theme-background));
          border-radius: 50%;
          width: 16px;
          height: 16px;
          display: none;
        }
        :host .member-star {
          position: absolute;
          top: -12px;
          left: -7px;
          z-index: 2;
          --icon-width: 16px;
          --icon-color: #ffc903;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #c59b00;
        }
        :host([account-status="FROZEN"]) .status-ball {
          display: initial;
          background: var(--mdc-theme-secondary);
        }
        :host([account-status="YELLOW"]) .status-ball {
          display: initial;
          background: var(--mdc-theme-warning);
        }
        :host([account-status="RED"]) .status-ball,
        :host([account-status="COLLECTIONS"]) .status-ball,
        :host([account-status="CANCELLED"]) .status-ball {
          display: initial;
          background: var(--mdc-theme-error);
        }
      </style>
    `;
  }
}
window.customElements.define('member-photo', MemberPhoto);
