import {InsightElement, html} from '../insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/button/insight-dropdown-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '@insight/insight-common/components/ui/insight-icon.js';

class InsightPagingFooter extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      disabled: {attribute: 'disabled', type: Boolean},
      noPaging: {attribute: 'no-paging', type: Boolean},
      __currentPage: {type: Number},
      __currentPageSizeIndex: {type: Number}
    };
  }

  configure(opts) {
    this.__options = Object.assign({}, this.__options, opts);
    this.__currentPageSizeIndex = undefined;
    this.__currentPage = this.__options.currentPage || 1;
    this.requestUpdate();
    this._afterRender(() => {
      const dd = this.__dropDownEl;
      if (dd) dd.data = this.__getPageSizeOptions().map(o => ({label: o}));
    });
  }

  get currentPageStatus() {
    const ttl = Math.ceil(this.__options.totalRows / this.__getCurrentPageSize());
    return this._i18n('components:common.pageStatus', {page: ttl === 0 ? 0 : this.__currentPage, total: ttl});
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__firstRender = true;
  }

  firstUpdated() {
    this._afterRender(() => (this.__firstRender = false));
  }

  __rowsPerPageChanged(e) {
    if (this.disabled) return;
    e.stopImmediatePropagation();
    this.__currentPageSizeIndex = this.__getPageSizeOptions().findIndex(i => i === e.detail.label);
    const maxPage = this.__getMaxPageForPageSize();
    if (maxPage < this.__currentPage) this.__currentPage = maxPage;
    if (!this.__firstRender) this._dispatchEvent('page-size-change', this.__getCurrentPageSize());
  }

  __getPageSizeOptions() {
    return this.__options.pageSizeOptions || [25, 50, 100];
  }

  __getCurrentPageSize() {
    const opts = this.__getPageSizeOptions();
    return !isNaN(this.__currentPageSizeIndex) ? opts[this.__currentPageSizeIndex] : this.__options.rowsPerPage || opts[0];
  }

  __getCurrentPageSizeIndex() {
    return this.__getPageSizeOptions().indexOf(this.__getCurrentPageSize());
  }

  __getMaxPageRowCount(page) {
    return Math.min(page * this.__getCurrentPageSize(), this.__options.totalRows ?? Number.MAX_SAFE_INTEGER);
  }

  __getMaxPageForPageSize() {
    return Math.ceil(this.__options.totalRows / this.__getCurrentPageSize());
  }

  __buildPageStatus() {
    const pageSize = this.__getCurrentPageSize();
    const lastRow = this.__getMaxPageRowCount(this.__currentPage);
    const firstRow = lastRow === 0 ? 0 : (this.__currentPage - 1) * pageSize + 1;
    return `${firstRow} - ${lastRow} of ${this.__options.totalRows ?? '??'}`;
  }

  __pageDown() {
    if (this.disabled) return;
    if (this.__currentPage <= 1) return;
    --this.__currentPage;
    this._dispatchEvent('page-down');
  }

  __pageUp() {
    if (this.disabled) return;
    if (this.__getMaxPageRowCount(this.__currentPage) === (this.__options.totalRows ?? Number.MAX_SAFE_INTEGER)) return;
    ++this.__currentPage;
    this._dispatchEvent('page-up');
  }

  __pageSizeMenuOpened() {
    const pi = this.__getCurrentPageSizeIndex();
    if (pi >= 0) this.__dropDownEl.focusAtIndex(pi);
  }

  get __dropDownEl() {
    return this._getElement('insight-dropdown-button');
  }

  _render() {
    if (!this.__options) return '';
    return html`
      ${unsafeHTML(this.__css)}
      <div id="pager-footer">
        <div class="pager-container flex-layout-horizontal flex-layout-center flex-layout-end-justified">
          <slot name="footerButtons"></slot>
          <div class="pager-settings">
            <div class="pager-nav-item">
              <div>${this.__options.rowsPerPageLbl || 'Rows per page:'}</div>
              <insight-dropdown-button
                @change=${this.__rowsPerPageChanged}
                @open=${this.__pageSizeMenuOpened}
                ?data-one-size=${this.__options && this.__options.pageSizeOptions && this.__options.pageSizeOptions.length === 1}
              >
                <insight-button slot="dropdown-button" role="button" tabindex="0" ?disabled=${this.disabled}>
                  <div class="flex-layout-horizontal">
                    <div>${this.__getCurrentPageSize()}</div>
                    <insight-icon icon-name="arrow_drop_down"></insight-icon>
                  </div>
                </insight-button>
              </insight-dropdown-button>
            </div>
          </div>
          <div class="pager-status">${this.__buildPageStatus()}</div>
          <div class="pager-nav">
            <div class="pager-nav-item">
              <insight-icon-button icon-name="chevron_left" @click=${this.__pageDown} ?disabled=${this.disabled}></insight-icon-button>
            </div>
            <div class="pager-nav-item">
              <insight-icon-button icon-name="chevron_right" @click=${this.__pageUp} ?disabled=${this.disabled}></insight-icon-button>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host .pager-container {
          box-sizing: border-box;
          width: 100%;
          height: 48px;
          border: var(--footer-border, 1px solid var(--mdc-theme-divider-color));
          border-bottom-left-radius: var(--footer-border-radius, 4px);
          border-bottom-right-radius: var(--footer-border-radius, 4px);
          background: var(--mdc-theme-dark-bg-4, var(--mdc-theme-background));
        }
        :host([no-side-border]) .pager-container {
          border-left: 0;
          border-right: 0;
        }
        :host slot[name="footerButtons"] {
          display: block;
          flex: 1;
        }
        :host .pager-nav-item {
          display: inline-block;
          color: var(--mdc-theme-text-secondary);
        }
        :host .pager-nav-item > div {
          display: inline-block;
        }
        :host .pager-nav {
          white-space: nowrap;
        }
        :host .pager-nav .pager-nav-item:last-child {
          margin-right: 4px;
        }
        :host([mobile-res]) .pager-nav-item insight-icon-button {
          width: 40px;
          --icon-width: 20px;
          --icon-padding: 10px;
        }
        :host .pager-status {
          font-size: 12px;
          font-weight: 400;
          color: var(--mdc-theme-text-secondary);
          margin: 0 24px;
          white-space: nowrap;
        }
        :host .pager-settings {
          padding: 0;
        }
        :host .pager-settings > div {
          font-size: 12px;
          font-weight: 400;
          white-space: nowrap;
        }
        :host([disabled]) .pager-settings > div {
          cursor: not-allowed;
        }
        :host .pager-settings .pager-nav-item > div:first-child {
          vertical-align: middle;
          cursor: initial;
        }
        :host .pager-settings insight-button {
          outline: none;
          --icon-color: var(--mdc-theme-text-secondary);
          --button-color: var(--mdc-theme-text-secondary);
          --button-icon-color: var(--mdc-theme-text-icon);
          --button-font-size: 12px;
          --button-font-weight: 500;
        }
        :host .pager-settings insight-dropdown-button[data-one-size] {
          cursor: initial;
          pointer-events: none;
        }
        :host .pager-settings insight-dropdown-button[data-one-size] insight-icon {
          display: none;
        }
        :host .pager-settings insight-button insight-icon {
          margin-top: -2px;
        }
        :host([no-rows-page]) .pager-settings {
          display: none;
        }
        :host([no-paging]) .pager-status,
        :host([no-paging]) .pager-nav,
        :host([no-paging]) .pager-settings {
          display: none;
        }
        @media only screen and (max-width: 600px) {
          :host .pager-status {
            margin: 0;
            white-space: normal;
          }
          :host .pager-settings {
            margin-left: 16px;
          }
        }
      </style>
    `;
  }
}
window.customElements.define('insight-paging-footer', InsightPagingFooter);
