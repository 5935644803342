export const WebSocketEvent = Object.freeze({
  CONNECTION: 'connection',
  MESSAGE: 'message',
  CONNECTING: 'connecting',
  CLOSE: 'close',
  PONG: 'pong',
  ERROR: 'error'
});

export class InsightWebSocket {
  static get defaultOptions() {
    return {
      autoJsonify: false,
      autoConnect: true,
      reconnectInterval: 1000,
      pingEnabled: false,
      pingInterval: 10000,
      pongTimeout: 5000,
      pingMessage: 'PING',
      pongMessage: 'PONG',
      reconnectOnError: true
    };
  }

  constructor(url, options, wsFactory) {
    this.__callbacks = new Map();
    this.__url = url;
    this.__options = {...InsightWebSocket.defaultOptions, ...options};
    this.__wsFactory = wsFactory || (socketUrl => new WebSocket(socketUrl));
    if (this.__options.autoConnect) this.__socket = this.connect();
  }

  connect(isReconnect) {
    if (isReconnect) {
      if (!this.__reconnectAttempts) this.__reconnectAttempts = 1;
      else this.__reconnectAttempts++;
      if (this.__reconnectAttempts >= 3) return null;
    }
    const url = typeof this.__url === 'function' ? this.__url() : this.__url;
    const socket = this.__wsFactory(url);
    this.__respondToCallbacks(WebSocketEvent.CONNECTING, this);
    socket.addEventListener('open', this);
    socket.addEventListener('message', this);
    socket.addEventListener('close', this);
    socket.addEventListener('error', this);
    return socket;
  }

  send(data) {
    if (this.__socket) this.__socket.send(this.__options.autoJsonify ? JSON.stringify(data) : data);
  }

  close() {
    this.__socket.close();
    this.__respondToCallbacks(WebSocketEvent.CLOSE, this);
    this.__cleanup();
  }

  on(event, callback) {
    let callbackList = this.__callbacks.get(event);
    if (!callbackList) {
      callbackList = new Set();
      this.__callbacks.set(event, callbackList);
    }
    callbackList.add(callback);
  }

  off(event, callback) {
    let callbackList = this.__callbacks.get(event);
    if (callbackList) callbackList.delete(callback);
  }

  handleEvent(evt) {
    switch (evt.type) {
      case 'open':
        this.__onOpen(evt);
        break;
      case 'message':
        this.__onMessage(evt);
        break;
      case 'close':
        this.__onClose(evt);
        break;
      case 'error':
        this.__onError(evt);
        break;
    }
  }

  __respondToCallbacks(event, data) {
    const callbacks = this.__callbacks.get(event);
    if (callbacks) callbacks.forEach(callback => callback(data));
  }

  __onOpen() {
    this.__respondToCallbacks(WebSocketEvent.CONNECTION, this);
    if (this.__options.pingEnabled) this.__sendPing();
  }

  __sendPing() {
    this.__pingTimeout = setTimeout(() => {
      this.send(this.__options.pingMessage);
      this.__pongTimeout = setTimeout(() => this.__pongTimedOut(), this.__options.pongTimeout);
    }, this.__options.pingInterval);
  }

  __pongTimedOut() {
    this.__socket.close();
  }

  __pongReceived() {
    clearTimeout(this.__pongTimeout);
    this.__respondToCallbacks(WebSocketEvent.PONG, this);
    this.__sendPing();
  }

  __onMessage(event) {
    this.__reconnectAttempts = 0;
    if (this.__options.pingEnabled && event.data === this.__options.pongMessage) return this.__pongReceived();
    const message = this.__options.autoJsonify ? JSON.parse(event.data) : event.data;
    this.__respondToCallbacks(WebSocketEvent.MESSAGE, message);
  }

  __onClose(event) {
    this.__cleanup();
    this.__respondToCallbacks(WebSocketEvent.CLOSE, event);
    setTimeout(() => (this.__socket = this.connect(true)), Math.floor(Math.random() * 59000 + 1000));
  }

  __onError(event) {
    this.__respondToCallbacks(WebSocketEvent.ERROR, event);
    if (this.__options.reconnectOnError) this.__onClose(event);
  }

  __cleanup() {
    clearTimeout(this.__pongTimeout);
    clearInterval(this.__pingTimeout);
    this.__socket.removeEventListener('error', this);
    this.__socket.removeEventListener('message', this);
    this.__socket.removeEventListener('open', this);
    this.__socket.removeEventListener('close', this);
    this.__socket = null;
  }
}
