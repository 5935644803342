const InsightPermUtils = {
  isAuthenticated() {
    return !!Insight.auth && !!Insight.auth.accessToken;
  },
  hasAccess(permKey) {
    if (!permKey) return true;
    if (!Insight.user) return false;
    if (Insight.user.administrator) return true;
    const parts = permKey.split(':');
    const lvl = parts.pop();
    const perms = [permKey];
    if (lvl === 'VIEW') perms.push(parts.join(':') + ':EDIT');
    if (lvl === 'VIEW' || lvl === 'EDIT') perms.push(parts.join(':') + ':ALL');
    return perms.some(p => Insight.user.permissions.includes(p));
  },
  hasAccessToAny(permPrefix) {
    if (!permPrefix) return true;
    if (!Insight.user) return false;
    if (Insight.user.administrator) return true;
    return Insight.user.permissions.some(p => p.startsWith(permPrefix) && !p.endsWith(':NONE'));
  },
  hasReportAccess(report) {
    if (Insight.user.administrator) return true;
    return Insight.roleSets.reports.includes(report);
  },
  hasFeature(flag, flags) {
    if (!flags) flags = Insight.location ? Insight.location.featureFlags || [] : [];
    return !flag || flags.includes(flag);
  },
  isMock() {
    return window.Testophobia || Insight.configs.mock;
  },
  isProd() {
    return Insight.version !== 'DEV';
  },
  isSuperAdmin() {
    //just Lawrence, George, Tim, and Jason right now
    return !InsightPermUtils.isProd() || [-852095144, -1144527335, -1583346399, -1801409165].includes(InsightPermUtils.getHash(Insight.auth.sub));
  },
  isWarmSprings(loc) {
    if (Insight.NotWarmSprings) return false;
    return InsightPermUtils.checkHash(loc || Insight.user.currentLocationGuid, -1091969914);
  },
  isGymInsight(loc) {
    return InsightPermUtils.checkHash(loc || Insight.user.currentLocationGuid, -941390844);
  },
  checkHash(str, hash) {
    return InsightPermUtils.getHash(str) === hash;
  },
  getHash(str) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
      let chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    return hash;
  }
};

export {InsightPermUtils};
