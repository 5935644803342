import {InsightElement, html} from '../insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';

class InsightCCIcon extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      ccType: {type: String, attribute: 'cc-type'},
      __type: {type: String}
    };
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__type = 'unknown';
  }

  updated(changedProperties) {
    if (changedProperties.has('ccType')) {
      const type = this.ccType.split('15')[0];
      switch (type) {
        case 'amex':
          this.__type = 'amex';
          this.__ccTpl = html`
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <svg class="svg" height="100%" width="100%"><text x="5%" y="45%" fill="#fff" stroke="#fff">AMERICAN</text></svg
            ><svg class="svg" height="100%" width="100%"><text x="13%" y="75%" fill="#fff" stroke="#fff">EXPRESS</text></svg>
          `;
          break;
        case 'visa':
          this.__type = 'visa';
          this.__ccTpl = html`
            <p>VISA</p>
          `;
          break;
        case 'mastercard':
          this.__type = 'mc';
          this.__ccTpl = html`
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <p>MasterCard</p>
          `;
          break;
        case 'discover':
          this.__type = 'disc';
          this.__ccTpl = html`
            <p>Discover</p>
          `;
          break;
        default:
          // uatp, diners, dankort, instapayment, jcb, maestro, mir, unionPay, general
          this.__type = 'unknown';
          this.__ccTpl = '';
          break;
      }
    }
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div class=${this.__type + ' cc-card'}>${this.__ccTpl}</div>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          user-select: none;
          font-size: 5px;
          box-sizing: border-box;
          color: black !important;
        }
        :host *,
        :host *:before,
        :host *:after {
          box-sizing: inherit;
        }
        :host p {
          margin: 0;
        }
        :host([small]) {
          font-size: 4px;
        }
        :host .cc-card {
          background: #fff;
          border-radius: 0.25em;
          box-shadow: 0 0.039em 0.132em rgba(0, 0, 0, 0.4);
          display: inline-block;
          font-size: 1em;
          height: 3em;
          overflow: hidden;
          position: relative;
          vertical-align: middle;
          width: 5em;
        }
        :host .visa {
          background: transparent;
          font-family: sans-serif;
          font-size: 2em;
          font-style: italic;
          font-weight: 800;
          padding: .2em .05em;
          z-index: 0;
        }
        :host .visa:before, :host .visa:after {
          background: #00579F;
          border-top-left-radius: 0.25em;
          border-top-right-radius: 0.25em;
          content: "";
          display: block;
          height: .75em;
          left: 50%;
          position: absolute;
          top: 0;
          -webkit-transform: translate(-50%, 0);
                  transform: translate(-50%, 0);
          width: 100%;
          z-index: 1;
        }
        :host .visa:after {
          background: #FBA31B;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 0.25em;
          border-bottom-right-radius: 0.25em;
          bottom: 0;
          top: auto;
        }
        :host .visa p {
          background: #fff;
          width: 5em;
          color: #00579F;
          line-height: 2.6em;
          position: relative;
          text-align: center;
          height: 2.5em;
        }
        :host([small]) .visa p {
          line-height: 2.8em;
        }
        :host .visa p:after {
          border-top: .1em solid transparent;
          border-bottom: .1em solid transparent;
          border-left: 0.4em solid #FBA31B;
          border-radius: 0.0625em;
          content: "";
          display: block;
          height: 0;
          left: 25.5%;
          position: absolute;
          top: 37.25%;
          -webkit-transform: rotateZ(189deg);
                  transform: rotateZ(189deg);
          width: 0;
          z-index: 2;
        }
        :host .mc {
          background: #0059B3;
          font-size: 2em;
        }
        :host .mc:before, :host .mc:after {
          background: #EF3526;
          border-radius: 50%;
          content: "";
          display: block;
          height: 2em;
          left: 35%;
          position: absolute;
          top: 50%;
          width: 2em;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          z-index: 1;
        }
        :host .mc:after {
          background: #FCB836;
          left: 65%;
        }
        :host .mc hr {
          background: #EF3526;
          border: none;
          border-top-right-radius: 0.25em;
          height: .14em;
          margin: .14em auto;
          position: relative;
          top: 25%;
          width: .65em;
          z-index: 2;
        }
        :host .mc hr:nth-of-type(1), :host .mc hr:nth-of-type(5) {
          -webkit-transform: translate(-28%, 0);
                  transform: translate(-28%, 0);
        }
        :host .mc hr:nth-of-type(2), :host .mc hr:nth-of-type(4) {
          -webkit-transform: translate(-10%, 0);
                  transform: translate(-10%, 0);
        }
        :host .mc hr:nth-of-type(4), :host .mc hr:nth-of-type(5) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0.25em;
        }
        :host .mc hr:nth-of-type(3) {
          border-top-right-radius: 4;
          border-bottom-right-radius: 0.25em;
        }
        :host .mc p {
          color: #fff;
          font-size: .63em;
          font-weight: 600;
          line-height: 1.5em;
          position: relative;
          text-shadow: 0.0625em 0.0625em 0.0625em black;
          top: -.8em;
          z-index: 2;
          text-align: center;
        }
        :host .amex {
          background: #0971bc;
          background-image: radial-gradient(top left, circle farthest-corner, #7ecdf2, #0971bc 60%);
          font-size: 2em;
          font-weight: 900;
        }
        :host .amex hr {
          border: none;
          border-top: 1px solid rgba(255, 255, 255, 0.05);
          margin: .15em 0;
        }
        :host .amex .svg {
          color: #FFF;
          font-size: .8em;
          left: 0;
          position: absolute;
          top: 0;
          stroke-width: 1;
        }
        :host .disc {
          font-size: 2em;
        }
        :host .disc:after {
          border-top: .75em solid transparent;
          border-bottom: 0 solid transparent;
          border-right: 1.75em solid #FF7800;
          bottom: 0;
          content: "";
          height: 0;
          position: absolute;
          right: 0;
          width: 0;
        }
        :host .disc p {
          font-size: .75em;
          font-weight: bold;
          letter-spacing: .0625em;
          line-height: 4em;
          position: relative;
          text-transform: uppercase;
          text-align: center;
        }
        :host .disc p:after {
          background: #FF7800;
          border-radius: 50%;
          content: "";
          display: block;
          height: .85em;
          left: 3.11em;
          position: absolute;
          top: 1.5em;
          width: .85em;
        }
        @media (min-width: 25.625em) {
          :host .disc p:after {
            top: 1.59em;
          }
        }
        :host .unknown {
          background: #ccc;
          font-size: 2em;
          padding: .5em .12em;
        }
        :host .unknown:before {
          background: #333;
          content: "";
          display: block;
          height: .6em;
          width: 100%;
        }
        :host .unknown:after {
          background: #333;
          border: 0.2em solid #fff;
          content: "";
          display: block;
          height: .55em;
          margin-left: 0.2em;
          margin-top: 0.28em;
          width: 55%;
        }
        :host .unknown span {
          background: #333;
          border: 0.15em solid #fff;
          display: block;
          height: .43em;
          margin-left: .2em;
          margin-top: .5em;
          position: absolute;
          right: .65em;
          top: 0.95em;
          width: .55em;
          z-index: 0;
        }
        :host .unknown span:before {
          border: 0.15em solid red;
          border-radius: 50%;
          content: "";
          height: .95em;
          left: -0.35em;
          position: absolute;
          top: -0.4em;
          width: .95em;
          z-index: 2;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-cc-icon', InsightCCIcon);
