import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '@insight/insight-common/components/form/insight-readonly-field.js';
import '@insight/insight-common/components/ui/insight-keyboard.js';

/* @imgex - none */
class InsightNumpadInput extends InsightElement {
  static get properties() {
    return {
      __label: {type: String, attribute: 'field-label'},
      __prefixIcon: {type: String, attribute: 'prefix-icon'},
      __initVal: {type: String, attribute: 'initial-value'},
      __password: {type: Boolean, attribute: 'password-field'},
      __value: {type: String},
      __valShown: {type: String}
    };
  }

  updated(changedProperties) {
    if (changedProperties.has('__initVal')) this.__checkInitVal(this.__initVal);
  }

  get value() {
    return this.__value;
  }

  set value(val) {
    this.__value = val;
    this.__kbEl.value = val;
    this.__update();
  }

  clear() {
    this.__clear();
  }

  constructor() {
    super();
    this.__value = '';
  }

  __clear() {
    this.value = '';
  }

  __update() {
    let val = this.__value;
    this.__inputEl.value =
      this.__password && !this.__valShown
        ? (() =>
            val
              .split('')
              .map(c => '*')
              .join(''))()
        : val;
    this._dispatchEvent('value-change', val);
  }

  __toggleVis() {
    this.__valShown = !this.__valShown;
    this.__update();
  }

  __checkInitVal(val) {
    if (!val) return;
    this._afterRender(() => (this.value = val));
  }

  __kbValChanged({detail: val}) {
    if ((val.endsWith('.') && val.indexOf('.') !== val.lastIndexOf('.')) || val.charAt(val.length - 4) === '.') {
      this.value = val.slice(0, -1);
      return;
    }
    // if (val.charAt(val.length - 2) === '.') val += '0';
    this.__value = val;
    this.__update();
  }

  get __inputEl() {
    return this._getElement('insight-readonly-field');
  }

  get __kbEl() {
    return this._getElement('insight-keyboard');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div>
        <insight-readonly-field
          class="flex-layout-flex"
          no-helper
          no-bottom-border
          outlined
          label=${this.__label}
          ?can-edit=${this.__password}
          edit-icon=${this.__valShown ? 'visibility_off' : 'visibility'}
          @edit-value=${this.__toggleVis}
        >
          ${!!this.__prefixIcon ? html`<insight-icon slot="prefix" icon-name=${this.__prefixIcon}></insight-icon>` : ''}
        </insight-readonly-field>
        <insight-keyboard ?numeric-only=${!this.__password} ?is-password=${this.__password} @kb-change=${this.__kbValChanged}></insight-keyboard>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host insight-readonly-field {
          width: var(--num-pad-width, 240px);
          --input-font-size: 24px;
          --input-text-align: left;
          --input-border-color: var(--mdc-theme-dark-highlight, var(--mdc-theme-divider-color));
          --input-label-bg: var(--mdc-theme-dark-bg-24);
          --input-padding: 24px 16px 3px;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-numpad-input', InsightNumpadInput);
