import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '@insight/insight-common/components/container/insight-confirmation-dialog.js';
import '@insight/insight-common/components/container/insight-snackbar.js';
import {PosMainView} from './pos-main-view.js';
import '../../point-of-sale/pos-item-list.js';
import '../../point-of-sale/pos-member-header.js';
import '../../point-of-sale/pos-order-form.js';
import '../../point-of-sale/pos-pay-dialog.js';

/* @imgex - none */
class PosSaleView extends InsightElement {
  static get properties() {
    return {
      __member: {type: Object}
    };
  }

  handleArgs(args) {
    if (args.ccScan) this.__payDlg.cardScanned();
    else if (args.drawerClosed) this.__payDlg.drawerClosed();
    else this.__member = args.member;
  }

  __addItem({detail: orderItem}) {
    this.__orderEl.addItem(orderItem);
  }

  __copySale({detail: sale}) {
    this.__orderEl.loadSale(sale);
  }

  __saveSale() {
    const dialog = this.__confirmDlg;
    dialog.show({
      body: 'Save this order for later?',
      submitFn: e => {
        dialog.close();
        this._dispatchEvent('show-snackbar', {message: 'Order Saved For Later', success: true});
        this.__resetOrder();
      },
      submitBtnText: 'Save For Later'
    });
  }

  __showPayment({detail: order}) {
    this.__payDlg.open(order);
  }

  __cancel() {
    const dialog = this.__confirmDlg;
    dialog.show({
      body: 'Cancel this order?',
      danger: true,
      submitFn: e => {
        dialog.close();
        this.__resetOrder();
      },
      submitBtnText: 'Yes, Cancel It'
    });
  }

  __resetOrder() {
    this.__orderEl.reset();
    this.__listEl.reset();
    this.__member = null;
    this._dispatchEvent('menu-nav', {view: PosMainView.Views.Menu});
  }

  __saleComplete() {
    this.__resetOrder();
    this._dispatchEvent('close-cash-drawer');
    this._dispatchEvent('show-snackbar', {message: 'Order Complete!', success: true});
  }

  get __orderEl() {
    return this._getElement('pos-order-form');
  }

  get __listEl() {
    return this._getElement('pos-item-list');
  }

  get __confirmDlg() {
    return this._getElement('insight-confirmation-dialog');
  }

  get __payDlg() {
    return this._getElement('pos-pay-dialog');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div id="scr-ovl" class="flex-layout-horizontal">
        <div id="lt-pane" class="flex-layout-vertical">
          <pos-member-header .member=${this.__member} top-level stand-alone @copy-sale=${this.__copySale}></pos-member-header>
          <pos-order-form
            class="flex-layout-flex"
            stand-alone
            @cancel-order=${this.__cancel}
            @save-order=${this.__saveSale}
            @complete-order=${this.__showPayment}
          ></pos-order-form>
        </div>
        <div id="rt-pane" class="flex-layout-vertical">
          <pos-item-list class="flex-layout-flex" stand-alone @add-item=${this.__addItem}></pos-item-list>
        </div>
      </div>
      <insight-confirmation-dialog></insight-confirmation-dialog>
      <pos-pay-dialog stand-alone @sale-complete=${this.__saleComplete}></pos-pay-dialog>
    `;
  }

  get __css() {
    return `
    <style>
      :host #scr-ovl {
        background: var(--mdc-theme-surface);
        padding: 32px;
        min-width: 1366px;
        max-width: 1366px;
        min-height: 768px;
        max-height: 768px;
        column-gap: 16px;
        overflow: hidden;
        box-sizing: border-box;
      }
      :host #lt-pane {
        flex: 1.618;
        row-gap: 16px;
      }
      :host #rt-pane {
        flex: 1;
      }
    </style>
  `;
  }
}
window.customElements.define('pos-sale-view', PosSaleView);
