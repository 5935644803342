import {ViewBase, html} from '@insight/insight-common/components/insight-view-base.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import './pos-login-view.js';
import './pos-member-view.js';
import './pos-member-scan.js';
import './pos-menu-view.js';
import './pos-sale-view.js';

/* @imgex - none */
export class PosMainView extends ViewBase {
  static get Views() {
    return {
      Login: 0,
      Menu: 1,
      NewSale: 2,
      MemSearch: 3
    };
  }

  static get properties() {
    return {
      __currentView: {type: Number}
    };
  }

  constructor() {
    super();
    this.__currentView = PosMainView.Views.Login;
    window.addEventListener('message', this.__onWindowMsg.bind(this), false);
    window.parent.postMessage({type: 'get-auth'}, location.origin);
  }

  firstUpdated() {
    this._dispatchEvent('view-loaded', {title: 'POS Playground'});
  }

  __getView(view) {
    switch (view) {
      case PosMainView.Views.Login:
        return html`<pos-login-view class="pos-view" @menu-nav=${e => this.__navigate(e.detail)}></pos-login-view>`;
      case PosMainView.Views.Menu:
        return html`<pos-menu-view class="pos-view" @menu-nav=${e => this.__navigate(e.detail)}></pos-menu-view>`;
      case PosMainView.Views.NewSale:
        return html`<pos-sale-view
          class="pos-view"
          @menu-nav=${e => this.__navigate(e.detail)}
          @open-cash-drawer=${this.__openDrawer}
          @close-cash-drawer=${this.__closeDrawer}
          @print-receipt=${this.__printReceipt}
        ></pos-sale-view>`;
      case PosMainView.Views.MemSearch:
        return html`<pos-member-view class="pos-view" @menu-nav=${e => this.__navigate(e.detail)}></pos-member-view>`;
    }
  }

  __navigate(params) {
    this.__currentView = params.view;
    if (!!params.args) this._afterRender(() => this.__viewRef.handleArgs(params.args));
  }

  __showSnackbar(e) {
    this.__snackbarEl.showSnackbar(e.detail);
  }

  __onWindowMsg(e) {
    switch (e.data.type) {
      case 'dkt-scan':
        if (this.__currentView === PosMainView.Views.Login) return;
        Insight.state.fetchOneTime(
          'members',
          state => {
            this.__memScanEl.show(state.items[Math.floor(Math.random() * state.totalAvailable)], this.__currentView === PosMainView.Views.NewSale);
          },
          false,
          {
            filters: [{value: ['Anthony', 'Jason', 'Danny', 'Joe', 'Lawrence'][Math.floor(Math.random() * 5)]}],
            page: 1,
            limit: 200
          }
        );
        break;
      case 'cc-scan':
        if (this.__currentView !== PosMainView.Views.NewSale) return;
        this.__viewRef.handleArgs({ccScan: true});
        break;
      case 'drawer-closed':
        if (this.__currentView !== PosMainView.Views.NewSale) return;
        this.__viewRef.handleArgs({drawerClosed: true});
        break;
      case 'auth-info':
        if (e.data.token === 'mocked') return;
        window.Insight.auth = {accessToken: e.data.token};
        const payloadEncoded = window.Insight.auth.accessToken.split('.')[1];
        const payload = JSON.parse(atob(payloadEncoded));
        window.Insight.auth = {...window.Insight.auth, ...this.__fixUnderscores(payload)};
        break;
    }
  }

  __fixUnderscores(obj) {
    if (Array.isArray(obj)) {
      for (let i = obj.length - 1; i >= 0; i--) obj[i] = this.__fixUnderscores(obj[i]);
      return obj;
    } else if (typeof obj === 'object' && obj !== null) {
      const keyValues = Object.keys(obj).map(key => {
        return {
          [key.replace(/([_][a-z])/gi, $1 => $1.toUpperCase().replace('_', ''))]: this.__fixUnderscores(obj[key])
        };
      });
      return Object.assign({}, ...keyValues);
    }
    return obj;
  }

  __useScannedMember({detail: member}) {
    switch (this.__currentView) {
      case PosMainView.Views.Login:
        //shouldnt happen
        break;
      case PosMainView.Views.NewSale:
        this.__viewRef.handleArgs({member: member});
        break;
      case PosMainView.Views.Menu:
      case PosMainView.Views.MemSearch:
        this.__navigate({view: PosMainView.Views.NewSale, args: {member: member}});
        break;
    }
  }

  __openDrawer() {
    window.parent.postMessage({type: 'open-drawer'}, location.origin);
  }

  __closeDrawer() {
    window.parent.postMessage({type: 'close-drawer'}, location.origin);
  }

  __printReceipt() {
    window.parent.postMessage({type: 'print-receipt'}, location.origin);
  }

  get __viewRef() {
    return this._getElement('.pos-view');
  }

  get __snackbarEl() {
    return this._getElement('insight-snackbar');
  }

  get __memScanEl() {
    return this._getElement('pos-member-scan');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div class="pos-main" @show-snackbar=${this.__showSnackbar}>${this.__getView(this.__currentView)}</div>
      <insight-snackbar></insight-snackbar>
      <pos-member-scan @apply-scanned-member=${this.__useScannedMember}></pos-member-scan>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          min-width: 1366px;
          max-width: 1366px;
          min-height: 768px;
          max-height: 768px;
          --button-font-size: 16px;
        }
        :host insight-snackbar {
          display: block;
          transform: translateX(0);
          position: absolute;
          width: 100%;
          z-index: 1;
        }
    `;
  }
}
window.customElements.define('pos-main-view', PosMainView);
