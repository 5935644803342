import {InsightElement, html} from '../insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {createPopup} from '@picmo/popup-picker';
import {lightTheme, darkTheme} from 'picmo';
import '../button/insight-icon-button.js';

class InsightEmojiPicker extends InsightElement {
  close() {
    if (this.__picker) this.__picker.close();
  }
  firstUpdated() {
    const isDark = document.body.hasAttribute('dark-mode');
    const refEl = this._getElement('#emoji-picker');
    this.__picker = createPopup(
      {
        theme: isDark ? darkTheme : lightTheme
      },
      {
        referenceElement: refEl,
        triggerElement: refEl,
        hideOnClickOutside: false
      }
    );
    this.__boundListener = this.__emojiSelected.bind(this);
    this.__picker.addEventListener('emoji:select', this.__boundListener);
  }

  _destructor() {
    super._destructor();
    if (this.__picker) this.__picker.removeEventListener('emoji:select', this.__boundListener);
  }

  __emojiSelected(e) {
    this._dispatchEvent('emoji-selected', e.emoji);
  }

  __showPicker() {
    if (this.__picker) this.__picker.toggle();
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-icon-button id="emoji-picker" icon-name="😀" @click=${this.__showPicker}></insight-icon-button>
    `;
  }

  get __css() {
    return `
      <style>
        :host #emoji-picker {
          position: absolute;
          top: 2px;
          right: 4px;
          width: 22px;
          height: 22px;
          --icon-display: block;
          --icon-vertical-align: top;
          --icon-width: 18px;
          --icon-padding: 2px;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-emoji-picker', InsightEmojiPicker);
