import {default as createAppState} from '@insight/insight-common/state/insight-state-factory.js';
import {Mutex} from 'async-mutex';
import InsightAPI from '../api/insight-api.js';
import InsightSharedWebsocket from '../api/insight-shared-websocket.js';

const __insightApi = new InsightAPI();

const __sharedSocket = new InsightSharedWebsocket();
window.Insightsocket = __sharedSocket;

const __startActivitySubscription = async (callback, payload) => {
  await __sharedSocket.startSubscription(__insightApi, 'access', 'startActivitySubscription', callback, payload);
};

const __startActivitySubscriptionOld = async (callback, payload) => {
  await __sharedSocket.startSubscription(__insightApi, 'access', 'startActivitySubscriptionOld', callback, payload);
};

const __startNotificationSubscription = async (callback, payload) => {
  await __sharedSocket.startSubscription(__insightApi, 'notifications', 'startNotificationSubscription', callback, payload);
};

/************************************************************************************************/
/****  App State  *******************************************************************************/
/************************************************************************************************/

let __appState = createAppState();

const __initialize = () => {
  //shell
  __appState.registerFetch('locations', (...args) => __insightApi.invoke('shell', 'getLocations', ...args));
  __appState.registerFetch('locationDetails', (...args) => __insightApi.invoke('shell', 'getLocationDetails', ...args));
  __appState.registerCommand('changeLocation', (...args) => __insightApi.invoke('shell', 'changeLocation', ...args));
  __appState.registerCommand('askAQuestion', (...args) => __insightApi.invoke('shell', 'postAskAQuestion', ...args));

  //user
  __appState.registerCommand('loginComplete', (...args) => __insightApi.invoke('user', 'loginComplete', ...args));
  __appState.registerCommand('linkUser', (...args) => __insightApi.invoke('user', 'linkUser', ...args));
  __appState.registerFetch('userDetails', (...args) => __insightApi.invoke('user', 'getUserDetails', ...args));
  __appState.registerFetch('userDetailsWithLocations', (...args) => __insightApi.invoke('user', 'getUserDetailsWithLocations', ...args));
  __appState.registerFetch('userUpdates', (...args) => __insightApi.invoke('user', 'getUserUpdates', ...args));
  __appState.registerUpdate('userDetails', (...args) => __insightApi.invoke('user', 'updateUserDetails', ...args));
  __appState.registerUpdate('username', (...args) => __insightApi.invoke('user', 'updateUsername', ...args));
  __appState.registerUpdate('password', (...args) => __insightApi.invoke('user', 'updatePassword', ...args));
  __appState.registerFetch('userPermissions', (...args) => __insightApi.invoke('user', 'getUserPermissions', ...args));
  __appState.registerUpdate('userPermissions', (...args) => __insightApi.invoke('user', 'updateUserPermissions', ...args));
  __appState.registerFetch('userSubscriptions', (...args) => __insightApi.invoke('user', 'getUserSubscriptions', ...args));
  __appState.registerUpdate('userSubscription', (...args) => __insightApi.invoke('user', 'updateUserSubscription', ...args));
  __appState.registerFetch('commissionGroups', (...args) => __insightApi.invoke('user', 'getCommissionGroups', ...args));
  __appState.registerFetch('userAppointmentTypes', (...args) => __insightApi.invoke('user', 'getUserAppointmentTypes', ...args));
  __appState.registerCreate('userAppointmentType', (...args) => __insightApi.invoke('user', 'createUserAppointmentType', ...args));
  __appState.registerDelete('userAppointmentType', (...args) => __insightApi.invoke('user', 'deleteUserAppointmentType', ...args));
  __appState.registerFetch('userApptTimeSlots', (...args) => __insightApi.invoke('user', 'getUserApptTimeSlots', ...args));
  __appState.registerCreate('userApptTimeSlot', (...args) => __insightApi.invoke('user', 'createUserApptTimeSlot', ...args));
  __appState.registerDelete('userApptTimeSlot', (...args) => __insightApi.invoke('user', 'deleteUserApptTimeSlot', ...args));
  __appState.registerUpdate('userPreference', (...args) => __insightApi.invoke('user', 'updateUserPreference', ...args));
  __appState.registerCreate('user', (...args) => __insightApi.invoke('user', 'createUser', ...args));
  __appState.registerUpdate('user', (...args) => __insightApi.invoke('user', 'updateUser', ...args));
  __appState.registerFetch('userCommissions', (...args) => __insightApi.invoke('user', 'getUserCommissions', ...args));
  __appState.registerCreate('userCommissionAdj', (...args) => __insightApi.invoke('user', 'adjustUserCommission', ...args));

  //notifications
  __appState.registerFetch('recentNotifications', (...args) => __insightApi.invoke('notifications', 'getRecentNotifications', ...args));
  __appState.registerFetch('recentNotificationUpdates', __startNotificationSubscription.bind(this));

  //search
  __appState.registerFetch('search', (...args) => __insightApi.invoke('search', 'performSearch', ...args));

  //admin
  __appState.registerFetch('gymInsightTags', (...args) => __insightApi.invoke('admin', 'getGymInsightTags', ...args));
  __appState.registerFetch('gymInsightItems', (...args) => __insightApi.invoke('admin', 'getGymInsightItems', ...args));
  __appState.registerFetch('purchaseOrderItems', (...args) => __insightApi.invoke('admin', 'getPurchaseOrderItems', ...args));
  __appState.registerCreate('purchaseOrder', (...args) => __insightApi.invoke('admin', 'createPurchaseOrder', ...args));
  __appState.registerCommand('sendSystemMessage', (...args) => __insightApi.invoke('admin', 'sendSystemMessage', ...args));
  __appState.registerCommand('clearFeatureCache', (...args) => __insightApi.invoke('admin', 'clearFeatureCache', ...args));
  __appState.registerFetch('locationsRevenue', (...args) => __insightApi.invoke('admin', 'getLocationsRevenue', ...args));
  __appState.registerFetch('allMPAs', (...args) => __insightApi.invoke('admin', 'getAllMPAs', ...args));
  __appState.registerFetch('clientsLocations', (...args) => __insightApi.invoke('admin', 'getClientLocations', ...args));
  __appState.registerFetch('clientDetails', (...args) => __insightApi.invoke('admin', 'getClientDetails', ...args));
  __appState.registerFetch('clientUsers', (...args) => __insightApi.invoke('admin', 'getClientUsers', ...args));
  __appState.registerFetch('clientFeed', (...args) => __insightApi.invoke('admin', 'getClientFeed', ...args));
  __appState.registerFetch('clientEditDetails', (...args) => __insightApi.invoke('admin', 'getClientEditDetails', ...args));
  __appState.registerUpdate('clientEditDetails', (...args) => __insightApi.invoke('admin', 'updateClientLocation', ...args));
  __appState.registerFetch('clientLocationCharges', (...args) => __insightApi.invoke('admin', 'getClientLocationCharges', ...args));
  __appState.registerCommand('processLocationCharges', (...args) => __insightApi.invoke('admin', 'processClientLocationCharges', ...args));
  __appState.registerFetch('quotes', (...args) => __insightApi.invoke('admin', 'getQuotes', ...args));
  __appState.registerFetch('quote', (...args) => __insightApi.invoke('admin', 'getQuote', ...args));
  __appState.registerFetch('quoteCreators', (...args) => __insightApi.invoke('admin', 'getQuoteCreators', ...args));
  __appState.registerCreate('quote', (...args) => __insightApi.invoke('admin', 'createQuote', ...args));
  __appState.registerUpdate('quote', (...args) => __insightApi.invoke('admin', 'updateQuote', ...args));
  __appState.registerCommand('expireQuote', (...args) => __insightApi.invoke('admin', 'invalidateQuote', ...args));
  __appState.registerCommand('publishQuote', (...args) => __insightApi.invoke('admin', 'publishQuote', ...args));
  __appState.registerDelete('locationGateways', (...args) => __insightApi.invoke('admin', 'clearGateways', ...args));
  __appState.registerFetch('pricingPlans', (...args) => __insightApi.invoke('admin', 'getPricingPlans', ...args));
  __appState.registerFetch('chiDetails', (...args) => __insightApi.invoke('admin', 'getChiDetails', ...args));
  __appState.registerFetch('chiDetailsByDate', (...args) => __insightApi.invoke('admin', 'getChiDetailsByDate', ...args));

  //admin dashboard
  __appState.registerFetch('adminRevenue', (...args) => __insightApi.invoke('admin', 'getAdminRevenue', ...args));

  //client
  __appState.registerCreate('location', (...args) => __insightApi.invoke('client', 'createLocation', ...args));

  //location
  __appState.registerFetch('locationUsers', (...args) => __insightApi.invoke('location', 'getUsers', ...args));
  __appState.registerFetch('locationUserNames', (...args) => __insightApi.invoke('location', 'getUserNames', ...args));
  __appState.registerFetch('billingInfo', (...args) => __insightApi.invoke('location', 'getBillingInfo', ...args));
  __appState.registerFetch('billingHistory', (...args) => __insightApi.invoke('location', 'getBillingHistory', ...args));
  __appState.registerFetch('planHistory', (...args) => __insightApi.invoke('location', 'getPlanHistory', ...args));
  __appState.registerFetch('billingEstimate', (...args) => __insightApi.invoke('location', 'getBillingEstimate', ...args));
  __appState.registerUpdate('locationPaymentMethod', (...args) => __insightApi.invoke('location', 'updatePaymentMethod', ...args));
  __appState.registerFetch('locationDocuments', (...args) => __insightApi.invoke('location', 'getLocationDocuments', ...args));
  __appState.registerFetch('locationDocument', (...args) => __insightApi.invoke('location', 'getLocationDocument', ...args));
  __appState.registerCreate('locationDocument', (...args) => __insightApi.invoke('location', 'uploadLocationDocument', ...args));
  __appState.registerUpdate('locationDocument', (...args) => __insightApi.invoke('location', 'updateLocationDocument', ...args));
  __appState.registerDelete('locationDocument', (...args) => __insightApi.invoke('location', 'deleteLocationDocument', ...args));
  __appState.registerFetch('locationTags', (...args) => __insightApi.invoke('location', 'getLocationTags', ...args));
  __appState.registerCreate('locationTag', (...args) => __insightApi.invoke('location', 'addLocationTag', ...args));
  __appState.registerUpdate('locationTag', (...args) => __insightApi.invoke('location', 'updateLocationTag', ...args));
  __appState.registerDelete('locationTag', (...args) => __insightApi.invoke('location', 'deleteLocationTag', ...args));
  __appState.registerFetch('locationTaskSettings', (...args) => __insightApi.invoke('location', 'getTaskSettings', ...args));
  __appState.registerUpdate('locationTaskSettings', (...args) => __insightApi.invoke('location', 'updateTaskSettings', ...args));
  __appState.registerFetch('locationGateways', (...args) => __insightApi.invoke('location', 'getGateways', ...args));
  __appState.registerUpdate('locationGateway', (...args) => __insightApi.invoke('location', 'updateGateway', ...args));
  __appState.registerFetch('locationMpas', (...args) => __insightApi.invoke('location', 'getMerchantApps', ...args));
  __appState.registerFetch('locationMpa', (...args) => __insightApi.invoke('location', 'getMerchantApp', ...args));
  __appState.registerCreate('locationMpa', (...args) => __insightApi.invoke('location', 'createMerchantApp', ...args));
  __appState.registerDelete('locationMpa', (...args) => __insightApi.invoke('location', 'deleteMerchantApp', ...args));
  __appState.registerFetch('locationClientDetails', (...args) => __insightApi.invoke('location', 'getLocationClientDetails', ...args));
  __appState.registerFetch('locationGeneralInfo', (...args) => __insightApi.invoke('location', 'getGeneralInfo', ...args));
  __appState.registerUpdate('locationGeneralInfo', (...args) => __insightApi.invoke('location', 'updateGeneralInfo', ...args));
  __appState.registerUpdate('locationLogo', (...args) => __insightApi.invoke('location', 'updateLocationLogo', ...args));
  __appState.registerFetch('locationContactInfo', (...args) => __insightApi.invoke('location', 'getContactInfo', ...args));
  __appState.registerUpdate('locationContactInfo', (...args) => __insightApi.invoke('location', 'updateContactInfo', ...args));
  __appState.registerFetch('locationOptionsInfo', (...args) => __insightApi.invoke('location', 'getOptionsInfo', ...args));
  __appState.registerUpdate('locationOptionsInfo', (...args) => __insightApi.invoke('location', 'updateOptionsInfo', ...args));
  __appState.registerFetch('locationFeesInfo', (...args) => __insightApi.invoke('location', 'getFeesInfo', ...args));
  __appState.registerUpdate('locationFeesInfo', (...args) => __insightApi.invoke('location', 'updateFeesInfo', ...args));
  __appState.registerFetch('locationGoalsInfo', (...args) => __insightApi.invoke('location', 'getGoalsInfo', ...args));
  __appState.registerUpdate('locationGoalsInfo', (...args) => __insightApi.invoke('location', 'updateGoalsInfo', ...args));
  __appState.registerFetch('locationItems', (...args) => __insightApi.invoke('location', 'getLocationItems', ...args));
  __appState.registerCreate('locationItem', (...args) => __insightApi.invoke('location', 'addLocationItem', ...args));
  __appState.registerUpdate('locationItem', (...args) => __insightApi.invoke('location', 'updateLocationItem', ...args));
  __appState.registerDelete('locationItem', (...args) => __insightApi.invoke('location', 'deleteLocationItem', ...args));
  __appState.registerFetch('locationSystemItems', (...args) => __insightApi.invoke('location', 'getLocationSystemItems', ...args));
  __appState.registerFetch('locationSystemItem', (...args) => __insightApi.invoke('location', 'getLocationSystemItem', ...args));
  __appState.registerUpdate('locationSystemItemTaxRate', (...args) => __insightApi.invoke('location', 'changeSystemItemTaxRate', ...args));
  __appState.registerDelete('locationSystemItemTaxRate', (...args) => __insightApi.invoke('location', 'removeSystemItemTaxRate', ...args));
  __appState.registerFetch('locationTaxRates', (...args) => __insightApi.invoke('location', 'getLocationTaxRates', ...args));
  __appState.registerCreate('locationTaxRate', (...args) => __insightApi.invoke('location', 'addLocationTaxRate', ...args));
  __appState.registerUpdate('locationTaxRate', (...args) => __insightApi.invoke('location', 'updateLocationTaxRate', ...args));
  __appState.registerDelete('locationTaxRate', (...args) => __insightApi.invoke('location', 'deleteLocationTaxRate', ...args));
  __appState.registerFetch('locationTaxDue', (...args) => __insightApi.invoke('location', 'getLocationTaxDue', ...args));
  __appState.registerCreate('locationTaxPayment', (...args) => __insightApi.invoke('location', 'addLocationTaxPayment', ...args));
  __appState.registerCreate('locationTaxAdj', (...args) => __insightApi.invoke('location', 'addLocationTaxAdjustment', ...args));
  __appState.registerFetch('locationApps', (...args) => __insightApi.invoke('location', 'getLocationApps', ...args));
  __appState.registerCommand('toggleAppAuth', (...args) => __insightApi.invoke('location', 'toggleAppAuthorization', ...args));
  __appState.registerDelete('appAuthorization', (...args) => __insightApi.invoke('location', 'deleteAppAuthorization', ...args));
  __appState.registerFetch('mailchimpSettings', (...args) => __insightApi.invoke('location', 'getMailchimpSettings', ...args));
  __appState.registerFetch('mailchimpLists', (...args) => __insightApi.invoke('location', 'getMailchimpLists', ...args));
  __appState.registerCommand('linkMailchimp', (...args) => __insightApi.invoke('location', 'linkMailchimp', ...args));
  __appState.registerCommand('unlinkMailchimp', (...args) => __insightApi.invoke('location', 'unlinkMailchimp', ...args));
  __appState.registerFetch('messageOptions', (...args) => __insightApi.invoke('location', 'getMessageOptions', ...args));
  __appState.registerUpdate('messageOptions', (...args) => __insightApi.invoke('location', 'updateMessageOptions', ...args));
  __appState.registerFetch('agreementLogo', (...args) => __insightApi.invoke('location', 'getAgreementLogo', ...args));
  __appState.registerUpdate('agreementLogo', (...args) => __insightApi.invoke('location', 'updateAgreementLogo', ...args));
  __appState.registerUpdate('delinquentLocation', (...args) => __insightApi.invoke('location', 'delinquentLocationUpdate', ...args));
  __appState.registerFetch('locationPlans', (...args) => __insightApi.invoke('location', 'getLocationPlans', ...args));
  __appState.registerUpdate('locationPlan', (...args) => __insightApi.invoke('location', 'upgradeLocationPlan', ...args));
  __appState.registerFetch('customerQuote', (...args) => __insightApi.invoke('location', 'getCustomerQuote', ...args));
  __appState.registerCommand('customerQuotePurchase', (...args) => __insightApi.invoke('location', 'customerQuotePurchase', ...args));
  __appState.registerCommand('planContact', (...args) => __insightApi.invoke('location', 'contactSalesAboutPlans', ...args));

  //roles
  __appState.registerFetch('userRoles', (...args) => __insightApi.invoke('roles', 'getRoles', ...args));
  __appState.registerFetch('userRole', (...args) => __insightApi.invoke('roles', 'getRole', ...args));
  __appState.registerCreate('userRole', (...args) => __insightApi.invoke('roles', 'createRole', ...args));
  __appState.registerUpdate('userRole', (...args) => __insightApi.invoke('roles', 'updateRole', ...args));
  __appState.registerDelete('userRole', (...args) => __insightApi.invoke('roles', 'deleteRole', ...args));
  __appState.registerFetch('userRoleSet', (...args) => __insightApi.invoke('roles', 'getRoleSet', ...args));
  __appState.registerUpdate('userRoleSet', (...args) => __insightApi.invoke('roles', 'updateRoleSet', ...args));
  __appState.registerDelete('userRoleSet', (...args) => __insightApi.invoke('roles', 'deleteRoleSet', ...args));
  __appState.registerFetch('currentUserRoleSet', (...args) => __insightApi.invoke('roles', 'getUserRoleSet', ...args));

  //groups
  __appState.registerFetch('userGroups', (...args) => __insightApi.invoke('groups', 'getGroups', ...args));
  __appState.registerCreate('userGroup', (...args) => __insightApi.invoke('groups', 'createGroup', ...args));
  __appState.registerUpdate('userGroup', (...args) => __insightApi.invoke('groups', 'updateGroup', ...args));
  __appState.registerDelete('userGroup', (...args) => __insightApi.invoke('groups', 'deleteGroup', ...args));
  __appState.registerFetch('userGroupRules', (...args) => __insightApi.invoke('groups', 'getGroupRules', ...args));
  __appState.registerDelete('userGroupRule', (...args) => __insightApi.invoke('groups', 'deleteGroupRule', ...args));
  __appState.registerUpdate('userGroupRule', (...args) => __insightApi.invoke('groups', 'updateGroupRule', ...args));

  //applications
  __appState.registerFetch('credentialFormats', (...args) => __insightApi.invoke('location', 'getCredentialFormats', ...args));
  __appState.registerFetch('appointmentTypes', (...args) => __insightApi.invoke('location', 'getAppointmentTypes', ...args));
  __appState.registerCreate('appointmentType', (...args) => __insightApi.invoke('location', 'createAppointmentType', ...args));
  __appState.registerUpdate('appointmentType', (...args) => __insightApi.invoke('location', 'updateAppointmentType', ...args));
  __appState.registerDelete('appointmentType', (...args) => __insightApi.invoke('location', 'deleteAppointmentType', ...args));
  __appState.registerFetch('calendarWidgets', (...args) => __insightApi.invoke('location', 'getCalendarWidgets', ...args));
  __appState.registerCreate('calendarWidget', (...args) => __insightApi.invoke('location', 'createCalendarWidget', ...args));
  __appState.registerUpdate('calendarWidget', (...args) => __insightApi.invoke('location', 'updateCalendarWidget', ...args));
  __appState.registerDelete('calendarWidget', (...args) => __insightApi.invoke('location', 'deleteCalendarWidget', ...args));
  __appState.registerFetch('membershipWidgets', (...args) => __insightApi.invoke('location', 'getMembershipWidgets', ...args));
  __appState.registerCreate('membershipWidget', (...args) => __insightApi.invoke('location', 'createMembershipWidget', ...args));
  __appState.registerUpdate('membershipWidget', (...args) => __insightApi.invoke('location', 'updateMembershipWidget', ...args));
  __appState.registerDelete('membershipWidget', (...args) => __insightApi.invoke('location', 'deleteMembershipWidget', ...args));
  __appState.registerFetch('appointmentWidgets', (...args) => __insightApi.invoke('location', 'getAppointmentWidgets', ...args));
  __appState.registerCreate('appointmentWidget', (...args) => __insightApi.invoke('location', 'createAppointmentWidget', ...args));
  __appState.registerUpdate('appointmentWidget', (...args) => __insightApi.invoke('location', 'updateAppointmentWidget', ...args));
  __appState.registerDelete('appointmentWidget', (...args) => __insightApi.invoke('location', 'deleteAppointmentWidget', ...args));
  __appState.registerFetch('guruSettings', (...args) => __insightApi.invoke('location', 'getGuruSettings', ...args));
  __appState.registerUpdate('guruSettings', (...args) => __insightApi.invoke('location', 'updateGuruSettings', ...args));
  __appState.registerUpdate('guruBackground', (...args) => __insightApi.invoke('location', 'uploadGuruBackground', ...args));
  __appState.registerDelete('guruBackground', (...args) => __insightApi.invoke('location', 'removeGuruBackground', ...args));
  __appState.registerUpdate('memberAppSettings', (...args) => __insightApi.invoke('location', 'updateMemberAppSettings', ...args));
  __appState.registerFetch('locationDevices', (...args) => __insightApi.invoke('location', 'getDevices', ...args));
  __appState.registerDelete('locationDevice', (...args) => __insightApi.invoke('location', 'deleteDevice', ...args));

  //accounts
  __appState.registerFetch('accountDetails', (...args) => __insightApi.invoke('accounts', 'getAccountDetails', ...args));
  __appState.registerFetch('accountInfo', (...args) => __insightApi.invoke('accounts', 'getAccountInfo', ...args));
  __appState.registerFetch('accountTasks', (...args) => __insightApi.invoke('accounts', 'getAccountTasks', ...args));
  __appState.registerFetch('accountContactInfo', (...args) => __insightApi.invoke('accounts', 'getAccountContactInfo', ...args));
  __appState.registerFetch('accountPaymentInfo', (...args) => __insightApi.invoke('accounts', 'getAccountPaymentInfo', ...args));
  __appState.registerFetch('accountInsightInfo', (...args) => __insightApi.invoke('accounts', 'getAccountInsightInfo', ...args));
  __appState.registerFetch('accountSubledger', (...args) => __insightApi.invoke('accounts', 'getAccountSubledger', ...args));
  __appState.registerFetch('accountInsightPricing', (...args) => __insightApi.invoke('accounts', 'getAccountInsightPricing', ...args));
  __appState.registerFetch('accountSchedPayments', (...args) => __insightApi.invoke('accounts', 'getSchedPayments', ...args));
  __appState.registerFetch('accountMembers', (...args) => __insightApi.invoke('accounts', 'getAccountMembers', ...args));
  __appState.registerFetch('accountStatement', (...args) => __insightApi.invoke('accounts', 'getAccountStatement', ...args));
  __appState.registerFetch('accountFullStatement', (...args) => __insightApi.invoke('accounts', 'getAccountFullStatement', ...args));
  __appState.registerFetch('accountAgreements', (...args) => __insightApi.invoke('accounts', 'getAgreements', ...args));
  __appState.registerFetch('accountFiles', (...args) => __insightApi.invoke('accounts', 'getAccountFiles', ...args));
  __appState.registerFetch('accountMessages', (...args) => __insightApi.invoke('accounts', 'getAccountMessages', ...args));
  __appState.registerFetch('accountHistory', (...args) => __insightApi.invoke('accounts', 'getAccountHistory', ...args));
  __appState.registerFetch('accountSummary', (...args) => __insightApi.invoke('accounts', 'getAccountSummary', ...args));
  __appState.registerFetch('accountSchedPayment', (...args) => __insightApi.invoke('accounts', 'getSchedPayment', ...args));
  __appState.registerCreate('accountSchedPayment', (...args) => __insightApi.invoke('accounts', 'createSchedPayment', ...args));
  __appState.registerUpdate('accountSchedPayment', (...args) => __insightApi.invoke('accounts', 'updateSchedPayment', ...args));
  __appState.registerDelete('accountSchedPayment', (...args) => __insightApi.invoke('accounts', 'deleteSchedPayment', ...args));
  __appState.registerCreate('accountNote', (...args) => __insightApi.invoke('accounts', 'createAccountNote', ...args));
  __appState.registerCreate('accountAlert', (...args) => __insightApi.invoke('accounts', 'createAccountAlert', ...args));
  __appState.registerDelete('accountAlert', (...args) => __insightApi.invoke('accounts', 'removeAccountAlert', ...args));
  __appState.registerCommand('checkinAccount', (...args) => __insightApi.invoke('accounts', 'checkinAccount', ...args));
  __appState.registerDelete('accountFlag', (...args) => __insightApi.invoke('accounts', 'deleteAccountFlag', ...args));
  __appState.registerCreate('accountTag', (...args) => __insightApi.invoke('accounts', 'createAccountTag', ...args));
  __appState.registerDelete('accountTag', (...args) => __insightApi.invoke('accounts', 'deleteAccountTag', ...args));
  __appState.registerDelete('accountAgreement', (...args) => __insightApi.invoke('accounts', 'deleteAgreement', ...args));
  __appState.registerCreate('accountFile', (...args) => __insightApi.invoke('accounts', 'uploadFile', ...args));
  __appState.registerDelete('accountFile', (...args) => __insightApi.invoke('accounts', 'deleteFile', ...args));
  __appState.registerCommand('sendAgreementEmail', (...args) => __insightApi.invoke('accounts', 'emailAgreement', ...args));
  __appState.registerCommand('sendAccountFile', (...args) => __insightApi.invoke('accounts', 'emailFile', ...args));
  __appState.registerUpdate('accountTransaction', (...args) => __insightApi.invoke('accounts', 'updateAccountTransaction', ...args));
  __appState.registerDelete('accountTransaction', (...args) => __insightApi.invoke('accounts', 'deleteAccountTransaction', ...args));
  __appState.registerCreate('guestAccount', (...args) => __insightApi.invoke('accounts', 'createGuestAccount', ...args));
  __appState.registerCreate('account', (...args) => __insightApi.invoke('accounts', 'createAccount', ...args));
  __appState.registerUpdate('account', (...args) => __insightApi.invoke('accounts', 'updateAccount', ...args));
  __appState.registerDelete('account', (...args) => __insightApi.invoke('accounts', 'deleteAccount', ...args));
  __appState.registerCommand('finalizeAccount', (...args) => __insightApi.invoke('accounts', 'finalizeAccount', ...args));
  __appState.registerCommand('cloneAccount', (...args) => __insightApi.invoke('accounts', 'cloneAccount', ...args));
  __appState.registerCreate('accountPaymentMethod', (...args) => __insightApi.invoke('accounts', 'addPaymentMethod', ...args));
  __appState.registerDelete('accountPaymentMethod', (...args) => __insightApi.invoke('accounts', 'deletePaymentMethod', ...args));
  __appState.registerFetch('accountSsn', (...args) => __insightApi.invoke('accounts', 'getAccountSsn', ...args));
  __appState.registerFetch('accountPan', (...args) => __insightApi.invoke('accounts', 'getAccountPan', ...args));
  __appState.registerCommand('sendAccountMessage', (...args) => __insightApi.invoke('accounts', 'sendMessage', ...args));
  __appState.registerCommand('freezeAccount', (...args) => __insightApi.invoke('accounts', 'freezeAccount', ...args));
  __appState.registerCommand('unfreezeAccount', (...args) => __insightApi.invoke('accounts', 'unfreezeAccount', ...args));
  __appState.registerCommand('cancelAccount', (...args) => __insightApi.invoke('accounts', 'cancelAccount', ...args));
  __appState.registerCommand('uncancelAccount', (...args) => __insightApi.invoke('accounts', 'uncancelAccount', ...args));
  __appState.registerCommand('toCollections', (...args) => __insightApi.invoke('accounts', 'toCollections', ...args));
  __appState.registerCommand('updateGreen', (...args) => __insightApi.invoke('accounts', 'updateGreen', ...args));
  __appState.registerUpdate('billingAddress', (...args) => __insightApi.invoke('accounts', 'updateBillingAddress', ...args));
  __appState.registerUpdate('paymentExpiration', (...args) => __insightApi.invoke('accounts', 'updateCCExpiration', ...args));
  __appState.registerFetch('accountAgreementForSign', (...args) => __insightApi.invoke('accounts', 'getAgreementForSign', ...args));
  __appState.registerCreate('accountAgreement', (...args) => __insightApi.invoke('accounts', 'createAgreement', ...args));
  __appState.registerCommand('signAgreement', (...args) => __insightApi.invoke('accounts', 'signAgreement', ...args));
  __appState.registerCommand('signDocument', (...args) => __insightApi.invoke('accounts', 'signDocument', ...args));
  __appState.registerFetch('accountAgreement', (...args) => __insightApi.invoke('accounts', 'getAccountAgreement', ...args));
  __appState.registerFetch('accountDocument', (...args) => __insightApi.invoke('accounts', 'getAccountDocument', ...args));
  __appState.registerUpdate('accountDocument', (...args) => __insightApi.invoke('accounts', 'updateAccountDocument', ...args));
  __appState.registerFetch('accountDocumentDetails', (...args) => __insightApi.invoke('accounts', 'getAccountDocumentDetails', ...args));
  __appState.registerUpdate('accountBalance', (...args) => __insightApi.invoke('accounts', 'updateBalance', ...args));
  __appState.registerCreate('accountCredit', (...args) => __insightApi.invoke('accounts', 'addAccountCredit', ...args));
  __appState.registerFetch('transactionDetails', (...args) => __insightApi.invoke('accounts', 'getTransactionDetails', ...args));
  __appState.registerFetch('accountAgreementData', (...args) => __insightApi.invoke('accounts', 'getAgreementData', ...args));
  __appState.registerFetch('accountAgreementPreview', (...args) => __insightApi.invoke('accounts', 'getAgreementPreview', ...args));
  __appState.registerFetch('accountFilePreview', (...args) => __insightApi.invoke('accounts', 'getFilePreview', ...args));
  __appState.registerCommand('refundTransaction', (...args) => __insightApi.invoke('accounts', 'refundTransaction', ...args));
  __appState.registerCommand('voidTransaction', (...args) => __insightApi.invoke('accounts', 'voidTransaction', ...args));
  __appState.registerCommand('undoTransaction', (...args) => __insightApi.invoke('accounts', 'undoTransaction', ...args));
  __appState.registerFetch('accountSMSNumbers', (...args) => __insightApi.invoke('accounts', 'getAccountSMSNumbers', ...args));
  __appState.registerFetch('accountEmails', (...args) => __insightApi.invoke('accounts', 'getAccountEmails', ...args));
  __appState.registerCreate('accountSale', (...args) => __insightApi.invoke('accounts', 'addAccountSale', ...args));

  //members
  __appState.registerFetch('members', (...args) => __insightApi.invoke('members', 'getMembers', ...args));
  __appState.registerFetch('memberDetails', (...args) => __insightApi.invoke('members', 'getMemberDetails', ...args));
  __appState.registerFetch('memberLogins', (...args) => __insightApi.invoke('members', 'getMemberLogins', ...args));
  __appState.registerUpdate('memberLogins', (...args) => __insightApi.invoke('members', 'updateMemberLogins', ...args));
  __appState.registerUpdate('member', (...args) => __insightApi.invoke('members', 'updateMember', ...args));
  __appState.registerCreate('member', (...args) => __insightApi.invoke('members', 'addMember', ...args));
  __appState.registerFetch('memberHistory', (...args) => __insightApi.invoke('members', 'getMemberHistory', ...args));
  __appState.registerFetch('memberCredentials', (...args) => __insightApi.invoke('members', 'getMemberCredentials', ...args));
  __appState.registerFetch('memberVisits', (...args) => __insightApi.invoke('members', 'getMemberVisits', ...args));
  __appState.registerFetch('memberAttendance', (...args) => __insightApi.invoke('members', 'getMemberAttendance', ...args));
  __appState.registerFetch('memberAddons', (...args) => __insightApi.invoke('members', 'getMemberAddons', ...args));
  __appState.registerFetch('memberPtAddons', (...args) => __insightApi.invoke('members', 'getMemberPtAddons', ...args));
  __appState.registerFetch('memberMessages', (...args) => __insightApi.invoke('members', 'getMemberMessages', ...args));
  __appState.registerDelete('member', (...args) => __insightApi.invoke('members', 'deleteMember', ...args));
  __appState.registerDelete('memberCredential', (...args) => __insightApi.invoke('members', 'deleteMemberCredential', ...args));
  __appState.registerCreate('memberCredential', ['memberCredentials'], (...args) => __insightApi.invoke('members', 'addCredentialToMember', ...args));
  __appState.registerUpdate('memberCredential', (...args) => __insightApi.invoke('members', 'updateMemberCredential', ...args));
  __appState.registerCommand('checkInAddon', (...args) => __insightApi.invoke('members', 'checkInAddon', ...args));
  __appState.registerCreate('addonSale', (...args) => __insightApi.invoke('members', 'chargeMemberAddon', ...args));
  __appState.registerCreate('memberAddon', (...args) => __insightApi.invoke('members', 'createMemberAddon', ...args));
  __appState.registerUpdate('memberAddon', (...args) => __insightApi.invoke('members', 'updateMemberAddon', ...args));
  __appState.registerDelete('memberAddon', (...args) => __insightApi.invoke('members', 'deleteMemberAddon', ...args));
  __appState.registerFetch('memberAddonSessions', (...args) => __insightApi.invoke('members', 'getMemberAddonSessions', ...args));
  __appState.registerDelete('memberAddonSession', (...args) => __insightApi.invoke('members', 'deleteMemberAddonSession', ...args));
  __appState.registerCommand('checkInPtAddon', (...args) => __insightApi.invoke('members', 'checkInPtAddon', ...args));
  __appState.registerCreate('memberPtAddon', (...args) => __insightApi.invoke('members', 'createMemberPtAddon', ...args));
  __appState.registerUpdate('memberPtAddon', (...args) => __insightApi.invoke('members', 'updateMemberPtAddon', ...args));
  __appState.registerDelete('memberPtAddon', (...args) => __insightApi.invoke('members', 'deleteMemberPtAddon', ...args));
  __appState.registerFetch('memberPtAddonSessions', (...args) => __insightApi.invoke('members', 'getMemberPtAddonSessions', ...args));
  __appState.registerDelete('memberPtAddonSession', (...args) => __insightApi.invoke('members', 'deleteMemberPtAddonSession', ...args));
  __appState.registerUpdate('memberPhoto', (...args) => __insightApi.invoke('members', 'updateMemberPhoto', ...args));
  __appState.registerCommand('mergeMember', (...args) => __insightApi.invoke('members', 'mergeMember', ...args));
  __appState.registerCommand('moveMember', (...args) => __insightApi.invoke('members', 'moveMember', ...args));
  __appState.registerCreate('memberNote', (...args) => __insightApi.invoke('members', 'createMemberNote', ...args));
  __appState.registerCommand('setMemberPrimary', (...args) => __insightApi.invoke('members', 'setMemberPrimary', ...args));
  __appState.registerDelete('memberLoginLink', (...args) => __insightApi.invoke('members', 'unlinkLogin', ...args));
  __appState.registerCommand('sendMemberMessage', (...args) => __insightApi.invoke('members', 'sendMessage', ...args));
  __appState.registerCommand('sendMemberInvite', (...args) => __insightApi.invoke('members', 'sendMemberInvite', ...args));
  __appState.registerFetch('memberIdentity', (...args) => __insightApi.invoke('members', 'getMemberIdentity', ...args));
  __appState.registerFetch('activePTAddons', (...args) => __insightApi.invoke('members', 'getMemberActivePTAddons', ...args));
  __appState.registerFetch('memberSMSNumbers', (...args) => __insightApi.invoke('members', 'getMemberSMSNumbers', ...args));
  __appState.registerFetch('memberEmails', (...args) => __insightApi.invoke('members', 'getMemberEmails', ...args));

  //POS
  __appState.registerFetch('posItems', (...args) => __insightApi.invoke('pos', 'getPosItems', ...args));
  __appState.registerFetch('posItemCategories', (...args) => __insightApi.invoke('pos', 'getPosItemCategories', ...args));
  __appState.registerFetch('memberPosHistory', (...args) => __insightApi.invoke('pos', 'getMemberPosHistory', ...args));

  //memberships
  __appState.registerFetch('memberships', (...args) => __insightApi.invoke('memberships', 'getMemberships', ...args));
  __appState.registerFetch('membership', (...args) => __insightApi.invoke('memberships', 'getMembership', ...args));
  __appState.registerFetch('pricingPlanLink', (...args) => __insightApi.invoke('memberships', 'getPricingPlanLink', ...args));
  __appState.registerFetch('pricingPlan', (...args) => __insightApi.invoke('memberships', 'getPricingPlanDetails', ...args));
  __appState.registerCreate('membership', (...args) => __insightApi.invoke('memberships', 'createMembership', ...args));
  __appState.registerUpdate('membership', (...args) => __insightApi.invoke('memberships', 'updateMembership', ...args));
  __appState.registerDelete('membership', (...args) => __insightApi.invoke('memberships', 'deleteMembership', ...args));
  __appState.registerCommand('moveMembership', (...args) => __insightApi.invoke('memberships', 'moveMembership', ...args));

  //training
  __appState.registerFetch('trainingClients', (...args) => __insightApi.invoke('training', 'getTrainingClients', ...args));
  __appState.registerFetch('trainingAppts', (...args) => __insightApi.invoke('training', 'getMemberTrainingAppts', ...args));

  //access
  __appState.registerFetch('recentActivity', (...args) => __insightApi.invoke('access', 'getRecentActivity', ...args));
  __appState.registerFetch('recentActivityUpdates', __startActivitySubscription.bind(this));

  //calendar
  __appState.registerFetch('calendarOptions', (...args) => __insightApi.invoke('calendar', 'getCalendarOptions', ...args));
  __appState.registerFetch('calendarData', (...args) => __insightApi.invoke('calendar', 'getCalendarData', ...args));
  __appState.registerCreate('event', (...args) => __insightApi.invoke('calendar', 'createEvent', ...args));
  __appState.registerFetch('event', (...args) => __insightApi.invoke('calendar', 'getEvent', ...args));
  __appState.registerUpdate('event', (...args) => __insightApi.invoke('calendar', 'updateEvent', ...args));
  __appState.registerDelete('event', (...args) => __insightApi.invoke('calendar', 'cancelEvent', ...args));
  __appState.registerFetch('eventReservations', (...args) => __insightApi.invoke('calendar', 'getEventReservations', ...args));
  __appState.registerCreate('eventReservation', (...args) => __insightApi.invoke('calendar', 'createReservation', ...args));
  __appState.registerDelete('eventReservation', (...args) => __insightApi.invoke('calendar', 'cancelReservation', ...args));
  __appState.registerFetch('scheduleReservations', (...args) => __insightApi.invoke('calendar', 'getScheduleReservations', ...args));
  __appState.registerCommand('sendMessageToClassReservations', (...args) => __insightApi.invoke('calendar', 'sendMessageToClassReservations', ...args));
  __appState.registerUpdate('eventAttendance', (...args) => __insightApi.invoke('calendar', 'updateEventAttendance', ...args));

  //reports
  __appState.registerFetch('report', (...args) => __insightApi.invoke('reports', 'getReport', ...args));
  __appState.registerCommand('runBulkAction', (...args) => __insightApi.invoke('reports', 'runBulkAction', ...args));

  //tasks
  __appState.registerFetch('taskCounts', (...args) => __insightApi.invoke('tasks', 'getTaskCounts', ...args));
  __appState.registerFetch('memberTasks', (...args) => __insightApi.invoke('tasks', 'getMemberTasks', ...args));
  __appState.registerFetch('messages', (...args) => __insightApi.invoke('tasks', 'getMessages', ...args));
  __appState.registerFetch('tasks', (...args) => __insightApi.invoke('tasks', 'getTasks', ...args));
  __appState.registerDelete('tasks', (...args) => __insightApi.invoke('tasks', 'deleteAllTasks', ...args));
  __appState.registerCreate('task', (...args) => __insightApi.invoke('tasks', 'createTask', ...args));
  __appState.registerUpdate('task', (...args) => __insightApi.invoke('tasks', 'updateTask', ...args));
  __appState.registerDelete('task', (...args) => __insightApi.invoke('tasks', 'deleteTask', ...args));
  __appState.registerCommand('completeTask', (...args) => __insightApi.invoke('tasks', 'completeTask', ...args));
  __appState.registerCommand('completeTaskAsMsg', (...args) => __insightApi.invoke('tasks', 'completeTaskAsMessage', ...args));
  __appState.registerCommand('replyToMessage', (...args) => __insightApi.invoke('tasks', 'replyToMessage', ...args));
  __appState.registerCommand('markMessageRead', (...args) => __insightApi.invoke('tasks', 'markMessageRead', ...args));

  //devices
  __appState.registerFetch('devices', (...args) => __insightApi.invoke('device', 'getDevices', ...args));
  __appState.registerUpdate('device', (...args) => __insightApi.invoke('device', 'updateDevice', ...args));
  __appState.registerDelete('device', (...args) => __insightApi.invoke('device', 'deleteDevice', ...args));
  __appState.registerFetch('mqttMessageHandler', (...args) => __insightApi.invoke('device', 'addMqttMessageListener', ...args));
  __appState.registerDelete('mqttMessageHandler', (...args) => __insightApi.invoke('device', 'removeMqttMessageListener', ...args));
  __appState.registerCreate('statusSubscriptions', (...args) => __insightApi.invoke('device', 'addStatusSubscriptions', ...args));
  __appState.registerFetch('deviceStatuses', (...args) => __insightApi.invoke('device', 'requestDeviceStatuses', ...args));
  __appState.registerCommand('mqttReconnect', (...args) => __insightApi.invoke('device', 'reconnect', ...args));
  __appState.registerCommand('unlockDoor', (...args) => __insightApi.invoke('device', 'unlockDoor', ...args));
  __appState.registerCommand('setInterfaceStatus', (...args) => __insightApi.invoke('device', 'setInterfaceStatus', ...args));

  return __appState;
};

export default __initialize;
