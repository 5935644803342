import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {i18nMoney} from '@insight/insight-common/i18n/i18n-money.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/ui/insight-icon.js';

/* @imgex - none */
class PosCardOnFile extends InsightElement {
  static get properties() {
    return {
      balanceDue: {type: Number},
      __txnComplete: {type: Boolean},
      __txnError: {type: Boolean}
    };
  }

  firstUpdated() {
    setTimeout(() => {
      this.__txnComplete = true;
      // this.__txnError = true;
      this._dispatchEvent('print-receipt');
    }, 2000);
  }

  __getSubtitle(cpl, err) {
    if (cpl) return this._i18n('account:addSale.paySucc');
    else if (err) return this._i18n('account:addSale.payDecl');
    return this._i18n('account:addSale.payProc');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
      <div class="flex-layout-vertical flex-layout-center flex-layout-evenly-justified" style="height:300px">
        <div class="typo-headline" style="text-align:center"><b>${this.__getSubtitle(this.__txnComplete, this.__txnError)}</b></div>
        <img id="pay-img" src="/images/paypad.png" style="height:200px" />
        <div
          class="graphic-loader insight-loader"
          ?hidden=${this.__txnComplete || this.__txnError}
          style=${window.Testophobia ? 'border-top-color:#ccc;' : ''}
        ></div>
        <div class="success-check${this.__txnComplete ? ' grow-in' : ''}">
          <div class="success-check-bg"></div>
          <insight-icon icon-name="check_circle"></insight-icon>
        </div>
        <div class="error-x${this.__txnError ? ' grow-in' : ''}">
          <div class="error-x-bg"></div>
          <insight-icon icon-name="error"></insight-icon>
        </div>
      </div>
      ${this.__txnComplete
        ? html`<div id="pay-rcpt">
              <div style="margin-bottom:8px">
                <div class="typo-title" style="margin-right:8px"><strong>${this._i18n('common:amount')}</strong></div>
                <div class="typo-title" style="font-weight:400">${i18nMoney.format(this.balanceDue)}</div>
              </div>
              <div style="margin-bottom:8px">
                <div class="typo-title" style="margin-right:8px"><strong>${this._i18n('account:addSale.txnId')}</strong></div>
                <div class="typo-title" style="font-weight:400">123456789</div>
              </div>
              <div>
                <div class="typo-title" style="margin-right:8px"><strong>${this._i18n('account:addSale.authCode')}</strong></div>
                <div class="typo-title" style="font-weight:400">555666777</div>
              </div>
            </div>
            <div id="rec-btm"><img src="/images/playground/rec-btm-2.png" /></div>
            <div style="text-align:center">
              <insight-button id="btn-confirm" success contained @click=${() => this._dispatchEvent('card-on-file-complete', true)}
                >${this._i18n('common:finish')}</insight-button
              >
            </div>`
        : ''}
      ${this.__txnError
        ? html` <div style="text-align:center">
            <insight-button id="btn-confirm" contained @click=${() => this._dispatchEvent('card-on-file-complete', false)}
              >${this._i18n('components:common.close')}</insight-button
            >
          </div>`
        : ''}
    `;
  }

  get __css() {
    return `
      <style>
        :host #pay-img {
          margin-left: 8px;
        }
        :host .graphic-loader {
          position: absolute;
          top: 184px;
          border: 4px solid white;
          border-top-color: var(--mdc-theme-primary);
        }
        :host .success-check,
        :host .error-x {
          opacity: 0;
          position: absolute;
          top: 178px;
        }
        :host .success-check-bg,
        :host .error-x-bg {
          background-color: white;
          width: 36px;
          height: 36px;
          margin: 6px;
          border-radius: 50%;
        }
        :host .success-check insight-icon,
        :host .error-x insight-icon {
          position: absolute;
          left: 0;
          top: 0;
          --icon-width: 48px;
        }
        :host .success-check insight-icon {
          --icon-color: var(--mdc-theme-success);
        }
        :host .error-x insight-icon {
          --icon-color: var(--mdc-theme-error);
        }
        :host #pay-rcpt {
          border: 1px solid #ccc;
          width: 200px;
          padding: 8px 16px 16px;
          margin: -16px auto 32px;
        }
        :host #rec-btm {
          position: absolute;
          left: 205px;
          bottom: 80px;
        }
        :host([stand-alone]) #rec-btm {
          bottom: 90px;
        }
        :host #rec-btm img {
          width: 231px;
        }
        :host #btn-confirm {
          margin-bottom: 24px;
          display: block;
        }
        :host([stand-alone]) #btn-confirm {
          --button-height: 48px;
          --button-min-width: 120px;
        }
        :host([stand-alone]) #btn-confirm {
          --button-font-size: 24px;
        }
        @media only screen and (max-width: 680px) {
          :host #rec-btm {
            left: calc((95vw - 231px) / 2);
          }
        }
        @keyframes grow-in {
          from,
          33%,
          66%,
          to {
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          }
          0% {
            opacity: 0;
            transform: scale3d(0.3, 0.3, 0.3);
          }
          33% {
            transform: scale3d(1.1, 1.1, 1.1);
          }
          66% {
            transform: scale3d(0.9, 0.9, 0.9);
          }
          to {
            opacity: 1;
            transform: scale3d(1, 1, 1);
          }
        }
        .grow-in {
          animation-duration: 0.5s;
          animation-fill-mode: both;
          animation-name: grow-in;
        }
      </style>
  `;
  }
}
window.customElements.define('pos-card-on-file', PosCardOnFile);
