import sha256 from '@insight/insight-common/util/sha256.cjs';
import ky from 'ky';
import InsightPublicAPIUtils from '../../common/public-api-utils.js';

class CustomerRegisterServerAPI {
  async getMembershipDetails(membershipId) {
    return new Promise(async resolve => {
      resolve(InsightPublicAPIUtils.fixUnderscores(await ky.get(`${Insight.configs.insightApiUrl}/plans/${membershipId}`).json()));
    });
  }
  async getQuoteDetails(quoteId) {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(InsightPublicAPIUtils.fixUnderscores(await ky.get(`${Insight.configs.insightApiUrl}/pricing-quotes/${quoteId}/fetch`).json()));
      } catch (e) {
        let error;
        if (!!e.response && e.response.status === 400) error = (await e.response.json()).error_code;
        else error = 'unexpected_error';
        reject(error);
      }
    });
  }
  async verifyUrl() {
    return new Promise(async (resolve, reject) => {
      try {
        const request = window.location.href;
        const hashed = sha256(`"${request}"`);
        const result = await ky
          .post(`${Insight.configs.insightApiUrl}/registration/verify-confirmation`, {json: request, headers: {'X-Anonymous-Hash': hashed}})
          .json();
      } catch (err) {
        reject(err);
      }
      resolve({success: true});
    });
  }
  async startQuoteReg(quoteId) {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await ky
            .post(`${Insight.configs.insightApiUrl}/registration/quote/start`, {
              json: {
                quote_id: quoteId,
                confirmation_link: window.location.href,
                development: Insight.isDevReg
              }
            })
            .json()
        );
      } catch (err) {
        reject(err);
      }
    });
  }
  async completeRegistration(request) {
    let url = !!request.quote_id ? 'quote/complete' : 'plan/complete';
    return new Promise(async (resolve, reject) => {
      try {
        const hashed = sha256(JSON.stringify(request));
        const headers = {'X-Anonymous-Hash': hashed};
        resolve(await ky.post(`${Insight.configs.insightApiUrl}/registration/${url}`, {json: request, headers: headers}).json());
      } catch (err) {
        reject(err);
      }
    });
  }
  async postModel(request) {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await ky.post(`${Insight.configs.insightApiUrl}/registration/step`, {json: request}).json());
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default CustomerRegisterServerAPI;
