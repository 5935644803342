import {InsightAppBase, html} from '@insight/insight-common/components/insight-app-base.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import './views/public/pricing-quote/pricing-quote-view.js';

class GymInsightQuote extends InsightAppBase {
  constructor() {
    super('GymInsightPublic', ['quote']);
  }

  async firstUpdated() {
    await this._configure(null, true);
    await this._init(null);
    this.__configured = true;
    this.requestUpdate();
  }

  async __viewLoaded({detail: headerCfg}) {
    this._applicationReady();
  }

  __handleError({detail: err}) {
    this._applicationLoadFailure(err);
  }

  get _viewportEl() {
    return this._getElement('.viewport');
  }

  get _viewEl() {
    const vp = this._viewportEl;
    return vp ? vp.firstElementChild : null;
  }

  _getSubclassTemplate() {
    if (!this.__configured) return;
    return html`
      ${unsafeHTML(this.__css)}
      <div class="viewport flex-layout-horizontal flex-layout-center-center" @view-loaded=${this.__viewLoaded}>
        <pricing-quote-view @app-error=${this.__handleError}></pricing-quote-view>
      </div>
    `;
  }

  get __css() {
    return `
    <style>
      :host .viewport {
        height: 100%;
        display: flex;
        background-color: var(--mdc-theme-primary);
      }
    </style>
  `;
  }
}
window.customElements.define('gym-insight-quote', GymInsightQuote);
