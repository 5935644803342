import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {i18nMoney} from '@insight/insight-common/i18n/i18n-money.js';
import '@insight/insight-common/components/container/insight-dialog.js';
import '@insight/insight-common/components/ui/insight-icon.js';
import './pos-card-on-file.js';
import './pos-new-card.js';
import './pos-quick-cash.js';

/* @imgex - none */
class PosPayDialog extends InsightElement {
  static get PayState() {
    return {
      Initial: 0,
      QuickCash: 1,
      CardOnFile: 2,
      NewCard: 3
    };
  }

  static get properties() {
    return {
      standAlone: {type: Boolean, attribute: 'stand-alone'},
      __order: {type: Object},
      __saveState: {type: Number},
      __canDismiss: {type: Boolean}
    };
  }

  open(order) {
    this.__canDismiss = true;
    this.__saveState = 0;
    this.__order = order;
    this.__dialogEl.open();
  }

  close() {
    this.__dialogEl.close();
  }

  cardScanned() {
    if (this.__saveState === PosPayDialog.PayState.NewCard) this._getElement('pos-new-card').cardScanned();
  }

  drawerClosed() {
    if (this.__saveState === PosPayDialog.PayState.QuickCash) this._getElement('pos-quick-cash').drawerClosed();
  }

  __dialogClosed() {
    this.__saveState = 0;
    this.__canDismiss = true;
    window.parent.postMessage({type: 'no-pay-cc'}, location.origin);
  }

  __cardOnFile() {
    this.__canDismiss = false;
    this.__saveState = PosPayDialog.PayState.CardOnFile;
  }

  __quickCash() {
    this.__canDismiss = true;
    this.__saveState = PosPayDialog.PayState.QuickCash;
  }

  __cashReceived() {
    this.__canDismiss = false;
    this._dispatchEvent('open-cash-drawer');
  }

  __newCard() {
    this.__canDismiss = true;
    this.__saveState = PosPayDialog.PayState.NewCard;
  }

  __cardProcessing() {
    this.__canDismiss = false;
  }

  __getTitle(state) {
    switch (state) {
      case PosPayDialog.PayState.Initial:
        return this._i18n('account:addSale.payTtlInit');
      case PosPayDialog.PayState.QuickCash:
        return this._i18n('account:addSale.payTtlCash');
      case PosPayDialog.PayState.CardOnFile:
        return this._i18n('account:addSale.payTtlOnFile');
      case PosPayDialog.PayState.NewCard:
        return this._i18n('account:addSale.payTtlNewCard');
    }
  }

  __finishSale({detail: success}) {
    this.close();
    if (success) this._dispatchEvent('sale-complete');
  }

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dialog id="pay-dialog" modal ?can-dismiss=${this.__canDismiss} @closed=${this.__dialogClosed}>
        <div slot="modalHeader"><div>${this.__getTitle(this.__saveState)}</div></div>
        <div id="modal-body" slot="modalBody">
          ${this.__saveState === PosPayDialog.PayState.QuickCash
            ? html`<pos-quick-cash
                .balanceDue=${this.__order.total}
                ?stand-alone=${this.standAlone}
                @cash-received=${this.__cashReceived}
                @cash-complete=${this.__finishSale}
              ></pos-quick-cash>`
            : this.__saveState === PosPayDialog.PayState.CardOnFile
              ? html`<pos-card-on-file
                  .balanceDue=${this.__order.total}
                  ?stand-alone=${this.standAlone}
                  @card-on-file-complete="${this.__finishSale}"
                ></pos-card-on-file>`
              : this.__saveState === PosPayDialog.PayState.NewCard
                ? html`<pos-new-card
                    .balanceDue=${this.__order.total}
                    ?stand-alone=${this.standAlone}
                    @card-processing=${this.__cardProcessing}
                    @new-card-complete=${this.__finishSale}
                  ></pos-new-card>`
                : !this.__order
                  ? ''
                  : html`<div class="${this.standAlone ? 'typo-display1' : 'typo-headline'} pay-ttl">
                        <b>${this._i18n('account:addSale.orderTtl')}:</b> ${i18nMoney.format(this.__order.total)}
                      </div>
                      <div class="flex-layout-horizontal flex-layout-center-justified pay-btn-row">
                        <div class="flex-layout-vertical flex-layout-center-center pay-btn" @click=${this.__quickCash}>
                          <div class="icon-bg"><insight-icon icon-name="payments"></insight-icon></div>
                          <div class="lbl-outer">
                            <div class="lbl-inner">
                              <div class="typo-title pay-lbl">${this._i18n('account:addSale.payCash')}</div>
                            </div>
                          </div>
                        </div>
                        <div class="flex-layout-vertical flex-layout-center-center pay-btn" @click=${this.__cardOnFile}>
                          <div class="icon-bg"><insight-icon icon-name="account_balance_wallet"></insight-icon></div>
                          <div class="lbl-outer">
                            <div class="lbl-inner">
                              <div class="typo-title pay-lbl">${this._i18n('account:addSale.payOnFile')}</div>
                            </div>
                          </div>
                        </div>
                        <div class="flex-layout-vertical flex-layout-center-center pay-btn" @click=${this.__newCard}>
                          <div class="icon-bg"><insight-icon icon-name="add_card"></insight-icon></div>
                          <div class="lbl-outer">
                            <div class="lbl-inner">
                              <div class="typo-title pay-lbl">${this._i18n('account:addSale.payNewCard')}</div>
                            </div>
                          </div>
                        </div>
                      </div>`}
        </div>
      </insight-dialog>
    `;
  }

  get __css() {
    let dlgWidth;
    switch (this.__saveState) {
      case PosPayDialog.PayState.Initial:
        dlgWidth = 'unset';
        break;
      case PosPayDialog.PayState.QuickCash:
        dlgWidth = 'min(410px, 95vw)';
        break;
      default:
        dlgWidth = 'min(640px, 95vw)';
        break;
    }
    return `
      <style>
        :host insight-dialog {
          --icon-width: 32px;
          --dialog-padding-body: 16px 32px 0;
          --dialog-min-width: ${dlgWidth};
          --dialog-overflow-x: hidden;
        }
        :host .pay-ttl {
          margin-bottom: 32px;
          font-weight: 500;
          color: var(--mdc-theme-text-error);
        }
        :host .pay-ttl b {
          margin-right: 8px;
          color: var(--mdc-theme-text-primary);
        }
        :host .pay-btn-row {
          column-gap: 32px;
          margin-bottom: 32px;
        }
        :host .icon-bg {
          background: var(--mdc-theme-primary);
          border-radius: 50%;
          padding: 16px;
          --icon-color: white;
        }
        :host .pay-btn {
          padding-top: 12px;
          row-gap: 4px;
          width: 140px;
          height: 160px;
          box-shadow: var(--mdc-theme-shadow-2);
          cursor: pointer;
          --icon-width: 48px;
        }
        :host([stand-alone]) .icon-bg {
          padding: 24px;
        }
        :host([stand-alone]) .pay-btn {
          width: 180px;
          height: 212px;
          --icon-width: 80px;
        }
        :host .pay-btn:active {
          transform: scale(95%);
        }
        :host .lbl-outer {
         display: table;
        }
        :host .lbl-inner {
          display: table-cell;
          vertical-align: middle;
          width: 140px;
          max-width: 140px;
          height: 72px;
        }
        :host([stand-alone]) .lbl-inner {
          width: 160px;
          max-width: 160px;
        }
        :host .pay-lbl {
          font-weight: 500;
          user-select: none;
          display: -webkit-box;
          white-space: initial;
          text-align: center;
          line-height: 28px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        @media only screen and (max-width: 600px) {
         :host .pay-btn-row {
            flex-direction: column;
            row-gap: 16px;
         }
         :host .pay-btn {
            width: 100%;
            flex-direction: row;
            justify-content: flex-start;
            padding: 0 0 0 16px;
            height: 120px;
            --icon-width: 32px;
          }
          :host .lbl-inner > div {
            font-size: 24px;
            padding-left: 16px;
            text-align: left;
          }
          :host .pay-ttl,
          :host .pay-ttl b {
            font-size: 16px;
          }
        }
      </style>
  `;
  }
}
window.customElements.define('pos-pay-dialog', PosPayDialog);
