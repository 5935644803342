import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {i18nMoney} from '@insight/insight-common/i18n/i18n-money.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '@insight/insight-common/components/container/insight-card.js';
import '@insight/insight-common/components/container/insight-datatable.js';
import './pos-discount-dialog.js';

/* @imgex - none */
class PosOrderForm extends InsightElement {
  static get properties() {
    return {
      standAlone: {type: Boolean, attribute: 'stand-alone'},
      __order: {type: Object},
      __headers: {type: Array},
      __items: {type: Object},
      __tableOpts: {type: Object}
    };
  }

  constructor() {
    super();
    this.__initOrder();
  }

  reset() {
    this.__initOrder();
  }

  addItem(orderItem) {
    orderItem.quantity = 1;
    const item = this.__order.items.find(i => i.guid === orderItem.guid);
    if (!!item) item.quantity += 1;
    else this.__order.items.push(orderItem);
    this.__calculate();
  }

  loadSale(sale) {
    this.__order = {
      subtotal: 0,
      discounts: 0,
      tax: 0,
      total: 0,
      items: sale.items
    };
    this.__calculate();
  }

  __initOrder() {
    this.__order = {
      subtotal: 0,
      discounts: 0,
      tax: 0,
      total: 0,
      items: []
    };
  }

  __getOrderItems({detail: {page, pageSize, sortField, sortAsc}}) {
    const items = {
      totalAvailable: this.__order.items.length,
      rows: this.__order.items.map(i => {
        return {
          id: i.roleGuid,
          columns: [
            {
              iconButton: 'close',
              danger: true,
              style: this.standAlone ? 'min-width:56px;max-width:56px;padding:4px 0 3px;--icon-width:32px;' : 'min-width:36px;max-width:36px;',
              miniIconButton: true,
              iconButtonNoFocus: this.standAlone,
              handler: e => this.__removeItem(i)
            },
            {
              iconButton: 'remove_circle',
              miniIconButton: true,
              showsAt: 1,
              handler: e => this.__changeQty(i, -1)
            },
            {
              iconButton: 'add_circle',
              miniIconButton: true,
              showsAt: 1,
              handler: e => this.__changeQty(i, 1)
            },
            {
              value: i.name,
              typoClass: this.standAlone ? 'typo-subtitle' : null,
              style: 'font-weight:500;',
              miniValueStyle: 'font-weight:700;'
            },
            {
              template: i.discount
                ? html`<div></div>`
                : html`<div class="flex-layout-horizontal flex-layout-center-center qty-col" style="--icon-width:32px;">
                    <insight-icon-button
                      no-focus-circle
                      icon-name="remove_circle"
                      style="--icon-color:var(--mdc-theme-primary)"
                      @click=${() => this.__changeQty(i, -1)}
                    ></insight-icon-button>
                    <div class="${this.standAlone ? 'typo-subtitle ' : ''}ord-qty" style=${!this.standAlone ? 'padding: 0 8px;' : ''}>${i.quantity}</div>
                    <insight-icon-button
                      no-focus-circle
                      icon-name="add_circle"
                      style="--icon-color:var(--mdc-theme-primary)"
                      @click=${() => this.__changeQty(i, 1)}
                    ></insight-icon-button>
                  </div>`,
              style: 'min-width:160px;max-width:160px;text-align:center;' + (this.standAlone ? 'padding:4px 0 3px;' : ''),
              miniLabel: true
            },
            {
              value: i18nMoney.format(i.amount),
              style: 'flex:none;min-width:12cqw;max-width:100px;text-align:right;font-weight:500;',
              typoClass: this.standAlone ? 'typo-subtitle' : null,
              miniLabel: true
            },
            {
              value: i.quantity,
              showsAt: 1,
              miniLabel: true
            },
            {
              value: i18nMoney.format(i.discount ? -i.amount : i.total),
              style: 'flex:none;min-width:12cqw;max-width:100px;text-align:right;font-weight:500;',
              typoClass: this.standAlone ? 'typo-subtitle' : null,
              miniLabel: true
            }
          ]
        };
      })
    };
    this.__items = items;
    this.__headers = [
      {style: this.standAlone ? 'min-width:56px;max-width:56px;' : 'min-width:36px;max-width:36px;'},
      {style: 'min-width:0px;max-width:0px;', showsAt: 1},
      {style: 'min-width:0px;max-width:0px;', showsAt: 1},
      {label: 'account:item'},
      {label: 'common:qty', style: 'min-width:160px;max-width:160px;justify-content:center;--icon-color:var(--mdc-theme-primary);'},
      {label: 'common:price', style: 'flex:none;min-width:12cqw;max-width:100px;justify-content:flex-end;padding-right:4px;box-sizing:border-box;'},
      {label: 'common:qty', showsAt: 1},
      {label: 'common:total', style: 'flex:none;min-width:12cqw;max-width:100px;justify-content:flex-end;padding-right:4px;box-sizing:border-box;'}
    ];
  }

  __changeQty(item, amt) {
    if (amt < 0 && item.quantity === 1) return;
    item.quantity += amt;
    item.total = item.amount * item.quantity;
    this.__calculate();
  }

  __removeItem(item) {
    this.__order.items = this.__order.items.filter(i => {
      return i.guid !== item.guid;
    });
    this.__calculate();
  }

  __discountAdded({detail: discount}) {
    this.__order.items.push({
      guid: new Date().getTime(),
      name: this._i18n('account:addSale.discount') + ': ' + discount.reason.label,
      amount: discount.amount,
      discount: true
    });
    this.__calculate();
  }

  __calculate() {
    if (!this.__order.items.length) return this.__initOrder();
    this.__order.discounts = this.__order.tax = this.__order.subtotal = this.__order.total = 0;
    this.__order.items.forEach(i => {
      if (i.discount) {
        this.__order.discounts += -i.amount;
      } else {
        this.__order.subtotal += i.amount * (i.quantity || 1);
        this.__order.tax += i.amount * (i.quantity || 1) * (i.taxExempt ? 0 : i.taxRate || 0);
      }
    });
    this.__order.total = Math.max(0, this.__order.subtotal + this.__order.discounts + this.__order.tax);
    this.requestUpdate();
    this._afterRender(() => this.__tableEl.fetchItems());
  }

  __addDiscount() {
    this.__discountDlg.open();
  }

  __showItems() {
    this._dispatchEvent('show-items');
  }

  get __discountDlg() {
    return this._getElement('pos-discount-dialog');
  }

  get __tableEl() {
    return this._getElement('insight-datatable');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-card id="crd-odr">
        <div slot="cardContent" class="flex-layout-vertical">
          ${!this.__order.items.length
            ? html`<div class="flex-layout-flex flex-layout-vertical flex-layout-center-center">
                <div class="empty-lbl">${this._i18n('account:addSale.selItem')}</div>
              </div>`
            : html`<div id="order" class="flex-layout-flex flex-layout-vertical">
                <insight-datatable
                  no-footer
                  can-mini
                  .headers=${this.__headers}
                  .data=${this.__items}
                  ?dense=${!this.standAlone}
                  @fetch-items=${this.__getOrderItems}
                ></insight-datatable>
              </div>`}
          <div class="flex-layout-horizontal flex-layout-center-center add-btn">
            <insight-button contained dense @click=${this.__showItems}>${this._i18n('account:addSale.addItem')}</insight-button>
          </div>
          <div id="ord-dtls" class="flex-layout-horizontal">
            <div class="flex-layout-vertical">
              <div class="flex-layout-horizontal typo-title subttl-row">
                <div class="dtl-lbl">${this._i18n('common:subtotal')}:</div>
                <div class="dtl-val">${i18nMoney.format(this.__order.subtotal)}</div>
              </div>
              <div class="flex-layout-horizontal typo-title subttl-row">
                <div class="dtl-lbl">${this._i18n('account:addSale.discounts')}:</div>
                <div class="dtl-val${this.__order.discounts < 0 ? ' dtl-disc' : ''}">${i18nMoney.format(this.__order.discounts)}</div>
              </div>
              <div class="flex-layout-horizontal typo-title subttl-row">
                <div class="dtl-lbl">${this._i18n('common:tax')}:</div>
                <div class="dtl-val">${i18nMoney.format(this.__order.tax)}</div>
              </div>
            </div>
            <div class="flex-layout-vertical">
              <div class="flex-layout-horizontal flex-layout-justified typo-display1 dtl-ttl">
                <div class="dtl-lbl">${this._i18n('common:total')}:</div>
                <div class="dtl-val">${i18nMoney.format(this.__order.total)}</div>
              </div>
              <div class="flex-layout-horizontal flex-layout-center typo-subhead dtl-sls">
                <div class="dtl-lbl">${this._i18n('account:addSale.salesp')}:</div>
                <div class="dtl-val">
                  <div class="flex-layout-horizontal flex-layout-end-justified flex-layout-center">
                    <div>${Insight.user.firstName + ' ' + Insight.user.lastName}</div>
                    <insight-icon-button icon-name="search" style="margin:0 -16px 0 8px"></insight-icon-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="ord-footer" class="flex-layout-horizontal flex-layout-center">
            ${this.standAlone || !this._mobileRes
              ? html`<insight-button contained unelevated warning @click=${this.__addDiscount}>${this._i18n('account:addSale.discount')}</insight-button>`
              : html`<insight-button outlined warning @click=${this.__addDiscount}><insight-icon icon-name="discount"></insight-icon></insight-button>`}
            <div class="flex-layout-flex"></div>
            ${this.standAlone || !this._mobileRes
              ? html`<insight-button id="ord-save-btn" outlined @click=${() => this._dispatchEvent('save-order')}>${this._i18n('common:save')}</insight-button>`
              : html`<insight-button id="ord-save-btn" outlined @click=${() => this._dispatchEvent('save-order')}
                  ><insight-icon icon-name="save"></insight-icon
                ></insight-button>`}
            ${this.standAlone || !this._mobileRes
              ? html`<insight-button id="ord-cancel-btn" outlined danger @click=${() => this._dispatchEvent('cancel-order')}
                  >${this._i18n('common:cancel')}</insight-button
                >`
              : html`<insight-button id="ord-cancel-btn" danger outlined @click=${() => this._dispatchEvent('cancel-order')}
                  ><insight-icon icon-name="delete"></insight-icon
                ></insight-button>`}
            <insight-button
              id="ord-pay-btn"
              contained
              success
              ?disabled=${!this.__order || this.__order.total <= 0}
              @click=${() => this._dispatchEvent('complete-order', this.__order)}
              >${this._i18n('common:pay')}</insight-button
            >
          </div>
        </div>
      </insight-card>
      <pos-discount-dialog @add-discount=${this.__discountAdded}></pos-discount-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          height: 100%;
        }
        :host #crd-odr {
          height: 100%;
          --card-explicit-height: 100%;
          --card-padding: 0;
          --card-body-padding-top: 0;
        }
        :host #crd-odr > div {
          height: 100%;
        }
        :host(:not([stand-alone])) #crd-odr > div {
          container-type: size;
        }
        :host .dtl-sls .dtl-val {
          width: 200px;
        }
        :host .dtl-ttl {
          height: 64px;
        }
        :host .dtl-ttl .dtl-val {
          width: 180px;
        }
        :host #crd-odr > div  > div:first-child {
          container-type: size;
          --table-list-max-height: 100cqh;
        }
        :host([stand-alone]) .ord-qty {
          margin: 0 8px;
        }
        :host .empty-lbl {
          color: var(--mdc-theme-text-disabled);
          margin: 0 16px;
          text-align: center;
        }
        :host #ord-dtls {
          padding: 16px 24px;
          border-top: 1px solid var(--mdc-theme-divider-color);
          border-bottom: 1px solid var(--mdc-theme-divider-color);
          column-gap: 20%;
        }
        :host #ord-dtls > div {
          width: 50%;
        }
        :host .dtl-lbl {
          font-weight: 700;
          width: 120px;
        }
        :host .dtl-val {
          width: 120px;
          font-weight: 500;
          text-align: right;
        }
        :host .dtl-sls .dtl-lbl,
        :host .dtl-sls .dtl-val {
          font-weight: 400;
        }
        :host .dtl-sls .dtl-lbl {
          width: 140px;
        }
        :host .dtl-sls .dtl-val {
          width: 200px;
        }
        :host(:not([stand-alone])) .dtl-sls {
          display: none;
        }
        :host .dtl-ttl {
          height: 64px;
        }
        :host .dtl-ttl .dtl-val {
          width: 180px;
        }
        :host .dtl-disc {
          margin-left: 6px;
        }
        :host #ord-footer {
          padding: 8px 16px;
          column-gap: 16px;
        }
        :host #ord-footer {
          --button-min-width: 120px;
        }
        :host #ord-footer insight-button:has(> insight-icon) {
          --button-min-width: 60px;
        }
        :host([stand-alone]) #ord-footer {
          --button-height: 48px;
          --button-min-width: 120px;
          --button-font-size: 16px;
        }
        :host([stand-alone]) #ord-pay-btn {
          --button-font-size: 24px;
        }
        :host .add-btn {
          margin-bottom: 8px;
          padding-top: 8px;
          border-top: 1px solid var(--mdc-theme-divider-color);
        }
        :host([stand-alone]) .add-btn,
        :host(:not([tablet-res])) .add-btn {
          display: none;
        }
        @media only screen and (max-width: 480px) {
          :host #ord-dtls {
            justify-content: center;
            flex-wrap: wrap;
          }
          :host .subttl-row {
            justify-content: space-between;
          }
          :host #ord-footer {
            --button-min-width: 80px;
          }
        }
        @container (width < 800px) {
          :host .dtl-lbl {
            width: 100px;
            font-size: 18px;
          }
          :host .dtl-val {
            width: 100px;
            font-size: 18px;
          }
          :host .dtl-ttl > * {
            font-size: 24px;
          }
          :host .dtl-ttl .dtl-val {
            width: 120px;
          }
        }
        @container (width < 600px) {
          :host .dtl-lbl {
            width: 80px;
            font-size: 16px;
          }
          :host .dtl-val {
            width: 80px;
            font-size: 16px;
          }
          :host .dtl-ttl > * {
            font-size: 20px;
          }
          :host .dtl-ttl .dtl-val {
            width: 100px;
          }
          :host #ord-footer {
            column-gap: 8px;
          }
        }
      </style>
  `;
  }
}
window.customElements.define('pos-order-form', PosOrderForm);
