import {InsightElement, html} from '../insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';

class InsightTooltip extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      disabled: {attribute: 'disabled', type: Boolean},
      horizontalAlign: {attribute: 'horizontal-align', type: String},
      verticalOffset: {attribute: 'vertical-offset', type: Number},
      isImage: {attribute: 'is-image', type: Boolean}
    };
  }

  /**************************************************  Private Methods  *************************************************/

  _destructor() {
    super._destructor();
    this.__finishRemoveTooltip();
  }

  __mousedOver(e) {
    if (this.disabled) return;
    this.__over = true;
    this.__overTimer = setTimeout(() => this.__positionTooltip(e), 500);
  }

  __positionTooltip(e) {
    if (this.__tooltip) this.__finishRemoveTooltip();
    const align = this.horizontalAlign || 'center';
    const offset = this.verticalOffset || 0;
    this.__anchor = this._getElement('slot:not([name="tooltip"])').assignedElements()[0];
    this.__tooltip = this._getElement('slot[name="tooltip"]').assignedElements()[0];
    if (!this.__tooltip) return;
    this.__tooltip.style.display = 'block';
    this.__tooltip.style.position = 'fixed';
    this.__tooltip.style.zIndex = 9;
    this.__tooltip.style.pointerEvents = 'none';
    if (!this.isImage) {
      this.__tooltip.style.background = '#254158';
      this.__tooltip.style.color = 'rgba(255, 255, 255, 0.87)';
      this.__tooltip.style.padding = '4px 8px';
      this.__tooltip.style.lineHeight = '24px';
      this.__tooltip.style.borderRadius = '4px';
      this.__tooltip.style.fontSize = '12px';
      this.__tooltip.style.fontWeight = 400;
      this.__tooltip.style.letterSpacing = '0.0333333333em';
      this.__tooltip.style.lineHeight = '20px';
      this.__tooltip.style.textAlign = this.__tooltip.style.textAlign || 'left';
    }
    this._dispatchEvent('show');
    this._afterRender(() => {
      if (!this.__tooltip) return;
      const pos = this.__anchor.getBoundingClientRect();
      switch (align) {
        case 'left':
          this.__tooltip.style.left = pos.x + 'px';
          break;
        case 'center':
          this.__tooltip.style.left = pos.x + pos.width / 2 - this.__tooltip.offsetWidth / 2 + 'px';
          break;
        case 'right':
          this.__tooltip.style.left = pos.x + pos.width - this.__tooltip.offsetWidth + 'px';
          break;
      }
      this.__tooltip.style.top = pos.y + pos.height + offset + 'px';
      document.body.appendChild(this.__tooltip);
      this.__tooltip.style.transformOrigin = 'top';
      this.__tooltip.style.transition = '.15s all';
      this.__tooltip.style.transform = 'scale(0.7)';
      this._afterRender(() => {
        if (!this.__tooltip) return;
        this.__tooltip.style.transform = 'scale(1)';
        this.__tooltip.style.opacity = 1;
      });
    });
  }

  __removeTooltip() {
    clearTimeout(this.__overTimer);
    this.__over = false;
    if (!this.__tooltip) return;
    this.__tooltip.style.opacity = 0;
    this.__animTimeout = setTimeout(() => this.__finishRemoveTooltip(), 200);
  }

  __finishRemoveTooltip() {
    clearTimeout(this.__animTimeout);
    if (!this.__tooltip) return;
    this.__tooltip.style.opacity = 0;
    this.__tooltip.style.display = 'none';
    this.appendChild(this.__tooltip);
    this.__tooltip = null;
    this._dispatchEvent('remove');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <slot @mouseover=${this.__mousedOver} @mouseout=${this.__removeTooltip}></slot>
      <slot name="tooltip"></slot>
    `;
  }

  get __css() {
    return `
      <style>
        :host slot::slotted(*) {
          cursor: help;
        }
        :host([disabled]) slot::slotted(*),
        :host([no-help]) slot::slotted(*) {
          cursor: default;
        }
        :host([can-click]) slot::slotted(*) {
          cursor: pointer;
        }
        :host slot[name='tooltip']::slotted(*) {
          opacity: 0;
          position: fixed;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-tooltip', InsightTooltip);
