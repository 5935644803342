export class SessionManager {
  constructor(appRef) {
    this.__appRef = appRef;
  }

  serverLogout() {
    this.logout(6);
  }

  logout(reason) {
    this.__appRef._authEl.logout(!!Insight.currentLocation ? {location_id: Insight.currentLocation} : undefined);
    // if (!reason) this.__appRef._authEl.logout(!!Insight.currentLocation ? {location_id: Insight.currentLocation} : undefined);
    // else {
    //   Insight.logoutReason = reason;
    //   Insight.deniedResource = location.pathname;
    //   if (reason !== 9) this.__appRef._authEl.clearAuth();
    //   this.__appRef._performNavigation({detail: 'warnings/signout'});
    // }
  }

  becomeUser(userId) {
    if (Insight.configs.mock) {
      this._dispatchEvent('show-snackbar', 'Would have become user: ' + userId);
      return;
    }
    this.__appRef._authEl.becomeUser(userId);
  }
}
