import {ViewBase, html} from '@insight/insight-common/components/insight-view-base.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@insight/insight-common/components/container/insight-card.js';
import '../../../components/common/quote-preview-component.js';
import {default as MockAPI} from './api/mock-api.js';
import {default as ServerAPI} from './api/server-api.js';

/* @imgex - desktop/view-quote/9nLGvMUKhvYNzLezgt.jpeg */
class PricingQuoteView extends ViewBase {
  static get properties() {
    return {
      __quote: {type: String},
      __qError: {type: String}
    };
  }

  constructor() {
    super();
    this.__api = this._isMock() ? new MockAPI() : new ServerAPI();
    this.__prospectId = new URLSearchParams(location.search).get('p');
  }

  firstUpdated() {
    if (!this.__prospectId) this._dispatchEvent('app-error', this._i18n('quote:notExist'));
    else this.__loadQuote();
  }

  async __loadQuote() {
    this._dispatchEvent('view-loaded', {});
    try {
      this.__quote = (await this.__api.getQuote(this.__prospectId)).data;
    } catch (e) {
      this.__qError = e;
    }
  }
  __getErrorMessage() {
    return this._i18n('quote:notExist');
  }

  __navBack() {
    this._dispatchEvent('navigate-app', `admin/pricing-quotes/list`);
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      ${!this.__qError
        ? html` <quote-preview-component .quote=${this.__quote} is-public></quote-preview-component> `
        : html`
            <insight-card id="err-card" elevation="8">
              <div slot="cardContent" class="flex-layout-vertical flex-layout-center">
                <div class="flex-layout-horizontal flex-layout-center-justified main-hdr">
                  <img class="main-logo" src="images/website-brand-tp.png" />
                </div>
                <div class="typo-title main-msg">${this.__getErrorMessage()}</div>
              </div>
            </insight-card>
          `}
    `;
  }

  get __css() {
    const isDark = document.body.hasAttribute('dark-mode');
    return `
    <style>
      ${
        !this.__qError
          ? `:host {
        overflow-y: auto;
        width: 100vw;
        background: var(--mdc-theme-background);
      }`
          : `:host {
        background: var(--mdc-theme-primary);
        min-height: 100% !important;
        height: unset !important;
        display: flex !important;
        align-items:  center;
        justify-content: center;
      }`
      }
      :host([mobile-res]) {
        padding: 8px !important;
      }
      :host #err-card {
        min-width: min(680px, 95vw);
        max-width: min(680px, 95vw);
        margin: 0 auto;
        --card-padding: 0 0 16px;
        --card-body-padding-top: 0;
        --card-bg-color: var(--mdc-theme-dark-bg-12, var(--mdc-theme-background));
      }
      :host #err-card div[slot="cardContent"] {
        min-height: 540px;
        padding-bottom: '32px';
      }
      :host([mobile-res]) #err-card div[slot="cardContent"] {
        min-height: 90vh;
      }
      :host .main-logo {
        width: 30vw;
        min-width: 100px;
        max-width: 200px;
        margin: 32px 0 16px;
      }
      :host .main-hdr {
        width: 80%;
        border-bottom: 2px solid var(--mdc-theme-dark-highlight, var(--mdc-theme-divider-color));
      }
      :host .main-msg {
        padding: 20px 10%;
        text-align: center;
      }
      :host .typo-display1,
      :host .typo-title,
      :host .typo-headline,
      :host .typo-subhead {
        white-space: unset;
      }
    </style>
  `;
  }
}
window.customElements.define('pricing-quote-view', PricingQuoteView);
