import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/button/insight-dropdown-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '@insight/insight-common/components/container/insight-dialog.js';
import '@insight/insight-common/components/form/insight-dropdown-menu.js';

/* @imgex - desktop/location-menu/Rn5xk7QiXM4UAAnFhHCyUj6eL1X68fpK5Lbq38GowEmjN.jpeg */
class LocationMenu extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      locations: {type: Array},
      otherLocations: {type: Array},
      activityShown: {type: Boolean, attribute: 'activity-shown', reflect: true}
    };
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
  }

  firstUpdated() {
    this._afterRender(() => this.__configureLocationDropdown());
  }

  updated(changedProperties) {
    if (changedProperties.has('_mobileRes') || changedProperties.has('_tabletRes')) {
      this._afterRender(() => this.__configureLocationDropdown());
    }
  }

  __configureLocationDropdown() {
    this.otherLocations = this.locations.sort(function (a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    this.otherLocations = this.otherLocations.map(l => ({label: l.name, value: l.id, logo: l.logoUrl, selected: l.id === Insight.user.currentLocationGuid}));
    const currIdx = this.otherLocations.findIndex(l => l.selected);
    let currLoc;
    if (currIdx >= 0) currLoc = this.otherLocations.splice(currIdx, 1)[0];
    let menuItems = [];
    if (Insight.roleSets.reports.length)
      menuItems.push({label: this._i18n('appshell:locationmenu.reports'), value: 'reports', url: 'reports', icon: 'bar_chart'});
    if (this._hasAccessToAny('LOCATION:MEMBERSHIP'))
      menuItems.push({
        label: this._i18nIf(['common:plans', 'common:memberships'], () => this._isGymInsight()),
        value: 'memberships',
        url: 'memberships/list',
        icon: this._isGymInsight() ? 'sell' : 'assignment'
      });
    if (this._hasAccess('USER:LIST:VIEW')) menuItems.push({label: this._i18n('common:users'), value: 'users', url: 'location/users', icon: 'person'});
    if (this._hasAccess('USER:ROLE:VIEW'))
      menuItems.push({label: this._i18n('appshell:locationmenu.roles'), value: 'roles', url: 'location/roles/list', icon: 'admin_panel_settings'});
    if (this._hasAccess('USER:COMMISSIONGROUP:VIEW'))
      menuItems.push({label: this._i18n('appshell:locationmenu.groups'), value: 'groups', url: 'client/groups', icon: 'people'});
    if (this._hasAccessToAny('APPLICATION:'))
      menuItems.push({label: this._i18n('appshell:locationmenu.apps'), value: 'apps', url: 'settings/applications', icon: 'laptop'});
    if (this._hasAccessToAny('SETTING:')) menuItems.push({label: this._i18n('common:settings'), value: 'settings', url: 'settings/location', icon: 'build'});
    if (menuItems.length && currLoc) currLoc.divider = 'after';
    if (currLoc) menuItems.unshift(currLoc);
    if (this.otherLocations.length > 3) {
      menuItems.push({label: this._i18n('common:otherLocs'), value: 'switch', icon: 'other_houses', smallIcon: true, divider: 'before'});
    } else if (this.otherLocations.length) {
      if (menuItems.length) this.otherLocations[0].divider = 'before';
      menuItems = menuItems.concat(this.otherLocations);
    }
    if (menuItems.length) this.__locationSelectEl.data = menuItems;
  }

  __locationChanged(e) {
    this.__locationSelectEl.selectedIndex = 0;
    if (e.detail.url) {
      this._navigateOnClick(e.detail._event_, e.detail.url);
    } else if (e.detail.selected) {
      //nothing
    } else if (e.detail.value === 'switch') {
      this.__dialogEl.open();
    } else if (e.detail.value) {
      this.__switchLocation(e.detail.value);
    }
  }

  __switchLocation(locId) {
    localStorage.setItem('insight-location', locId);
    Insight.state.sendCommandOneTime('changeLocation', () => {}, {location: locId});
  }

  __hasAnySettings() {
    return (
      this._hasAccessToAny('LOCATION:MEMBERSHIP') ||
      this._hasAccessToAny('SETTING:') ||
      this._hasAccessToAny('APPLICATION:') ||
      this._hasAccess('USER:LIST:VIEW') ||
      this._hasAccess('USER:COMMISSIONGROUP:VIEW') ||
      Insight.roleSets.reports.length ||
      this._hasAccess('USER:ROLE:VIEW')
    );
  }

  get __locationSelectEl() {
    return this._getElement('#location-selector');
  }

  get __dialogEl() {
    return this._getElement('#location-dialog');
  }

  get __singleLocTpl() {
    return html`
      <insight-button id="location-selector" outlined @click=${e => this.__locationChanged({detail: {selected: true, _event_: e}})}>
        <div class="flex-layout-horizontal flex-layout-center-center">
          <insight-icon icon-name="home"></insight-icon>
          <div>${this.locations[0].name}</div>
        </div>
      </insight-button>
    `;
  }

  get __desktopTpl() {
    return html`
      <insight-dropdown-menu
        id="location-selector"
        outlined
        dense
        no-background
        no-bottom-margin
        no-underline
        no-label
        full-width
        prefix-icon="home"
        @change=${this.__locationChanged}
      ></insight-dropdown-menu>
    `;
  }

  get __mobileTpl() {
    return html`
      <insight-dropdown-button id="location-selector" min-width="192" @change=${this.__locationChanged}>
        <insight-icon-button icon-name="home" slot="dropdown-button"></insight-icon-button>
      </insight-dropdown-button>
    `;
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      ${this.locations.length === 1 && !this.__hasAnySettings()
        ? this.__singleLocTpl
        : this._mobileRes || this._tabletRes
          ? this.__mobileTpl
          : this.__desktopTpl}
      ${!!this.otherLocations && this.otherLocations.length > 3
        ? html`
            <insight-dialog id="location-dialog" modal can-dismiss>
              <div slot="modalHeader" class="modal-header flex-layout-horizontal flex-layout-center">
                <div>${this._i18n('common:otherLocs')}</div>
              </div>
              <div id="modal-body" slot="modalBody">
                ${this.otherLocations.map(l => {
                  return html`
                    <div class="loc-item flex-layout-horizontal flex-layout-center" @click=${() => this.__switchLocation(l.value)}>
                      <div class="loc-logo-wrapper"><img class="loc-logo" src=${l.logo} onerror="this.style.width=0;" /></div>
                      <div class="loc-details">
                        <div class="typo-subhead2 loc-name">${l.label}</div>
                      </div>
                    </div>
                  `;
                })}
              </div>
            </insight-dialog>
          `
        : ''}
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          margin-left: 24px;
          margin-right: 12px;
        }
        :host([tablet-res]) {
          margin-right: 0;
        }
        :host([mobile-res]) {
          margin-left: -8px;
          margin-right: -8px;
        }
        :host #location-selector {
          display: block;
          --dropdown-active-color: var(--mdc-theme-dark-highlight, var(--mdc-theme-divider-color));
          --input-font-size: 16px;
          --input-font-weight: 400;
          --input-letter-spacing: 0.009375em;
          --input-line-height: 28px;
        }
        :host(:not([mobile-res]):not([tablet-res])) #location-selector {
          min-width: 192px !important;
        }
        :host insight-button#location-selector {
          --button-icon-color: var(--mdc-theme-text-icon);
          --button-color: var(--mdc-theme-text-primary);
          --button-border-color: var(--mdc-theme-divider-color);
        }
        :host insight-button#location-selector insight-icon {
          height: 24px;
          margin-top: -2px;
          margin-right: 8px;
          --icon-align: top;
        }
        :host #location-dialog {
          --dialog-padding-body: 0 24px 20px;
        }
        :host #location-dialog #modal-body {
          border: solid 1px var(--mdc-theme-divider-color);
          max-height: 40vh;
          overflow: auto;
        }
        :host #location-dialog  .loc-item {
          height: 56px;
          padding: 8px 16px;
          cursor: pointer;
        }
        :host #location-dialog  .loc-item:not(:last-child) {
          border-bottom: solid 1px var(--mdc-theme-divider-color);
        }
        :host #location-dialog  .loc-item:hover {
          background-color: var(--mdc-theme-dark-highlight, var(--mdc-theme-surface));
        }
        :host #location-dialog  .loc-item:active {
          background-color: var(--mdc-theme-dark-bg-0, var(--mdc-theme-selected-item));
        }
        :host #location-dialog  .loc-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        :host #location-dialog  .loc-details {
          overflow: hidden;
        }
        :host #location-dialog  .loc-logo-wrapper {
          height: 40px;
          width: 180px;
          max-width: 25vw;
          max-height: 5.5555vw;
          margin-right: 32px;
        }
        :host #location-dialog  .loc-logo {
          width: 100%;
          height: 100%;
        }
        @media only screen and (max-width: 400px) {
          :host #location-dialog  .loc-item {
            padding: 4px 8px;
          }
          :host #location-dialog  .loc-logo-wrapper {
            margin-right: 16px;
          }
        }
      </style>
    `;
  }
}
window.customElements.define('location-menu', LocationMenu);
