import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {i18nMoney} from '@insight/insight-common/i18n/i18n-money.js';
import {i18nPhone} from '@insight/insight-common/i18n/i18n-phone.js';
import '@insight/insight-common/components/button/insight-button.js';
import {PricingPlanUtils} from '../../util/pricing-plan-utils.js';

/* @imgex - desktop/register-with-membership/7wopdGQ6a7rBWG8beK.jpeg */
class RegisterReview extends InsightElement {
  static get properties() {
    return {
      registration: {type: Object},
      __mappedReg: {type: Object},
      __saving: {type: Boolean}
    };
  }

  enableAfterError() {
    this.__saving = false;
  }

  firstUpdated() {
    this._afterRender(() => {
      this._dispatchEvent('step-load', this);
    });
  }

  updated(changedProperties) {
    if (changedProperties.has('registration')) {
      const isPrem = !!this.registration.plan.premium;
      this.__mappedReg = {};
      this.__mappedReg[this._i18n('common:customer')] = {
        [this._i18n('common:name')]: this.registration.contact.fullName,
        [this._i18n('location:register.emailAddress')]: this.registration.contact.email,
        [this._i18n('common:phoneNumber')]: !!this.registration.contact.phone ? i18nPhone.format(this.registration.contact.phone.replace(/[^0-9]/g, '')) : ''
      };
      this.__mappedReg[this._i18n('common:location')] = {
        [this._i18n('common:locationName')]: this.registration.location.name,
        [this._i18n('location:register.businessPhone')]: this.registration.location.phone,
        [this._i18n('location:register.timezone')]: this.registration.location.timezone
      };

      if (!!this.registration.membership) {
        this.__mappedReg[this._i18n('common:account')] = {
          [this._i18n('common:type')]: this.registration.membership.title,
          [this._i18n('common:price')]: this.registration.membership.pricingString
        };
        if (!!this.registration.membership.quoteTotal)
          this.__mappedReg[this._i18n('common:account')][this._i18n('location:register.dueNow')] = i18nMoney.format(this.registration.membership.quoteTotal);
      } else {
        this.__mappedReg[this._i18n('common:account')] = {
          [this._i18n('common:type')]:
            this._i18nIf(['location:register.premPlan', 'location:register.stdPlan'], () => Boolean(isPrem)) +
            (this.registration.payment === 'trial' ? ` (${this._i18n('location:register.trial')})` : ''),
          [this._i18n('common:price')]:
            '$' + this.registration.plan.price + '/mo' + (this.registration.payment === 'trial' ? ` (${this._i18n('location:register.afterTrial')})` : '')
        };
      }
      if (this.registration.owners && this.registration.owners.length) {
        this.__mappedReg[this._i18n('common:location')][this._i18n('location:register.owners')] = this.registration.owners.map(o => o.title).join(', ');
      }
      if (!this.registration.membership) {
        this.__mappedReg[this._i18n('common:account')][this._i18n('location:register.initialTier')] = this._i18n('location:register.initMemTier', {
          members: this.registration.plan.members
        });
        this.__mappedReg[this._i18n('common:account')][this._i18n('location:register.nextTier')] = this._i18n('location:register.nextMemTier', {
          members: this.registration.plan.members + 100
        });
      }
      const pymt = this.registration.payment.details;
      if (!!pymt) {
        if (this.registration.payment.methodName === 'insight-ach') {
          let acctType;
          switch (pymt.accountType) {
            case 'CHECKING':
              acctType = this._i18n('components:payment.checking');
              break;
            case 'SAVINGS':
              acctType = this._i18n('components:payment.savings');
              break;
            default:
              acctType = this._i18n('components:payment.bizChecking');
              break;
          }
          this.__mappedReg[this._i18n('common:paymentInfo')] = {
            [this._i18n('common:accountType')]: acctType,
            [this._i18n('common:bankName')]: pymt.bankName,
            [this._i18n('common:routingNum')]: pymt.routingNumber,
            [this._i18n('common:acctNum')]: pymt.acctNumber
          };
        } else {
          this.__mappedReg[this._i18n('common:paymentInfo')] = {
            [this._i18n('common:type')]: pymt.cardType.charAt(0).toUpperCase() + pymt.cardType.slice(1),
            [this._i18n('common:cardNum')]: '•••••' + pymt.cardNumber.substr(-4),
            [this._i18n('common:nameOnCard')]: pymt.cardholderName,
            [this._i18n('common:expires')]: pymt.expiryMonth + '/' + pymt.expiryYear
          };
        }
      }
    }
  }

  __finish() {
    this.__saving = true;
    this._dispatchEvent('complete-reg');
  }

  _render() {
    if (!this.__mappedReg) return;
    return html`
      <style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
      ${unsafeHTML(this.__css)}
      <div>
        <div class="typo-title">${this._i18n('location:register.reviewAccount')}</div>
        <div class="flex-layout-horizontal flex-layout-wrap review-container">
          ${Object.keys(this.__mappedReg).map(s => {
            return html`
              <div class="review-section">
                <div class="typo-overline review-title">${s}</div>
                ${Object.keys(this.__mappedReg[s]).map(f => {
                  return html`
                    <div class="flex-layout-horizontal flex-layout-end">
                      <div class="typo-subhead2 review-lbl">${f}:</div>
                      <div class="typo-body2 review-val">${this.__mappedReg[s][f]}</div>
                    </div>
                  `;
                })}
              </div>
            `;
          })}
        </div>
        <div class="footer-btns">
          <insight-button @click=${() => this._dispatchEvent('start-over')} ?disabled=${this.__saving}>${this._i18n('common:back')}</insight-button>
          <insight-button contained @click=${this.__finish} ?disabled=${this.__saving}>
            <div id="btn-loader" ?hidden=${!this.__saving}>
              <div class="insight-loader" style="${window.Testophobia ? 'border-top-color:#ccc;' : ''}"></div>
            </div>
            ${this.__saving
              ? this._i18n('location:register.processing')
              : !!this.registration.payment.details
                ? `${this._i18n('common:pay')} $${
                    !!this.registration.membership
                      ? !!this.registration.membership.quoteTotal
                        ? this.registration.membership.quoteTotal.toFixed(2)
                        : PricingPlanUtils.calcPrice(this.registration.membership)
                      : this.registration.plan.price.toFixed(2)
                  }`
                : this._i18n('location:register.createAccount')}
          </insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
    <style>
      :host .review-container {
        max-width: 920px;
      }
      :host .review-section {
        width: 360px;
        margin: 10px 0;
      }
      :host .review-title {
        color: var(--mdc-theme-text-secondary);
        letter-spacing: 5px;
        line-height: 20px;
      }
      :host .review-lbl {
        max-width: 140px;
        min-width: 140px;
      }
      :host .review-val {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      :host .footer-btns {
        margin: 20px 0 10px;
      }
      :host .footer-btns insight-button:first-child {
        margin-right: 20px;
      }
      :host .footer-btns insight-button:last-child {
        --button-color: var(--mdc-theme-success);
      }
      :host #btn-loader {
        display: inline-block;
        transform: scale(.75);
        vertical-align: middle;
        margin: -2px 0 0 -4px;
      }
      :host #btn-loader[hidden] {
        display: none;
      }
      @media only screen and (max-width: 480px) {
        :host .review-section {
          width: 100%;
        }
      }
    </style>
  `;
  }
}
window.customElements.define('register-review', RegisterReview);
