import {InsightComponent, html} from '../insight-component.js';
import {ifDefined} from 'lit/directives/if-defined.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-help-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';

class InsightPanel extends InsightComponent {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      panelTitleHelp: {type: String},
      panelTitleHelpTitle: {type: String},
      __panelTitle: {attribute: 'panel-title', type: String},
      __canReorder: {attribute: 'can-reorder', type: Boolean},
      __canCollapse: {attribute: 'can-collapse', type: Boolean},
      __showButtons: {attribute: 'show-buttons', type: Boolean},
      __hasExtraButtons: {attribute: 'extra-buttons', type: Boolean},
      collapsed: {attribute: 'collapsed', type: Boolean, reflect: true}
    };
  }

  /**************************************************  Private Methods  *************************************************/

  __collapse(e) {
    e.stopPropagation();
    this.collapsed = !this.collapsed;
    this._dispatchEvent('collapse-panel', this.collapsed);
  }

  __checkCanMovePanel(val) {
    if (window.Testophobia && !this.__showButtons && !new URLSearchParams(location.search).get('panel-move')) return false;
    else return val;
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div class="panel-wrapper">
        <div class="flex-layout-horizontal panel-header" @click=${e => (!this.__canReorder && this.__canCollapse ? this.__collapse(e) : null)}>
          <div class="typo-caption panel-title">${this.__panelTitle}</div>
          ${this.panelTitleHelp
            ? html`
                <div>
                  <insight-help-button help-title=${ifDefined(this.panelTitleHelpTitle || undefined)} help-message=${this.panelTitleHelp}></insight-help-button>
                </div>
              `
            : ''}
          <div class="flex-layout-flex"></div>
          <div class="panel-header-btns">
            <slot name="headerButtons"></slot>
            ${this.__checkCanMovePanel(this.__canReorder)
              ? html`
                  <insight-icon-button
                    id="btn-move-up"
                    title=${this._i18nComp('common', 'moveUp', 'Move Up')}
                    icon-name="arrow_upward"
                    @click=${() => this._dispatchEvent('move-panel', true)}
                  ></insight-icon-button>
                  <insight-icon-button
                    id="btn-move-down"
                    title=${this._i18nComp('common', 'moveDown', 'Move Down')}
                    icon-name="arrow_downward"
                    @click=${() => this._dispatchEvent('move-panel', false)}
                  ></insight-icon-button>
                `
              : ''}
            ${this.__checkCanMovePanel(this.__canCollapse)
              ? html`
                  <insight-icon-button
                    id="btn-collapse"
                    title=${this._i18nComp('common', 'collapse', 'Collapse')}
                    icon-name=${this.collapsed ? 'arrow_drop_down' : 'arrow_drop_up'}
                    @click=${this.__collapse}
                  ></insight-icon-button>
                `
              : ''}
          </div>
        </div>
        <div class="panel-body">
          <slot></slot>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          display: block;
        }
        :host .panel-wrapper {
          width: var(--panel-width, unset);
          height: var(--panel-height, unset);
          box-sizing: border-box;
          border: 2px solid var(--panel-border-color, var(--mdc-theme-dark-bg-24, var(--mdc-theme-primary)));
          border-radius: 4px;
          position: relative;
          padding: var(--panel-padding, 8px 0);
          margin: var(--panel-margin, 0 0 24px);
        }
        :host([secondary]) .panel-wrapper {
          border: 2px solid var(--panel-border-color, var(--mdc-theme-dark-bg-24, var(--mdc-theme-surface)));
        }
        :host([error]) .panel-wrapper {
          border: 2px solid var(--mdc-theme-error);
        }
        :host .panel-header {
          position: absolute;
          top: -10px;
          background: var(--panel-header-bg, var(--mdc-theme-background));
          left: 10px;
          padding: var(--panel-header-padding, 0 4px);
        }
        :host([in-dialog]) .panel-header {
          background: var(--mdc-theme-dark-bg-24, var(--mdc-theme-background));
        }
        :host(:not([can-reorder])[can-collapse]) .panel-header {
          cursor: pointer;
        }
        ${
          this.panelTitleHelp
            ? `:host .panel-header {
            padding: var(--panel-header-padding, 0 24px 0 4px);
          }`
            : ''
        }
        :host([inner-label]) .panel-wrapper {
          padding: var(--panel-padding, 18px 16px 8px);
          border: 2px solid var(--panel-border-color, var(--mdc-theme-dark-bg-24, var(--mdc-theme-divider-color)));
        }
        :host([in-dialog]) .panel-wrapper {
          border-color: var(--mdc-theme-dark-highlight, var(--mdc-theme-divider-color));
        }
        :host([inner-label][dense]) .panel-wrapper {
          padding: var(--panel-padding, 18px 16px 2px);
        }
        :host([inner-label]) .panel-header {
          top: 0;
          left: 16px;
          padding: 0;
          background: unset;
        }
        :host([border-thin]) .panel-wrapper {
          border-width: 1px;
        }
        :host .panel-title {
          color: var(--panel-label-color, var(--mdc-theme-dark-ripple, var(--mdc-theme-primary)));
          text-transform: var(--panel-title-transform);
          font-weight: var(--panel-title-weight, 500);
          z-index: 1;
        }
        :host([inner-label]) .panel-title {
          font-weight: unset;
          color: var(--panel-label-color, var(--mdc-theme-dark-ripple, var(--mdc-theme-text-disabled)));
        }
        :host([secondary]) .panel-title {
          color: var(--mdc-theme-text-secondary);
        }
        :host([error]) .panel-title {
          color: var(--mdc-theme-text-error);
        }
        :host .panel-header insight-help-button {
          display: block;
          margin-left: 4px;
          --help-icon-margin: 0;
        }
        :host([filled-title]) .panel-header insight-help-button {
          display: block;
          margin-top: 5px;
          margin-left: -10px;
          --help-icon-margin: unset;
        }
        :host([danger-bg]) .panel-wrapper:before,
        :host([success-bg]) .panel-wrapper:before,
        :host([danger-bg]) .panel-header:before,
        :host([success-bg]) .panel-header:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
        :host([danger-bg]) .panel-wrapper .panel-title:before,
        :host([success-bg]) .panel-wrapper .panel-title:before,
        :host([danger-bg]) .panel-header .panel-title:before,
        :host([success-bg]) .panel-header .panel-title:before {
          content: '';
          position: absolute;
          top: 50%;
          bottom: 0;
          left: 0;
          right: 0;
        }
        :host([danger-bg]) .panel-wrapper:before,
        :host([success-bg]) .panel-wrapper:before {
          opacity: 0.1;
        }
        :host([danger]) .panel-wrapper,
        :host([danger]) .panel-header {
          border-color: var(--mdc-theme-error);
        }
        :host([success]) .panel-wrapper .panel-title,
        :host([danger]) .panel-wrapper .panel-title {
          color: white;
        }
        :host([danger-bg]) .panel-wrapper:before,
        :host([danger-bg]) .panel-header:before {
          background: var(--mdc-theme-error);
        }
        :host([success]) .panel-wrapper,
        :host([success]) .panel-header {
          border-color: var(--mdc-theme-success);
        }
        :host([success-bg]) .panel-wrapper:before,
        :host([success-bg]) .panel-header:before {
          background: var(--mdc-theme-success);
        }
        :host([danger]) .panel-header,
        :host([success]) .panel-header {
          border-width: 2px;
          border-style: solid;
          border-radius: 4px;
          padding: var(--panel-header-padding, 0 16px);
        }
        :host([filled-title]) .panel-wrapper {
          padding-top: 26px;
        }
        :host([filled-title]) .panel-header {
          background: var(--panel-title-bg, var(--mdc-theme-dark-bg-16, var(--mdc-theme-primary)));
          left: 0;
          width: calc(100% + 2px);
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
          margin: 0 -1px;
          padding: 0;
          height: 36px;
        }
        :host([filled-title][secondary]) .panel-header {
          background: var(--panel-title-bg, var(--mdc-theme-dark-bg-16, var(--mdc-theme-surface)));
        }
        :host([filled-title]) .panel-title {
          padding: 8px 16px;
          color: var(--panel-title-color, white);
          font-size: 14px;
        }
        :host([filled-title][secondary]) .panel-title {
          color: var(--panel-title-color, var(--mdc-theme-text-primary));
        }
        :host([filled-title]:not([border-thin])) .panel-wrapper {
          padding-top: 32px;
        }
        :host([filled-title]:not([border-thin])) .panel-header {
          top: -2px;
        }
        :host([filled-title][bold-title]) .panel-title {
          font-weight: 700;
        }
        :host([filled-title][error]) .panel-header {
          background: var(--panel-title-color, var(--mdc-theme-error));
        }
        :host([filled-title][danger]) .panel-header {
          background: var(--panel-title-color, var(--mdc-theme-error));
        }
        :host([filled-title][success]) .panel-header {
          background: var(--panel-title-color, var(--mdc-theme-success));
        }
        :host([filled-title][danger]) .panel-title,
        :host([filled-title][success]) .panel-title {
          color: white;
        }
        :host .panel-header-btns {
          padding: 7px 4px;
        }
        :host .panel-header-btns {
          display: none;
        }
        :host .panel-header #btn-move-up,
        :host .panel-header #btn-move-down {
          display: none;
        }
        :host([can-reorder]) .panel-header .panel-header-btns,
        :host([can-collapse]) .panel-header .panel-header-btns,
        :host([show-buttons]) .panel-header .panel-header-btns,
        :host([extra-buttons]) .panel-header .panel-header-btns {
          display: flex;
        }
        @media (hover: hover) and (pointer: fine) {
          :host([can-reorder]) .panel-header .panel-header-btns,
          :host([can-collapse]) .panel-header .panel-header-btns {
            display: none;
          }
          :host([can-reorder]) .panel-header:hover .panel-header-btns,
          :host([can-collapse]) .panel-header:hover .panel-header-btns {
            display: flex;
          }
          :host .panel-header #btn-move-up,
          :host .panel-header #btn-move-down {
            display: inline-block;
          }
        }
        :host([show-buttons]) .panel-header .panel-header-btns {
          display: flex;
        }
        :host([show-buttons]) .panel-header #btn-move-up,
        :host([show-buttons]) .panel-header #btn-move-down {
          display: inline-block;
        }
        :host .panel-header insight-icon-button {
          width: 22px;
          height: 22px;
          --icon-display: block;
          --icon-vertical-align: top;
          --icon-width: 18px;
          --icon-padding: 2px;
          --icon-color: white;
        }
        :host([secondary]) .panel-header insight-icon-button {
          --icon-color: unset;
        }
        :host .panel-body {
          min-height: var(--panel-min-height, unset);
        }
        :host([collapsed]) .panel-body {
          display: none;
        }
        :host([dense]) .panel-body > slot::slotted(*) {
          line-height: 18px;
        }
        :host([filled-title][collapsed]) .panel-header {
          border-radius: 4px;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-panel', InsightPanel);
