import {InsightElement, html} from '../insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@insight/insight-common/components/container/insight-message-dialog.js';
import '@insight/insight-common/components/ui/insight-icon.js';
import '@insight/insight-common/components/ui/insight-tooltip.js';

class InsightHelpButton extends InsightElement {
  /****************************************************  Public Api  ****************************************************/
  static get properties() {
    return {
      disabled: {type: Boolean, attribute: 'disabled'},
      __helpTitle: {type: String, attribute: 'help-title'},
      __helpMessage: {type: String, attribute: 'help-message'}
    };
  }

  /**************************************************  Private Methods  *************************************************/

  __handleClick() {
    if (this.__helpTitle || this.__helpMessage) this.__dlgEl.show(this.__helpTitle, this.__helpMessage);
  }

  get __dlgEl() {
    return this._getElement('insight-message-dialog');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      ${this._tabletRes
        ? html`
            <div class="icon-wrapper">
              <div class="icon-bg"></div>
              <insight-icon icon-name="help" @click=${this.__handleClick}></insight-icon>
            </div>
            <insight-message-dialog></insight-message-dialog>
          `
        : html`
            <insight-tooltip>
              <div class="icon-wrapper">
                <div class="icon-bg"></div>
                <insight-icon icon-name="help"></insight-icon>
              </div>
              <div slot="tooltip" style="max-width:320px;pointer-events:none;">${unsafeHTML(this.__helpMessage || this.__helpTitle)}</div>
            </insight-tooltip>
          `}
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          position: relative;
        }
        :host([disabled]) {
          cursor: not-allowed;
        }
        :host .icon-wrapper {
          position: relative;
          width: 28px;
          height: 24px;
        }
        :host([field-attached]) .icon-wrapper {
          margin: 0 0 0 -4px;
          height: 40px;
          background: var(--help-icon-bg, var(--mdc-theme-dark-bg-12, var(--mdc-theme-surface)));
        }
        :host([in-divider]) .icon-wrapper {
          background: var(--mdc-theme-background);
        }
        :host([disabled]) insight-icon {
          pointer-events: none;
        }
        :host insight-icon {
          cursor: pointer;
          position: absolute;
          margin: var(--help-icon-margin, 2px 0);
          --icon-color: var(--mdc-theme-secondary);
          --icon-width: var(--help-icon-width, 20px);
          --icon-align: top;
        }
        :host([disabled]) insight-icon {
          --icon-color: var(--mdc-theme-secondary-dim);
        }
        :host([tablet-res]) insight-icon:hover {
          --icon-color: var(--mdc-theme-secondary-dark);
        }
        :host([field-attached]) insight-icon {
          margin: 0;
          height: 40px;
          box-sizing: border-box;
          border-left: none;
          border-bottom: 1px solid rgba(0,0,0,.42);
          border-top-right-radius: 4px;
          padding: 3px 3px 0px 1px;
          --icon-margin: 6px 2px;
        }
        :host([ro-field]) insight-icon {
          border: 1px solid var(--mdc-theme-dark-bg-24, var(--mdc-theme-divider-color));
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-left: none;
          background: var(--help-icon-bg, var(--mdc-theme-background));
        }
        :host([disabled][field-attached]) insight-icon {
          border-bottom: 1px solid rgba(0,0,0,.06);
        }
        :host .icon-bg {
          background: white;
          width: 14px;
          height: 14px;
          position: absolute;
          left: 2px;
          top: 4px;
          border-radius: 50%;
          margin: var(--help-icon-margin, 2px 0);
        }
        :host([field-attached]) .icon-bg {
          left: 6px;
          top: 10px;
        }
        :host insight-message-dialog {
          white-space: initial;
          --dialog-min-width: var(--help-dialog-min-width, 0);
          --dialog-max-width: var(--help-dialog-max-width, 340px);
          --dialog-padding-body: 0 24px 20px;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-help-button', InsightHelpButton);
