import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {ifDefined} from 'lit/directives/if-defined.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '@insight/insight-common/components/container/insight-menu-list.js';
import '@insight/insight-common/components/container/insight-tabs.js';
import '@insight/insight-common/components/form/insight-switch.js';
import '@insight/insight-common/components/layout/insight-master-detail-layout.js';
import {default as componentList} from '@insight/insight-common/catalog/components.js';
import CodeFlask from 'codeflask';

class GymInsightCatalog extends InsightElement {
  constructor() {
    super();
    this.__loadedDemos = [];
  }

  firstUpdated() {
    this.__buildMenu();
    this._listenForWindowResize(() => this._checkMobileRes());
    window.onpopstate = e => this.__buildMenu();
    const splash = document.getElementById('splash-screen');
    if (splash) document.body.removeChild(splash);
    this.__debouncedCodeUpdate = this._debounce(500, this.__codeUpdated).bind(this);
    if (localStorage.getItem('dark-mode') === 'true') {
      this.__isDark = true;
      this._afterRender(() => (this._getElement('.header insight-switch').value = true));
    }
    this._afterRender(() => {
      this._checkMobileRes();
      this.__editor = new CodeFlask(this._getElement('.code-view'), {defaultTheme: false, language: 'js', styleParent: this.shadowRoot});
      this.__editor.onUpdate(this.__debouncedCodeUpdate);
    });
  }

  __buildMenu() {
    const data = [];
    componentList.forEach(cat => {
      data.push({label: cat.category, subtitle: true});
      cat.components.forEach(comp => {
        data.push({label: comp.name, category: cat.category});
      });
      data[data.length - 1].divider = 'after';
    });
    let comp = location.hash;
    comp = !!comp ? comp.substr(1).split('/') : null;
    let focusIdx = 1;
    if (comp && comp.length >= 2) {
      const mi = data.find(d => d.label === comp[1]);
      if (mi) {
        mi.selected = true;
        focusIdx = data.indexOf(mi);
      } else {
        data[1].selected = true;
      }
    } else if (comp && comp.length === 1 && comp[0] === 'editor') {
      this.__showEditor(false, true);
      setTimeout(() => (this._menuEl.data = data), 1000);
      return;
    } else {
      data[1].selected = true;
    }
    this._menuEl.data = data;
    this._afterRender(() => {
      if (comp && comp.length > 2 && comp[0] && comp[1] && comp[2]) {
        this.__showComponent(comp[0], comp[1], comp[2]);
      } else if (comp && comp.length > 1 && comp[0] && comp[1]) {
        this.__showComponent(comp[0], comp[1]);
      } else {
        this.__showComponent(data[1].category, data[1].label);
        window.history.pushState(null, '', 'catalog.html#' + data[1].category + '/' + data[1].label);
      }
      this._afterRender(() => this._menuEl.focusAtIndex(focusIdx));
    });
  }

  __changeComponent(cat, comp) {
    window.history.pushState(null, '', 'catalog.html#' + cat + '/' + comp);
    this.__showComponent(cat, comp);
  }

  __navToEditor(editCurrent) {
    localStorage.removeItem('edit-state');
    window.history.pushState(null, '', 'catalog.html#' + 'editor');
    this.__showEditor(editCurrent);
  }

  __navToComponents() {
    const cat = componentList[0].category;
    const comp = componentList[0].components[0].name;
    window.history.pushState(null, '', 'catalog.html#' + cat + '/' + comp);
    this.__showComponent(cat, comp);
    this._afterRender(() => this._menuEl.focusAtIndex(1));
  }

  async __showComponent(cat, comp, demo) {
    this.__editorShown = false;
    this._getElement('.demo-view').style.overflow = 'auto';
    this.__category = cat;
    this.__component = comp;
    this.__demo = demo;
    let compRef = componentList.find(c => c.category === cat);
    compRef = compRef.components.find(c => c.name === comp);
    this.__configureTabs(compRef.demos, demo);
    // this.__buildDocs(comp);
    if (!demo && compRef.demos.length) demo = compRef.demos[0].element;
    if (demo) {
      if (!this.__loadedDemos.includes(demo)) {
        if (cat) await import(`@insight/insight-common/catalog/views/${cat}/${comp}/${demo}.js`);
        else await import(`@insight/insight-common/catalog/views/${demo}.js`);
        this.__loadedDemos.push(demo);
      }
      this.__updateView(demo);
    } else {
      this.__view = html` <div></div> `;
      if (this.__editor) {
        this.__skipNextCodeUpdate = true;
        this.__editor.updateCode('');
      }
    }
    document.title = 'Insight Catalog - ' + comp;
    this.requestUpdate();
  }

  async __updateView(view, code) {
    const el = document.createElement(view);
    el.classList.add('demo-element');
    this.__view = html` ${el} `;
    this._afterRender(() => {
      const elRef = this._getElement('.demo-element');
      if (!code) this.__skipNextCodeUpdate = true;
      if (elRef.setContent) elRef.setContent(code);
      this.__editor.updateCode(elRef.demo);
    });
  }

  async __showEditor(editCurrent, loadLS) {
    let code;
    if (loadLS) {
      code = localStorage.getItem('edit-state');
      if (code) code = decodeURIComponent(code);
    }
    if (!editCurrent) {
      const view = 'editor-default-view';
      if (!this.__loadedDemos.includes(view)) {
        await import(`@insight/insight-common/catalog/views/${view}.js`);
        this.__loadedDemos.push(view);
      }
      await this.__updateView(view, code);
      document.title = 'Insight Catalog - Editor';
      this._afterRender(() => this._getElement(view).importComponents());
    } else {
      document.title = 'Insight Catalog - Editing ' + this.__component;
    }
    this.__editorShown = true;
    this.requestUpdate();
  }

  __configureTabs(demos, demo) {
    const tabs = demos.map(d => ({name: d.label, element: d.element}));
    if (tabs.length) {
      if (demo) {
        const el = tabs.find(t => t.element === demo);
        if (el) el.active = true;
        else tabs[0].active = true;
      } else {
        tabs[0].active = true;
      }
    }
    this.__tabsEl.tabs = tabs;
  }

  // async __buildDocs(comp) {
  //   console.log(comp);
  //   await customElements.whenDefined(comp);
  //   const cls = customElements.get(comp);
  //   console.log(cls);
  //   // console.log(cls.properties);
  //   // console.log(Object.getOwnPropertyDescriptors(cls.prototype));
  //   Object.getOwnPropertyNames(cls.prototype).forEach(name => {
  //     if (name !== 'constructor' && !name.startsWith('_')) {
  //       console.log(name);
  //       // console.log(cls.prototype[name]);
  //       console.log(Object.getOwnPropertyDescriptor(cls.prototype, name));
  //       // console.log(this.__getFuncParams(cls.prototype[name]).join(','));
  //     }
  //   });
  // }

  // __getFuncParams(func) {
  //   let str = func.toString();
  //   str = str
  //     .replace(/\/\*[\s\S]*?\*\//g, '')
  //     .replace(/\/\/(.)*/g, '')
  //     .replace(/{[\s\S]*}/, '')
  //     .replace(/=>/g, '')
  //     .trim();
  //   const start = str.indexOf('(') + 1;
  //   const end = str.length - 1;
  //   const result = str.substring(start, end).split(', ');
  //   const params = [];
  //   result.forEach(element => {
  //     element = element.replace(/=[\s\S]*/g, '').trim();
  //     if (element.length > 0) params.push(element);
  //   });
  //   return params;
  // }

  __tabChanged({detail: tab}) {
    window.history.pushState(null, '', 'catalog.html#' + this.__category + '/' + this.__component + '/' + tab.element);
    this.__showComponent(this.__category, this.__component, tab.element);
  }

  __codeUpdated(code) {
    if (this.__skipNextCodeUpdate) return (this.__skipNextCodeUpdate = false);
    const demoEl = this._getElement('.demo-element');
    if (this.__editorShown) localStorage.setItem('edit-state', encodeURIComponent(code));
    if (demoEl) demoEl.demo = code;
  }

  __toggleDark(e) {
    localStorage.setItem('dark-mode', e.detail);
    setTimeout(() => window.location.reload(), 500);
  }

  _checkMobileRes() {
    const bc = this.parentElement.getBoundingClientRect();
    document.documentElement.style.setProperty('--vh', `${bc.height * 0.01}px`);
    window.Insight.resolution.width = bc.width;
    window.Insight.resolution.height = bc.height;
    let dbc;
    const dv = this._getElement('.demo-view');
    dbc = !!dv ? dv.getBoundingClientRect() : this._getElement.getBoundingClientRect();
    window.Insight.resolution.mobile = this._mobileRes = dbc.width < 768;
    window.Insight.resolution.tablet = this._tabletRes = dbc.width <= 1024;
  }

  __export() {
    let link = document.createElement('a');
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.__editor.getCode()));
    link.setAttribute('download', `insight-catalog-editor-${new Date().getTime()}.json`);
    document.body.appendChild(link);
    link.click();
    this._afterRender(() => document.body.removeChild(link));
  }

  __import(evt) {
    let files = evt.target.files;
    for (let i = 0, f; (f = files[i]); i++) {
      if (!f.type === 'application/json') continue;
      let reader = new FileReader();
      reader.onload = (() => {
        return e => {
          this.__editor.updateCode(e.target.result);
        };
      })(f);
      reader.readAsText(f);
    }
  }

  get _menuEl() {
    return this._getElement('insight-menu-list');
  }

  get __tabsEl() {
    return this._getElement('#demo-tabs');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      ${unsafeHTML(this.__getThemeCss)}
      <div class="root">
        <div class="flex-layout-horizontal flex-layout-center header">
          <img src="images/insight-logo.png" />
          <div class="typo-headline header-title">Insight Component Catalog</div>
          <div class="flex-layout-flex"></div>
          <div class="flex-layout-horizontal header-btns">
            ${
              this.__editorShown
                ? html`
                    <insight-icon-button icon-name="save" title="Export" @click=${this.__export}></insight-icon-button>
                    <div class="fileUpload" title="Import">
                      <input type="file" @change=${e => this.__import(e)} />
                      <insight-icon-button icon-name="file_upload"></insight-icon-button>
                    </div>
                    <insight-icon-button icon-name="widgets" title="Back to Components" @click=${this.__navToComponents}></insight-icon-button>
                  `
                : html` <insight-icon-button icon-name="construction" @click=${() => this.__navToEditor()} title="View Editor"></insight-icon-button> `
            }
          </div>
          <div class="divider"></div>
          <insight-switch leading @change=${this.__toggleDark}>Dark Mode</insight-switch>
        </div>
        <div class="main">
          <insight-master-detail-layout fixed-size style=${ifDefined(this.__editorShown ? '--master-width:0px;' : undefined)}>
            <div slot="masterContent">
              <insight-menu-list @change=${e => this.__changeComponent(e.detail.category, e.detail.label)}></insight-menu-list>
            </div>
            <div slot="detailContent">
              <div>
                <insight-tabs id="demo-tabs" @change=${this.__tabChanged} scrollable ?hidden=${this.__editorShown}></insight-tabs>
                <div class="flex-layout-vertical demo-panes">
                  <div class="flex-layout-flex demo-view">${this.__view}</div>
                  <div class="flex-layout-flex code-view"></div>
                    ${
                      this.__editorShown
                        ? ''
                        : html`
                            <div class="overlay-btns">
                              <insight-icon-button
                                icon-name="construction"
                                @click=${() => this.__navToEditor(true)}
                                title="Load this demo into the Editor"
                              ></insight-icon-button>
                            </div>
                          `
                    }
                  </div>
                </div>
              </div>
            </div>
          </insight-master-detail-layout>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host .root {
          background: var(--mdc-theme-surface);
        }
        :host .header {
          width: calc(100vw - 32px);
          max-width: 1600px;
          height: 56px;
          margin: 10px auto 0;
          column-gap: 12px;
          background: #337ab7;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding: 0 24px 0 16px;
          box-sizing: border-box;
        }
        :host .header img {
          margin: 8px 0;
          width: 40px;
          height: 40px;
          border: 2px solid #81fa59;
          border-radius: 4px;
          box-sizing: border-box;
          background: #204d74;
          border-style: ridge;
        }
        :host .header-title {
          font-weight: 700;
          color: white;
          text-shadow: 0 1px 1px #000;
        }
        :host .divider {
          height: 24px;
          border-right: 1px solid white;
        }
        :host .header-btns > * {
          --icon-color: white;
        }
        :host .header-btns .fileUpload {
          position: relative;
        }
        :host .header-btns .fileUpload input {
          position: absolute;
          opacity: 0;
          z-index: 2;
          width: 40px;
          height: 60px;
          top: -20px;
          cursor: pointer;
        }
        :host .header insight-switch {
          --switch-label-color: white;
          --switch-color-selected: var(--mdc-theme-primary-dark);
        }
        :host insight-master-detail-layout {
          --md-min-height: calc(100vh - 80px);
          --md-max-height: calc(100vh - 80px);
          --md-width: calc(100vw - 32px);
          --md-max-width: 1600px;
          --md-br-border: 4px;;
        }
        :host div[slot="masterContent"] {
          overflow-y: auto;
        }
        :host div[slot="detailContent"] {
          padding: 0;
        }
        :host div[slot="detailContent"] > div {
          height: 100%;
        }
        :host #demo-tabs {
          --tabs-tab-width: 200px;
        }
        :host .demo-panes {
          height: calc(100% - 48px);
        }
        :host #demo-tabs[hidden] ~ .demo-panes {
          height: 100%;
        }
        :host .demo-view {
          padding: 32px;
          box-sizing: border-box;
          overflow: auto;
        }
        :host .demo-view,
        :host .code-view {
          border-top: 1px solid var(--mdc-theme-divider-color);
          min-height: 50%;
          position: relative;
        }
        :host .code-view > * {
          height: 100%;
        }
        :host .overlay-btns {
          height: 48px;
          position: absolute;
          top: calc(50% + 25px);
          right: 20px;
          width: 48px;
          z-index: 2;
          --icon-color: #aaa;
        }
        :host .overlay-btns:before {
          content: '';
          background: rgba(255,255,255,0.2);
          width: 100%;
          height: 100%;
          position: absolute;
          border-radius: 50%;
        }
      </style>
    `;
  }

  get __getThemeCss() {
    return `
      <style>
        .codeflask {
          background: ${this.__isDark ? 'black' : 'white'};
          color: ${this.__isDark ? '#717cff' : '#4f559c'};
        }
        .codeflask__textarea {
          color: ${this.__isDark ? '#717cff' : '#4f559c'} !important;
          caret-color: ${this.__isDark ? '#cccccc' : '#111111'} !important;
        }
        .codeflask .token.punctuation {
          color: ${this.__isDark ? '#9badb7' : '#4a4a4a'};
        }
        .codeflask .token.keyword {
          color: ${this.__isDark ? '#d19eff' : '#8500ff'};
        }
        .codeflask .token.operator {
          color: ${this.__isDark ? '#ff5598' : '#ff5598'};
        }
        .codeflask .token.string {
          color: ${this.__isDark ? '#41ad8f' : '#41ad8f'};
        }
        .codeflask .token.comment {
          color: ${this.__isDark ? '#9badb7' : '#9badb7'};
        }
        .codeflask .token.function {
          color: ${this.__isDark ? '#d19eff' : '#8500ff'};
        }
        .codeflask .token.boolean {
          color: ${this.__isDark ? '#d19eff' : '#8500ff'};
        }
        .codeflask .token.number {
          color: ${this.__isDark ? '#d19eff' : '#8500ff'};
        }
        .codeflask .token.selector {
          color: ${this.__isDark ? '#d19eff' : '#8500ff'};
        }
        .codeflask .token.property {
          color: ${this.__isDark ? '#d19eff' : '#8500ff'};
        }
        .codeflask .token.tag {
          color: ${this.__isDark ? '#d19eff' : '#8500ff'};
        }
        .codeflask .token.attr-value {
          color: ${this.__isDark ? '#d19eff' : '#8500ff'};
        }
      </style>
    `;
  }
}
window.customElements.define('gym-insight-catalog', GymInsightCatalog);
