import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {i18nMoney} from '@insight/insight-common/i18n/i18n-money.js';
import '@insight/insight-common/components/button/insight-button.js';
import '../../components/common/insight-numpad-input.js';

/* @imgex - none */
class PosQuickCash extends InsightElement {
  static get properties() {
    return {
      balanceDue: {type: Number},
      __amount: {type: Number},
      __cashReceived: {type: Boolean},
      __drawerClosed: {type: Boolean}
    };
  }

  constructor() {
    super();
    this.__amount = 0;
  }

  clear() {
    this.__formEl.clear();
    this.__numpadEl.clear();
  }

  drawerClosed() {
    this.__drawerClosed = true;
  }

  __submit() {
    if (!this.__cashReceived) {
      this.__cashReceived = true;
      this.__drawerClosed = false;
      this._dispatchEvent('cash-received');
      this._dispatchEvent('print-receipt');
    } else {
      this._dispatchEvent('cash-complete', true);
    }
  }

  __getQuickDenoms(balDue) {
    const denoms = [Math.ceil(balDue)];
    [5, 10, 20, 50, 100].some(d => {
      let calc = Math.max(Math.ceil(denoms[0] / d) * d, d);
      if (!denoms.includes(calc)) denoms.push(calc);
      return denoms.length > 3;
    });
    return denoms;
  }

  __useDenom(denom) {
    this.__numpadEl.value = this.__amount = Number(i18nMoney.format(denom).slice(1));
  }

  get __numpadEl() {
    return this._getElement('insight-numpad-input');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div>
        <div class="bal-hdr">
          <div class="typo-title csh-lbl bal-due"><b>${this._i18n('account:addSale.balDue')}:</b><span>${i18nMoney.format(this.balanceDue)}</span></div>
          ${
            this.__cashReceived
              ? html`<div class="typo-title csh-lbl csh-rec">
                    <b>${this._i18n('account:addSale.cashRec')}:</b><span>${i18nMoney.format(this.__amount)}</span>
                  </div>
                  <div class="typo-title csh-lbl chg-due">
                    <b>${this._i18n('account:addSale.chgDue')}:</b><span>${i18nMoney.format(Math.abs(this.balanceDue - this.__amount))}</span>
                  </div>`
              : ''
          }
        </div>
        ${
          this.__cashReceived
            ? this.__drawerClosed
              ? ''
              : html`<div class="typo-title drw-open"><i>${this._i18n('account:addSale.drwOpen')}</i></div>`
            : html`<div class="flex-layout-horizontal csh-cols">
                <div>
                  <insight-numpad-input
                    prefix-icon=${i18nMoney.getCurrencyIcon('USD')}
                    field-label=${this._i18n('account:addSale.amtTend')}
                    @value-change=${e => (this.__amount = Number(e.detail))}
                  >
                  </insight-numpad-input>
                </div>
                <div class="flex-layout-vertical quick-btns">
                  ${this.__getQuickDenoms(this.balanceDue).map(d => {
                    return html`<insight-button contained class="quick-btn" @click=${() => this.__useDenom(d)}>$${d}</insight-button>`;
                  })}
                </div>
              </div>`
        }
        </div>
      </div>
      <div class="flex-layout-horizontal flex-layout-center-center buttons">
        <insight-button id="btn-save" contained success tabindex="0" @click=${this.__submit} ?disabled=${(this.__cashReceived && !this.__drawerClosed) || !this.__amount || this.__amount < this.balanceDue}
          >${this.__cashReceived ? this._i18n('common:finish') : this._i18n('common:pay')}</insight-button
        >
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host .bal-hdr {
          margin-bottom: 24px;
        }
        :host .csh-lbl {
          font-weight: 500;
          color: var(--mdc-theme-text-error);
        }
        :host .csh-rec {
          padding-bottom: 8px;
          color: var(--mdc-theme-text-success);
        }
        :host .chg-due {
          padding-top: 8px;
          border-top: 1px solid var(--mdc-theme-divider-color);
        }
        :host .csh-lbl b {
          color: var(--mdc-theme-text-primary);
          margin-right: 8px;
        }
        :host .csh-lbl span {
          text-align: right;
          width: 100px;
          display: inline-block;
        }
        :host .csh-cols {
          column-gap: 16px;
        }
        :host insight-numpad-input {
          --num-pad-width: min(240px, 60vw);
        }
        :host .quick-btns {
          justify-content: end;
          row-gap: 5px;
          margin-bottom: 5px;
        }
        :host .quick-btn {
          display: block;
          --button-width: 90px;
          --button-height: 40px;
          --button-font-size: 18px;
        }
        :host .buttons {
          margin: 16px;
        }
        :host #btn-save {
          display: block;
          --button-height: 40px;
          --button-min-width: 120px;
        }
        :host([stand-alone]) #btn-save {
          --button-font-size: 20px;
        }
        @media only screen and (max-width: 400px) {
          :host .quick-btn {
            --button-width: 72px;
            --button-padding: 12px;
            --button-font-size: 14px;
          }
        }
      </style>
      ${
        this.__cashReceived
          ? `
      <style>
        :host .bal-due {
          color: var(--mdc-theme-text-primary);
        }
        :host .csh-lbl b {
          margin-right: 0;
          min-width: 160px;
          display: inline-block;
        }
        :host .drw-open {
          text-align: center;
          bottom: 100px;
          margin: 40px 0;
          color: var(--mdc-theme-text-error);
          animation: blink 1s infinite
        }
        @media only screen and (max-width: 400px) {
          :host .csh-lbl b,
          :host .csh-lbl span {
            font-size: 14px;
          }
          :host .csh-lbl b {
            min-width: 120px;
          }
        }
        @keyframes blink {
          from {
            opacity: 0
          }
          to {
            opacity: 1
          }
        }
      </style>
      `
          : ''
      }
  `;
  }
}
window.customElements.define('pos-quick-cash', PosQuickCash);
