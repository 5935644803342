export class CacheManager {
  constructor(appRef) {
    this.__appRef = appRef;
  }

  loadStartupCache() {
    this.__startupCache = localStorage.getItem('startup-cache');
    if (!!this.__startupCache) this.__startupCache = JSON.parse(this.__startupCache);
    else this.__startupCache = undefined;
    if (!!this.__startupCache) Insight.locationChanged = this.__startupCache.isLocationChange;
    localStorage.removeItem('startup-cache');
  }

  setStartupCache(opts) {
    const startupCache = {};
    if (opts.noCache !== true) {
      if (!opts.noCache || !opts.noCache.includes('configs')) startupCache.appConfigs = Insight.configs;
      if (!opts.noCache || !opts.noCache.includes('locationDetails')) startupCache.locationDetails = Insight.location;
      if (!opts.noCache || !opts.noCache.includes('userDetails')) startupCache.userDetails = Insight.user;
      if (!opts.noCache || !opts.noCache.includes('roleSets')) startupCache.roleSets = Insight.roleSets;
    }
    if (opts.isLocationChange) startupCache.isLocationChange = true;
    localStorage.setItem('startup-cache', JSON.stringify(startupCache));
  }
}
