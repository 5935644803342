import {leftAndMiddleClick, InsightComponent, html} from '../insight-component.js';
import {ifDefined} from 'lit/directives/if-defined.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '../button/insight-button.js';
import '../button/insight-icon-button.js';
import '../button/insight-paging-footer.js';
import '../form/insight-checkbox.js';
import '../ui/insight-icon.js';

class InsightDatatable extends InsightComponent {
  static get properties() {
    return {
      noFooter: {type: Boolean, attribute: 'no-footer'},
      noPaging: {type: Boolean, attribute: 'no-paging'},
      canMini: {type: Boolean, attribute: 'can-mini'},
      canMiniTab: {type: Boolean, attribute: 'can-mini-tab'},
      dragDrop: {type: Boolean, attribute: 'can-drag-drop'},
      checkColumn: {type: Boolean, attribute: 'check-column'},
      exclusive: {type: Boolean, attribute: 'exclusive'},
      headers: {type: Array},
      data: {type: Object},
      options: {type: Object},
      summaryTemplate: {type: Object},
      __loading: {type: Boolean},
      __currentPage: {type: Number},
      __currentPageSize: {type: Number},
      __hasScroll: {type: Boolean},
      __tableWidth: {type: Number},
      __draggable: {type: Boolean}
    };
  }

  get currentPage() {
    return this.__currentPage;
  }

  get currentPageStatus() {
    return this.noFooter ? '' : this.__footerEl.currentPageStatus;
  }

  setToPageOne() {
    this.__currentPage = 1;
  }

  setSort(field, asc) {
    this.__sortField = field;
    this.__sortAsc = asc;
  }

  getSelectedItems() {
    if (!this.checkColumn) return [];
    const items = this._getElement('.data-table').children;
    const selected = [];
    let cb;
    Array.from(items).forEach(i => {
      cb = i.children[0].children[0].children[0];
      if (cb.value) selected.push(cb.getAttribute('data-item-id'));
    });
    return selected;
  }

  getContentForPrint() {
    if (!this.data || !this.data.totalAvailable) return '';
    let html = this.__getCss(true, this.__hasScroll).split(':host ').join('');
    if (this.hasAttribute('dense')) html = html.split(':host([dense]) ').join('.data-table ');
    html += this._getElement('.header-row').outerHTML;
    html += this._getElement('.data-table').outerHTML;
    html = html.replace(/data-link/g, '');
    if (this.summaryTemplate) html += this._getElement('.data-table + *').outerHTML;
    return html;
  }

  fetchItems(resetPage) {
    this.__loading = true;
    if (resetPage === true) this.setToPageOne();
    this.data = null;
    if (!!this.checkColumn && !this.exclusive) this.__clearCheckAll();
    this._dispatchEvent('fetch-items', {
      page: this.__currentPage,
      pageSize: this.__currentPageSize,
      sortField: this.__sortField,
      sortAsc: this.__sortAsc,
      datatable: this
    });
  }

  scrollToIndex(index) {
    const dt = this._getElement('.data-table');
    const el = dt.children.item(index);
    el.scrollIntoView();
  }

  constructor() {
    super();
    this.__currentPage = 1;
    this.__currentPageSize = 100;
    this.__boundCopyHandler = this.__copyHandler.bind(this);
    this.addEventListener('copy', this.__boundCopyHandler);
  }

  _destructor() {
    super._destructor();
    if (this.__resizeObserver) this.__resizeObserver.disconnect();
    if (this.__boundCopyHandler) this.removeEventListener('copy', this.__boundCopyHandler);
  }

  firstUpdated() {
    this._afterRender(() => {
      this.__renderReady = true;
      this.__resizeObserver = new ResizeObserver(this._debounce(50, () => this.__checkWidth())).observe(this._getElement('.list-wrapper'));
      this.__checkWidth();
      this.__checkReady();
    });
  }

  __copyHandler(e) {
    const selection = document.getSelection().toString();
    if (!selection) return;
    const lines = selection.split('\n');
    const cols = !!this.headers ? this.headers.length : 0;
    if (cols) {
      const newLines = [];
      let line;
      lines.forEach((l, idx) => {
        if (idx % cols === 0) {
          if (line) newLines.push(line.substr(0, line.length - 1));
          line = '';
        }
        line += l + '\t';
      });
      if (line) newLines.push(line.substr(0, line.length - 1));
      e.clipboardData.setData('text/plain', newLines.join('\n'));
      e.preventDefault();
    }
  }

  __checkWidth() {
    this.__tableWidth = this._getElement('.list-wrapper').offsetWidth;
    if (
      ((this.__wasMobile || this.__wasTablet) && !this._tabletRes && !this._mobileRes) ||
      (!this.__wasMobile && !this.__wasTablet && (this._tabletRes || this._mobileRes)) ||
      (this.__wasMobile && !this._mobileRes && this._tabletRes) ||
      (this.__wasTablet && this._mobileRes)
    )
      this._dispatchEvent('form-factor-change');
    this.__wasTablet = this._tabletRes && !this._mobileRes;
    this.__wasMobile = this._mobileRes;
    this.__checkScroll();
  }

  updated(changedProperties) {
    if (changedProperties.has('data')) {
      this.__dataReady = true;
      this.__checkReady();
      this.__checkScroll();
    }
    if (changedProperties.has('options')) {
      if (this.options.pageSizes) this.__pageSizes = this.options.pageSizes;
      if (this.options.initialPageSize) this.__currentPageSize = this.options.initialPageSize;
    }
  }

  __checkScroll() {
    this._afterRender(() => {
      const st = this._getElement('.data-table');
      if (st) {
        let scrollWas = this.__hasScroll;
        this.__hasScroll = st.scrollHeight > st.clientHeight;
        if (scrollWas !== this.__hasScroll) this._dispatchEvent('scroll-change', this.__hasScroll);
      }
    });
  }

  __checkReady() {
    if (this.__renderReady && this.__dataReady && this.data) {
      if (!this.noFooter) this.__configureFooter(this.data.totalAvailable, this.data.pageSizes);
      this.__loading = false;
    }
  }

  __configureFooter(totalAvailable) {
    const footer = this.__footerEl;
    if (footer) {
      footer.configure({
        pageSizeOptions: this.__pageSizes || [100],
        currentPage: this.__currentPage,
        rowsPerPage: this.__currentPageSize,
        totalRows: totalAvailable,
        rowsPerPageLbl: this._i18nComp(null, 'footerPerPage', 'Rows per page:')
      });
    }
  }

  __pageSizeChanged(e) {
    this.__currentPageSize = e.detail;
    this.__currentPage = 1;
    this.fetchItems();
  }

  __pageUp(e) {
    this.__currentPage++;
    this.fetchItems();
  }

  __pageDown(e) {
    this.__currentPage--;
    this.fetchItems();
  }

  __sort(field, defaultIsAsc) {
    if (!field) return;
    if (this.__loading) return;
    if (field !== this.__sortField) {
      this.__sortField = field;
      this.__sortAsc = defaultIsAsc;
    } else {
      if (this.__sortAsc === undefined) this.__sortAsc = defaultIsAsc;
      else this.__sortAsc = !this.__sortAsc;
    }
    this.fetchItems();
  }

  __check(checked, id) {
    if (this.exclusive) {
      if (checked) {
        const items = this._getElement('.data-table').children;
        let cb;
        Array.from(items).forEach(i => {
          cb = i.children[0].children[0].children[0];
          if (cb.getAttribute('data-item-id') !== id.toString()) cb.value = false;
        });
      }
    } else {
      if (!checked) this.__clearCheckAll();
    }
  }

  __clearCheckAll() {
    const cb = this._getElement('.header-row').children[0].children[0];
    if (cb.value) cb.value = false;
  }

  __checkAll({detail: checked}) {
    const items = this._getElement('.data-table').children;
    Array.from(items).forEach(i => {
      i.children[0].children[0].children[0].value = checked;
    });
  }

  __dragInit(e) {
    this.__draggable = true;
  }

  __dragEnd(e) {
    e.preventDefault();
    this.__draggable = false;
  }

  __dragStart(e) {
    this.__dragItem = e.target;
  }

  __dragOver(e) {
    e.preventDefault();
  }

  __drop(e) {
    this._dispatchEvent('drag-drop', {
      idx: Number(this.__dragItem.getAttribute('data-index')),
      targetIdx: Number(e.target.closest('.table-item').getAttribute('data-index'))
    });
  }

  get __footerEl() {
    return this._getElement('insight-paging-footer');
  }

  _render() {
    let startsIcon = false;
    let endsIcon = false;
    if (!!this.data && !!this.data.rows && !!this.data.rows.length) {
      startsIcon = !!this.data.rows[0].columns[0].iconButton || !!this.data.rows[0].columns[0].isIcon;
      endsIcon =
        !!this.data.rows[0].columns[this.data.rows[0].columns.length - 1].iconButton || this.data.rows[0].columns[this.data.rows[0].columns.length - 1].isIcon;
    }
    const isMini = (this._mobileRes && this.canMini) || (this._tabletRes && this.canMiniTab);
    return html`
      ${unsafeHTML(this.__getCss(false, this.__hasScroll))}${unsafeHTML(this.__miniCss)}
      <div class="flex-layout-vertical list-wrapper">
        <div
          class="flex-layout-horizontal header-row"
          ?data-scroll=${this.__hasScroll}
          ?disabled=${this.__loading}
          ?data-start-icon=${startsIcon}
          ?data-end-icon=${endsIcon}
        >
          ${!!this.dragDrop
            ? html`
                <div class="flex-layout-horizontal header drag-col">&nbsp;</div>
              `
            : ''}
          ${!!this.checkColumn
            ? html`
                <div class="flex-layout-horizontal header check-col" style="">
                  ${!this.exclusive
                    ? html`
                        <insight-checkbox @change=${this.__checkAll}></insight-checkbox>
                      `
                    : ''}
                </div>
              `
            : ''}
          ${!!this.headers
            ? html`
                ${this.headers.map(h => {
                  if (isMini && (h.miniIconButton || h.hideMini)) return '';
                  if (!isMini && h.hidesAt > this.__tableWidth) return '';
                  if (!isMini && h.showsAt < this.__tableWidth) return '';
                  return html`
                    <div
                      class="flex-layout-horizontal header${!!h.sortKey ? ' sortable' : ''}"
                      style=${ifDefined(h.style)}
                      ?data-flex=${!!h.flexAt && h.flexAt > this.__tableWidth}
                      ?has-icon-button=${!!h.iconButton}
                      @click=${() => this.__sort(h.sortKey, true)}
                    >
                      ${h.label ? this._i18nDirect(h.label) : ''}
                      ${!!h.sortKey && this.__sortField === h.sortKey
                        ? html`
                            <div class="sort-indicator" ?data-asc=${h.sortOnlyAsc || (this.__sortAsc && !h.sortOnlyDesc)}></div>
                          `
                        : ''}
                    </div>
                  `;
                })}
              `
            : ''}
        </div>
        ${!this.data
          ? html`
              <style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
              <div id="list-loader" class="flex-layout-flex flex-layout-horizontal flex-layout-center-center" style="margin:16px;">
                <div class="insight-loader" style=${window.Testophobia ? 'border-top-color:#ccc;' : ''}></div>
              </div>
            `
          : !this.data.totalAvailable
            ? html`
                <div class="no-items typo-body2">
                  ${!!this.options && this.options.noDataMessage ? this.options.noDataMessage : this._i18nComp(null, 'noData', 'No records found')}
                </div>
              `
            : html`
                <div class="data-table flex-layout-vertical">
                  ${this.data.rows.map((d, idx) => {
                    return html`
                      <div
                        class="table-item flex-layout-vertical"
                        data-guid="${d.id}"
                        data-index=${idx}
                        style=${ifDefined(d.style)}
                        draggable=${this.__draggable}
                        @dragstart=${this.__dragStart}
                        @dragover=${this.__dragOver}
                        @dragend=${this.__dragEnd}
                        @drop=${this.__drop}
                        ?data-start-icon=${startsIcon}
                        ?data-end-icon=${endsIcon}
                        ?data-caption=${!!d.caption}
                        ?data-scroll=${this.__hasScroll}
                      >
                        <div class="item-fields flex-layout-horizontal">
                          ${!!this.dragDrop
                            ? html`
                                <div class="item-field drag-col" style="">
                                  <insight-icon
                                    id="btn-drag"
                                    icon-name="drag_indicator"
                                    @mousedown=${this.__dragInit}
                                    @mouseup=${this.__dragEnd}
                                  ></insight-icon>
                                </div>
                              `
                            : ''}
                          ${!!this.checkColumn
                            ? html`
                                <div class="item-field check-col" style="">
                                  <insight-checkbox @change=${e => this.__check(e.detail, d.id)} data-item-id=${d.id}>
                                    ${isMini
                                      ? html`
                                          <span>${this._i18nComp('common', 'selectRow', 'Select this row')}</span>
                                        `
                                      : ''}
                                  </insight-checkbox>
                                </div>
                              `
                            : ''}
                          ${d.columns.map((c, idx) => {
                            if (c.exclude)
                              return html`
                                <div class="item-field" style=${ifDefined(isMini ? c.miniStyle || c.style : c.maxStyle || c.style)}></div>
                              `;
                            let valueStyle = !!c.align ? `text-align:${c.align};padding:0;` : '';
                            valueStyle += isMini && !!c.miniValueStyle ? c.miniValueStyle : !!c.valueStyle ? c.valueStyle : '';
                            return html`
                              <div
                                class="item-field${!!c.align ? ' aligned-field' : ''}"
                                style=${ifDefined(isMini ? c.miniStyle || c.style : c.maxStyle || c.style)}
                                ?mobile-multi-line=${!!c.mobileMultiLine}
                                ?mobile-multi-line-no-clamp=${!!c.mobileMultiLineNoClamp}
                                ?hidden=${(isMini && (c.miniIconButton || c.hideMini)) ||
                                (!isMini && (c.hidesAt > this.__tableWidth || c.showsAt < this.__tableWidth))}
                                ?data-flex=${!!c.flexAt && c.flexAt > this.__tableWidth}
                                ?has-button=${!!c.iconButton || !!c.icon || !!c.button}
                                ?has-icon-button=${!!c.iconButton}
                                ?is-template=${!!c.template || !!c.miniTemplate}
                              >
                                ${!!c.checkbox
                                  ? html`
                                      <insight-checkbox style="margin:4px 0;"></insight-checkbox>
                                    `
                                  : !!c.template && (!isMini || !!c.useTemplateMini)
                                    ? isMini && c.miniLabel
                                      ? html`
                                          <div class="flex-layout-horizontal">
                                            <div class="mobile-lbl">
                                              ${c.miniLabel === true ? this._i18nDirect(this.headers[idx].label) : this._i18nDirect(c.miniLabel)}
                                            </div>
                                            <div style=${valueStyle}>${c.template}</div>
                                          </div>
                                        `
                                      : c.template
                                    : !!c.miniTemplate && isMini
                                      ? c.miniLabel
                                        ? html`
                                            <div class="flex-layout-horizontal">
                                              <div class="mobile-lbl">
                                                ${c.miniLabel === true ? this._i18nDirect(this.headers[idx].label) : this._i18nDirect(c.miniLabel)}
                                              </div>
                                              <div style=${valueStyle}>${c.miniTemplate}</div>
                                            </div>
                                          `
                                        : c.miniTemplate
                                      : !!c.iconButton && (!isMini || !c.button)
                                        ? html`
                                            <insight-icon-button
                                              ?success=${c.success}
                                              ?danger=${c.danger}
                                              ?warning=${c.warning}
                                              ?disabled=${c.buttonDisabled}
                                              ?no-focus-circle=${c.iconButtonNoFocus}
                                              icon-name=${c.iconButton}
                                              style=${valueStyle}
                                              title=${ifDefined(!!c.title ? this._i18nDirect(c.title) : undefined)}
                                              @click=${e => c.handler(e)}
                                            ></insight-icon-button>
                                          `
                                        : !!c.icon && (!isMini || !c.value)
                                          ? html`
                                              <insight-icon icon-name=${c.icon} title=${this._i18nDirect(c.title)} style=${valueStyle}></insight-icon>
                                            `
                                          : !!c.button
                                            ? html`
                                                <insight-button
                                                  ?success=${c.success}
                                                  ?danger=${c.danger}
                                                  ?warning=${c.warning}
                                                  ?outlined=${c.outlined}
                                                  ?contained=${c.contained}
                                                  ?unelevated=${c.unelevated}
                                                  ?disabled=${c.buttonDisabled}
                                                  dense
                                                  title=${this._i18nDirect(c.title)}
                                                  style=${valueStyle}
                                                  @click=${e => c.handler(e)}
                                                >
                                                  ${c.button}
                                                </insight-button>
                                              `
                                            : isMini && c.miniLabel
                                              ? html`
                                                  <div
                                                    class="${!!c.typoClass ? c.typoClass : 'typo-body1'} flex-layout-horizontal"
                                                    title=${!!c.value ? c.value : ''}
                                                    ?data-link=${!!c.link}
                                                    ?data-empty-value=${!c.value}
                                                    ${leftAndMiddleClick(e => (!!c.link ? this._navigateOnClick(e, c.link) : null))}
                                                  >
                                                    <div class="mobile-lbl">
                                                      ${c.miniLabel === true ? this._i18nDirect(this.headers[idx].label) : this._i18nDirect(c.miniLabel)}
                                                    </div>
                                                    <div style=${valueStyle}>${!!c.value ? c.value : ''}</div>
                                                  </div>
                                                `
                                              : html`
                                                  <div
                                                    class=${!!c.typoClass ? c.typoClass : 'typo-body1'}
                                                    title=${ifDefined(!!c.title ? this._i18nDirect(c.title) : !!c.value ? c.value : undefined)}
                                                    style=${valueStyle}
                                                    ?data-link=${!!c.link}
                                                    ?data-empty-value=${!c.value}
                                                    ${leftAndMiddleClick(e => (!!c.link ? this._navigateOnClick(e, c.link) : null))}
                                                  >
                                                    ${!!c.value ? c.value : ''}
                                                  </div>
                                                `}
                              </div>
                            `;
                          })}
                          <div class="item-field mini-btn-bar" ?hidden=${!isMini} has-button>
                            ${d.columns
                              .filter(c => c.miniIconButton)
                              .map(c => {
                                if (c.exclude) return '';
                                return html`
                                  <insight-button
                                    class="btn-bar-btn"
                                    contained
                                    unelevated
                                    ?success=${c.success}
                                    ?danger=${c.danger}
                                    ?warning=${c.warning}
                                    ?disabled=${c.buttonDisabled}
                                    style=${c.style}
                                    title=${ifDefined(!!c.title ? this._i18nDirect(c.title) : undefined)}
                                    @click=${e => c.handler(e)}
                                  >
                                    <insight-icon icon-name=${c.iconButton}></insight-icon>
                                  </insight-button>
                                `;
                              })}
                          </div>
                        </div>
                        ${d.caption
                          ? html`
                              <div class="typo-caption row-caption" title=${d.caption.title}>${d.caption.value}</div>
                            `
                          : ''}
                      </div>
                    `;
                  })}
                </div>
              `}
        ${this.summaryTemplate}
        ${!this.noFooter
          ? html`
              <insight-paging-footer
                ?disabled=${this.__loading}
                ?no-paging=${this.noPaging}
                ?no-rows-page=${this._mobileRes || this._tabletRes}
                @page-size-change=${this.__pageSizeChanged}
                @page-up=${this.__pageUp}
                @page-down=${this.__pageDown}
              >
                <slot name="footerButtons" slot="footerButtons"></slot>
              </insight-paging-footer>
            `
          : ''}
      </div>
    `;
  }

  get __miniCss() {
    return `
    <style>
      :host([mobile-res][can-mini]) .header-row,
      :host([tablet-res][can-mini-tab]) .header-row {
        display: none;
      }
      :host([mobile-res][can-mini]) .item-fields,
      :host([tablet-res][can-mini-tab]) .item-fields {
        flex-direction: column;
        padding: 8px 0 7px;
      }
      :host([mobile-res][check-column][can-mini]) .item-fields,
      :host([tablet-res][check-column][can-mini-tab]) .item-fields {
        flex-direction: column;
        padding: 0 0 7px;
      }
      :host([mobile-res][can-mini]) .item-field,
      :host([tablet-res][can-mini-tab]) .item-field {
        padding: 2px 0;
        flex: 1 !important;
        min-width: unset !important;
        max-width: unset !important;
      }
      :host([mobile-res][can-mini]) .item-field[has-button],
      :host([tablet-res][can-mini-tab]) .item-field[has-button] {
        height: 48px;
      }
      :host([mobile-res][can-mini]) .item-field > *,
      :host([tablet-res][can-mini-tab]) .item-field > * {
        line-height: 24px;
        text-align: left !important;
        padding: 0;
      }
      :host([mobile-res][can-mini]) .item-field.aligned-field > *,
      :host([tablet-res][can-mini-tab]) .item-field.aligned-field > * {
        display: flex;
      }
      :host([mobile-res][can-mini]) .header-row[data-start-icon],
      :host([tablet-res][can-mini-tab]) .header-row[data-start-icon],
      :host([mobile-res][can-mini]) .header-row[data-end-icon],
      :host([tablet-res][can-mini-tab]) .header-row[data-end-icon] {
        padding: 8px 16px;
      }
      :host([mobile-res][can-mini]) .table-item[data-start-icon],
      :host([tablet-res][can-mini-tab]) .table-item[data-start-icon],
      :host([mobile-res][can-mini]) .table-item[data-end-icon],
      :host([tablet-res][can-mini-tab]) .table-item[data-end-icon] {
        padding: var(--table-row-padding, 0 16px);
      }
      :host([mobile-res][can-mini]) .item-field > *[data-empty-value],
      :host([tablet-res][can-mini-tab]) .item-field > *[data-empty-value],
      :host([mobile-res][can-mini]) .item-field:has(> *[data-empty-value]),
      :host([tablet-res][can-mini-tab]) .item-field:has(> *[data-empty-value]) {
        display: none;
      }
      :host([mobile-res][can-mini]) .item-field[mobile-multi-line],
      :host([mobile-res][can-mini]) .item-field[mobile-multi-line-no-clamp],
      :host([tablet-res][can-mini-tab]) .item-field[mobile-multi-line],
      :host([tablet-res][can-mini-tab]) .item-field[mobile-multi-line-no-clamp] {
        display: flex;
        align-items: center;
        height: 80px;
      }
      :host([mobile-res][can-mini]) .item-field[mobile-multi-line] *,
      :host([mobile-res][can-mini]) .item-field[mobile-multi-line-no-clamp] *,
      :host([tablet-res][can-mini-tab]) .item-field[mobile-multi-line] *,
      :host([tablet-res][can-mini-tab]) .item-field[mobile-multi-line-no-clamp] * {
        white-space: initial;
      }
      :host([mobile-res][can-mini]) .item-field[mobile-multi-line] *,
      :host([tablet-res][can-mini-tab]) .item-field[mobile-multi-line] * {
        display: -webkit-box;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;
      }
      :host([mobile-res][can-mini]) .item-field[mobile-multi-line] > div > *:not(.mobile-lbl),
      :host([tablet-res][can-mini-tab]) .item-field[mobile-multi-line] > div > *:not(.mobile-lbl),
      :host([mobile-res][can-mini]) .item-field[mobile-multi-line-no-clamp] > div > *:not(.mobile-lbl),
      :host([tablet-res][can-mini-tab]) .item-field[mobile-multi-line-no-clamp] > div > *:not(.mobile-lbl) {
        margin-top: 4px;
      }
      :host([mobile-res][can-mini]) .item-field.check-col insight-checkbox,
      :host([tablet-res][can-mini-tab]) .item-field.check-col insight-checkbox {
        margin-left: -11px;
      }
      :host([mobile-res][can-mini]) .item-field.drag-col,
      :host([tablet-res][can-mini-tab]) .item-field.drag-col {
        display: none;
      }
      :host .mini-btn-bar {
        padding: 0 !important;
        --button-ripple: white;
        --button-label-height: 24px;
        --button-width: 48px;
        --button-padding: 0;
      }
      :host .mini-btn-bar:has(> insight-button) {
        padding: 2px 0 !important;
      }
    </style>
  `;
  }

  __getCss(forPrint, hasScroll) {
    return `
    <style>
      :host .list-wrapper {
        height: 100%;
        min-height: var(--table-list-min-height, unset);
        max-height: var(--table-list-max-height, unset);
      }
      :host .data-table {
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-gutter: stable;
        background: var(--mdc-theme-dark-bg-1, var(--mdc-theme-background));
        ${!forPrint ? 'height: 100%;' : ''}
        ${!forPrint ? '' : 'display:table;width:100%;'}
      }
      :host .header-row {
        padding: 8px 16px;
        min-height: 24px;
        overflow: hidden;
        color: var(--table-header-color, unset);
        border-bottom: solid 1px var(--table-header-border-color, var(--mdc-theme-divider-color));
        background: var(--table-header-bg-color, var(--mdc-theme-dark-bg-4, var(--mdc-theme-background)));
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        scrollbar-gutter: stable;
      }
      :host .header-row ~ *[scroll-margin] > * {
        scrollbar-gutter: stable;
        overflow: hidden;
      }
      :host .header {
        flex: 1;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: capitalize;
      }
      :host .header.sortable {
        color: var(--mdc-theme-dark-primary-light, var(--mdc-theme-primary));
        cursor: pointer;
      }
      :host .header.sortable:hover {
        font-weight: 700;
      }
      :host .header .sort-indicator {
        width: 12px;
        height: 8px;
        margin: 8px 8px 0;
        background-color: var(--mdc-theme-dark-primary-light, var(--mdc-theme-primary));
        -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
                clip-path: polygon(100% 0%, 0 0%, 50% 100%);
      }
      :host .header .sort-indicator[data-asc] {
        -webkit-clip-path: polygon(100% 100%, 0 100%, 50% 0);
                clip-path: polygon(100% 100%, 0 100%, 50% 0);
      }
      :host .header-row[disabled] .header {
        opacity: 0.65;
        cursor: not-allowed;
      }
      :host .header-ph {
        min-width: 44px;
        max-width: 44px;
      }
      :host insight-paging-footer {
        display: block;
        border-top: 1px solid var(--mdc-theme-divider-color);
        --footer-border: none;
        --footer-border-radius: 4px;
      }
      :host([tall-footer]) insight-paging-footer {
        padding-bottom: 24px;
        background: var(--mdc-theme-dark-bg-4, var(--mdc-theme-background));
        --footer-border-radius: 0;
      }
      :host slot::slotted(.footer-buttons) {
        width: 100%;
        margin: 0 8px;
        --button-icon-color: var(--mdc-theme-text-icon);
        --icon-margin: -2px 0 0;
      }
      :host .no-items {
        flex: 1;
        margin: var(--table-no-items-margin, 24px);
        color: var(--mdc-theme-text-secondary);
      }
      :host .table-item {
        padding: var(--table-row-padding, 0 16px);
        background-color: var(--mdc-theme-background);
        border-bottom: solid 1px var(--mdc-theme-divider-color);
        flex-shrink: 0; /*fix for old Safari versions*/
        overflow: hidden;
      }
      :host([check-column]) .header-row,
      :host([check-column]) .table-item {
        padding-left: 8px;
      }
      :host([can-drag-drop]) .drag-col insight-icon {
        cursor: grab;
      }
      :host([no-scroll]) .table-item:last-child,
      :host .table-item[data-scroll]:last-child {
        border-bottom: none;
        margin-bottom: 1px;
      }
      :host .header-row[data-start-icon],
      :host .table-item[data-start-icon] {
        padding-left: 8px;
      }
      :host .header-row[data-end-icon],
      :host .table-item[data-end-icon] {
        padding-right: 0;
      }
      :host([no-padding]) .header-row,
      :host([no-padding]) .table-item {
        padding-left: 0;
        padding-right: 0;
      }
      :host .table-item[data-caption] .item-field {
        padding-bottom: 0;
      }
      :host .item-fields {
        width: 100%;
      }
      :host .item-field {
        flex: 1;
        height: 48px;
        padding: 8px 0 7px;
        min-width: 0;
        position: relative;
      }
      :host .header[has-icon-button],
      :host .item-field[has-icon-button] {
        min-width: 48px;
        max-width: 48px;
      }
      :host([dense]) .item-field {
        height: 36px;
        padding: 2px 0 1px;
      }
      :host([dense]) .header[has-icon-button],
      :host([dense]) .item-field[has-icon-button] {
        min-width: 36px;
        max-width: 36px;
      }
      :host .header[data-flex],
      :host .item-field[data-flex] {
        min-width: unset !important;
        max-width: unset !important;
        flex: 1;
      }
      :host .header.drag-col,
      :host .item-field.drag-col {
        min-width: 24px;
        max-width: 24px;
      }
      :host .header.check-col,
      :host .item-field.check-col {
        min-width: 48px;
        max-width: 48px;
      }
      :host .header.check-col {
        overflow: visible;
      }
      :host .header.check-col insight-checkbox {
        margin: -8px 0;
      }
      :host .item-field.check-col insight-checkbox {
        margin: 4px 0;
      }
      :host([dense]) .item-field.check-col insight-checkbox {
        margin: 0;
        --checkbox-ripple-scale: 1.5;
        --checkbox-size-multi: 0.9;
      }
      :host .item-field > * {
        line-height: 48px;
        font-weight: inherit;
        padding-right: 4px;
      }
      :host .item-field[is-template] > * {
        padding-right: 0;
      }
      :host([dense]) .item-field > * {
        line-height: 36px;
      }
      :host .item-field.aligned-field > * {
        display: block;
      }
      :host .item-field > *,
      :host .item-field > * > * {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      :host .item-field *[data-link] {
        max-width: 100%;
        font-weight: 500;
        cursor: pointer;
        color: var(--mdc-theme-secondary);
      }
      :host .item-field *[data-link]:hover {
        opacity: 0.7;
      }
      :host .item-field insight-icon {
        margin-top: -1px;
        display: block;
      }
      :host .item-field insight-icon-button {
        overflow: hidden;
      }
      :host([dense]) .item-field insight-icon-button {
        width: 28px;
        height: 28px;
        margin-top: 3px;
        --icon-width: 20px;
        --icon-padding: 0;
        --icon-margin: 3px 0 0;
      }
      :host .item-field insight-icon,
      :host .item-field insight-icon-button,
      :host .item-field insight-button {
        padding: 0;
      }
      :host .item-field insight-icon-button:not([danger]):not([success]):not([warning]) {
        --icon-color: var(--mdc-theme-secondary);
      }
      :host .item-field insight-icon-button[disabled] {
        cursor: initial;
        --icon-color: var(--mdc-theme-text-disabled);
      }
      :host .item-field .mobile-lbl {
        width: 160px;
        font-size: 16px !important;
        font-weight: 500;
        color: var(--mdc-theme-text-primary) !important;
        flex-shrink: 0;
        text-transform: capitalize;
      }
      :host([mobile-res]) .item-field .mobile-lbl {
        width: 96px;
      }
      :host .row-caption {
        margin-bottom: 12px;
      }
    </style>
  `;
  }
}
window.customElements.define('insight-datatable', InsightDatatable);
