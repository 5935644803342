import {getReportBase} from '../views/reports/report-registry.js';
import {UserPrefUtils} from '../util/user-pref-utils.js';

export class PrerequisiteAppData {
  constructor(appRef) {
    this.__appRef = appRef;
    this.__startupSemaphore = 2;
  }

  fetchAll() {
    this.__fetchUserDetails();
  }

  ready() {
    return this.__startupSemaphore <= 0;
  }

  checkLocationStatus(route, prefix) {
    let newRoute = null;
    if (!!Insight.logoutReason) newRoute = 'warnings/signout';
    else
      switch (Insight.location.locationStatus) {
        case 'DELINQUENT_NOTICE':
          newRoute = 'warnings/delinquent-warning';
          Insight.location.locationStatus = 'DELINQUENT_NOTICE_SHOWN';
          break;
        case 'DENY_ALL_ACCESS':
          if (route !== 'warnings/delinquent-warning') newRoute = 'warnings/access-denied';
          break;
        case 'CALL_US':
          newRoute = 'warnings/call-us';
          break;
        case 'TRIAL_EXPIRED':
          newRoute = 'warnings/trial-expired';
          break;
        case 'UNCONFIRMED':
          newRoute = 'warnings/pending';
          break;
      }
    if (newRoute) {
      if (prefix) newRoute = '/' + newRoute;
      if (route.hasOwnProperty('path')) route.path = newRoute;
      else route = newRoute;
    }
    return route;
  }

  isDeniedAccess() {
    if (!Insight.location || !Insight.location.locationStatus) return false;
    if (Insight.logoutReason) return true;
    return (
      ['DENY_ALL_ACCESS', 'CALL_US', 'TRIAL_EXPIRED', 'DELINQUENT_NOTICE', 'DELINQUENT_NOTICE_SHOWN', 'UNCONFIRMED'].indexOf(Insight.location.locationStatus) >=
      0
    );
  }

  getStartPage() {
    if (!!window.Testophobia) return '/testophobia';
    const hp = UserPrefUtils.get('general', 'homePage');
    if (hp) return this.__getHomePage(hp);
    else if (this.__appRef._hasAccess('TRAINING:CLIENTLIST:ALL') && !this.__appRef._hasAccess('DASHBOARD:SCREEN:VIEW')) return '/training/home';
    else if (!this.__appRef._hasAccess('DASHBOARD:SCREEN:VIEW')) return '/search';
    else return '/dashboard';
  }

  async finishRegistration(userLinkUrl) {
    Insight.state.sendCommandOneTime(
      'linkUser',
      state => {
        if (state.success) {
          this.fetchAll();
        } else if (state.errorCode) {
          this.__appRef._applicationLoadFailure(
            this.__appRef._i18n(`common:regError.${state.errorCode}`) +
              `
                <br>
                <br>
                <button onclick='javacript:location.href="logout"'>Log Out</button>
              `,
            true
          );
        }
      },
      {
        linkUrl: userLinkUrl
      }
    );
  }

  __applyUserDetails(data, type) {
    if (type === Insight.state.eventType.FETCH) {
      if (data.disabled) {
        this.__lockedOut();
        return true;
      } else {
        Insight.state.sendCommandOneTime('loginComplete', state => {}, {version: Insight.version});
        Insight.user = data;
        Insight.locations = data.locations;
      }
    }
  }

  async __fetchUserDetails() {
    const applyDetails = (user, type) => {
      if (!!user.data) {
        const err = JSON.parse(user.data).detail;
        this.__appRef._applicationLoadFailure(
          err +
            `
                <br>
                <br>
                <button onclick='javacript:location.href="logout"'>Log Out</button>
              `,
          true
        );
        return;
      }
      if (this.__applyUserDetails(user, type)) return;
      if (
        !this.__appRef.__initialRoute ||
        this.__appRef.__initialRoute === '/' ||
        this.__appRef.__initialRoute.startsWith('/?') ||
        this.__appRef.__initialRoute === '/signin' ||
        this.__appRef.__initialRoute === '/signout'
      )
        this.__appRef.__initialRoute = this.getStartPage();
      this.__fetchLocationInformation();
      this.__fetchReportRoleSets();
    };
    await this.__appRef._updateSplashStatus('Retrieving User Details...');
    if (!!this.__appRef.__startupCache && !!this.__appRef.__startupCache.userDetails) {
      Insight.state.setCurrentStateForKey('userDetailsWithLocations', this.__appRef.__startupCache.userDetails);
      applyDetails(this.__appRef.__startupCache.userDetails, Insight.state.eventType.FETCH);
      return;
    }
    Insight.state.fetchOneTime('userDetailsWithLocations', (state, key, type) => {
      applyDetails(state, type);
    });
  }

  async __fetchReportRoleSets() {
    Insight.roleSets = {};
    const applySets = sets => {
      Insight.roleSets = sets;
      this.__checkStartupComplete();
    };
    if (!!this.__appRef.__startupCache && !!this.__appRef.__startupCache.roleSets) {
      applySets(this.__appRef.__startupCache.roleSets);
      return;
    }
    Insight.state.fetchOneTime(
      'currentUserRoleSet',
      state => {
        applySets({reports: state.items === null ? getReportBase(Insight.user.baseRoleGuid || Insight.user.currentLocationRoleGuid) : state.items});
      },
      false,
      {
        type: 'reports'
      }
    );
  }

  async __fetchLocationInformation() {
    const applyLocationInfo = info => {
      Insight.location = info;
      Insight.components = {timezone: info.ianaTimezone};
      this.__appRef.__initialRoute = this.checkLocationStatus(this.__appRef.__initialRoute, true);
      this.__checkStartupComplete();
    };
    await this.__appRef._updateSplashStatus('Retrieving Location Information...');
    if (!!this.__appRef.__startupCache && !!this.__appRef.__startupCache.locationDetails) {
      applyLocationInfo(this.__appRef.__startupCache.locationDetails);
      return;
    }
    Insight.state.fetchOneTime('locationDetails', state => {
      applyLocationInfo(state);
    });
  }

  __checkStartupComplete() {
    this.__startupSemaphore--;
    if (this.__startupSemaphore <= 0) this.__appRef.__startupComplete();
  }

  __lockedOut() {
    this.__appRef._applicationLoadFailure(
      `<span style="color:#ff9090;">Your account is currently disabled!</span><br><br><span>Please contact your location administator for assistance.</span>`,
      true
    );
  }

  __getHomePage(key) {
    switch (key) {
      case 'search':
        return '/search';
      case 'calendar':
        return '/calendar';
      case 'tasks':
        return '/tasks';
      case 'training':
        return '/training/home';
      default:
        return '/dashboard';
    }
  }
}
