import {Mutex} from 'async-mutex';

class InsightAPI {
  constructor() {
    this.__api = {};
    this.__mockEdits = {editedItems: {}, newItems: {}, removedItems: {}, idCounters: {}};
    if (window.Testophobia) {
      if (!window.Testophobia.output) window.Testophobia.output = '';
      import('@insight/insight-common/api/insight-test-validator.js').then(r => (this.__tester = new r.default()));
    }
  }
  async invoke(domain, fn, ...args) {
    let impl = this.__api[domain];
    if (impl instanceof Mutex) {
      (await this.__api[domain].acquire())();
      impl = this.__api[domain];
    } else if (!impl) {
      this.__api[domain] = new Mutex();
      const release = await this.__api[domain].acquire();
      let api = await import(`./${!Insight.configs.mock ? 'server' : 'mock'}/${domain}-api.js`);
      impl = this.__api[domain] = new api.default(this.__mockEdits);
      release();
    }
    if (impl[fn]) {
      if (window.Testophobia && args && args.length > 1) {
        const params = args[1];
        //a few fields that are dynamic and cant be validated at the moment
        if (params) {
          if (params.docFields && params.docFields.fields)
            params.docFields.fields.forEach(f => {
              if (!!f.data) f.data = null;
            });
          else if (params.docFields)
            params.docFields.forEach(f => {
              if (!!f.data) f.data = null;
            });
          this.cleanDynamicFields(params);
        }
        if (!!params) delete params.__stateId;
        const valid = await this.__tester.validateTestData(fn, params, impl);
        if (!valid) return args[0]({});
      }
      if (!Insight.configs.mock) document.dispatchEvent(new CustomEvent('reset-idle', {detail: null}));
      return await impl[fn](...args);
    }
  }
  cleanDynamicFields(obj, depth = 0) {
    if (depth > 5) return;
    for (var k in obj) {
      if (typeof obj[k] === 'object' && obj[k] !== null && !(obj[k] instanceof Blob)) {
        this.cleanDynamicFields(obj[k], depth + 1);
      } else if (obj.hasOwnProperty(k)) {
        switch (k) {
          case 'signature':
          case 'initials':
            obj[k] = '(signatureData)';
            break;
          case 'imageData':
            obj[k] = '(binary)';
            break;
          case 'html':
            obj[k] = obj[k].replace(/"data\:image\/png\;base64([^"]*)"/g, '"(dataUrl)"');
            break;
        }
      }
    }
  }
}

export default InsightAPI;
