import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '@insight/insight-common/components/container/insight-card.js';
import '@insight/insight-common/components/ui/insight-icon.js';

/* @imgex - none */
class PosItemList extends InsightElement {
  static get properties() {
    return {
      standAlone: {type: Boolean, attribute: 'stand-alone'},
      __viewedItems: {type: Array},
      __itemLabel: {type: String},
      __searching: {type: Boolean}
    };
  }

  constructor() {
    super();
    this.__searching = true;
  }

  reset() {
    this.__itemBack();
  }

  firstUpdated() {
    this.reset();
    this.__fetchItemCategories();
  }

  __itemClick(item) {
    if (!this.__viewedItems) {
      this.__searching = true;
      Insight.state.fetchOneTime(
        'posItems',
        state => {
          if (state) {
            this.__viewedItems = state.items;
            this.__itemLabel = `${item.name}`;
            this.__searching = false;
          }
        },
        false,
        {
          categoryGuid: item.guid
        }
      );
    } else {
      const orderItem = JSON.parse(JSON.stringify(item));
      orderItem.quantity = 1;
      orderItem.total = item.amount;
      this._dispatchEvent('add-item', orderItem);
    }
  }

  __fetchItemCategories() {
    this.__searching = true;
    Insight.state.fetchOneTime(
      'posItemCategories',
      state => {
        if (state) {
          this.__categories = state.items;
          this.__searching = false;
        }
      },
      false,
      {}
    );
  }

  __itemBack() {
    this.__itemLabel = this._i18n('account:addSale.categories');
    this.__viewedItems = null;
    this.requestUpdate();
  }

  __hideItems() {
    this._dispatchEvent('hide-items');
    this.__itemBack();
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-card id="crd-cat">
        <div slot="cardContent" class="flex-layout-vertical">
          <div class="${this.standAlone ? 'typo-headline' : 'typo-title'} flex-layout-horizontal flex-layout-center cat-hdr">
            ${this.standAlone || this.__viewedItems || this._tabletRes
              ? html`<div
                  class="flex-layout-horizontal flex-layout-center${(this.standAlone || !this._tabletRes) && !!this.__viewedItems ? ' flex-layout-flex' : ''}"
                >
                  <insight-button id="btn-back" contained ?not-vis=${!this.__viewedItems} @click=${this.__itemBack}>
                    <insight-icon icon-name="arrow_back"></insight-icon>
                    ${this._tabletRes ? '' : html`<span class=${this.standAlone ? 'typo-title' : ''}>Back</span>`}
                  </insight-button>
                </div>`
              : ''}
            <div class="${this._tabletRes || !this.__viewedItems ? 'flex-layout-flex ' : ''}cat-ttl">${this.__itemLabel}</div>
            ${this.standAlone || !this._tabletRes
              ? ''
              : html`<div class="close-div">
                  <insight-icon-button icon-name="close" @click=${this.__hideItems}></insight-icon-button>
                </div>`}
          </div>
          <div class="flex-layout-horizontal flex-layout-wrap flex-layout-evenly-justified cat-list">
            ${this.__searching
              ? html`<style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
                  <div id="list-loader" class="flex-layout-horizontal flex-layout-center-center">
                    <div class="insight-loader" style=${window.Testophobia ? 'border-top-color:#ccc;' : ''}></div>
                  </div>`
              : html`${(this.__viewedItems || this.__categories).map(i => {
                  return html`<div class="flex-layout-vertical flex-layout-center cat-item" @click=${e => this.__itemClick(i)}>
                    <div class="flex-layout-flex-3 flex-layout-horizontal flex-layout-center-center">
                      ${!!i.image ? html`<img draggable="false" src=${i.image} />` : html`<insight-icon icon-name=${i.icon}></insight-icon>`}
                    </div>
                    <div class="lbl-outer">
                      <div class="lbl-inner">
                        <div class="${this.standAlone ? 'typo-subtitle' : 'typo-body2'} flex-layout-flex cat-lbl">${i.name}</div>
                      </div>
                    </div>
                  </div>`;
                })}`}
          </div>
        </div>
      </insight-card>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          height: 100%;
        }
        :host #crd-cat {
          height: 100%;
          --card-explicit-height: 100%;
          --card-padding: 0;
          --card-body-padding-top: 0;
        }
        :host #crd-cat > div {
          height: 100%;
          background: var(--mdc-theme-primary);
          border-radius: 4px;
        }
        :host .cat-hdr {
          padding: 8px 16px;
          color: white;
          background: var(--mdc-theme-primary-dark);
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        :host .cat-hdr insight-button {
          --button-color: #ffac3a;
        }
        :host .cat-hdr span {
          margin-left: 4px;
          color: var(--mdc-theme-primary-dark);;
          font-weight: 700;
          line-height: 36px;
        }
        :host .cat-hdr insight-icon {
          --icon-color: var(--mdc-theme-primary-dark);
        }
        :host .cat-hdr insight-icon-button {
          --icon-color: #fff;
        }
        :host(:not([stand-alone])) .cat-hdr {
          column-gap: 16px;
          min-height: 64px;
          max-height: 64px;
          box-sizing: border-box;
        }
        :host #btn-back[not-vis] {
          visibility: hidden;
        }
        :host([stand-alone]) #btn-back[not-vis] {
          display: none;
        }
        :host .cat-ttl {
          text-align: center;
          font-weight: 700;
        }
        :host .close-div {
          width: 56px;
          text-align: right;
        }
        :host .cat-list {
          padding: 32px;
          column-gap: 32px;
          row-gap: 32px;
          overflow-y: scroll;
        }
        :host .cat-list:has(> #list-loader) {
          height: 100%;
        }
        :host .cat-item {
          width: 120px;
          height: 120px;
          box-sizing: border-box;
          border-radius: 12px;
          padding: 10px 0;
          background: var(--mdc-theme-background);
          box-shadow: var(--mdc-theme-shadow-2);
          cursor: pointer;
        }
        :host([stand-alone]) .cat-item {
          width: 160px;
          height: 160px;
        }
        :host .cat-item:active {
          transform: scale(95%);
          box-shadow: var(--mdc-theme-shadow-1);
        }
        :host .cat-item insight-icon {
          width: 80px;
          height: 80px;
          text-align: center;
          margin-top: 10px;
          --icon-width: 64px;
        }
        :host([stand-alone]) .cat-item insight-icon {
          width: 100px;
          height: 100px;
          --icon-width: 80px;
        }
        :host .cat-item img {
          border-radius: 4px;
          user-select: none;
        }
        :host(:not([stand-alone])) .cat-item img {
          height: 80px;
        }
        :host .cat-item > div:first-child {
          max-height: 80px;
        }
        :host([stand-alone]) .cat-item > div:first-child {
          max-height: 100px;
        }
        :host .lbl-outer {
         display: table;
        }
        :host .lbl-inner {
          display: table-cell;
          vertical-align: middle;
          width: 120px;
          max-width: 120px;
          height: 20px;
        }
        :host([stand-alone]) .lbl-inner {
          width: 160px;
          max-width: 160px;
          height: 48px;
        }
        :host .cat-item .cat-lbl {
          font-weight: 700;
          user-select: none;
          display: -webkit-box;
          white-space: initial;
          text-align: center;
          line-height: 14px;
          padding: 0 4px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        :host([stand-alone]) .cat-item .cat-lbl {
          line-height: 20px;
        }
      </style>
  `;
  }
}
window.customElements.define('pos-item-list', PosItemList);
