import {InsightComponent, html} from '../insight-component.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '../button/insight-button.js';
import '../container/insight-dialog.js';

class InsightIdleDialog extends InsightComponent {
  /****************************************************  Public Api  ****************************************************/

  open(refreshFn, logoutFn) {
    clearTimeout(this.__logoutTimer);
    this.__refreshFn = refreshFn;
    this.__logoutFn = logoutFn;
    this.__dialogEl.open();
    this.__logoutTimer = setTimeout(() => {
      this.__logoutFn();
      this.__dialogEl.close();
    }, 15000);
  }

  reset() {
    clearTimeout(this.__logoutTimer);
    this.__dialogEl.close();
  }

  isOpen() {
    return this.__dialogEl.isOpen();
  }

  /**************************************************  Private Methods  *************************************************/

  __stillHere() {
    clearTimeout(this.__logoutTimer);
    this.__refreshFn();
  }

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dialog id="location-dialog" modal>
        <div slot="modalHeader" class="modal-header flex-layout-horizontal flex-layout-center">
          <div>${this._i18nComp('common', 'idleDlgTitle', 'Session Expiring')}</div>
        </div>
        <div id="modal-body" slot="modalBody">
          ${this._i18nComp('common', 'idleDlgBody', 'Your session is about to expire and there has been no activity in quite a while.  Logging you out...')}
        </div>
        <span slot="modalFooter" class="buttons modal-footer">
          <insight-button contained success dialog-dismiss @click=${this.__stillHere}>
            ${this._i18nComp('common', 'idleDlgBtn', "I'm still here!")}
          </insight-button>
        </span>
      </insight-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host insight-dialog {
          --dialog-max-width: 360px;
          --dialog-padding-footer: 16px;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-idle-dialog', InsightIdleDialog);
