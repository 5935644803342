export default [
  {
    category: 'button',
    components: [
      {
        name: 'insight-button',
        demos: [
          {
            label: 'Styles',
            element: 'insight-button-styles'
          },
          {
            label: 'Loading',
            element: 'insight-button-loading'
          },
          {
            label: 'W/ Icon',
            element: 'insight-button-with-icon'
          }
        ]
      },
      {
        name: 'insight-dropdown-button',
        demos: [
          {
            label: 'Dropdown Button',
            element: 'insight-dropdown-button-main'
          }
        ]
      },
      {
        name: 'insight-fab',
        demos: [
          {
            label: 'Fab Button',
            element: 'insight-fab-main'
          }
        ]
      },
      {
        name: 'insight-help-button',
        demos: [
          {
            label: 'Help Button',
            element: 'insight-help-button-main'
          },
          {
            label: 'Field Attached',
            element: 'insight-help-button-fields'
          }
        ]
      },
      {
        name: 'insight-icon-button',
        demos: [
          {
            label: 'Icon Button',
            element: 'insight-icon-button-main'
          },
          {
            label: 'Readonly Field',
            element: 'insight-icon-button-ro-field'
          }
        ]
      },
      {
        name: 'insight-paging-footer',
        demos: [
          {
            label: 'Paging Footer',
            element: 'insight-paging-footer-main'
          }
        ]
      }
    ]
  },
  {
    category: 'charts',
    components: [
      {
        name: 'insight-bar-chart',
        demos: [
          {
            label: 'Main',
            element: 'insight-bar-chart-main'
          },
          {
            label: 'Stacked',
            element: 'insight-bar-chart-stacked'
          }
        ]
      },
      {
        name: 'insight-line-chart',
        demos: [
          {
            label: 'Main',
            element: 'insight-line-chart-main'
          },
          {
            label: 'Range',
            element: 'insight-line-chart-range'
          }
        ]
      },
      {
        name: 'insight-pie-chart',
        demos: [
          {
            label: 'Main',
            element: 'insight-pie-chart-main'
          },
          {
            label: 'Donut',
            element: 'insight-pie-chart-donut'
          },
          {
            label: 'Labels',
            element: 'insight-pie-chart-labels'
          }
        ]
      }
    ]
  },
  {
    category: 'container',
    components: [
      {name: 'insight-alert-box', demos: [{label: 'Alert Box', element: 'insight-alert-box-main'}]},
      {
        name: 'insight-card',
        demos: [
          {
            label: 'Main',
            element: 'insight-card-main'
          },
          {
            label: 'Body Only',
            element: 'insight-card-body-only'
          },
          {
            label: 'Styling',
            element: 'insight-card-styling'
          }
        ]
      },
      {
        name: 'insight-confirmation-dialog',
        demos: [
          {
            label: 'Confirmation Dialog',
            element: 'insight-confirmation-dialog-main'
          }
        ]
      },
      {
        name: 'insight-datatable',
        demos: [
          {
            label: 'Main',
            element: 'insight-datatable-main'
          },
          {
            label: 'Dense',
            element: 'insight-datatable-dense'
          },
          {
            label: 'Sorting',
            element: 'insight-datatable-sort'
          },
          {
            label: 'No Footer',
            element: 'insight-datatable-no-footer'
          },
          {
            label: 'Flexing',
            element: 'insight-datatable-flex'
          },
          {
            label: 'Checkbox (Multi)',
            element: 'insight-datatable-check'
          },
          {
            label: 'Checkbox (Exclusive)',
            element: 'insight-datatable-check-exclusive'
          },
          {
            label: 'Mini (mobile)',
            element: 'insight-datatable-mini'
          },
          {
            label: 'Drag/Drop',
            element: 'insight-datatable-drag-drop'
          }
        ]
      },
      {
        name: 'insight-dialog',
        demos: [
          {
            label: 'Main',
            element: 'insight-dialog-main'
          },
          {
            label: 'Styling',
            element: 'insight-dialog-styling'
          },
          {
            label: 'Alignment',
            element: 'insight-dialog-align'
          },
          {
            label: 'Sizing',
            element: 'insight-dialog-sizing'
          },
          {
            label: 'Padding',
            element: 'insight-dialog-padding'
          },
          {
            label: 'No Header/Footer',
            element: 'insight-dialog-no-headfoot'
          },
          {
            label: 'Trivial',
            element: 'insight-dialog-trivial'
          },
          {
            label: 'No Dismiss',
            element: 'insight-dialog-dismiss'
          },
          {
            label: 'Extra Header Button',
            element: 'insight-dialog-header-button'
          },
          {
            label: 'Slide Down',
            element: 'insight-dialog-slidedown'
          }
        ]
      },
      {
        name: 'insight-dropdown-container',
        demos: [
          {
            label: 'Dropdown Container',
            element: 'insight-dropdown-container-main'
          }
        ]
      },
      {
        name: 'insight-menu-list',
        demos: [
          {
            label: 'Menu List',
            element: 'insight-menu-list-main'
          }
        ]
      },
      {
        name: 'insight-message-dialog',
        demos: [
          {
            label: 'Message Dialog',
            element: 'insight-message-dialog-main'
          }
        ]
      },
      {
        name: 'insight-panel',
        demos: [
          {
            label: 'Panel',
            element: 'insight-panel-main'
          },
          {
            label: 'Styles - Regular',
            element: 'insight-panel-styles-reg'
          },
          {
            label: 'Styles - Filled',
            element: 'insight-panel-styles-fill'
          },
          {
            label: 'Move/Collapse',
            element: 'insight-panel-move-collapse'
          }
        ]
      },
      {
        name: 'insight-simple-crud-list',
        demos: [
          {
            label: 'Simple CRUD list',
            element: 'insight-simple-crud-list-main'
          },
          {
            label: 'Hide Mini',
            element: 'insight-simple-crud-list-mini'
          }
        ]
      },
      {
        name: 'insight-snackbar',
        demos: [
          {
            label: 'Standard',
            element: 'insight-snackbar-main'
          },
          {
            label: 'Options',
            element: 'insight-snackbar-options'
          }
        ]
      },
      {
        name: 'insight-stepper',
        demos: [
          {
            label: 'Stepper',
            element: 'insight-stepper-main'
          }
        ]
      },
      {
        name: 'insight-tabs',
        demos: [
          {
            label: 'Main',
            element: 'insight-tabs-main'
          },
          {
            label: 'Scrollable',
            element: 'insight-tabs-scrollable'
          },
          {
            label: 'Styles',
            element: 'insight-tabs-styles'
          },
          {
            label: 'Icons',
            element: 'insight-tabs-icons'
          }
        ]
      }
    ]
  },
  {
    category: 'filters',
    components: [
      {
        name: 'insight-date-filter',
        demos: [
          {label: 'Date Filter', element: 'insight-date-filter-main'},
          {label: 'Range', element: 'insight-date-filter-range'}
        ]
      },
      {name: 'insight-filter-bar', demos: [{label: 'Filter Bar', element: 'insight-filter-bar-main'}]},
      {name: 'insight-list-filter', demos: [{label: 'List Filter', element: 'insight-list-filter-main'}]},
      {name: 'insight-month-filter', demos: [{label: 'Month Filter', element: 'insight-month-filter-main'}]},
      {name: 'insight-monthday-filter', demos: [{label: 'MonthDay Filter', element: 'insight-monthday-filter-main'}]},
      {name: 'insight-search-filter', demos: [{label: 'Search Filter', element: 'insight-search-filter-main'}]},
      {name: 'insight-text-filter', demos: [{label: 'Text Filter', element: 'insight-text-filter-main'}]}
    ]
  },
  {
    category: 'form',
    components: [
      {name: 'insight-checkbox', demos: [{label: 'Checkbox', element: 'insight-checkbox-main'}]},
      {name: 'insight-checkbox-list', demos: [{label: 'Checkbox List', element: 'insight-checkbox-list-main'}]},
      {name: 'insight-color-input', demos: [{label: 'Color Input', element: 'insight-color-input-main'}]},
      {
        name: 'insight-date-picker',
        demos: [
          {label: 'Date Picker', element: 'insight-date-picker-main'},
          {label: 'Styles', element: 'insight-date-picker-styles'},
          {label: 'Range', element: 'insight-date-picker-range'}
        ]
      },
      {
        name: 'insight-dropdown-menu',
        demos: [
          {label: 'Dropdown Menu', element: 'insight-dropdown-menu-main'},
          {label: 'Styles', element: 'insight-dropdown-menu-styles'}
        ]
      },
      {name: 'insight-file-upload', demos: [{label: 'File Upload', element: 'insight-file-upload-main'}]},
      {name: 'insight-form', demos: [{label: 'Form', element: 'insight-form-main'}]},
      {name: 'insight-form-divider', demos: [{label: 'Form Divider', element: 'insight-form-divider-main'}]},
      {
        name: 'insight-html-editor',
        demos: [
          {label: 'HTML Editor', element: 'insight-html-editor-main'},
          {label: 'Required', element: 'insight-html-editor-required'},
          {label: 'No Media', element: 'insight-html-editor-nomedia'}
        ]
      },
      {
        name: 'insight-image-upload',
        demos: [
          {label: 'Image Upload', element: 'insight-image-upload-main'},
          {label: 'Styles', element: 'insight-image-upload-styles'}
        ]
      },
      {
        name: 'insight-input',
        demos: [
          {label: 'Main', element: 'insight-input-main'},
          {label: 'Styles', element: 'insight-input-styles'},
          {label: 'Types', element: 'insight-input-types'},
          {label: 'Icons', element: 'insight-input-icons'},
          {label: 'Validation', element: 'insight-input-validation'}
        ]
      },
      {name: 'insight-list-input', demos: [{label: 'Main', element: 'insight-list-input-main'}]},
      {
        name: 'insight-number-input',
        demos: [
          {label: 'Main', element: 'insight-number-input-main'},
          {label: 'Styles', element: 'insight-number-input-styles'}
        ]
      },
      {
        name: 'insight-radio-group',
        demos: [
          {label: 'Main', element: 'insight-radio-group-main'},
          {label: 'Styles', element: 'insight-radio-group-styles'},
          {label: 'Validation', element: 'insight-radio-group-validation'}
        ]
      },
      {name: 'insight-readonly-field', demos: [{label: 'Main', element: 'insight-readonly-field-main'}]},
      {name: 'insight-search-input', demos: [{label: 'Main', element: 'insight-search-input-main'}]},
      {name: 'insight-search-list', demos: [{label: 'Main', element: 'insight-search-list-main'}]},
      {name: 'insight-signature', demos: [{label: 'Main', element: 'insight-signature-main'}]},
      {name: 'insight-state-dropdown', demos: [{label: 'Main', element: 'insight-state-dropdown-main'}]},
      {name: 'insight-switch', demos: [{label: 'Switch', element: 'insight-switch-main'}]},

      {
        name: 'insight-textarea',
        demos: [
          {label: 'Main', element: 'insight-textarea-main'},
          {label: 'Styles', element: 'insight-textarea-styles'}
        ]
      },
      {name: 'insight-time-picker', demos: [{label: 'Main', element: 'insight-time-picker-main'}]}
    ]
  },
  {
    category: 'layout',
    components: [
      {
        name: 'flex-form-row',
        demos: [{label: 'Flex Rows', element: 'flex-form-row-main'}]
      },
      {
        name: 'insight-master-detail-layout',
        demos: [
          {label: 'Master/Detail', element: 'insight-master-detail-layout-main'},
          {label: 'Mobile', element: 'insight-master-detail-layout-mobile'},
          {label: 'W/ Close', element: 'insight-master-detail-with-close'}
        ]
      }
    ]
  },
  {
    category: 'ui',
    components: [
      {
        name: 'insight-avatar',
        demos: [
          {
            label: 'Avatars',
            element: 'insight-avatar-main'
          }
        ]
      },
      {
        name: 'insight-badge',
        demos: [
          {
            label: 'Badges',
            element: 'insight-badge-main'
          }
        ]
      },
      {
        name: 'insight-cc-icon',
        demos: [
          {
            label: 'CC Icons',
            element: 'insight-cc-icon-main'
          }
        ]
      },
      {
        name: 'insight-chip',
        demos: [
          {
            label: 'Chips',
            element: 'insight-chip-main'
          }
        ]
      },
      {
        name: 'insight-icon',
        demos: [
          {
            label: 'Icons',
            element: 'insight-icon-main'
          },
          {
            label: 'Readonly Field',
            element: 'insight-icon-ro-field'
          }
        ]
      },
      {
        name: 'insight-keyboard',
        demos: [
          {
            label: 'Standard',
            element: 'insight-keyboard-main'
          },
          {
            label: 'Numeric',
            element: 'insight-keyboard-numeric'
          },
          {
            label: 'Pass code',
            element: 'insight-keyboard-password'
          }
        ]
      },
      {
        name: 'insight-pdf-signer',
        demos: [
          {
            label: 'PDF Signer',
            element: 'insight-pdf-signer-main'
          }
        ]
      },
      {
        name: 'insight-progress',
        demos: [
          {
            label: 'Progress Bar',
            element: 'insight-progress-main'
          }
        ]
      },
      {
        name: 'insight-section-label',
        demos: [
          {
            label: 'Section label',
            element: 'insight-section-label-main'
          }
        ]
      },
      {
        name: 'insight-shimmer',
        demos: [
          {
            label: 'Shimmer',
            element: 'insight-shimmer-main'
          }
        ]
      },
      {
        name: 'insight-tag-list',
        demos: [
          {
            label: 'Tag List',
            element: 'insight-tag-list-main'
          },
          {
            label: 'Editable',
            element: 'insight-tag-list-editable'
          }
        ]
      },
      {
        name: 'insight-tooltip',
        demos: [
          {
            label: 'Tooltip',
            element: 'insight-tooltip-main'
          }
        ]
      },
      {
        name: 'insight-usage-chart',
        demos: [
          {
            label: 'Usage Chart',
            element: 'insight-usage-chart-main'
          }
        ]
      }
    ]
  }
];
