import {AccountUtils} from './account-utils.js';
import {StringUtils} from './string-utils.js';

const PricingPlanUtils = {
  getPriceParts: plan => {
    return {
      base: plan.baseRate ?? 0,
      members: (((plan.minMembers || plan.totalMemberQty) ?? 0) - (plan.freeMemberQty ?? 0)) * (plan.costPerMember || 0),
      dkts: ((plan.totalDkQty ?? 0) - (plan.freeDkQty ?? 0)) * (plan.costPerDk || 0),
      messages: ((plan.totalMessageQty ?? 0) - (plan.freeMessageQty ?? 0)) * (plan.costPerMessage || 0)
    };
  },
  calcPrice: plan => {
    const parts = PricingPlanUtils.getPriceParts(plan);
    return parts.base + parts.members + parts.dkts + parts.messages;
  },
  calcOverages: (plan, used, type) => {
    const numOver = used - plan[`total${type}Qty`];
    if (numOver <= 0) return 0;
    return numOver * plan[`costPer${type}Overage`];
  },
  calcDownPayment: (plan, items) => {
    let itemTtl = 0;
    items.forEach(i => {
      itemTtl += i.amount || 0;
      itemTtl += i.shipping || 0;
    });
    if (!plan) return itemTtl;
    return PricingPlanUtils.calcPrice(plan) + itemTtl;
  },
  getIntervalString: (plan, i18n) => {
    const type = AccountUtils.getInterval(plan.intervalType);
    if (plan.interval !== 1) return `${i18n('common:every')} ${plan.interval} ${StringUtils.capitalize(type.plural)}`;
    else return StringUtils.capitalize(type.span);
  },
  getPricingString: (plan, i18n) => {
    return `$${PricingPlanUtils.calcPrice(plan)} ${PricingPlanUtils.getIntervalString(plan, i18n)} `;
  }
};

export {PricingPlanUtils};
