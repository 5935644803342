import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-button.js';
import '../../../components/common/insight-numpad-input.js';
import {PosMainView} from './pos-main-view.js';

/* @imgex - none */
class PosLoginView extends InsightElement {
  __login() {
    window.parent.postMessage({type: 'can-scan-dkt'}, location.origin);
    this._dispatchEvent('menu-nav', {view: PosMainView.Views.Menu});
  }

  _render() {
    if (!this.__viewReady) this._afterRender(() => (this._getElement('.pin-inner').style.opacity = '1'));
    return html`
      ${unsafeHTML(this.__css)}
      <div id="pin-main">
        <div class="flex-layout-vertical flex-layout-center-center pin-inner" style="opacity:0;">
          <div class="flex-layout-vertical pin-ctr">
            <div id="loc-logo">
              <img src="/images/generic_logo.png" />
            </div>
            <div class="typo-subtitle pin-lbl">Sign in with your PIN code</div>
            <insight-numpad-input password-field field-label="Employee Pin #" initial-value="1234" suffix-icon="visibility"></insight-numpad-input>
            <div class="pin-footer">
              <insight-button id="btn-signin" contained @click=${this.__login}>Sign In</insight-button>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
    <style>
      :host #pin-main {
        background: var(--mdc-theme-primary);
        min-width: 1366px;
        max-width: 1366px;
        min-height: 768px;
        max-height: 768px;
        overflow: hidden;
      }
      :host .pin-inner {
        width: 100%;
        height: 768px;
        row-gap: 32px;
        transition: opacity 500ms;
      }
      :host .pin-ctr {
        background: var(--mdc-theme-background);
        border-radius: 4px;
        padding: 32px;
        box-shadow: var(--mdc-theme-shadow-4);
      }
      :host #loc-logo img {
        width: 240px;
        margin-bottom: 8px;
      }
      :host .pin-lbl {
        color: var(--mdc-theme-primary);
        text-align: center;
        margin-bottom: 24px;
      }
      :host .pin-footer {
        margin-top: 24px;
        width: 100%;
        text-align: center;
      }
    </style>
  `;
  }
}
window.customElements.define('pos-login-view', PosLoginView);
