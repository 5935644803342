const consoleOverride = (function (origConsole) {
  const ignoredArr = [
    'Load failed',
    'Fetch is aborted',
    'Locations could not be retrieved',
    'Location details could not be retrieved',
    'NetworkError when attempting to fetch resource',
    'Not Found - Status: 404',
    'Request timed out'
  ];
  const checkIgnored = arg => {
    if (!arg.indexOf) return true;
    let ignored = false;
    ignoredArr.every(i => {
      if (arg.indexOf(i) >= 0) {
        ignored = true;
        return false;
      }
      return true;
    });
    return ignored;
  };
  const getAuthSub = () => {
    if (Insight.auth && Insight.auth.sub) return Insight.auth.sub;
    if (!!Insight.getOidcSub) return Insight.getOidcSub();
    return undefined;
  };
  const getLevelLbl = lvl => {
    switch (lvl) {
      case 0:
        return 'Debug';
      case 1:
        return 'Info';
      case 2:
        return 'Warn';
      case 3:
        return 'Error';
      default:
        return 'Unknown';
    }
  };
  const checkLevel = lvl => {
    const curr = Insight.logging.level;
    if (!curr) return true;
    return lvl >= curr;
  };
  const addDebugLog = (lvl, msg) => {
    if (!checkLevel(lvl)) return;
    if (!Insight.logging.logs) Insight.logging.logs = [];
    else if (Insight.logging.length > 100) Insight.logging.shift();
    const log = [
      new Date().toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
      }),
      getLevelLbl(lvl),
      msg
    ];
    Insight.logging.logs.unshift(log);
    window.dispatchEvent(new CustomEvent('log-added'));
  };
  return {
    assert: (...args) => origConsole.assert(...args),
    debug: (...args) => {
      if (!!Insight.logging) addDebugLog(0, args.join(' - '));
      origConsole.debug(...args);
    },
    insightDebug: (channel, ...args) => {
      if (!!Insight.logging && (!Insight.logging.source || Insight.logging.source === 'ALL' || Insight.logging.source === channel))
        addDebugLog(0, channel + ' - ' + args.join(' - '));
      origConsole.debug(...args);
    },
    dir: (...args) => origConsole.dir(...args),
    log: (...args) => {
      if (!!Insight.logging) addDebugLog(1, args.join(' - '));
      origConsole.log(...args);
    },
    info: (...args) => {
      if (!!Insight.logging) addDebugLog(1, args.join(' - '));
      origConsole.info(...args);
    },
    warn: (...args) => {
      if (!!Insight.logging) addDebugLog(2, args.join(' - '));
      origConsole.warn(...args);
    },
    error: (...args) => {
      if (!!Insight.logging) addDebugLog(3, args.join(' - '));
      origConsole.error(...args);
      if (!!Insight.configs && !Insight.configs.runConfig) {
        if (args && args.length > 0 && checkIgnored(args[0])) return;
        const stringArgs = JSON.stringify(args);
        if (stringArgs === '{}') return;
        const msgObj = {
          appName: Insight.appName,
          appVersion: Insight.version,
          timestamp: new Date().getTime(),
          browser: Insight.browser ? ' - ' + Insight.browser.toString() : null,
          detail: Insight.appName + ' - ' + stringArgs
        };
        if (Insight.auth && Insight.auth.clientId) msgObj.clientId = Insight.auth.clientId;
        if (Insight.auth && Insight.auth.locationId) msgObj.locationId = Insight.auth.locationId;
        msgObj.sub = getAuthSub();
        if (Insight.user && Insight.user.firstName) msgObj.name = Insight.user.firstName + ' ' + Insight.user.lastName;
        else if (Insight.auth && Insight.auth.name) msgObj.name = Insight.auth.name;
        if (Insight.location && Insight.location.locationName) msgObj.location = Insight.location.locationName;
        if (window.location) msgObj.path = window.location.href;
        const message = JSON.stringify(msgObj);
        if (!!Insight.configs.insightApiUrl) {
          fetch(Insight.configs.insightApiUrl + '/ui/logs', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({message: message, log_level: 4})
          });
        }
      }
    },
    logInfoToServer: message => {
      if (!!Insight.configs && !Insight.configs.runConfig) {
        if (!!Insight.configs.insightApiUrl) {
          fetch(Insight.configs.insightApiUrl + '/ui/logs', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              message: JSON.stringify({
                timestamp: new Date().getTime(),
                detail: message
              }),
              log_level: 2
            })
          });
        }
      }
    },
    time: (...args) => origConsole.time(...args),
    timeEnd: (...args) => origConsole.timeEnd(...args),
    timeLog: (...args) => origConsole.timeLog(...args)
  };
})(window.console);
if (Insight.version !== 'DEV') window.console = consoleOverride;
else window.console.insightDebug = (...args) => console.debug(args);
