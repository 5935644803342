const i18nPhone = {
  format: val => {
    //takes the last 10 digits and formats as (###) ###-####
    if (!val || val.length < 10) return '';
    if (val.match(/^\(\d\d\d\)\s\d\d\d-\d\d\d\d$/)) return val;
    val = val.substr(-10);
    return `(${val.slice(0, 3)}) ${val.slice(3, 6)}-${val.slice(6, 10)}`;
  },
  getAreaCode: val => {
    if (!val) return null;
    let code;
    if (val.startsWith('+')) code = val.substr(2, 3);
    else if (val.startsWith('(')) code = val.substr(1, 3);
    else return null;
    if (code.match(/^\d\d\d$/)) return code;
    else return null;
  }
};

export {i18nPhone};
