import {InsightComponent, html} from '../insight-component.js';
import {ifDefined} from 'lit/directives/if-defined.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import './insight-checkbox.js';

class InsightCheckboxList extends InsightComponent {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      noSelectAll: {type: Boolean, attribute: 'no-select-all'},
      exclusive: {type: Boolean, attribute: 'exclusive'},
      disabled: {type: Boolean, attribute: 'disabled'},
      selectedIconName: {type: String, attribute: 'selected-icon-name'},
      deselectedIconName: {type: String, attribute: 'deselected-icon-name'},
      options: {type: Array}
    };
  }

  get selectedItems() {
    const selected = [];
    const chks = this._getElement('#filter-checkbox-options insight-checkbox[data-value]', true);
    chks.forEach(c => c.value && selected.push(this.options.find(o => o.value.toString() === c.getAttribute('data-value'))));
    return selected;
  }

  set selectedItems(items) {
    const chks = this._getElement('#filter-checkbox-options insight-checkbox[data-value]', true);
    chks.forEach(c => (c.value = items && items.some(o => o.value.toString() === c.getAttribute('data-value'))));
    const selAll = this.__selectAllEl;
    if (selAll) selAll.value = false;
    this._afterRender(() => (this._getElement('#filter-checkbox-options').scrollTop = 0));
  }

  clear() {
    this.__selectAllFilters({detail: false});
  }

  validate() {
    return true;
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.options = [];
  }

  __selectAllFilters({detail}) {
    const chks = this._getElement('insight-checkbox[data-value]', true);
    chks.forEach(chk => (chk.value = detail));
  }

  __filterChecked(e) {
    if (this.exclusive) {
      const cb = this._getEventPath(e)[0];
      this._getElement('#filter-checkbox-options insight-checkbox[data-value]', true).forEach(chk => (chk !== cb ? (chk.value = false) : null));
    } else {
      const selAll = this.__selectAllEl;
      if (selAll && !e.detail) selAll.value = false;
    }
  }

  get __selectAllEl() {
    return this._getElement('#filter-list-select-all');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div id="filter-checkbox-list">
        ${this.noSelectAll || this.exclusive
          ? ''
          : html`
              <insight-checkbox id="filter-list-select-all" @change=${e => this.__selectAllFilters(e)} ?disabled=${this.disabled}>
                <span>${this._i18nComp(null, 'selectAll', 'Select All')}</span>
              </insight-checkbox>
            `}
        <div id="filter-checkbox-options">
          ${this.options.map(
            fi => html`
              <insight-checkbox
                data-value=${fi.value}
                ?checked=${fi.selected}
                ?disabled=${this.disabled}
                @change=${this.__filterChecked}
                selected-icon-name=${ifDefined(this.selectedIconName)}
                deselected-icon-name=${ifDefined(this.deselectedIconName)}
              >
                <span style=${ifDefined(fi.style)}>${fi.label}</span>
              </insight-checkbox>
            `
          )}
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host insight-checkbox {
          display: block;
          padding-right: 11px;
        }
        :host insight-checkbox:last-child {
          margin-bottom: 0;
        }
        :host #filter-list-select-all {
          border-bottom: 1px solid var(--mdc-theme-divider-color);
        }
        :host #filter-checkbox-options {
          overflow-y: auto;
          max-height: var(--list-max-height);
        }
      </style>
    `;
  }
}
window.customElements.define('insight-checkbox-list', InsightCheckboxList);
