import {InsightAppBase, html} from '@insight/insight-common/components/insight-app-base.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@insight/insight-common/components/auth/auth-token-refresh.js';
import '@insight/insight-common/components/auth/insight-idle-dialog.js';
import {default as initializeAppState} from './app-root/insight-state.js';
import {default as routerModel} from './app-root/router-model.js';
import {CacheManager} from './app-root/cache-manager.js';
import {PrerequisiteAppData} from './app-root/prerequisite-app-data.js';
import {SessionManager} from './app-root/session-manager.js';
import {getReportRoutes} from './views/reports/report-registry.js';
import {UserPrefUtils} from './util/user-pref-utils.js';
import './app-root/media-registrations.js';
import './components/app-shell/app-shell.js';

class GymInsight extends InsightAppBase {
  constructor() {
    super('GymInsight', ['common', 'components', 'appshell', 'account', 'reports', 'agreement', 'financial']);
    if (this._loadFailed) return;
    this.__initialNav = true;
    this.__idleReset = this._debounce(1000, () => {
      const el = this.__tokenRefreshEl;
      if (el) el.resetIdleTimer();
    });
    this.__cacheMgr = new CacheManager(this);
    this.__prereqData = new PrerequisiteAppData(this);
    this.__sessionMgr = new SessionManager(this);
    this.__cacheMgr.loadStartupCache();
  }

  async firstUpdated() {
    if (this._loadFailed) return;
    let userLinkUrl;
    if (location.search.includes('type=user')) {
      this.__clearAuth = true;
      userLinkUrl = location.href;
      localStorage.setItem('user-link-url', userLinkUrl);
    } else {
      userLinkUrl = localStorage.getItem('user-link-url');
    }
    await this._configure();
    let lv = localStorage.getItem('insight-logging');
    if (!!lv) {
      lv = JSON.parse(lv);
      const ts = lv.t;
      if (isNaN(ts) || Number(ts) < new Date().getTime() - 3600000) localStorage.removeItem('insight-logging');
      else Insight.logging = {level: lv.l, source: lv.s};
    }
    await this._init(initializeAppState());
    await this._updateSplashStatus('Authenticating...');
    if (this.__initialRoute === '/warnings/signout') this.__initialRoute = '';
    if (this.__clearAuth) this._authEl.clearAuth();
    if (!window.Insight.auth || !window.Insight.auth.accessToken) {
      if (!(await this._authEl.login())) return;
    }
    if (!!userLinkUrl) {
      localStorage.removeItem('user-link-url');
      this.__prereqData.finishRegistration(userLinkUrl);
    } else if (location.href.endsWith('/logout')) {
      this.__sessionMgr.logout(!!this.__router && this.__router.isInitialized ? 5 : null);
    } else {
      this.__prereqData.fetchAll();
    }
  }

  _addEventListeners() {
    super._addEventListeners();
    document.addEventListener('api-error', this.__handleAPIError.bind(this));
    document.addEventListener('log-out', e => this.__sessionMgr.logout(e.detail));
    document.addEventListener('become-user', e => this.__sessionMgr.becomeUser(e.detail));
    document.addEventListener('reload-app', e => this.__reloadApplication(e.detail));
    document.addEventListener('reset-idle', e => this.__resetIdle(e.detail));
  }

  __resetIdle() {
    const el = this.__tokenRefreshEl;
    if (!!el && !this.__prereqData.isDeniedAccess()) this.__idleReset();
  }

  __handleAPIError(e) {
    if (e.detail.status > 400 && e.detail.status <= 403) {
      console.warn(e.detail.message);
      if (e.detail.status === 403 && Insight.locationChanged) return this._performNavigation({detail: ''});
      if (!this.__prereqData.isDeniedAccess()) return this.__sessionMgr.logout(!this._appReady ? 0 : e.detail.status === 401 ? 1 : 2);
      else return;
    }
    if (!this._appReady) {
      this._applicationLoadFailure(e.detail.message);
    } else {
      if (!!window.Testophobia && e.detail.status === 'DATAVAL')
        this._dispatchEvent('show-confirm', {title: 'Validation Error', message: e.detail.message.replaceAll('\n', '<br>'), maxWidth: '95vw'});
      else this.__appShellEl.showSnackbar({message: e.detail.message, error: true});
    }
  }

  __startupComplete() {
    this.requestUpdate();
    this._afterRender(() => {
      this._setupRouter(getReportRoutes().concat(routerModel));
      this.__appShellEl.init();
      this.__tokenRefreshEl.init();
      this.__listenForStorageChanges();
    });
  }

  async __viewLoaded({detail: headerCfg}) {
    await this._updateSplashStatus('Ready!');
    this._applicationReady();
    const shell = this.__appShellEl;
    if (shell) {
      shell.applyHeaderConfig(headerCfg);
      shell.applyActivityShown();
    }
  }

  _performNavigation({detail: route}) {
    if (route === undefined || route === null) return;
    if (typeof route === 'string' && route === '') route = this.__prereqData.getStartPage().substr(1);
    else if (route.hasOwnProperty('path') && route.path === '') route.path = this.__prereqData.getStartPage().substr(1);
    Insight.locationChanged = false;
    this.__checkNoteReload();
    route = this.__prereqData.checkLocationStatus(route);
    if (!this.__prereqData.isDeniedAccess()) this.__idleReset();
    super._performNavigation({detail: route});
    if (this._mobileRes || this._tabletRes) this.__appShellEl.closeActivity();
  }

  _handleNavigation(route, context) {
    super._handleNavigation(route, context);
    const shell = this.__appShellEl;
    shell.setActiveNavigation(context ? context.pathname : '');
    if (!this.__initialNav) shell.navigationComplete();
    this.__initialNav = false;
  }

  _checkMobileRes() {
    const wasMobile = this._mobileRes === true;
    const wasTablet = this._tabletRes === true;
    super._checkMobileRes();
    const shell = this.__appShellEl;
    if (shell) {
      if (!wasMobile && !wasTablet && (this._mobileRes || this._tabletRes)) shell.activityShown = false;
      if ((wasMobile || wasTablet) && !this._mobileRes && !this._tabletRes && UserPrefUtils.get('access-activity-panel', 'activityShown'))
        shell.activityShown = true;
      this._afterRender(() => shell.applyActivityShown());
    }
  }

  __checkNoteReload() {
    if (!!Insight.reloadNotificationUtc) {
      const lastReload = localStorage.getItem('note-reload');
      if (!lastReload || lastReload !== Insight.reloadNotificationUtc) {
        localStorage.setItem('note-reload', Insight.reloadNotificationUtc);
        console.error('Application force-reloaded via notification!');
        this.__reloadApplication({noCache: true});
      }
    }
  }

  __idleTimeout(skipSync) {
    if (!skipSync) {
      window.localStorage.setItem('idle-shown', true);
      setTimeout(() => window.localStorage.removeItem('idle-shown'), 5000);
    }
    this._getElement('insight-idle-dialog').open(
      () => this.__tokenRefreshEl.resetIdleTimer(),
      () => this.__sessionMgr.logout(3)
    );
  }

  __serverLogout() {
    this.__sessionMgr.serverLogout();
  }

  __reloadApplication(opts) {
    opts = opts || {};
    this.__cacheMgr.setStartupCache(opts);
    if (opts.newTab) setTimeout(() => window.open(opts.url, '_blank'), 1);
    else if (opts.url) setTimeout(() => (window.location.href = opts.url), 1);
    else setTimeout(() => window.top.location.reload(), 1);
  }

  __listenForStorageChanges() {
    window.onstorage = async e => {
      if (e.key === 'idle-reset' && !!e.newValue) {
        this._getElement('insight-idle-dialog').reset();
        this.__tokenRefreshEl.resetIdleTimer(true);
      } else if (e.key === 'idle-shown' && !!e.newValue) {
        if (!this._getElement('insight-idle-dialog').isOpen()) this.__idleTimeout(true);
      } else if (e.key === 'token-renew' && !!e.newValue) {
        this._authEl.reloadTokenFromStorage();
      }
    };
  }

  get __appShellEl() {
    return this._getElement('app-shell');
  }

  get __tokenRefreshEl() {
    return this._getElement('auth-token-refresh');
  }

  get _viewportEl() {
    return this._getElement('.viewport');
  }

  get _viewEl() {
    const vp = this._viewportEl;
    return vp ? vp.firstElementChild : null;
  }

  _getSubclassTemplate() {
    if (this._loadFailed || !this.__prereqData.ready()) return '';
    return html`
      ${unsafeHTML(this.__css)}
      <app-shell .locations=${Insight.locations} @reset-idle=${() => this.__tokenRefreshEl.resetIdleTimer()}>
        <div slot="content" class="viewport" @view-loaded=${this.__viewLoaded}>${this._view}</div>
      </app-shell>
      <auth-token-refresh
        .authEl=${this._authEl}
        .disabled=${window.Testophobia || Insight.configs.mock}
        @idle-timeout=${() => this.__idleTimeout()}
        @force-logout=${() => this.__sessionMgr.logout(4)}
      ></auth-token-refresh>
      <insight-idle-dialog></insight-idle-dialog>
    `;
  }

  get __css() {
    return `
    <style>
    :host #main-activity-show {
      display: none;
      z-index: 1;
    }
    </style>
  `;
  }
}
window.customElements.define('gym-insight', GymInsight);
