import {InsightElement, html} from './insight-element.js';
export {html};
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import getBrowserInfo from '../util/browser-detection.js';
import './infrastructure/insight-logging.js';
import {InsightRouter} from './util/insight-router.js';
import {loadTranslationFiles, loadCommonTranslationFile} from './util/insight-localization.js';

export class InsightAppBase extends InsightElement {
  static get AuthType() {
    return {
      None: 0,
      OAuth: 1,
      Client: 2
    };
  }

  constructor(appName, defaultXlateModules = ['common']) {
    super();
    if (['GymInsight', 'MembersApp'].includes(appName)) {
      setTimeout(() => {
        if (this._isProd() && !this._appReady) window.top.location.reload();
      }, 15000);
    }
    Insight.appName = appName;
    this.__defaultXlateModules = defaultXlateModules;
    this.__authType = InsightAppBase.AuthType.OAuth;
    this.__modulesLoaded = [];
    if (window.location.pathname === '/reset') {
      this.__resetApplication();
      return;
    }
    this.__router = new InsightRouter();
    Insight.browser = getBrowserInfo();
    this.__checkBrowserVersion();
    this.__checkWidgetId();
  }

  async _configure(configFileSuffix, quiet, realClock) {
    if (!!this.__startupCache && !!this.__startupCache.appConfigs) Insight.configs = this.__startupCache.appConfigs;
    else await this.__loadConfiguration(configFileSuffix, quiet, realClock);
    await this.__checkClock();
    if (window.Testophobia && !Insight.configs.mock) document.write('⚠⚠⚠   Warning: Tring to run Testophobia tests against live services!   ⚠⚠⚠');
  }

  async _init(state) {
    Insight.state = state;
    if (this.__authType === InsightAppBase.AuthType.OAuth) await import('./auth/insight-auth.js');
    else if (this.__authType === InsightAppBase.AuthType.Client) await import('./auth/insight-auth-client.js');
    this.requestUpdate();
    await new Promise((resolve, reject) => {
      this._afterRender(async () => {
        try {
          if (!this._noAuthReq) await this._authEl.init(Insight.configs);
        } catch (e) {
          console.warn('Error occurred initializing auth', e);
          let message = e.message;
          if (message.includes('in the past') || message.includes('in the future')) {
            message = 'please check your system date and time';
            this._applicationLoadFailure('Authentication error: ' + message);
            reject();
            return;
          }
        }
        this._checkMobileRes();
        this._addEventListeners();
        resolve();
      });
    });
    if (!this._noAuthReq) this.__initialRoute = await this._authEl.getRedirectUrl();
    else this.__initialRoute = window.location.pathname + window.location.search;
  }

  _setAuthType(type) {
    this.__authType = type;
  }

  get _noAuthReq() {
    return this.__authType === InsightAppBase.AuthType.None;
  }

  async _setupRouter(routerModel) {
    if (routerModel && routerModel.length) this.__router.init(routerModel, this._handleNavigation.bind(this));
    localStorage.removeItem('redirectUrl'); //need just for client-auth now
    this.__router.navigate(this.__initialRoute || '/', true);
  }

  _addEventListeners() {
    this.addEventListener('click', () => {}); // https://github.com/material-components/material-components-web/issues/4441
    this.addEventListener('navigate-app', this._performNavigation.bind(this));
    this.addEventListener('navigate-external', this._navigateExternal.bind(this));
    this._listenForWindowResize(() => this._checkMobileRes());
  }

  _parseAPIError(detail) {
    let msg;
    if (typeof detail === 'string') {
      msg = detail;
    } else {
      msg = Object.keys(detail)
        .reduce((arr, i) => arr.push(this._i18n(`common:error.${detail[i]}`, {field: i})), [])
        .join('\n');
    }
    return msg;
  }

  _performNavigation({detail: route}) {
    if (route === undefined || route === null) return;
    if (typeof route === 'string') {
      this.__router.navigate(`/${route}`);
    } else {
      if (route.event && route.event instanceof MouseEvent && (route.event.type === 'click' || route.event.type === 'auxclick')) {
        if (route.event.ctrlKey || route.event.metaKey || route.event.shiftKey || route.event.which === 2) {
          this._dispatchEvent('reload-app', {newTab: true, url: window.location.origin + '/' + route.path});
          return;
        }
      }
      this.__router.navigate(`/${route.path}`, route.replaceHistory);
    }
  }

  _navigateExternal({detail: url}) {
    if (!this._isMock()) window.location.href = url;
    else this._dispatchEvent('show-snackbar', 'Navigate: ' + url);
  }

  _handleNavigation(route, context) {
    if (!route) return;
    let routeToUse;
    if (!!route.feature && this._hasFeature(route.feature.slug)) routeToUse = route.feature;
    else routeToUse = route;
    const sameRoute = this._activeRoute === route;
    this._activeRoute = route;
    const finishNav = () => {
      if (!sameRoute) {
        const el = document.createElement(routeToUse.view);
        el.classList.add('active-view');
        this._view = html`
          ${el}
        `;
        this.requestUpdate();
        this._afterRender(() => this._checkMobileRes());
      }
      if (context) this.__proxyRouteParams(context);
    };
    if (!!routeToUse.importer && !this.__modulesLoaded.includes(routeToUse.view)) {
      this.__modulesLoaded.push(routeToUse.view);
      routeToUse.importer().then(() => finishNav());
    } else {
      finishNav();
    }
  }

  _applicationReady() {
    this._removeSplashScreen();
    const vp = this._viewportEl;
    if (vp) vp.style.opacity = 1;
    this._appReady = true;
  }

  _removeSplashScreen() {
    const splash = document.getElementById('splash-screen');
    if (splash) document.body.removeChild(splash);
  }

  _updateSplashStatus(message) {
    if (this.__finalFailure) return;
    return new Promise(resolve => {
      const splash = document.querySelector('.splash-status');
      if (splash) splash.innerHTML = message;
      this._afterRender(resolve);
    });
  }

  _applicationLoadFailure(message, isFinal) {
    this._loadFailed = true;
    if (this.__finalFailure) return;
    this.__finalFailure = isFinal;
    const splash = document.querySelector('.splash-status');
    if (splash) {
      splash.innerHTML = message;
      splash.style.display = 'block';
      splash.style.top = '52vh';
      document.querySelector('.splash-loader').style.display = 'none';
    }
  }

  _checkMobileRes() {
    const bc = this.parentElement.getBoundingClientRect();
    document.documentElement.style.setProperty('--vh', `${bc.height * 0.01}px`);
    window.Insight.resolution.width = bc.width;
    window.Insight.resolution.height = bc.height;
    window.Insight.resolution.mobile = this._mobileRes = bc.width < 768;
    window.Insight.resolution.tablet = this._tabletRes = bc.width <= 1024;
  }

  async performUpdate() {
    if (!this.__i18nReady) {
      await loadCommonTranslationFile();
      await loadTranslationFiles(this.__defaultXlateModules);
      this.__i18nReady = true;
    }
    super.performUpdate();
  }

  async __loadConfiguration(configFileSuffix, quiet, realClock) {
    const suffix = !configFileSuffix ? '' : configFileSuffix;
    if (!quiet) await this._updateSplashStatus('Configuring Application...');
    const configfile = new URLSearchParams(location.search).get('demo-mode') ? 'demo' : 'config';
    await fetch(`${configfile}${suffix}.json?d=${new Date().getTime()}`)
      .then(r => r.json())
      .then(c => {
        Insight.configs = Object.assign({}, Insight.configs, c);
        if (!window.Testophobia) Insight.configs.realClock = realClock;
      });
  }

  async __checkClock() {
    if (this._isMock() && !Insight.configs.realClock) {
      Insight.oldDateCtr = Date;
      let FakeTimers = await import('@sinonjs/fake-timers');
      FakeTimers.install({now: 2069000375000, shouldAdvanceTime: true});
      // FakeTimers.install({now: 2069043575000, shouldAdvanceTime: true});
    }
  }

  __proxyRouteParams(context) {
    this._afterRender(() => {
      if (context && this._viewportEl.lastElementChild._handleRouteParams) this._viewportEl.lastElementChild._handleRouteParams(context);
    });
  }

  __resetApplication() {
    localStorage.clear();
    this._applicationLoadFailure('Application cache cleared<br><br><a style="color:white !important" href="/">Click to Return</a>', true);
  }

  __serverLogout() {
    if (!this._noAuthReq) {
      // console.error('Received a server logout event, logging out...');
      this._authEl.logout(!!Insight.currentLocation ? {location_id: Insight.currentLocation} : undefined);
    }
  }

  __checkWidgetId() {
    const widget = new URLSearchParams(location.search).get('widgetId');
    if (!!widget) Insight.widgetId = widget;
  }

  __checkBrowserVersion() {
    if (this._noAuthReq) return;
    if (!Object.hasOwn) {
      Object.hasOwn = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);
      document.body.lastElementChild.insertAdjacentHTML('afterend', this.__oldBrowserVersionHtml);
    }
  }

  get _authEl() {
    if (this._noAuthReq) return null;
    switch (this.__authType) {
      case InsightAppBase.AuthType.Client:
        return this._getElement('insight-auth-client');
      default:
        return this._getElement('insight-auth');
    }
  }

  get _routerEl() {
    return this.__router;
  }

  /*abstract*/ get _viewportEl() {
    return null;
  }

  /*abstract*/ get _viewEl() {
    return null;
  }

  /*abstract*/ _getSubclassTemplate() {
    return '';
  }

  /*abstract*/ _loginComplete() {}

  _render() {
    return html`
      ${unsafeHTML(this.___css)} ${this._getSubclassTemplate()}
      ${this.__authType === InsightAppBase.AuthType.Client
        ? html`
            <insight-auth-client></insight-auth-client>
          `
        : this.__authType === InsightAppBase.AuthType.OAuth
          ? html`
              <insight-auth @server-logout=${this.__serverLogout} @login-complete=${this._loginComplete}></insight-auth>
            `
          : ''}
    `;
  }

  get ___css() {
    return `
      <style>
        :host .viewport {
          opacity: 0;
          transition: opacity 0.135s linear;
          position: relative;
          background-color: var(--mdc-theme-surface);
          box-shadow: inset 1px 1px 1px 0 rgba(0,0,0,.08), inset 1px 1px 2px 1px rgba(0,0,0,.1), inset -1px 0 1px 0 rgba(0,0,0,.08), inset -1px 0 2px 1px rgba(0,0,0,.1);
        }
        :host .viewport > *:last-child {
          opacity: 0;
          transition: opacity 0.135s linear;
          padding: 16px 32px 32px;
          display: block;
          height: 100%;
          box-sizing: border-box;
        }
        @media only screen and (max-width: 680px) {
          :host .viewport > *:last-child {
            padding: 4px;
          }
        }
      </style>
    `;
  }

  get __oldBrowserVersionHtml() {
    return `
      <div id="dlg-brws-vrsn" style="z-index:10000000;position:fixed;top:0;left:0;width:100%;height:100%;color:#000;">
        <style>@media screen and (max-width:399px){.btn-row{white-space:nowrap;margin-left:-16px;}}@media screen and (max-width:599px){.dlg-main{top:20px !important;width:98% !important;left:1% !important;height:auto !important;}}.dlg-button:hover{box-shadow:4px 4px 20px 0 rgba(0,0,0,.2);-webkit-transform:translate(0,-2px) scale(1.02);-ms-transform:translate(0,-2px) scale(1.02);transform:translate(0,-2px) scale(1.02);}</style>
        <div style="background:rgba(0,0,0,0.6);width:100%;height:100%;"></div>
        <div class="dlg-main" style="position:absolute;width:560px;background:white;top:calc(50vh - 200px);left:calc(50vw - 280px);border-radius:4px;box-shadow:0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);">
          <div style="padding:16px 24px;">
            <div style="display:flex;flex-direction:row;">
              <h1 style="width:100%;margin-bottom:10px;line-height:1.4em;font-weight:700;margin-top:0;font-size:20px;"><span style="color:#e96e40;">Please Update your Web Browser to the Latest Version</span></h1>
            </div>
            <div style="width:100%;height:4px;border-radius:500px;background-image:linear-gradient(90deg,#3498db,#e96e40);margin-bottom:20px;"></div>
            <div style="line-height:1.4em;margin-bottom:10px;font-size:16px;">The web browser you are using appears to be too old to run this application properly.<br><br><i>(${Insight.browser.toString()})</i><br><br>Please update the browser to the latest version.<br><br>If the device is too old to update the web browser, consider installing a different web browser or using another device or computer.</div>
            <div class="btn-row" style="padding-top:20px;text-align:right;">
              <a id="btn-close" onclick="javascript:document.body.removeChild(document.getElementById('dlg-brws-vrsn'))" style="text-decoration:none;max-width:100%;display:inline-block;cursor:pointer;"><div class="dlg-button" style="padding:10px 25px;border:3px solid #16405c;border-radius:10px;background-color:#fff;-webkit-transition:all .3s cubic-bezier(.455,.03,.515,.955);transition:all .3s cubic-bezier(.455,.03,.515,.955);color:#16405c;font-size:18px;font-weight:500;text-decoration:none;">OK</div></a>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}
