import {InsightComponent, html} from '../insight-component.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '../button/insight-button.js';
import './insight-dialog.js';

class InsightConfirmationDialog extends InsightComponent {
  static get properties() {
    return {
      danger: {type: Boolean, attribute: 'danger'},
      warning: {type: Boolean, attribute: 'warning'},
      success: {type: Boolean, attribute: 'success'},
      __noTitle: {type: Boolean},
      __confirmationPause: {type: Boolean},
      __spinOnPause: {type: Boolean},
      __noButtons: {type: Boolean},
      __noFooter: {type: Boolean},
      __confirmationTitle: {type: String},
      __confirmationBody: {type: Object},
      __confirmationSubmitFn: {type: Function},
      __confirmationCancelFn: {type: Function},
      __confirmationBtnText: {type: String}
    };
  }

  /****************************************************  Public Api  ****************************************************/

  show(params) {
    this.__noTitle = params.noTitle || false;
    this.__confirmationPause = false;
    this.__spinOnPause = params.spinOnPause;
    this.__confirmationTitle = params.title || this._i18nComp(null, 'areYouSure', 'Are You Sure?');
    this.__confirmationBody = params.body;
    this.__noButtons = !params.submitFn;
    this.__confirmationSubmitFn = params.submitFn;
    this.__confirmationCancelFn = params.cancelFn;
    this.__confirmationBtnText = params.submitBtnText || this._i18nComp(null, 'ok', 'OK');
    this.__focusEl = params.focusEl;
    this.__maxWidth = params.maxWidth;
    this.__bodyPadding = params.bodyPadding;
    this.__noDismiss = params.noDismiss;
    this.danger = !!params.danger;
    this.warning = !!params.warning;
    this.success = !!params.success;
    this.__addEventListeners();
    this.__dialogEl.open();
    this.focusElement();
  }

  close() {
    this.__removeEventListeners();
    this.__dialogEl.close();
  }

  enable() {
    this.__confirmationPause = false;
  }

  updateTitle(newTitle) {
    this.__confirmationTitle = newTitle;
  }

  updateBody(newBody) {
    this.__confirmationBody = newBody;
  }

  updateSubmitFn(fn) {
    this.__confirmationSubmitFn = fn;
  }

  focusElement() {
    if (this.__focusEl)
      setTimeout(() => {
        this._getElement(this.__focusEl).setFocus();
      }, 200);
  }

  /**************************************************  Private Methods  *************************************************/

  _destructor() {
    super._destructor();
    this.__removeEventListeners();
  }

  __addEventListeners() {
    this.__removeEventListeners();
    this.__boundEnterHandler = this.__handleEnterKey.bind(this);
    this.addEventListener('keydown', this.__boundEnterHandler);
  }

  __removeEventListeners() {
    if (this.__boundEnterHandler) this.removeEventListener('keydown', this.__boundEnterHandler);
    this.__boundEnterHandler = null;
  }

  __handleEnterKey(e) {
    if (e.key === 'Enter' || e.keyCode === 13) this.__handleConfirmationSubmit();
  }

  __handleConfirmationSubmit() {
    this.__confirmationPause = true;
    if (this.__confirmationSubmitFn) this.__confirmationSubmitFn();
  }

  __handleConfirmationCancel() {
    this.close();
    if (this.__confirmationCancelFn) this.__confirmationCancelFn();
  }

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dialog
        id="confirm-dialog"
        modal
        ?can-dismiss=${!this.__noDismiss}
        ?hide-header=${this.__noTitle}
        ?hide-footer=${this.__noButtons}
        ?disabled=${this.__confirmationPause}
        @dismissed=${this.__handleConfirmationCancel}
      >
        >
        <div slot="modalHeader" class="modal-header" ?hidden=${this.__noTitle}>${this.__confirmationTitle}</div>
        <div id="modal-body" slot="modalBody">
          ${typeof this.__confirmationBody === 'string' ? unsafeHTML(this.__confirmationBody) : this.__confirmationBody}
        </div>
        <span slot="modalFooter" class="buttons modal-footer">
          <insight-button
            id="btn-confirm"
            ?danger=${this.danger}
            ?success=${this.success}
            ?warning=${this.warning}
            contained
            @click=${this.__handleConfirmationSubmit}
            ?disabled=${this.__confirmationPause}
            ?loading=${this.__confirmationPause && this.__spinOnPause}
          >
            ${this.__confirmationBtnText}
          </insight-button>
        </span>
      </insight-dialog>
    `;
  }

  get __css() {
    const mw = !!this.__maxWidth ? (isNaN(this.__maxWidth) ? this.__maxWidth : this.__maxWidth + 'px') : '480px';
    const bp = !!this.__bodyPadding ? `--dialog-padding-body:${this.__bodyPadding};` : '';
    return `
      <style>
        :host #modal-body b {
          color: var(--mdc-theme-dark-primary-light, var(--mdc-theme-primary));
        }
        :host #modal-body em {
          font-weight: bold;
          font-style: initial;
          color: var(--mdc-theme-text-error)
        }
        :host #modal-body strong {
          font-weight: bold;
          font-style: initial;
          color: var(--mdc-theme-text-primary)
        }
        :host #modal-body i {
          font-weight: initial;
          font-style: italic;
          color: var(--mdc-theme-text-primary)
        }
        :host #modal-body mark {
          font-weight: bold;
          background-color: initial;
          color: var(--mdc-theme-text-success)
        }
        :host #confirm-dialog {
          --dialog-max-width: min(${mw}, 96vw);
          ${bp}
        }
        :host #confirm-dialog[hide-header] {
          --dialog-min-height: 100px;
          ${!!bp ? bp : '--dialog-padding-body: 20px 48px 20px 24px'}
        }
      </style>
    `;
  }
}
window.customElements.define('insight-confirmation-dialog', InsightConfirmationDialog);
