import {ViewBase, html} from '@insight/insight-common/components/insight-view-base.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@insight/insight-common/components/container/insight-card.js';
import '@insight/insight-common/components/container/insight-message-dialog.js';
import '@insight/insight-common/components/container/insight-stepper.js';
import '../../../components/registration/register-confirm.js';
import '../../../components/registration/register-location.js';
import '../../../components/registration/register-plan.js';
import '../../../components/registration/register-review.js';
import InsightAPIUtils from '../../../api/insight-api-utils.js';
import {PricingPlanUtils} from '../../../util/pricing-plan-utils.js';
import {default as MockAPI} from './api/mock-api.js';
import {default as ServerAPI} from './api/server-api.js';

/* @imgex - desktop/register-with-membership/BJHuxzv7FrSkCFcEnRDAXN.jpeg */
class CustomerRegister extends ViewBase {
  static get properties() {
    return {
      __subtitle: {type: String}
    };
  }

  constructor() {
    super();
    this.__subtitle = '';
    this.__registration = {contact: {}, location: {}, plan: {}, payment: {}};
    this.__api = this._isMock() ? new MockAPI() : new ServerAPI();
  }

  async firstUpdated() {
    await this.__parseQueryString();
    this._dispatchEvent('view-loaded');
    this._afterRender(() => {
      if (this.__expired) this.__msgEl.show(this._i18n('common:errorTitle'), this._i18n('location:register.expiredMsg'), true);
      else if (this.__membershipInvalid) this.__msgEl.show(this._i18n('common:errorTitle'), this._i18n('location:register.invalidMsg'), true);
      else if (this.__quoteInvalid) this.__msgEl.show(this._i18n('location:register.quoteExpTitle'), this._i18n('location:register.invalidQteMsg'), true);
      else this.__startRegistration();
    });
  }

  async __parseQueryString() {
    const params = new URLSearchParams(location.search);
    Insight.isDevReg = params.get('dt') === '1';
    this.__registration.contact.firstName = params.get('fn') || '';
    if (!!this.__registration.contact.firstName) this.__registration.contact.firstName = this.__registration.contact.firstName.trim();
    this.__registration.contact.lastName = params.get('ln') || '';
    if (!!this.__registration.contact.lastName) this.__registration.contact.lastName = this.__registration.contact.lastName.trim();
    this.__registration.contact.fullName = this.__registration.contact.firstName;
    this.__registration.contact.fullName += (this.__registration.contact.fullName.length ? ' ' : '') + this.__registration.contact.lastName;
    this.__registration.contact.email = params.get('email');
    this.__registration.contact.phone = params.get('phone');
    this.__registration.quoteId = params.get('qid');
    this.__registration.membershipId = params.get('mid');
    if (!!this.__registration.membershipId) {
      try {
        this.__registration.membership = await this.__api.getMembershipDetails(this.__registration.membershipId);
        this.__registration.membership.guid = this.__registration.membershipId;
        this.__registration.membership.minMembers = !!params.get('accounts') ? parseInt(params.get('accounts')) : null;
        this.__registration.membership.pricingString = PricingPlanUtils.getPricingString(this.__registration.membership, this._i18n);
        this.__subtitle = this._i18n('location:plans.plan') + ': ' + this.__registration.membership.title;
      } catch (e) {
        this.__registration.membershipId = null;
        this.__membershipInvalid = true;
        console.error('Error locating membership during registration: ' + e);
      }
    } else if (!!this.__registration.quoteId) {
      try {
        const quote = (await this.__api.getQuoteDetails(this.__registration.quoteId)).data;
        this.__registration.membership = quote.costBreakdown;
        this.__registration.membership.guid = this.__registration.quoteId;
        this.__registration.membership.title = quote.title;
        this.__registration.membership.quoteTotal = quote.quoteTotal;
        this.__registration.membership.pricingString = PricingPlanUtils.getPricingString(this.__registration.membership, this._i18n);
        this.__subtitle = this._i18n('location:plans.plan') + ': ' + this.__registration.membership.title;
      } catch (e) {
        this.__registration.quoteId = null;
        this.__quoteInvalid = true;
        console.error('Error locating quote during registration: ' + e);
      }
    } else {
      this.__membershipInvalid = true;
      console.error('Membership/Quote not provided!');
    }
    let exp = params.get('exp');
    if (!!exp) {
      exp = parseInt(exp) * 1000;
      const minsRemaining = (exp - new Date().getTime()) / 1000 / 60;
      if (minsRemaining <= 30) this.__expired = true;
    }
  }

  __stepViewLoaded({detail: stepEl}) {
    stepEl.registration = this.__registration;
  }

  __startOver() {
    this.__postModel('Clicked back, starting over');
    this.__stepperEl.setActive(0);
  }

  __moveNext(e) {
    if (e && e.detail) this.__postModel(e.detail);
    this.__stepperEl.moveNext();
  }

  async __startRegistration() {
    if (this._isMock()) return;
    if (!this.__registration.quoteId) {
      this.__api
        .verifyUrl()
        .then(result => {
          this.__postModel('Registration confirmation link was clicked', true);
        })
        .catch(err => {
          this.__handleAPIError(err, true);
        });
    } else {
      this.__api
        .startQuoteReg(this.__registration.quoteId)
        .then(result => {
          this.__postModel('Customer signing up from pricing quote', true);
        })
        .catch(err => {
          this.__handleAPIError(err, true);
        });
    }
  }

  async __completeRegistration() {
    this.__api
      .completeRegistration(this.__buildModel())
      .then(result => {
        this.__registration.result = result;
        this.__postModel('Registration was completed');
        this.__moveNext();
      })
      .catch(err => {
        this.__handleAPIError(err, false);
        this.__postModel('Error occured on registration: ' + err.message);
        this.__stepperEl.getActiveView().enableAfterError();
      });
  }

  __getCardType(card) {
    if (!card) return 'NONE';
    if (card === 'mastercard') card = 'MC';
    else if (card === 'discover') card = 'DIS';
    return card.toUpperCase();
  }

  __getNameParts(name) {
    const nameArr = name.split(/\s+/);
    return {first: nameArr.slice(0, -1).join(' '), last: nameArr.pop()};
  }

  __buildModel(action) {
    const phone = this.__registration.location.phone ? this.__registration.location.phone : this.__registration.contact.phone;
    const request = {
      confirmation_link: window.location.href,
      first_name: this.__registration.contact.firstName,
      last_name: this.__registration.contact.lastName,
      email: this.__registration.contact.email,
      location_name: this.__registration.location.name,
      location_phone_number: phone,
      location_timezone: this.__registration.location.timezone,
      development: Insight.isDevReg
    };
    if (!!this.__registration.quoteId) {
      request.quote_id = this.__registration.quoteId;
      request.starting_members = this.__registration.membership.totalMemberQty;
      request.payment_amount = this.__registration.membership.paymentAmount;
    } else {
      request.membership_id = this.__registration.membership.guid;
      request.starting_members = this.__registration.membership.totalMemberQty;
      request.payment_amount = this.__registration.membership.paymentAmount;
    }
    if (!!this.__registration.payment.details)
      request.payment = InsightAPIUtils.mapPaymentInfo(
        this.__registration.payment.methodName === 'insight-ach',
        this.__registration.payment.methodName === 'insight-token',
        this.__registration.payment.details
      );
    if (!!action) request.latest_action = action;
    return request;
  }

  async __postModel(action, addBrowserInfo) {
    if (this._isMock()) return;
    const model = this.__buildModel(action);
    if (addBrowserInfo) model.browser = Insight.browser;
    this.__api
      .postModel(model)
      .then(result => {})
      .catch(err => {
        console.error('Could not post registration model: ' + err.message);
      });
  }

  async __handleAPIError(err, isFatal) {
    if (!!err && !!err.response && !!err.response.text) {
      const error = await err.response.text();
      if (error.length) {
        const jsonResp = JSON.parse(error);
        if (jsonResp.errors) {
          let message = '';
          Object.values(jsonResp.errors).forEach(v => (message = `${message}<br/>${v}`));
          this.__msgEl.show(this._i18n('common:errorTitle'), '<em>' + message + '</em>', isFatal);
        } else {
          this.__msgEl.show(this._i18n('common:errorTitle'), '<em>' + jsonResp.title + '</em><br><br>' + jsonResp.detail, isFatal);
        }
      } else {
        this.__msgEl.show(
          this._i18n('common:errorTitle'),
          '<em>' + err.response.status + ' ' + err.name + '</em><br><br>An error occurred with url: ' + err.request.url,
          isFatal
        );
      }
    } else {
      this.__msgEl.show(
        this._i18n('common:errorTitle'),
        'An error occurred' + (!!err && !!err.request && !!err.request.url ? ' with url: ' + err.request.url : '!'),
        isFatal
      );
    }
  }

  get __stepperSteps() {
    return [
      {label: this._i18n('location:register.setupYourLoc'), view: 'register-location'},
      {label: this._i18n('location:register.planInfo'), view: 'register-plan'},
      {label: this._i18n('location:register.review'), view: 'register-review'},
      {label: this._i18n('location:register.confirmation'), view: 'register-confirm'}
    ];
  }

  get __stepperEl() {
    return this._getElement('insight-stepper');
  }

  get __msgEl() {
    return this._getElement('insight-message-dialog');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      ${!!this.__registration && !!this.__registration.membership
        ? html`
            <insight-card id="register-main">
              <div slot="cardContent" class="flex-layout-vertical">
                <div id="card-header" class="flex-layout-horizontal flex-layout-center flex-layout-justified">
                  <img
                    src=${`images/website-brand${document.body.hasAttribute('dark-mode') ? '-dark' : ''}.png`}
                    title=${`Gym Insight - v${Insight.version}`}
                  />
                  <div>
                    <div class="typo-headline card-title">${this._i18n('location:register.mainTitle')}</div>
                    <div class="typo-subhead2 card-subtitle">${this.__subtitle}</div>
                  </div>
                </div>
                <insight-stepper
                  class="flex-layout-flex"
                  .steps=${this.__stepperSteps}
                  @step-load=${this.__stepViewLoaded}
                  @move-next=${this.__moveNext}
                  @start-over=${this.__startOver}
                  @complete-reg=${this.__completeRegistration}
                ></insight-stepper>
              </div>
            </insight-card>
          `
        : ''}
      <insight-message-dialog modal></insight-message-dialog>
    `;
  }

  get __css() {
    return `
    <style>
      :host {
        height: unset !important;
      }
      :host #register-main {
        display: block;
        width: 1000px;
        min-height: 600px;
        max-height: 90vh;
        --card-explicit-height: 90vh;
        --card-explicit-min-height: 600px;
        --card-explicit-max-height: 90vh;
        --card-padding: 20px 30px;
        --card-body-padding-top: 0;
      }
      :host div[slot="cardContent"] {
        height: 100%;
      }
      :host #card-header {
        padding-bottom: 10px;
        border-bottom: solid 1px var(--mdc-theme-dark-white, #16405c);
      }
      :host .card-title {
        font-weight: 700;
        color: var(--mdc-theme-dark-white, #16405c);
      }
      :host .card-subtitle {
        text-align: right;
        color: var(--mdc-theme-primary);
      }
      :host insight-stepper {
        overflow-y: auto;
      }
      :host insight-message-dialog {
        --dialog-min-height: 140px;
        --dialog-max-width: 640px;
      }
      @media only screen and (max-width: 1040px), only screen and (max-height: 640px) {
        :host {
          padding: 0 !important;
          width: 100% !important;
          height: 100% !important;
        }
        :host #register-main {
          width: 100%;
          height: 100%;
          min-height: unset;
          max-height: unset;
          --card-explicit-min-height: unset;
          --card-explicit-max-height: unset;
          --card-explicit-height: 100%;
          --card-padding: 8px 12px;
        }
        :host insight-stepper {
          margin-right: -12px;
          padding-right: 36px;
        }
      }
      @media only screen and (max-width: 640px) {
        :host .card-title {
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0.0125em;
          line-height: 32px;
          text-align: right;
        }
        :host insight-message-dialog {
          --dialog-max-width: 340px;
        }
      }
      @media only screen and (max-width: 440px) {
        :host #card-header {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
        :host .card-title {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.0071428571em;
          line-height: 22px;
          margin-top: 4px;
          text-align: left;
        }
      }
    </style>
  `;
  }
}
window.customElements.define('customer-register-view', CustomerRegister);
