import {LitElement, html} from 'lit';

class AuthTokenRefresh extends LitElement {
  static get properties() {
    return {
      authEl: {type: Object},
      disabled: {type: Boolean},
      idleDisabled: {type: Boolean}
    };
  }

  init() {
    try {
      this.__checkToken();
      this.__startTokenRefreshInterval();
      this.resetIdleTimer();
    } catch (e) {
      console.error(e);
    }
  }

  resetIdleTimer(skipSync) {
    if (this.disabled || this.idleDisabled) return;
    clearTimeout(this.__idleTimer);
    this.__idleTimer = setTimeout(() => {
      console.warn(`Access token expiring and application idle - ${Insight.auth.nbf}|${Insight.auth.exp} ! Logging out unless pre-empted...`);
      this.dispatchEvent(new CustomEvent('idle-timeout', {bubbles: true, composed: true}));
    }, 30 * 60 * 1000);
    if (!skipSync) {
      window.localStorage.setItem('idle-reset', true);
      setTimeout(() => window.localStorage.removeItem('idle-reset'), 5000);
    }
  }

  __startTokenRefreshInterval() {
    if (this.disabled) return;
    this.__stopTokenRefreshInterval();
    this.__refreshInterval = setInterval(() => this.__checkToken(), 3 * 60 * 1000);
  }

  __checkToken() {
    if (Insight.auth && Insight.auth.exp && Insight.auth.nbf) {
      const ttl = (Insight.auth.exp - Insight.auth.nbf) * 1000;
      if (new Date().getTime() > new Date(Insight.auth.nbf * 1000 + ttl * 0.5 + parseInt(Math.random() * 60) * 1000).getTime()) {
        this.__refreshAccessToken();
      }
    }
  }

  __stopTokenRefreshInterval() {
    clearInterval(this.__refreshInterval);
  }

  __refreshAccessToken() {
    if (this.disabled) return;
    this.__stopTokenRefreshInterval();
    this.authEl.refreshAccessToken(
      token => {
        Insight.auth.accessToken = token;
        this.authEl.parseToken(token);
        this.__startTokenRefreshInterval();
        window.localStorage.setItem('token-renew', true);
        setTimeout(() => window.localStorage.removeItem('token-renew'), 5000);
      },
      error => {
        console.warn(`Unable to refresh the access token: ${error ? error.message : 'unknown error'}.  Retrying...`);
        if (error && error.message === 'login_required') this.dispatchEvent(new CustomEvent('force-logout', {bubbles: true, composed: true}));
        else setTimeout(() => this.__refreshAccessToken(), 60000);
      }
    );
  }
}
window.customElements.define('auth-token-refresh', AuthTokenRefresh);
