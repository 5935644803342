import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/container/insight-dialog.js';
import './pos-member-search.js';

/* @imgex - none */
class PosMemberDialog extends InsightElement {
  static get properties() {
    return {
      __member: {type: String}
    };
  }
  open() {
    this.__dialogEl.open();
    setTimeout(() => this.__searchEl.focusSearch(), 200);
  }

  close() {
    this.__dialogEl.close();
  }

  __dialogClosed() {
    this.__member = null;
    this.__searchEl.reset();
  }

  __memberSelected({detail: member}) {
    this.__member = member;
  }

  __submit() {
    this._dispatchEvent('member-applied', this.__member);
    this.close();
  }

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  get __searchEl() {
    return this._getElement('pos-member-search');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dialog id="mbr-dialog" modal can-dismiss @closed=${this.__dialogClosed}>
        <div slot="modalHeader"><div>${this._i18n('account:addSale.memLookup')}</div></div>
        <div id="modal-body" slot="modalBody">
          <pos-member-search no-cancelled @member-select=${this.__memberSelected}></pos-member-search>
        </div>
        <span slot="modalFooter" class="buttons modal-footer">
          <insight-button id="btn-save" contained success tabindex="0" @click=${this.__submit} ?disabled=${!this.__member}
            >${this._i18n('account:addSale.applyMem')}</insight-button
          >
        </span>
      </insight-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host insight-dialog {
          --dialog-min-width: 540px;
          --dialog-padding-body: 0 24px 0;
          --dialog-overflow-x: hidden;
        }
        :host .modal-footer {
          width: 100%;
          text-align: center;
        }
        :host #btn-save {
          --button-font-size: 18px;
        }
      </style>
  `;
  }
}
window.customElements.define('pos-member-dialog', PosMemberDialog);
