// prettier-ignore
export default [
  {path: 'search', view: 'search-view', langKey: 'appshell', importer: async () => await import('../views/adv-search/search-view.js')},
  {path: 'dashboard', view: 'insight-dashboard-view', langKey: 'dashboard', importer: async () => await import('../views/dashboard/insight-dashboard-view.js')},
  {path: 'checkin', view: 'account-view', langKey: ['account', 'member', 'training', 'tasks'], importer: async () => await import('../views/account/account-view.js')},
  {path: 'calendar', view: 'calendar-view', langKey: ['calendar', 'training'], importer: async () => await import('../views/calendar/calendar-view.js')},
  {path: 'accounts/:accountGuid/view', view: 'account-view', langKey: ['account', 'member', 'training', 'tasks'], importer: async () => await import('../views/account/account-view.js')},
  {path: 'accounts/:accountGuid/statement', view: 'account-statement-view', langKey: 'account', importer: async () => await import('../views/account/account-statement-view.js')},
  {path: 'accounts/:accountGuid/edit', view: 'new-account-view', langKey: 'account', importer: async () => await import('../views/account/new-account/new-account-view.js')},
  {path: 'accounts/:accountGuid/balance', view: 'update-balance-view', langKey: 'account', importer: async () => await import('../views/account/update-balance-view.js')},
  {path: 'members/:accountGuid/new', view: 'new-member-view', langKey: 'member', importer: async () => await import('../views/member/new-member-view.js')},
  {path: 'accounts/new', view: 'new-account-view', langKey: 'account', importer: async () => await import('../views/account/new-account/new-account-view.js')},
  {path: 'accounts/:accountGuid/addons/new', view: 'new-addon-view', langKey: 'member', importer: async () => await import('../views/member/new-addon-view.js')},
  {path: 'accounts/:accountGuid/sched-payment/:paymentId', view: 'new-sched-pymt-view', langKey: ['account','member'], importer: async () => await import('../views/account/new-sched-pymt-view.js')},
  {path: 'accounts/:accountGuid/sign-document', view: 'account-sign-document-view', langKey: 'account', importer: async () => await import('../views/account/account-sign-document-view.js')},
  {path: 'accounts/:accountGuid/sign-agreement', view: 'account-sign-agreement-view', langKey: 'account', importer: async () => await import('../views/account/account-sign-agreement-view.js')},
  {path: 'accounts/:accountGuid/sale', view: 'account-sale-view', langKey: 'account', importer: async () => await import('../views/account/account-sale-view.js')},
  {path: 'accounts/:accountGuid/sale-v2', view: 'add-sale-view', langKey: 'pos', importer: async () => await import('../views/point-of-sale/add-sale-view.js')},
  {path: 'members/:memberGuid/edit', view: 'member-view', langKey: 'member', importer: async () => await import('../views/member/member-view.js')},
  {path: 'members/:memberGuid/move-member', view: 'move-member-view', langKey: 'member', importer: async () => await import('../views/member/move-member-view.js')},
  {path: 'members/:memberGuid/merge', view: 'member-view', langKey: 'member', importer: async () => await import('../views/member/member-view.js')},
  {path: 'members/:memberGuid/set-picture', view: 'member-view', langKey: 'member', importer: async () => await import('../views/member/member-view.js')},
  {path: 'members/:memberGuid/view', view: 'member-view', langKey: ['member', 'training', 'tasks'], importer: async () => await import('../views/member/member-view.js')},
  {path: 'members/:memberGuid/addons/new', view: 'new-addon-view', langKey: 'member', importer: async () => await import('../views/member/new-addon-view.js')},
  {path: 'memberships/list', view: 'membership-list-view', langKey: ['account', 'plans'], importer: async () => await import('../views/memberships/membership-list-view.js')},
  {path: 'memberships/new', view: 'membership-edit-view', langKey: ['account', 'member', 'plans'], importer: async () => await import('../views/memberships/membership-edit-view.js')},
  {path: 'memberships/edit/:memGuid', view: 'membership-edit-view', langKey: ['account', 'member', 'plans'], importer: async () => await import('../views/memberships/membership-edit-view.js')},
  {path: 'events/:eventGuid/:subView?', view: 'event-view', langKey: 'calendar', importer: async () => await import('../views/events/event-view.js')},
  {path: 'sales/:salesDate/new', view: 'new-sale-view', langKey: 'sales', importer: async () => await import('../views/sales/new-sale-view.js')},
  {path: 'tasks/:subView?', view: 'tasks-view', langKey: 'tasks', importer: async () => await import('../views/tasks/tasks-view.js')},
  {path: 'training/home', view: 'training-home-view', langKey: ['training','member'], importer: async () => await import('../views/training/training-home-view.js')},
  {path: 'devices/control', view: 'device-control-view', langKey: 'devices', importer: async () => await import('../views/devices/device-control-view.js')},
  {path: 'settings/location/plans', view: 'plan-selection-view', langKey: 'location', importer: async () => await import('../views/location/settings/plans/plan-selection-view.js')},
  {path: 'settings/location/:subView?', view: 'location-settings-view', langKey: 'location', importer: async () => await import('../views/location/settings/location-settings-view.js')},
  {path: 'settings/applications/:subView?', view: 'location-applications-view', langKey: ['settings','devices'], importer: async () => await import('../views/location/applications/location-applications-view.js')},
  {path: 'location/quote/latest', view: 'customer-quote-view', langKey: ['quote', 'plans'], importer: async () => await import('../views/location/customer-quote-view.js')},
  {path: 'location/roles/list', view: 'role-list-view', langKey: 'perms', importer: async () => await import('../views/location/users/role-list-view.js')},
  {path: 'location/roles/compare', view: 'role-diff-view', langKey: ['perms', 'reports'], importer: async () => await import('../views/location/users/role-diff-view.js')},
  {path: 'location/roles/:roleId', view: 'role-permissions-edit-view', langKey: ['user','perms'], importer: async () => await import('../views/location/users/role-permissions-edit-view.js')},
  {path: 'location/roles/new/:roleId', view: 'role-permissions-edit-view', langKey: ['user','perms'], importer: async () => await import('../views/location/users/role-permissions-edit-view.js')},
  {path: 'location/rolesets/:roleId/:type', view: 'role-sets-edit-view', langKey: ['user','perms','reports', 'dashboard'], importer: async () => await import('../views/location/users/role-sets-edit-view.js')},
  {path: 'location/users', view: 'location-users-view', langKey: 'location', importer: async () => await import('../views/location/users/location-users-view.js')},
  {path: 'location/users/new', view: 'edit-user-view', langKey: 'user', importer: async () => await import('../views/settings/edit-user-view.js')},
  {path: 'location/users/edit/:userId', view: 'edit-user-view', langKey: 'user', importer: async () => await import('../views/settings/edit-user-view.js')},
  {path: 'client/groups', view: 'user-groups-view', langKey: 'groups', importer: async () => await import('../views/groups/user-groups-view.js')},
  {path: 'client/groups/:groupId/rules', view: 'group-rules-view', langKey: 'groups', importer: async () => await import('../views/groups/group-rules-view.js')},
  {path: 'settings/user/:subView?', view: 'user-settings-view', langKey: 'user', importer: async () => await import('../views/settings/user-settings/user-settings-view.js')},
  {path: 'user/commissions', view: 'user-commissions-view', langKey: 'user', importer: async () => await import('../views/user/user-commissions-view.js')},
  {path: 'warnings/call-us', view: 'call-us-warning-view', langKey: 'warnings', importer: async () => await import('../views/warnings/call-us-warning-view.js')},
  {path: 'warnings/delinquent-warning', view: 'delinquent-warning-view', langKey: 'warnings', importer: async () => await import('../views/warnings/delinquent-warning-view.js')},
  {path: 'warnings/access-denied', view: 'access-denied-warning-view', langKey: 'warnings', importer: async () => await import('../views/warnings/access-denied-warning-view.js')},
  {path: 'warnings/signout', view: 'signout-warning-view', langKey: 'warnings', importer: async () => await import('../views/warnings/signout-warning-view.js')},
  {path: 'warnings/trial-expired', view: 'trial-expired-warning-view', langKey: 'warnings', importer: async () => await import('../views/warnings/trial-expired-warning-view.js')},
  {path: 'warnings/pending', view: 'pending-warning-view', langKey: 'warnings', importer: async () => await import('../views/warnings/pending-warning-view.js')},
  {path: 'reports/:subView?', view: 'reports-view', langKey: 'reports', importer: async () => await import('../views/reports/reports-view.js')},
  {path: 'admin', view: 'admin-view', langKey: 'admin', importer: async () => await import('../views/admin/admin-view.js')},
  {path: 'admin/browser-check', view: 'browser-check-view', langKey: 'admin', importer: async () => await import('../views/admin/browser-check/browser-check-view.js')},
  {path: 'admin/features', view: 'admin-features-view', langKey: 'admin', importer: async () => await import('../views/admin/features/admin-features-view.js')},
  {path: 'admin/clients/list', view: 'clients-list-view', langKey: 'admin', importer: async () => await import('../views/admin/clients/clients-list-view.js')},
  {path: 'admin/clients/locations/new', view: 'new-location-view', langKey: 'location', importer: async () => await import('../views/admin/registration/new-location-view.js')},
  {path: 'admin/clients/:clientGuid/view', view: 'client-view', langKey: 'admin', importer: async () => await import('../views/admin/clients/client-view.js')},
  {path: 'admin/clients/:clientGuid/edit/:locationGuid', view: 'client-location-edit-view', langKey: ['admin','location'], importer: async () => await import('../views/admin/clients/client-location-edit-view.js')},
  {path: 'admin/clients/:clientGuid/charges/:locationGuid', view: 'client-location-gateway-charges-view', langKey: 'admin', importer: async () => await import('../views/admin/clients/client-location-gateway-charges-view.js')},
  {path: 'admin/clients/:clientGuid/devices/:locationGuid', view: 'client-devices-view', langKey: ['admin','devices'], importer: async () => await import('../views/admin/clients/client-devices-view.js')},
  {path: 'admin/location/plan-selection', view: 'plan-selection-view', langKey: 'location', importer: async () => await import('../views/location/settings/plans/plan-selection-view.js')},
  {path: 'admin/location/billing-estimate', view: 'billing-estimate-view', langKey: 'location', importer: async () => await import('../views/admin/location/billing-estimate-view.js')},
  {path: 'admin/purchase-orders', view: 'admin-po-view', langKey: 'admin', importer: async () => await import('../views/admin/purchase-order/admin-po-view.js')},
  {path: 'admin/system-message', view: 'admin-sysmsg-view', langKey: 'admin', importer: async () => await import('../views/admin/system-messages/admin-sysmsg-view.js')},
  {path: 'admin/pricing-quotes/list', view: 'quote-list-view', langKey: ['admin', 'account', 'plans'], importer: async () => await import('../views/admin/pricing-quotes/quote-list-view.js')},
  {path: 'admin/pricing-quotes/new', view: 'quote-edit-view', langKey: ['admin', 'account', 'plans'], importer: async () => await import('../views/admin/pricing-quotes/quote-edit-view.js')},
  {path: 'admin/pricing-quotes/edit/:qteGuid', view: 'quote-edit-view', langKey: ['admin', 'account', 'plans'], importer: async () => await import('../views/admin/pricing-quotes/quote-edit-view.js')},
  {path: 'admin/pricing-quotes/revise/:qteGuid', view: 'quote-edit-view', langKey: ['admin', 'account', 'plans'], importer: async () => await import('../views/admin/pricing-quotes/quote-edit-view.js')},
  {path: 'admin/pricing-quotes/archive/:qteGuid', view: 'quote-edit-view', langKey: ['admin', 'account', 'plans'], importer: async () => await import('../views/admin/pricing-quotes/quote-edit-view.js')},
  {path: 'admin/pricing-quotes/preview/:qteGuid', view: 'quote-preview-internal-view', langKey: ['admin', 'account', 'plans'], importer: async () => await import('../views/admin/pricing-quotes/quote-preview-internal-view.js')},
  {path: 'admin/mpas', view: 'admin-mpas-view', langKey: 'admin', importer: async () => await import('../views/admin/mpas/admin-mpas-view.js')},
  {path: 'admin/reports/locations-revenue-report', view: 'locations-revenue-report', langKey: 'admin', importer: async () => await import('../views/admin/reports/locations-revenue-report.js')},
  {path: 'playground/menu', view: 'playground-admin-view', langKey: 'admin', importer: async () => await import('../views/playground/playground-admin-view.js')},
  {path: 'playground/payments', view: 'pg-payment-view', langKey: 'admin', importer: async () => await import('../views/playground/pg-payment-view.js')},
  {path: 'playground/pos', view: 'point-of-sale-view', langKey: 'admin', importer: async () => await import('../views/playground/point-of-sale-view.js')},
  {path: 'hangfire', view: 'i-frame-view', importer: async () => await import('../views/i-frame/i-frame-view.js')},
  {path: 'appredirect', view: 'redirecting-view', importer: async () => await import('../views/admin/redirecting-view.js')},
  {path: 'testophobia', view: 'testophobia-view', importer: async () => await import('../views/admin/testophobia-view.js')},
  {path: '*', view: 'insight-404-view', importer: async () => await import('../views/insight-404-view.js')}
];
