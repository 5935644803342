const i18nNumber = {
  format: (val, i18n, allowFree) => {
    val = parseFloat(val);
    const fmt = n => n.toLocaleString(window.navigator.language);
    if (val && val.toLocaleString) return fmt(val);
    if (allowFree) return i18n ? i18n('common:free') : fmt(0);
    return i18n ? '' : fmt(0);
  },
  bankersRound(n, d = 2) {
    const x = n * Math.pow(10, d);
    const r = Math.round(x);
    const br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
    return br / Math.pow(10, d);
  }
};

export {i18nNumber};
