import {InsightElement, leftAndMiddleClick, html} from './insight-element.js';
import i18next from 'i18next';
export {html, leftAndMiddleClick};

export class InsightComponent extends InsightElement {
  _i18nComp(componentModule, key, defValue, obj) {
    if (!i18next || !i18next.language) return !!defValue ? defValue : !!key ? key : componentModule;
    const keys = componentModule ? [`components:${componentModule}.${key}`, defValue] : `common:${key}`;
    return super._i18n(keys, obj);
  }

  _i18nDirect(key, obj) {
    if (!!key && !key.includes(':')) return key;
    return super._i18n(key, obj);
  }
}
