import {InsightComponent, html} from '../insight-component.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '../button/insight-button.js';
import '../ui/insight-icon.js';

class InsightMasterDetailLayout extends InsightComponent {
  /****************************************************  Public Api  ****************************************************/
  static get properties() {
    return {
      __hasClose: {attribute: 'has-close', type: Boolean}
    };
  }
  /**************************************************  Private Methods  *************************************************/

  __handleMobileBack() {
    this._dispatchEvent('mobile-back');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div id="master-detail-container" class="flex-layout-horizontal">
        <div id="master-panel">
          <slot name="masterContent"></slot>
        </div>
        <div id="detail-panel">
          <insight-button id="mobile-back-btn" @click=${this.__handleMobileBack}>
            <insight-icon icon-name="arrow_back"></insight-icon>
            <span>${this._i18nComp(null, 'back', 'Back')}</span>
          </insight-button>
          <slot name="detailContent"></slot>
        </div>
        ${this.__hasClose
          ? html`
              <insight-icon-button
                id="close-btn"
                icon-name="close"
                no-focus-circle
                title=${this._i18nComp('common', 'close', 'Close')}
                @click=${() => this._dispatchEvent('md-close')}
              ></insight-icon-button>
            `
          : ''}
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host #master-detail-container {
          height: 100%;
          position: relative;
          min-height: var(--md-min-height);
          max-height: var(--md-max-height);
          width: var(--md-width);
          max-width: var(--md-max-width, 1200px);
          margin: 0 auto;
          background: var(--mdc-theme-background);
          border-radius: 4px;
          box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
        }
        :host #master-panel {
          min-width: var(--master-width, 300px);
          max-width: var(--master-width, 300px);
          position: relative;
        }
        :host #master-panel *:last-child::slotted(*) {
          height: 100%;
          border-right: solid 1px var(--mdc-theme-divider-color);
        }
        :host #detail-panel {
          width: calc(100% - var(--master-width, 300px));
          overflow: auto;
          position: relative;
          border-bottom-right-radius: var(--md-br-border);
        }
        :host([fixed-size]) #detail-panel {
          overflow: hidden;
        }
        :host #detail-panel *:last-child::slotted(*) {
          height: 100%;
          box-sizing: border-box;
          padding: 16px 32px;
        }
        :host([fixed-size]) #detail-panel *:last-child::slotted(*) {
          padding: 0;
        }
        :host #mobile-back-btn {
          display: none;
        }
        :host([mobile-master]) #detail-panel {
          display: none;
        }
        :host([mobile-master]) #master-panel {
          min-width: 100%;
          max-width: 100%;
        }
        :host([mobile-detail]) #master-panel {
          display: none;
        }
        :host([mobile-detail]) #detail-panel {
          width: 100%;
        }
        :host([mobile-detail]) #mobile-back-btn {
          display: block;
          position: absolute;
          top: 8px;
          left: 4px;
          z-index: 1;
          --icon-color: var(--mdc-theme-primary);
        }
        :host([mobile-detail]) #detail-panel *:last-child::slotted(*) {
          padding: 52px 16px 16px;
          height: 100%;
        }
        :host([mobile-detail][fixed-size]) #detail-panel *:last-child::slotted(*) {
          padding: 52px 0 0;
        }
        :host #close-btn {
          position: absolute;
          top: 0;
          right: 0;
          --icon-color: var(--dialog-close-color);
        }
      </style>
    `;
  }
}
window.customElements.define('insight-master-detail-layout', InsightMasterDetailLayout);
