import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {ifDefined} from 'lit-html/directives/if-defined.js';
import '@insight/insight-common/components/form/insight-checkbox.js';
import '@insight/insight-common/components/form/insight-input.js';
import '@insight/insight-common/components/ui/insight-icon.js';
import '@insight/insight-common/components/ui/insight-keyboard.js';
import {i18nDate} from '@insight/insight-common/i18n/i18n-date.js';
import '../../components/common/member-photo.js';

/* @imgex - none */
class PosMemberSearch extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      disabled: {attribute: 'disabled', type: Boolean},
      invalid: {attribute: 'invalid', type: Boolean},
      noCancelled: {attribute: 'no-cancelled', type: Boolean},
      isAccount: {attribute: 'is-account', type: Boolean},
      locations: {type: Array},
      __members: {type: Array},
      __selectedMember: {type: Number},
      __noResults: {type: Boolean},
      __placeholderLbl: {type: String},
      __inclCancel: {type: Boolean}
    };
  }

  excludeMember(guid) {
    this.__excludeMember = guid;
  }

  excludeAccount(guid) {
    this.__excludeAccount = guid;
  }

  focusSearch() {
    // this._afterRender(() => this.__inputEl.setFocus());
  }

  reset() {
    this.__noResults = true;
    this.__setPlaceholderLbl(true);
    this._afterRender(() => {
      this.__kbEl.value = this.__inputEl.value = '';
      this.__selectedMember = -1;
      this.__members = [];
    });
  }

  searchMember(memberName) {
    this.__kbEl.value = this.__inputEl.value = memberName;
    this.__searchMembers(memberName);
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__noResults = true;
    this.__selectedMember = -1;
    this.__members = [];
    this.__debouncedInputChanged = this._debounce(750, this.__inputChanged);
    this.__setPlaceholderLbl(true);
  }

  __loadMembers(state) {
    this.__selectedMember = -1;
    this.__queryActive = false;
    this.__setPlaceholderLbl(false);
    let items = state.items;
    if (!!items && this.__excludeAccount) items = items.filter(i => i.accountGuid !== this.__excludeAccount);
    if (!!items && this.__excludeMember) items = items.filter(i => i.memberId !== this.__excludeMember);
    this.__noResults = !items || !items.length;
    items = items.map(i => {
      i.joinedStr = this._i18n('common:joined') + ': ' + (i.createdUtc ? i18nDate.formatDateInLocale(i.createdUtc, Insight.location.ianaTimezone) : '');
      i.typeStr = this._i18n('common:type') + ': ' + i.accountType;
      i.statusStr = this._i18n('common:status') + ': <span>' + i.accountStatus + '</span>';
      i.photo = i.imageUrl || window._photoPlaceholder;
      return i;
    });
    this.__members = items;
  }

  __inputChanged() {
    this._dispatchEvent('member-select', null);
    const inputStr = this.__inputEl.value;
    if (inputStr.length < 3) {
      this.__noResults = true;
      this.__setPlaceholderLbl(true);
      this.__selectedMember = -1;
      this.__members = [];
      return;
    }
    this.__searchMembers(inputStr);
  }

  __searchMembers(searchStr) {
    this.__queryActive = true;
    this.__noResults = false;
    this.__showShimmer();
    this.__searchFilter = [{name: 'name', value: searchStr, type: 'text'}];
    Insight.state.fetchOneTime('members', state => this.__loadMembers(state), false, {
      locations: this.locations,
      filters: this.__searchFilter,
      primaryOnly: this.isAccount,
      excludeCancelled: this.noCancelled || !this.__inclCancel,
      page: 1,
      limit: 100
    });
  }

  __handleSelection(idx) {
    this.__selectedMember = idx;
    this._dispatchEvent('member-select', this.__members[idx]);
  }

  __setPlaceholderLbl(inputLen) {
    this.__placeholderLbl = !inputLen ? this._i18n('components:common.noResults') : this._i18n('common:searchMin');
  }

  __showShimmer() {
    setTimeout(() => {
      if (this.__queryActive) this.__members = new Array(20).fill({shimmer: true, photo: window._photoPlaceholder});
    }, 300);
  }

  __cancelChanged(e) {
    this.__inclCancel = e.detail;
    this.__inputChanged();
  }

  __kbValChanged({detail: val}) {
    this.__inputEl.value = val;
    this.__debouncedInputChanged();
  }

  get __inputEl() {
    return this._getElement('insight-input');
  }

  get __kbEl() {
    return this._getElement('insight-keyboard');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div id="member-search" class="flex-layout-horizontal">
        <div>
          <div class="typo-subhead2 pane-lbl">${this._i18n('account:addSale.memSrch1')}</div>
          <div id="member-search-input">
            <insight-input
              label=${this._i18n('components:common.findMember')}
              outlined
              no-bottom-margin
              no-label-float
              ?invalid=${this.invalid}
              ?disabled=${this.disabled}
              readonly
            >
              <insight-icon icon-name="search" slot="suffix"></insight-icon>
            </insight-input>
            <insight-keyboard @kb-change=${this.__kbValChanged}></insight-keyboard>
          </div>
        </div>
        <div>
          <div class="typo-subhead2 pane-lbl">${this._i18n('account:addSale.memSrch2')}</div>
          <div id="member-search-results" class="flex-layout-vertical" ?disabled=${this.disabled}>
            ${this.__noResults ? html` <div id="no-results" class="typo-subhead">${this.__placeholderLbl}</div> ` : ''}
            ${this.__members.map((m, idx) => {
              return html`
                <div
                  class="member-item flex-layout-horizontal"
                  ?data-selected=${this.__selectedMember === idx}
                  ?data-inactive=${m.memberCancelled}
                  ?show-shimmer=${m.shimmer === true}
                  @click=${() => this.__handleSelection(idx)}
                >
                  <member-photo image-url=${m.photo} account-id=${m.accountGuid} ?primary-member=${!this.isAccount && m.memberPrimary === true}></member-photo>
                  <div class="member-details flex-layout-flex flex-layout-vertical flex-layout-justified">
                    <div class="typo-body2 member-name" title=${m.fullName}>${m.fullName}</div>
                    <div class="typo-caption member-joined">${m.joinedStr}</div>
                    <div class="typo-caption member-location">${m.locationName}</div>
                  </div>
                  <div class="member-details flex-layout-vertical flex-layout-justified">
                    <div class="typo-body2 member-account">${m.accountNumber}</div>
                    <div class="typo-caption member-status" data-status=${m.accountStatus}>${unsafeHTML(m.statusStr)}</div>
                    <div class="typo-caption member-type">${m.typeStr}</div>
                  </div>
                </div>
              `;
            })}
          </div>
        </div>
      </div>
      ${!this.noCancelled
        ? html`
            <div>
              <insight-checkbox id="chk-cncl" left-aligned @change=${this.__cancelChanged}>
                <span>${this._i18n('common:inclCncl')}</span>
              </insight-checkbox>
            </div>
          `
        : ''}
    `;
  }

  get __css() {
    return `
      <style>
        :host insight-input {
          max-width: 480px;
          display: block;
        }
        :host .pane-lbl {
          margin-bottom: 8px;
        }
        :host #member-search {
          margin-top: 16px;
          column-gap: 16px;
        }
        :host #member-search-input {
          width: 480px;
        }
        :host #member-search-results {
          width: 480px;
          height: 240px;
          background: var(--mdc-theme-background);
          border: 1px solid var(--mdc-theme-dark-highlight, var(--mdc-theme-divider-color));
          border-radius: 4px;
          position: relative;
          overflow: auto;
          box-sizing: border-box;
        }
        :host([invalid]) #member-search-results {
          border-color: var(--mdc-theme-error);
        }
        @media only screen and (max-width: 600px) {
          :host #member-search-results {
            width: var(--search-width, calc(95vw - 50px));
          }
        }
        :host #member-search-results[disabled] {
          opacity: 0.5 ;
          pointer-events: none;
        }
        :host #no-results {
          color: var(--mdc-theme-text-secondary);
          text-align: center;
          top: 50%;
          position: absolute;
          left: 0;
          right: 0;
          transform: translateY(-50%);
        }
        :host .member-item {
          height: 48px;
          padding: 8px 16px 7px;
          background-color: var(--mdc-theme-background);
          border-bottom: solid 1px var(--mdc-theme-divider-color);
          cursor: pointer;
          position: relative;
        }
        :host .member-item[data-selected]:before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: '';
          pointer-events: none;
          background: var(--mdc-theme-secondary);
          z-index: 0;
          opacity: 0.1;
        }
        :host .member-item .member-details:last-child {
          -webkit-flex: 1;
          flex: 1;
        }
        @media only screen and (max-width: 500px) {
          :host .member-item .member-details:last-child {
            -webkit-flex: unset;
            flex: unset;
            width: 90px;
          }
        }
        @media only screen and (max-width: 400px) {
          :host .member-item .member-details:last-child {
            display: none;
          }
        }
        :host .member-item .member-name,
        :host .member-item .member-account {
          font-size: 13px;
          font-weight: 500;
          color: var(--mdc-theme-text-primary);
          max-height: 26px;
          overflow: hidden;
          word-break: break-word;
          line-height: 13px;
        }
        :host .member-item .member-account {
          color: var(--mdc-theme-secondary);
        }
        :host .member-item[data-selected] .member-name {
          color: var(--mdc-theme-dark-white, var(--mdc-theme-primary));
          font-weight: 700;
        }
        :host .member-item .member-location,
        :host .member-item .member-type {
          font-size: 10px;
          font-weight: 400;
          color: var(--mdc-theme-text-secondary);
          max-height: 16px;
          overflow: hidden;
          word-break: break-word;
          line-height: 11px;
        }
        :host .member-item .member-joined,
        :host .member-item .member-status {
          font-size: 10px;
          font-weight: 400;
          color: var(--mdc-theme-text-primary);
          max-height: 16px;
          overflow: hidden;
          word-break: break-word;
          line-height: 11px;
        }
        :host .member-item .member-status span {
          color: var(--mdc-theme-text-error);
        }
        :host .member-item .member-status[data-status='GREEN'] span {
          color: var(--mdc-theme-text-success);
        }
        :host .member-item .member-status[data-status='YELLOW'] span {
          color: rgba(190, 154, 0);
        }
        :host .member-item .member-status[data-status='CANCELLED'] span {
          color: var(--mdc-theme-text-secondary);
          text-decoration: line-through;
        }
        :host .member-item .member-inactive {
          display: none;
          position: absolute;
          top: calc(50% - 14px);
          left: 84px;
          line-height: 32px;
          font-size: 32px;
          opacity: 0.12;
          color: var(--mdc-theme-error);
          text-transform: uppercase;
        }
        :host .member-item:not([show-shimmer])[data-inactive] .member-inactive {
          display: block;
        }
        :host .member-item:not([show-shimmer])[data-inactive] .member-name {
          color: var(--mdc-theme-error);
          text-decoration: line-through;
        }
        :host .member-item member-photo {
          margin-right: 16px;
        }
        :host .member-item[data-selected] member-photo {
          --photo-border-color: var(--mdc-theme-primary);
          --photo-border-width: 2px;
        }
        :host .member-item[data-selected][data-inactive] member-photo {
          --photo-border-color: var(--mdc-theme-error);
        }
        :host .member-item[show-shimmer] .member-name,
        :host .member-item[show-shimmer] .member-location,
        :host .member-item[show-shimmer] .member-joined,
        :host .member-item[show-shimmer] .member-account,
        :host .member-item[show-shimmer] .member-type,
        :host .member-item[show-shimmer] .member-status {
          background-color: var(--mdc-theme-surface);
          height: 12px;
          opacity: 0.7;
        }
        :host .member-item[show-shimmer] .member-name,
        :host .member-item[show-shimmer] .member-account {
          width: 70%;
        }
        :host .member-item[show-shimmer] .member-location,
        :host .member-item[show-shimmer] .member-type {
          width: 80%;
        }
        :host .member-item[show-shimmer] .member-joined,
        :host .member-item[show-shimmer] .member-status {
          width: 60%;
        }
        :host #chk-cncl {
          margin-top: 4px;
          margin-left: 0;
        }
      </style>
    `;
  }
}
window.customElements.define('pos-member-search', PosMemberSearch);
