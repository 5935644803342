import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '@insight/insight-common/components/container/insight-card.js';
import {PosMainView} from './pos-main-view.js';
import '../../point-of-sale/pos-member-search.js';

/* @imgex - none */
class PosMemberView extends InsightElement {
  static get properties() {
    return {
      __selectedMember: {type: Object}
    };
  }

  __memberSelected({detail: member}) {
    this.__selectedMember = member;
  }

  get __searchEl() {
    return this._getElement('pos-member-search');
  }

  _render() {
    if (!this.__viewReady)
      this._afterRender(() => {
        this._getElement('.mbr-inner').style.opacity = '1';
        this.__searchEl.focusSearch();
      });
    return html`
      ${unsafeHTML(this.__css)}
      <div id="mbr-main">
        <div class="flex-layout-vertical flex-layout-center-center mbr-inner" style="opacity:0;">
          <insight-card id="mbr-card">
            <div slot="cardContent" class="flex-layout-vertical">
              <div class="typo-title flex-layout-horizontal flex-layout-justified card-title">
                <div>Member Lookup</div>
                <insight-icon-button
                  icon-name="clear"
                  @click=${() => this._dispatchEvent('menu-nav', {view: PosMainView.Views.Menu})}
                  title=${this._i18n('common:back')}
                ></insight-icon-button>
              </div>
              <pos-member-search no-cancelled @member-select=${this.__memberSelected}></pos-member-search>
              <div class="flex-layout-horizontal flex-layout-center-center button-row">
                <insight-button
                  id="btn-sale"
                  contained
                  success
                  @click=${() => this._dispatchEvent('menu-nav', {view: PosMainView.Views.NewSale, args: {member: this.__selectedMember}})}
                  ?disabled=${!this.__selectedMember}
                >
                  New Sale
                </insight-button>
              </div>
            </div>
          </insight-card>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
    <style>
      :host #mbr-main {
        background: var(--mdc-theme-primary);
        min-width: 1366px;
        max-width: 1366px;
        min-height: 768px;
        max-height: 768px;
        overflow: hidden;
      }
      :host .mbr-inner {
        width: 100%;
        height: 768px;
        row-gap: 32px;
        transition: opacity 500ms;
      }
      :host insight-card {
        --card-body-padding-top: 0;
        --card-padding: 16px 24px 24px;
      }
      :host .card-title insight-icon-button {
        width: 32px;
        height: 32px;
        --icon-display: block;
        --icon-vertical-align: top;
        --icon-width: 24px;
        --icon-padding: 4px;
      }
      :host .button-row {
        margin-top: 24px;
        --button-font-size: 18px;
      }
      :host #btn-sale {
        --button-width: 160px;
      }
    </style>
  `;
  }
}
window.customElements.define('pos-member-view', PosMemberView);
