import {WebSocketEvent, InsightWebSocket} from '@insight/insight-common/api/insight-websocket.js';
import {Mutex} from 'async-mutex';

class InsightSharedWebsocket {
  constructor() {
    this.__websocket = null;
    this.__webSocketMutex = null;
    this.__webSocketMessageListeners = [];
    this.__webSocketConnectionListeners = [];
  }

  __webSocketShutdown() {
    if (!this.__websocket) return;
    this.__websocket.closing = true;
    this.__websocket.close();
    this.__websocket = null;
  }

  __connectWebSocket() {
    this.__webSocketShutdown();
    if (!Insight.auth || !Insight.auth.accessToken) {
      console.warn('Websocket Connection', 'Unable to start websocket. Access token not set!');
      return;
    }
    return new Promise((resolve, reject) => {
      const url = () => {
        return `${Insight.configs.insightWSUrl}/ws?access_token=${Insight.auth.accessToken}`;
      };
      this.__websocket = new InsightWebSocket(url, {});
      this.__websocket.on(WebSocketEvent.MESSAGE, message => this.__webSocketMessageListeners.forEach(l => l(message)));
      this.__websocket.on(WebSocketEvent.CONNECTION, () => {
        if (this.__websocket.reconnecting) {
          this.__websocket.reconnecting = false;
          this.__webSocketConnectionListeners.forEach(l => l());
          this.__webSocketMessageListeners = [];
          this.__webSocketConnectionListeners = [];
        }
        this.__websocket.on(WebSocketEvent.CLOSE, event => {
          if (!this.__websocket.closing && !this.__websocket.reconnecting) {
            console.warn(event.reason ? event.reason : event);
            console.warn('The websocket closed unexpectedly, reconnecting...');
            this.__websocket.reconnecting = true;
          }
        });
        this.__websocket.on(WebSocketEvent.ERROR, event => {
          if (!this.__websocket.closing && !this.__websocket.reconnecting) {
            console.warn(event.reason ? event.reason : event);
            console.warn('An error occurred with the websocket, reconnecting...');
            this.__websocket.reconnecting = true;
          }
        });
        resolve();
      });
    });
  }

  async __getWebsocket(payload) {
    if (Insight.configs.mock) return payload;
    if (this.__webSocketMutex) {
      return this.__webSocketMutex;
    } else {
      if (!this.__websocket) {
        this.__webSocketMutex = new Mutex();
        const release = await this.__webSocketMutex.acquire();
        this.__connectWebSocket().then(() => {
          release();
          this.__webSocketMutex = null;
        });
        return this.__webSocketMutex;
      }
    }
    if (!payload) payload = {};
    payload.websocket = {
      send: this.__websocket.send.bind(this.__websocket),
      addConnectionListener: l => this.__webSocketConnectionListeners.push(l),
      addMessageListener: l => this.__webSocketMessageListeners.push(l)
    };
    return payload;
  }

  async startSubscription(api, apiCat, command, callback, payload) {
    let wsPayload = await this.__getWebsocket(payload);
    if (wsPayload instanceof Mutex) {
      (await wsPayload.acquire())();
      wsPayload = await this.__getWebsocket(payload);
    }
    await api.invoke(apiCat, command, data => callback(data), wsPayload);
  }
}

export default InsightSharedWebsocket;
