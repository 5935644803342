import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {ifDefined} from 'lit/directives/if-defined.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-dropdown-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '@insight/insight-common/components/ui/insight-avatar.js';

/* @imgex - desktop/user-menu/2AQ1fr82EqzQesFa7X2mFsoUSeve.jpeg */
class UserMenu extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  /**************************************************  Private Methods  *************************************************/

  firstUpdated() {
    this._afterRender(() => this.__configureUserMenu());
  }

  __configureUserMenu() {
    const dd = this._getElement('#user-menu');
    const data = [];
    if (Insight.auth.giAdminSub)
      data.push({
        key: 'revert-user',
        label: this._i18n('appshell:usermenu.revertUserLbl') + Insight.auth.giAdminName,
        icon: 'supervisor_account',
        divider: 'after'
      });
    if (this._hasAccess('COMMISSION:VIEW:ALL'))
      data.push({key: 'commission', label: this._i18n('common:commission'), url: 'user/commissions', icon: 'thumb_up'});
    data.push({key: 'settings', label: this._i18n('appshell:usermenu.settingsLbl'), url: 'settings/user', icon: 'build'});
    data.push({
      key: 'logout',
      label: this._i18n('appshell:usermenu.logoutLbl'),
      icon: 'power_settings_new',
      divider: 'before'
    });
    data.unshift({
      divider: 'after',
      disabled: true,
      template: html`
        <insight-avatar
          photo-url=${ifDefined(Insight.auth.photo)}
          user-name=${Insight.auth.preferredUsername}
          user-email=${Insight.user.email}
        ></insight-avatar>
        <div style="font-size:14px;font-weight:500;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;max-width:152px;">
          ${Insight.auth.preferredUsername}
        </div>
      `
    });
    dd.data = data;
  }

  __handleUserAction({detail}) {
    if (detail.url) this._navigateOnClick(detail._event_, detail.url);
    else if (detail.key === 'logout') this._dispatchEvent('log-out');
    else if (detail.key === 'revert-user') this._dispatchEvent('become-user', Insight.auth.giAdminSub);
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dropdown-button
        id="user-menu"
        @change=${this.__handleUserAction}
        min-width="240"
        horizontal-offset=${this._mobileRes ? '10' : '40'}
        vertical-offset=${this._mobileRes ? '0' : '-10'}
        horizontal-align=${this._mobileRes ? 'right' : 'left'}
      >
        <insight-icon-button icon-name="account_circle" slot="dropdown-button"></insight-icon-button>
      </insight-dropdown-button>
    `;
  }

  get __css() {
    return `
      <style>
        :host #user-menu {
          position: absolute;
          bottom: 0;
          padding: 0 4px 4px;
          outline: none;
          cursor: pointer;
          background-color: var(--mdc-theme-dark-bg-8, var(--mdc-theme-primary));
          z-index: 1;
          --icon-color: #fff;
        }
        :host([mobile-res]) #user-menu {
          bottom: unset;
          top: -4px;
          right: -4px;
          background-color: unset;
          z-index: unset;
        }
      </style>
    `;
  }
}
window.customElements.define('user-menu', UserMenu);
