import {InsightElement, html} from '../insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';

class InsightIcon extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      iconName: {type: String, attribute: 'icon-name'}
    };
  }

  /**************************************************  Private Methods  *************************************************/

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <i role="presentation" class="material-icons">${this.iconName}</i>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          user-select: none;
        }
        :host .material-icons {
          opacity: var(--icon-opacity, unset);
          margin: var(--icon-margin, unset);
          max-width: var(--icon-width, 24px);
          font-size: var(--icon-width, 24px);
          vertical-align: var(--icon-align, middle);
          color: var(--icon-color, var(--mdc-theme-text-icon));
          font-family: var(--icon-font, 'Material Icons');
          font-weight: normal;
          font-style: normal;
          display: inline-block;
          line-height: 1;
          text-transform: none;
          letter-spacing: normal;
          word-wrap: normal;
          white-space: nowrap;
          direction: ltr;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          -moz-osx-font-smoothing: grayscale;
          font-feature-settings: 'liga';
        }
        :host([outlined]) .material-icons {
          font-family: var(--icon-font, 'Material Icons Outlined');
        }
        :host([success]) .material-icons  {
          color: var(--icon-ovrd-color, var(--mdc-theme-success));
        }
        :host([danger]) .material-icons {
          color: var(--icon-ovrd-color, var(--mdc-theme-error));
        }
        :host([warning]) .material-icons {
          color: var(--icon-ovrd-color, var(--mdc-theme-warning));
        }
        :host([ro-field-attached]) {
          width: 36px;
          height: 36px;
          margin: 0 0 0 -4px;
          border: 1px solid var(--mdc-theme-dark-bg-24, var(--mdc-theme-divider-color));
          border-left: none;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          background: var(--mdc-theme-background);
          padding: 4px;
          z-index: 0;
          --icon-margin: 4px 0 0 7px;
          --icon-width: 20px;
          --icon-padding: 0;
        }
        :host([ro-field-attached][danger]) {
          border-color: var(--mdc-theme-error);
        }
      </style>
    `;
  }
}
window.customElements.define('insight-icon', InsightIcon);
