import {leftAndMiddleClick, InsightElement, html} from '../insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '../button/insight-icon-button.js';

class InsightReadonlyField extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      dense: {attribute: 'dense', type: Boolean},
      initialValue: {attribute: 'initial-value', type: String},
      label: {attribute: 'label', type: String},
      helperText: {attribute: 'helper-text', type: String},
      canEdit: {attribute: 'can-edit', type: Boolean},
      editIcon: {attribute: 'edit-icon', type: String},
      __inDialog: {attribute: 'in-dialog', type: Boolean, reflect: true}
    };
  }

  clear(clearValidation) {}

  validate() {
    return true;
  }

  set value(v) {
    this.initialValue = v;
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.initialValue = '';
  }

  firstUpdated() {
    this.__inDialog = this._inDialog();
  }

  __getInputClasses() {
    return this.__hasSlot('prefix') ? ' with-leading-icon' : '';
  }

  __hasSlot(name) {
    const slot = this.querySelector(`*[slot="${name}"]`);
    if (!slot) return false;
    return true;
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div class="flex-layout-vertical">
        <div class="readonly-field${this.__getInputClasses()}">
          <div slot="prefix"><slot name="prefix"></slot></div>
          <div class="readonly-label">${this.label}</div>
          <div class="readonly-value" ${leftAndMiddleClick(e => this._dispatchEvent('value-click', {clickEvent: e}))}>${this.initialValue}</div>
          ${this.canEdit
            ? html`
                <div class="edit-btn">
                  <insight-icon-button icon-name=${this.editIcon || 'edit'} @click=${() => this._dispatchEvent('edit-value')}></insight-icon-button>
                </div>
              `
            : ''}
        </div>
        <div class="readonly-helper-line">
          <div class="readonly-helper">${unsafeHTML(this.helperText || '')}</div>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          display: grid;
        }
        :host([dense]) .readonly-field {
          height: var(--input-height, 40px);
        }
        :host .readonly-field {
          margin-bottom: 0;
          height: var(--input-height, 56px);
          border: 1px solid var(--input-border-color, var(--mdc-theme-dark-bg-24, var(--mdc-theme-divider-color)));
          border-radius: 4px;
          background-color: var(--input-bg, transparent);
          display: inline-flex;
          position: relative;
          box-sizing: border-box;
          overflow: hidden;
        }
        :host([in-dialog]) .readonly-field {
          border-color: var(--input-border-color, var(--mdc-theme-dark-bg-3, var(--mdc-theme-divider-color)));
        }
        :host([no-border]) .readonly-field {
          border: none;
        }
        :host([no-bottom-border]) .readonly-field {
          border-bottom: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
        :host .readonly-label {
          transform: translateY(-106%) scale(.75);
          color: var(--input-label-color, var(--mdc-theme-text-disabled));
          line-height: 18.4px;
          top: 50%;
          left: 16px;
          right: initial;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-family: Roboto, sans-serif;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.009375em;
          text-decoration: inherit;
          text-transform: inherit;
          position: absolute;
          transform-origin: left top;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          user-select: none;
        }
        :host([dense]) .readonly-label {
          transform: translateY(-28%) scale(0.75);
          line-height: 23px;
          top: 8px;
        }
        :host .readonly-value {
          color: var(--input-color, var(--mdc-theme-text-primary));
          font-size: var(--input-font-size, 16px);
          line-height: var(--input-line-height, 29px);
          font-weight: var(--input-font-weight, 400);
          letter-spacing: var(--input-letter-spacing, .009375em);
          text-align: var(--input-text-align, unset);
          padding: var(--input-padding, 20px 16px 7px);
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-family: Roboto,sans-serif;
          text-decoration: inherit;
          text-transform: inherit;
          align-self: flex-end;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          white-space: nowrap;
        }
        :host([dense]) .readonly-value {
          line-height: var(--input-line-height, 13px);
        }
        :host([no-border]) .readonly-label {
          left: 2px;
        }
        :host([no-border]) .readonly-value {
          padding: 20px 2px 7px;
        }
        :host .readonly-helper-line {
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;
          padding-right: 16px;
          padding-left: 16px;
          min-height: 19px;
        }
        :host .readonly-helper {
          -webkit-font-smoothing: antialiased;
          font-family: Roboto, sans-serif;
          font-size: 0.75rem;
          font-weight: 400;
          letter-spacing: 0.0333333333em;
          text-decoration: inherit;
          text-transform: inherit;
          margin: 0;
          transition: none;
          opacity: 1;
          will-change: initial;
          line-height: 19px;
          display: inline-flex;
          max-height: 19px;
          color: var(--mdc-theme-text-disabled);
        }
        :host([no-helper]) .readonly-helper-line {
          min-height: 0;
        }
        :host([no-wrap]) .readonly-value {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        :host([value-link]) .readonly-value {
          color: var(--mdc-theme-secondary);
          cursor: pointer;
          font-weight: 500;
        }
        :host([value-link]) .readonly-value:hover {
          opacity: 0.7;
        }
        :host .edit-btn {
          position: absolute;
          top: 8px;
          right: 8px;
          width: 38px;
          height: 38px;
        }
        :host([dense]) .edit-btn {
          top: unset;
          right: 0;
        }
        :host .edit-btn insight-icon-button {
          width: 100%;
          height: 100%;
          --icon-width: 24px;
          --icon-padding: 7px;
        }
        :host([dense]) .edit-btn insight-icon-button {
          --icon-width: 20px;
        }
        :host slot[name='prefix']::slotted(*) {
          color: var(--mdc-theme-text-secondary);
          line-height: 48px;
          top: 4px;
          left: 0 !important;
          position: absolute;
          width: var(--input-prefix-width, 48px);
          text-align: center;
          z-index: 1;
        }
        :host .with-leading-icon .readonly-value {
          padding-left: var(--input-prefix-width, 40px) !important;
        }
        :host .with-leading-icon .readonly-label {
          left: var(--input-prefix-width, 40px);
        }
        :host([dense]) slot[name='prefix']::slotted(*),
        :host([dense]) slot[name='suffix']::slotted(*) {
          top: 0;
          line-height: 40px;
          --icon-padding: 8px;
        }
        :host([dense]) slot[name='prefix']::slotted(*) {
          width: var(--input-prefix-width, 40px);
        }
        :host([invalid]) .readonly-field {
          border-color: var(--mdc-theme-error);
        }
        :host([help-attached]) .readonly-field {
          border-right: none;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-readonly-field', InsightReadonlyField);
