import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {i18nDate} from '@insight/insight-common/i18n/i18n-date.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '../../../components/common/member-photo.js';

/* @imgex - none */
class PosMemberScan extends InsightElement {
  show(member, isMidSale) {
    this.__isMidSale = isMidSale;
    this.__member = member;
    this.__member.joinedStr =
      this._i18n('common:joined') +
      ': ' +
      (this.__member.createdUtc ? i18nDate.formatDateInLocale(this.__member.createdUtc, Insight.location.ianaTimezone) : '');
    this.__member.typeStr = this._i18n('common:type') + ': ' + this.__member.accountType;
    this.__member.statusStr = this._i18n('common:status') + ': <span>' + this.__member.accountStatus + '</span>';
    this.__member.photo = this.__member.imageUrl || window._photoPlaceholder;
    this.requestUpdate();
  }

  close() {
    this.__reset();
  }

  __reset() {
    this.__member = null;
    this.__isMidSale = false;
    this.requestUpdate();
  }

  __applyMember() {
    this._dispatchEvent('apply-scanned-member', this.__member);
    this.close();
  }

  _render() {
    if (!this.__member) return '';
    return html`
      ${unsafeHTML(this.__css)}
      <div id="toast-body">
        <div class="typo-title">New Member Scan</div>
        <insight-icon-button id="close-btn" icon-name="close" no-focus-circle @click=${() => this.close()}></insight-icon-button>
        <div class="flex-layout-horizontal member-item">
          <member-photo
            image-url=${!!this.__member ? this.__member.imageUrl : undefined}
            account-id=${!!this.__member ? this.__member.accountGuid : undefined}
            ?primary-member=${!!this.__member ? Boolean(this.__member.primary) : false}
          ></member-photo>
          <div class="member-details flex-layout-flex-2 flex-layout-vertical flex-layout-justified">
            <div class="typo-body2 member-name" title=${this.__member.fullName}>${this.__member.fullName}</div>
            <div class="typo-caption member-joined">${this.__member.joinedStr}</div>
            <div class="typo-caption member-location">${this.__member.locationName}</div>
          </div>
          <div class="member-details flex-layout-flex flex-layout-vertical flex-layout-justified">
            <div class="typo-body2 member-account">${this.__member.accountNumber}</div>
            <div class="typo-caption member-status" data-status=${this.__member.accountStatus}>${unsafeHTML(this.__member.statusStr)}</div>
            <div class="typo-caption member-type">${this.__member.typeStr}</div>
          </div>
        </div>
        <div>
          <insight-button contained unelevated ?success=${!this.__isMidSale} ?warning=${this.__isMidSale} @click=${this.__applyMember}
            >${this.__isMidSale ? 'Swap Member' : 'Start New Sale'}</insight-button
          >
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          position: fixed;
          bottom: 8px;
          width: 360px;
          right: 8px;
          z-index: 6;
        }
        :host #toast-body {
          background: var(--mdc-theme-background);
          padding: 8px 16px;
          border-radius: 4px;
          box-shadow: var(--mdc-theme-shadow-4);
        }
        :host #close-btn {
          position: absolute;
          top: 0;
          right: 0;
        }
        :host .member-item {
          height: 48px;
          padding: 8px 16px 7px;
          background-color: var(--mdc-theme-background);
          border: solid 1px var(--mdc-theme-divider-color);
          margin: 16px 0;
          position: relative;
        }
        :host .member-item .member-details:last-child {
          -webkit-flex: 1;
          flex: 1;
        }
        :host .member-item .member-name,
        :host .member-item .member-account {
          font-size: 13px;
          font-weight: 500;
          color: var(--mdc-theme-text-primary);
          max-height: 26px;
          overflow: hidden;
          word-break: break-word;
          line-height: 13px;
        }
        :host .member-item .member-account {
          color: var(--mdc-theme-secondary);
        }
        :host .member-item .member-location,
        :host .member-item .member-type {
          font-size: 10px;
          font-weight: 400;
          color: var(--mdc-theme-text-secondary);
          max-height: 16px;
          overflow: hidden;
          word-break: break-word;
          line-height: 11px;
        }
        :host .member-item .member-joined,
        :host .member-item .member-status {
          font-size: 10px;
          font-weight: 400;
          color: var(--mdc-theme-text-primary);
          max-height: 16px;
          overflow: hidden;
          word-break: break-word;
          line-height: 11px;
        }
        :host .member-item .member-status span {
          color: var(--mdc-theme-text-error);
        }
        :host .member-item .member-status[data-status='GREEN'] span {
          color: var(--mdc-theme-text-success);
        }
        :host .member-item .member-status[data-status='YELLOW'] span {
          color: rgba(190, 154, 0);
        }
        :host .member-item .member-status[data-status='CANCELLED'] span {
          color: var(--mdc-theme-text-secondary);
          text-decoration: line-through;
        }
        :host .member-item member-photo {
          margin-right: 16px;
        }
      </style>
  `;
  }
}
window.customElements.define('pos-member-scan', PosMemberScan);
