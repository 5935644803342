import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {darken, Colors} from '@insight/insight-common/components/util/insight-color-utils.js';
import {PosMainView} from './pos-main-view.js';

/* @imgex - none */
class PosMenuView extends InsightElement {
  _render() {
    if (!this.__viewReady) this._afterRender(() => (this._getElement('.menu-inner').style.opacity = '1'));
    return html`
      ${unsafeHTML(this.__css)}
      <div id="menu-main">
        <div class="flex-layout-vertical flex-layout-center-center menu-inner" style="opacity:0;">
          <div>
            <img src="/images/generic_logo_light.png" />
          </div>
          <div class="flex-layout-vertical btns-ctr">
            <div
              class="flex-layout-horizontal flex-layout-center menu-btn"
              @click=${() => this._dispatchEvent('menu-nav', {view: PosMainView.Views.MemSearch})}
            >
              <div class="icon-wrp" style="background:${Colors.red};border-color:${darken(Colors.red, 10)}">
                <insight-icon icon-name="person_search"></insight-icon>
              </div>
              <div>
                <div class="typo-headline">Member Lookup</div>
              </div>
            </div>
            <div class="flex-layout-horizontal flex-layout-center menu-btn" @click=${() => this._dispatchEvent('menu-nav', {view: PosMainView.Views.NewSale})}>
              <div class="icon-wrp" style="background:${Colors.green};border-color:${darken(Colors.green, 10)}">
                <insight-icon icon-name="shopping_cart_checkout"></insight-icon>
              </div>
              <div class="typo-headline">New Sale</div>
            </div>
            <div class="flex-layout-horizontal flex-layout-center menu-btn">
              <div class="icon-wrp" style="background:${Colors.orange};border-color:${darken(Colors.orange, 10)}">
                <insight-icon icon-name="inventory"></insight-icon>
              </div>
              <div>
                <div class="typo-headline">Manage Inventory</div>
                <div class="typo-body4"><i>coming soon!</i></div>
              </div>
            </div>
            <div class="flex-layout-horizontal flex-layout-center menu-btn">
              <div class="icon-wrp" style="background:${Colors.purple};border-color:${darken(Colors.purple, 10)}">
                <insight-icon icon-name="point_of_sale"></insight-icon>
              </div>
              <div>
                <div class="typo-headline">POS Settings</div>
                <div class="typo-body4"><i>coming soon!</i></div>
              </div>
            </div>
          </div>
          <div class="flex-layout-horizontal flex-layout-center-center gi-footer">
            <img src="/images/playground/gi-logo-2.png" />
          </div>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
    <style>
      :host #menu-main {
        background: var(--mdc-theme-primary);
        min-width: 1366px;
        max-width: 1366px;
        min-height: 768px;
        max-height: 768px;
        overflow: hidden;
      }
      :host .menu-inner {
        width: 100%;
        height: 768px;
        row-gap: 32px;
        transition: opacity 500ms;
      }
      :host .btns-ctr {
        row-gap: 32px;
      }
      :host .menu-btn {
        width: 300px;
        height: 80px;
        background: white;
        color: var(--mdc-theme-primary);
        border-radius: 10px;
        cursor: pointer;
        padding: 0 24px;
        column-gap: 24px;
        box-shadow: var(--mdc-theme-shadow-3);
        position: relative;
      }
      :host .menu-btn:active {
        transform: scale(95%);
        box-shadow: var(--mdc-theme-shadow-1);
      }
      :host .menu-btn .typo-headline {
        font-weight: 700;
      }
      :host .menu-btn .typo-body4 {
        color: var(--mdc-theme-error);
        position: absolute;
        bottom: 11px;
      }
      :host .menu-btn .icon-wrp {
        padding: 8px;
        border-width: 2px;
        border-style: solid;
        border-radius: 8px;
        --icon-color: white;
        --icon-width: 32px;
      }
      :host .gi-footer {
        margin-top: 32px;
        border: 4px ridge #0a1218;
        background: #303030;
        border-radius: 8px;
        padding: 4px 10px;
      }
    </style>
  `;
  }
}
window.customElements.define('pos-menu-view', PosMenuView);
