import {InsightComponent, html} from '../insight-component.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '../button/insight-button.js';
import '../container/insight-dialog.js';
import './parts/insight-payment-add-ach.js';
import './parts/insight-payment-add-address.js';
import './parts/insight-payment-add-card.js';
import './parts/insight-payment-add-token.js';

class InsightPaymentMethodUpdate extends InsightComponent {
  static get properties() {
    return {
      alignTop: {type: Boolean, attribute: 'align-top'},
      __options: {type: Object},
      __view: {type: Number},
      __processing: {Type: Boolean}
    };
  }

  static get Views() {
    return {
      AddCard: 4,
      AddAddress: 6,
      AddAch: 7,
      AddToken: 8
    };
  }

  /****************************************************  Public Api  ****************************************************/

  show(options) {
    return new Promise(resolve => {
      this.__options = options;
      this.__callback = resolve;
      if (options.ach) {
        this.__options.editItem = this.__options;
        this.__view = InsightPaymentMethodUpdate.Views.AddAch;
      } else if (options.token) {
        this.__options.editItem = this.__options;
        this.__view = InsightPaymentMethodUpdate.Views.AddToken;
      } else if (this.__options.cardsOnFile && this.__options.cardsOnFile.length) {
        this.__options.editItem = this.__options.cardsOnFile[0];
        this.__view = InsightPaymentMethodUpdate.Views.AddCard;
      } else {
        this.__view = InsightPaymentMethodUpdate.Views.AddCard;
      }
      this._afterRender(() => {
        this.__dialogEl.open(this.__viewElement.getFocusEl());
        setTimeout(() => this.__viewElement.getFocusEl().setFocus(true), 500);
      });
    });
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__options = {networks: []};
  }

  __close(complete) {
    this.__dialogEl.close();
    if (!complete) {
      const result = this.__resultTemplate;
      result.cancelled = true;
      this.__callback(result);
    }
  }

  __dialogClosed() {
    this.__processing = false;
    this.__view = InsightPaymentMethodUpdate.Views.AddCard;
  }

  __getCurrentView(view, processing) {
    if (this.__options && this.__options.isCharge && processing) {
      return html`
        <div id="payment-loader" class="flex-layout-vertical flex-layout-center-center">
          <div class="typo-title loader-title">${this._i18nComp('payment', 'processing', 'Processing')}...</div>
          <div class="insight-loader" style=${window.Testophobia ? 'border-top-color:#ccc;' : ''}></div>
        </div>
      `;
    }
    switch (view) {
      case InsightPaymentMethodUpdate.Views.AddAddress:
        return html`
          <insight-payment-add-address .options=${this.__options} @change-view=${e => (this.__view = e.detail)}></insight-payment-add-address>
        `;
      case InsightPaymentMethodUpdate.Views.AddAch:
        return html`
          <insight-payment-add-ach
            .options=${this.__options}
            no-payment
            @change-view=${e => (this.__view = e.detail)}
            @finish=${this.__finish}
            @close-dialog=${() => this.__close()}
          ></insight-payment-add-ach>
        `;
      case InsightPaymentMethodUpdate.Views.AddToken:
        return html`
          <insight-payment-add-token
            .options=${this.__options}
            no-payment
            @change-view=${e => (this.__view = e.detail)}
            @finish=${this.__finish}
            @close-dialog=${() => this.__close()}
          ></insight-payment-add-token>
        `;
      default:
        return html`
          <insight-payment-add-card
            .options=${this.__options}
            no-payment
            @change-view=${e => (this.__view = e.detail)}
            @finish=${this.__finish}
            @close-dialog=${() => this.__close()}
          ></insight-payment-add-card>
        `;
    }
  }

  __finish() {
    this.__processing = true;
    const result = this.__resultTemplate;
    result.methodName = this.__options.paymentType;
    if (this.__options.requestPayerName) result.payerName = this.__options.contactsOnFile[0].fullName;
    if (this.__options.requestPayerEmail) result.payerEmail = this.__options.contactsOnFile[0].email;
    if (this.__options.requestPayerPhone) result.payerPhone = this.__options.contactsOnFile[0].phone;
    if (this.__options.ach) {
      result.details = this.__options.ach;
    } else if (this.__options.token) {
      result.details = this.__options.token;
    } else {
      const paymentCard = this.__options.cardsOnFile[0];
      result.details = {
        billingAddress: {
          addressLine: [paymentCard.address],
          city: paymentCard.city,
          country: paymentCard.country,
          dependentLocality: '',
          organization: '',
          phone: paymentCard.phone,
          postalCode: paymentCard.postalCode,
          recipient: paymentCard.nameOnCard,
          region: paymentCard.state,
          sortingCode: ''
        },
        id: paymentCard.id,
        cardType: paymentCard.type,
        cardNumber: paymentCard.number,
        cardSecurityCode: paymentCard.cvc,
        cardholderName: paymentCard.nameOnCard,
        expiryMonth: paymentCard.expireMonth,
        expiryYear: paymentCard.expireYear
      };
    }
    this.__callback(result);
  }

  get __resultTemplate() {
    return {
      complete: () => this.__close(true)
    };
  }

  get __viewElement() {
    return this._getElement('#modal-body > div > *');
  }

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  _render() {
    return html`
      <style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
      ${unsafeHTML(this.__css)}
      <insight-dialog
        id="payment-dialog"
        modal
        full-height-content
        ?slide-down-overlay=${this.alignTop}
        ?data-single-addy=${!!this.__options && this.__options.singleAddress}
        @closed=${this.__dialogClosed}
      >
        <div id="modal-body" slot="modalBody">
          <div>${this.__getCurrentView(this.__view, this.__processing)}</div>
        </div>
      </insight-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host insight-dialog {
          --dialog-max-width: 600px;
          --dialog-min-width: 600px;
          --dialog-max-height: 500px;
          --dialog-min-height: 500px;
          --dialog-padding-body: 16px 20px 8px;
        }
        :host #modal-body,
        :host #modal-body > div {
          height: 100%;
        }
        :host #payment-loader {
          height: 100%;
        }
        :host .loader-title {
          margin-bottom: 16px;
        }
        @media only screen and (max-width: 620px) {
          :host insight-dialog {
            --dialog-max-width: 95vw;
            --dialog-min-width: 95vw;
            --dialog-padding-body: 16px 8px 8px;
          }
        }
        @media only screen and (max-height: 620px) {
          :host insight-dialog {
            --dialog-max-height: 80vh;
            --dialog-min-height: 80vh;
          }
        }
        :host([mobile-res]) insight-dialog[data-single-addy] {
          --dialog-max-height: 80vh;
          --dialog-min-height: 80vh;
        }
        @media only screen and (min-height: 780px) {
          :host([mobile-res]) insight-dialog[data-single-addy] {
            --dialog-max-height: 700px;
            --dialog-min-height: 700px;
          }
        }
      </style>
    `;
  }
}
window.customElements.define('insight-payment-method-update', InsightPaymentMethodUpdate);
