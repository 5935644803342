import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {i18nMoney} from '@insight/insight-common/i18n/i18n-money.js';
import '@insight/insight-common/components/button/insight-button.js';

/* @imgex - desktop/register-with-membership/6NeKkn3ehbXLb3PsPSf7FNSrMWBkZ.jpeg */
class RegisterConfirm extends InsightElement {
  static get properties() {
    return {
      registration: {type: Object},
      __regResult: {type: Object}
    };
  }

  firstUpdated() {
    this._afterRender(() => {
      this._dispatchEvent('step-load', this);
    });
  }

  updated(changedProperties) {
    if (changedProperties.has('registration')) {
      this.__regResult = {
        price: this.registration.result.billed_amount || this.registration.result.billedAmount,
        confNum: this.registration.result.transaction_id || this.registration.result.transactionId,
        location: this.registration.result.locationGuid,
        redirect: this.registration.result.identity_register_url
      };
    }
  }

  __finish() {
    if (!this.registration.result.locationGuid) {
      window.location.href = this.__regResult.redirect;
    } else {
      window.location.href = '/';
      localStorage.setItem('insight-location', this.registration.result.locationGuid);
      Insight.state.sendCommandOneTime('changeLocation', () => {}, {location: this.registration.result.locationGuid});
    }
  }

  _render() {
    if (!this.__regResult) return;
    return html`
      ${unsafeHTML(this.__css)}
      <div>
        <div class="typo-headline">
          ${this._i18n('location:register.acctActive')}
        </div>
        <div class="flex-layout-horizontal flex-layout-wrap confirm-container">
          <p>
            <div class="typo-subhead">
              ${unsafeHTML(
                this._i18nIf(['location:register.clickToLogin', 'location:register.clickToSwitch'], () => Boolean(!this.registration.result.locationGuid), {
                  locationName: this.registration.location.name
                })
              )}
            </div>
          </p>
        </div>

        <div class="footer-btns">
          <insight-button contained @click=${this.__finish}>
            ${this._i18nIf(['location:register.loginGI', 'location:register.switchToNewLoc'], () => Boolean(!this.registration.result.locationGuid))}
          </insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
    <style>
      :host .typo-headline {
        margin: 5px 0 20px;
      }
      :host .confirm-container {
        max-width: 920px;
      }
      :host .footer-btns {
        margin: 10px 0;
      }
      :host .footer-btns insight-button:last-child {
        --button-color: var(--mdc-theme-success);
      }
    </style>
  `;
  }
}
window.customElements.define('register-confirm', RegisterConfirm);
