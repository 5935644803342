import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {i18nDate} from '@insight/insight-common/i18n/i18n-date.js';
import {i18nMoney} from '@insight/insight-common/i18n/i18n-money.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '@insight/insight-common/components/container/insight-dialog.js';
import '@insight/insight-common/components/ui/insight-tooltip.js';
import './pos-member-header.js';

/* @imgex - none */
class PosHistoryDialog extends InsightElement {
  static get properties() {
    return {
      __member: {type: String}
    };
  }
  open(member) {
    this.__member = member;
    this.__dialogEl.open();
    this.__fetchHistory();
  }

  close() {
    this.__dialogEl.close();
  }

  __dialogClosed() {
    this.__member.null;
  }

  __fetchHistory() {
    Insight.state.fetchOneTime(
      'memberPosHistory',
      state => {
        if (state) {
          this.__sales = state.history;
          this.requestUpdate();
        }
      },
      false,
      {
        memberGuid: this.__member.guid
      }
    );
  }

  __copySale(sale) {
    this._dispatchEvent('copy-sale', sale);
    this.close();
  }

  __getFirst3Cols(item, itemStr) {
    return html`<div class="flex-layout-flex hist-date">
        ${i18nDate.formatDateInLocale(item.date, window.Insight && Insight.location ? Insight.location.ianaTimezone : 'America/Los_Angeles')}
      </div>
      <div class="flex-layout-flex hist-desc" style=${!!itemStr ? 'color:var(--mdc-theme-secondary);font-weight:500' : ''}>
        ${!!itemStr
          ? html`<insight-tooltip horizontal-align="left">
              <div slot="tooltip" style="max-width:320px;pointer-events:none;">${unsafeHTML(itemStr)}</div>
              <div>${this._i18n('account:addSale.nItems', {len: item.items.length})}</div>
            </insight-tooltip>`
          : html`${item.items[0].name}`}
      </div>
      <div class="hist-ttl">${i18nMoney.format(item.total)}</div>`;
  }

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dialog id="discount-dialog" modal can-dismiss @closed=${this.__dialogClosed}>
        <div slot="modalHeader"><div>${this._i18n('account:addSale.memHistTtl')}</div></div>
        <div id="modal-body" slot="modalBody">
          <pos-member-header .member=${this.__member}></pos-member-header>
          <div id="hist-lst">
            ${!!this.__sales && this.__sales.length
              ? this.__sales.map(s => {
                  const itemStr =
                    !!s.items && s.items.length > 1
                      ? s.items.reduce((str, i, idx) => (str += (idx > 0 ? '<br>' : '') + `(1) ${i.category} - ${i.name}`), '')
                      : null;
                  return html` <div class="flex-layout-horizontal flex-layout-center hist-row">
                    ${this._tabletRes ? html` <div class="flex-layout-flex hist-3-col">${this.__getFirst3Cols(s, itemStr)}</div>` : html`${this.__getFirst3Cols(s, itemStr)}`}
                    <div class="hist-btn">
                      ${
                        !this._tabletRes
                          ? html`<insight-button outlined ?hidden=${s.incomplete}>${this._i18n('account:addSale.reprint')}</insight-button>`
                          : html`<insight-icon-button contained title=${this._i18n('account:addSale.reprint')} icon-name="receipt"></insight-icon-button>`
                      }
                        </div>
                      <div class="hist-btn">
                        ${
                          !this._tabletRes
                            ? html`<insight-button contained danger ?hidden=${s.incomplete}>${this._i18n('account:addSale.voidRef')}</insight-button>`
                            : html`<insight-icon-button
                                contained
                                title=${this._i18n('account:addSale.voidRef')}
                                icon-name="replay"
                                fixed-bg
                                style="--icon-color:#fff;--icon-bg-color:var(--mdc-theme-error)"
                              ></insight-icon-button>`
                        }
                          </div>
                        <div class="hist-btn">
                          ${
                            !this._tabletRes
                              ? html`<insight-button contained ?success=${s.incomplete} @click=${() => this.__copySale(s)}
                                  >${s.incomplete ? this._i18n('account:addSale.finishSale') : this._i18n('account:addSale.copySale')}</insight-button
                                >`
                              : html`<insight-icon-button
                                  contained
                                  title=${s.incomplete ? this._i18n('account:addSale.finishSale') : this._i18n('account:addSale.copySale')}
                                  icon-name=${s.incomplete ? 'point_of_sale' : 'content_copy'}
                                  fixed-bg
                                  style="--icon-color:#fff;--icon-bg-color:var(--mdc-theme-${s.incomplete ? 'success' : 'primary'})"
                                  @click=${() => this.__copySale(s)}
                                ></insight-icon-button>`
                          }
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>`;
                })
              : html`<style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
                  <div id="list-loader" class="flex-layout-horizontal flex-layout-center-center">
                    <div class="insight-loader" style=${window.Testophobia ? 'border-top-color:#ccc;' : ''}></div>
                  </div>`}
          </div>
        </div>
      </insight-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host insight-dialog {
          --dialog-min-width: min(95vw, 900px);
          --dialog-padding-body: 0 24px;
          --dialog-overflow-x: hidden;
        }
        :host([tablet-res]) insight-dialog {
          --dialog-min-width: min(95vw, 400px);
        }
        :host([stand-alone]) insight-dialog {
          --dialog-padding-body: 16px 24px 0;
          --icon-width: 32px;
        }
        :host #hist-lst {
          margin: 16px 0 32px;
          min-height: 170px;
          border: 1px solid var(--mdc-theme-divider-color);
        }
        :host #list-loader {
          margin-top: 69px;
        }
        :host .hist-row {
          padding: 0 8px;
          column-gap: 8px;
        }
        :host(:not([tablet-res])) .hist-row {
          height: 56px;
          padding: 0 16px;
          column-gap: 16px;
        }
        :host .hist-row:not(:last-child) {
          border-bottom: 1px solid var(--mdc-theme-divider-color);
        }
        :host .hist-desc {
          line-height: 18px !important;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        :host(:not([tablet-res])) .hist-date {
          min-width: 100px;
          max-width: 100px;
        }
        :host(:not([tablet-res])) .hist-ttl {
          text-align: right;
          margin-right: 16px;
          min-width: 80px;
          max-width: 80px;
        }
        :host .hist-3-col {
          overflow: hidden;
        }
        :host(:not([tablet-res])) .hist-btn {
          width: 160px;
        }
        :host .hist-btn insight-button {
          --button-width: 160px;
        }
      </style>
  `;
  }
}
window.customElements.define('pos-history-dialog', PosHistoryDialog);
