const StringUtils = {
  capitalize(s) {
    if (!s) return s;
    s = s.toLowerCase();
    return s.charAt(0).toUpperCase() + s.slice(1);
  },
  capitalizeAll(s) {
    if (!s) return s;
    return s
      .split(' ')
      .map(w => StringUtils.capitalize(w))
      .join(' ');
  },
  capitalizeAndSpace(s) {
    if (!s) return s;
    s = s.replaceAll('_', ' ');
    return StringUtils.capitalizeAll(s);
  },
  camelCaseToFriendly(str) {
    return StringUtils.capitalizeAll(str.replace(/([A-Z])/g, ' $1'));
  }
};

export {StringUtils};
