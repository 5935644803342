import {InsightElement, html} from '../insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {MDCMenuSurface} from '@insight/insight-common/components/_material/menu-surface/dist/mdc.menuSurface.min.cjs';

class InsightDropdownContainer extends InsightElement {
  constructor() {
    super();
    this.__open = false;
    this.__noOutsideClick = false;
    this.__boundWindowBlurHandler = this.__handleWindowBlur.bind(this);
  }

  _destructor() {
    if (this.__dropdown) this.__dropdown.destroy();
    window.removeEventListener('blur', this.__boundWindowBlurHandler);
  }

  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      maxHeight: {attribute: 'max-height', type: Number},
      minWidth: {attribute: 'min-width', type: Number},
      horizontalAlign: {attribute: 'horizontal-align', type: String},
      horizontalOffset: {attribute: 'horizontal-offset', type: Number},
      verticalOffset: {attribute: 'vertical-offset', type: Number},
      fixed: {attribute: 'fixed', type: Boolean}
    };
  }

  get height() {
    return this.__dropdownEl.offsetHeight;
  }

  isOpen() {
    return Boolean(this.__open);
  }

  open() {
    if (!this.__open) {
      this.__open = true;
      this.__dropdown.open();
      window.addEventListener('blur', this.__boundWindowBlurHandler);
    }
  }

  close() {
    if (this.__open) {
      this.__open = false;
      this.__dropdown.close();
      window.removeEventListener('blur', this.__boundWindowBlurHandler);
    }
  }

  toggle() {
    if (this.__open) this.close();
    else this.open();
  }

  preventCloseOnOutsideClick(prevent) {
    this.__noOutsideClick = prevent;
  }

  /**************************************************  Private Methods  *************************************************/

  firstUpdated() {
    this.__dropdown = new MDCMenuSurface(this.__dropdownEl);
    this.origMaxHeight = this.maxHeight;
    this.__overrideDropdownOrigin();
    this.__overrideDropdownMaxHeight();
    this.__overrideDropdownClick();
    this.__dropdown.listen('MDCMenuSurface:closed', () => this.__dropdownClosed());
  }

  updated(changedProperties) {
    if (changedProperties.has('maxHeight') && this.__dropdown) {
      try {
        this.__dropdown.foundation_.autoPosition_();
      } catch (e) {}
    }
  }

  __getOffsetStyling() {
    let style = !isNaN(this.horizontalOffset)
      ? this.horizontalAlign === 'right'
        ? !this.fixed && this.horizontalOffset < 0
          ? `margin-left:${this.horizontalOffset}px;`
          : `margin-right:-${this.horizontalOffset}px;`
        : !this.fixed && this.horizontalOffset < 0
        ? `margin-right:${Math.abs(this.horizontalOffset)}px;`
        : `margin-left:${this.horizontalOffset}px;`
      : '';
    style += !isNaN(this.verticalOffset)
      ? !this.fixed && this.verticalOffset < 0
        ? `margin-bottom:${Math.abs(this.verticalOffset)}px;`
        : `margin-top:${this.verticalOffset}px;`
      : '';
    style += !isNaN(this.minWidth) ? `min-width: ${this.minWidth}px;` : '';
    return style;
  }

  __overrideDropdownOrigin() {
    const _this = this;
    const origCornerFn = this.__dropdown.foundation_.getOriginCorner_.bind(this.__dropdown.foundation_);
    this.__dropdown.foundation_.getOriginCorner_ = function () {
      let corner = origCornerFn();
      if (_this.horizontalAlign && _this.horizontalAlign === 'right') corner = this.setBit_(corner, 4);
      return corner;
    }.bind(this.__dropdown.foundation_);
  }

  __overrideDropdownMaxHeight() {
    const _this = this;
    const origMaxHeightFn = this.__dropdown.foundation_.adapter_.setMaxHeight.bind(this.__dropdown.foundation_.adapter_);
    this.__dropdown.foundation_.adapter_.setMaxHeight = function (mh) {
      origMaxHeightFn(
        _this.origMaxHeight
          ? _this.origMaxHeight + 'px'
          : _this.maxHeight
          ? _this.maxHeight + 'px'
          : window.innerHeight - _this.getBoundingClientRect().top - 16 + 'px'
      );
    }.bind(this.__dropdown.foundation_.adapter_);
    const origMaxHeightFn2 = this.__dropdown.foundation_.getMenuSurfaceMaxHeight_.bind(this.__dropdown.foundation_);
    this.__dropdown.foundation_.getMenuSurfaceMaxHeight_ = function (corner) {
      if (!!_this.origMaxHeight) return _this.origMaxHeight;
      else return origMaxHeightFn2(corner);
    }.bind(this.__dropdown.foundation_);
  }

  __overrideDropdownClick() {
    let _this = this;
    this.__dropdown.handleBodyClick_ = function (evt) {
      if (_this.__noOutsideClick) return;
      if (_this._getEventPath(evt).indexOf(this.root_) < 0) return this.foundation_.handleBodyClick(evt);
    }.bind(this.__dropdown);
  }

  __handleWindowBlur() {
    if (!this.__noOutsideClick) this.close();
  }

  __dropdownClosed() {
    this.__open = false;
    this._dispatchEvent('close');
  }

  get __dropdownEl() {
    return this._getElement('.mdc-menu-surface');
  }

  _render() {
    return html`
      <style>.mdc-menu-surface{display:none;position:absolute;box-sizing:border-box;max-width:calc(100vw - 32px);max-height:calc(100vh - 32px);margin:0;padding:0;-webkit-transform:scale(1);transform:scale(1);-webkit-transform-origin:top left;transform-origin:top left;opacity:0;overflow:auto;will-change:transform,opacity;z-index:8;transition:opacity .03s linear,transform .12s cubic-bezier(0,0,.2,1);transition:opacity .03s linear,transform .12s cubic-bezier(0,0,.2,1),-webkit-transform .12s cubic-bezier(0,0,.2,1);box-shadow:0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12);background-color:#fff;background-color:var(--mdc-theme-surface, #fff);color:#000;color:var(--mdc-theme-on-surface, #000);border-radius:4px;transform-origin-left:top left;transform-origin-right:top right}.mdc-menu-surface:focus{outline:0}.mdc-menu-surface--open{display:inline-block;-webkit-transform:scale(1);transform:scale(1);opacity:1}.mdc-menu-surface--animating-open{display:inline-block;-webkit-transform:scale(.8);transform:scale(.8);opacity:0}.mdc-menu-surface--animating-closed{display:inline-block;opacity:0;transition:opacity .075s linear}.mdc-menu-surface[dir=rtl],[dir=rtl] .mdc-menu-surface{transform-origin-left:top right;transform-origin-right:top left}.mdc-menu-surface--anchor{position:relative;overflow:visible}.mdc-menu-surface--fixed{position:fixed}</style>
      ${unsafeHTML(this.__css)}
      <div class="mdc-menu-surface${this.fixed ? ' mdc-menu-surface--fixed' : ''}" style=${this.__getOffsetStyling()}>
        <slot></slot>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          position: absolute;
        }
        :host .mdc-menu-surface {
          background-color: var(--mdc-theme-dark-bg-8, var(--mdc-theme-background));
          padding: var(--dropdown-padding, 16px);
          width: var(--dropdown-width);
          min-width: var(--dropdown-min-width);
          max-width: var(--dropdown-max-width);
          overflow-x: var(--dropdown-overflow-x, auto);
          overflow-y: var(--dropdown-overflow-y, auto);
        }
        :host([fixed]) .mdc-menu-surface {
          top: unset !important;
          left: unset !important;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-dropdown-container', InsightDropdownContainer);
