import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@insight/insight-common/components/container/insight-confirmation-dialog.js';
import '../common/account-summary.js';

/* @imgex - none */
class ShellModals extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  async showAskAQuestion() {
    const noEl = !this._getElement('insight-ask-question');
    const el = await this.__getOrLoadElement('insight-ask-question', () => import('@insight/insight-common/components/assistance/insight-ask-question.js'));
    if (noEl) el.addEventListener('submit', e => this.__postQuestion(e));
    this._afterRender(() => el.show());
  }

  async showCredentialAssoc(member, eventId, credId, credDate, credType, credTypeGuid, facilityCode) {
    const el = await this.__getOrLoadElement('credential-member-assoc-dialog', () => import('../credential-member-assoc/credential-member-assoc-dialog.js'));
    this._afterRender(() => el.show(member, eventId, credId, credDate, credType, credTypeGuid, facilityCode));
  }

  async showFeatureHistory() {
    const el = await this.__getOrLoadElement('feature-history-dialog', () => import('../app-shell/feature-history-dialog.js'));
    this._afterRender(() => el.show());
  }

  async addFavoriteReport(url) {
    const el = await this.__getOrLoadElement('favorite-report-dialog', () => import('../common/favorite-report-dialog.js'));
    this._afterRender(() => el.show(url));
  }

  async showReportProcessor(params) {
    const el = await this.__getOrLoadElement('report-processor', () => import('../../views/reports/processor/report-processor.js'));
    this._afterRender(() => el.show(params));
  }

  async showChiDialog(params) {
    const el = await this.__getOrLoadElement('chi-details-dialog', () => import('../common/chi-details-dialog.js'));
    this._afterRender(() => el.show(params));
  }

  async showDebugDialog() {
    const el = await this.__getOrLoadElement('app-debug-dialog', () => import('../common/app-debug-dialog.js'));
    this._afterRender(() => el.show());
  }

  showConfirmation(params) {
    const dialog = this._getElement('insight-confirmation-dialog');
    dialog.show({
      title: params.title,
      body: params.message,
      danger: !!params.danger,
      warning: !!params.warning,
      success: !!params.success,
      spinOnPause: params.spinOnPause,
      bodyPadding: params.bodyPadding,
      noDismiss: params.noDismiss,
      maxWidth: params.maxWidth,
      submitFn: !!params.onAccept ? e => params.onAccept(() => dialog.close(), dialog) : null,
      cancelFn: e => (!!params.onDecline ? params.onDecline(dialog) : null),
      submitBtnText: params.confirmBtnText,
      noTitle: params.noTitle,
      focusEl: params.focusEl
    });
  }

  closeConfirmation() {
    const dialog = this._getElement('insight-confirmation-dialog');
    dialog.close();
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__addListeners();
  }

  __addListeners() {
    document.addEventListener('toggle-acct-summary', e => this.__toggleAccountSummary(e.detail));
  }

  async __getOrLoadElement(element, importer) {
    if (!customElements.get(element)) await importer();
    this.shadowRoot.appendChild(document.createElement(element));
    await customElements.whenDefined(element);
    return this._getElement(element);
  }

  __postQuestion({detail}) {
    Insight.state.sendCommandOneTime('askAQuestion', state => {}, {question: detail, route: window.location.href});
    this._dispatchEvent('show-snackbar', this._i18n('appshell:helpmenu.thankYou'));
  }

  __toggleAccountSummary({id, x, y}) {
    this._getElement('account-summary').toggle(id, x, y);
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <account-summary></account-summary>
      <insight-confirmation-dialog></insight-confirmation-dialog>
    `;
  }

  get __css() {
    return `
      <style>

      </style>
    `;
  }
}
window.customElements.define('shell-modals', ShellModals);
