class InsightAPIUtils {
  static getCardType(card) {
    if (!card) return 'NONE';
    if (card === 'mastercard') card = 'MC';
    else if (card === 'discover') card = 'DIS';
    return card.toUpperCase();
  }

  static getNameParts(name) {
    const nameArr = name.split(/\s+/);
    return {first: nameArr.slice(0, -1).join(' '), last: nameArr.pop()};
  }

  static mapPaymentInfo(isAch, isToken, details) {
    if (isAch) {
      const achName = InsightAPIUtils.getNameParts(details.nameOnAcct);
      return {
        type: 'ACH',
        ach_routing_number: details.routingNumber,
        ach_account_number: details.acctNumber,
        ach_bank_name: details.bankName,
        ach_account_type: details.accountType,
        first_name: achName.first,
        last_name: achName.last
      };
    } else if (isToken) {
      return {
        type: 'TOKEN',
        token_source: details.source,
        customer_profile_id: details.customerId,
        payment_profile_id: details.paymentId
      };
    } else {
      const billingName = InsightAPIUtils.getNameParts(details.cardholderName);
      return {
        type: 'CREDIT',
        first_name: billingName.first,
        last_name: billingName.last,
        billing_phone: details.billingAddress.phone,
        billing_address: {
          line1: details.billingAddress.addressLine[0],
          city: details.billingAddress.city,
          state_province_county: details.billingAddress.region,
          postal_code: details.billingAddress.postalCode,
          country: details.billingAddress.country || 'US'
        },
        card_number: details.cardNumber,
        card_expiration_month: details.expiryMonth,
        card_expiration_year: details.expiryYear,
        cvv: details.cardSecurityCode,
        card_type: InsightAPIUtils.getCardType(details.cardType)
      };
    }
  }

  static getFlattenedPermissions() {
    if (!Insight.user || !Insight.user.permissions || !Insight.user.permissions.length) return [];
    const perms = [];
    let parts, lvl, pre;
    Insight.user.permissions.forEach(p => {
      if (p.includes(':')) {
        parts = p.split(':');
        lvl = parts.pop();
        pre = parts.join(':');
        perms.push(p);
        if (lvl === 'EDIT') perms.push(pre + ':VIEW');
        if (lvl === 'ALL') {
          perms.push(pre + ':EDIT');
          perms.push(pre + ':VIEW');
        }
      }
    });
    return perms;
  }

  static hasReportAccess(report) {
    if (!Insight.user) return false;
    if (Insight.user.administrator) return true;
    return Insight.roleSets.reports.includes(report);
  }
}

export default InsightAPIUtils;
