import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {ifDefined} from 'lit-html/directives/if-defined.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '@insight/insight-common/components/container/insight-card.js';
import '@insight/insight-common/components/ui/insight-icon.js';
import '../../components/common/member-photo.js';
import './pos-history-dialog.js';
import './pos-member-dialog.js';

/* @imgex - none */
class PosMemberHeader extends InsightElement {
  static get properties() {
    return {
      member: {type: Object},
      topLevel: {type: Boolean, attribute: 'top-level'},
      standAlone: {type: Boolean, attribute: 'stand-alone'}
    };
  }

  __clearMember() {
    this.member = null;
  }

  __showHistory() {
    this.__historyDlg.open(this.member);
  }

  __findMember() {
    this.__memberDlg.open();
  }

  __applyMember({detail: member}) {
    this.member = member;
  }

  get __historyDlg() {
    return this._getElement('pos-history-dialog');
  }

  get __memberDlg() {
    return this._getElement('pos-member-dialog');
  }

  _render() {
    const cardInnerTpl = html`<div class="flex-layout-horizontal flex-layout-center card-inner">
      <member-photo
        image-url=${!!this.member ? this.member.imageUrl : undefined}
        account-id=${!!this.member ? this.member.accountGuid : undefined}
        ?primary-member=${!!this.member ? Boolean(this.member.primary) : false}
      ></member-photo>
      <div class="mbr-info">
        <div class="typo-overline">${this._i18n('common:member')}${!!this.member ? html`<span class="acct-num">${this.member.accountNumber}</span>` : ''}</div>
        <div class="flex-layout-horizontal flex-layout-center mbr-nme">
          <div class=${!this.member ? 'typo-subtitle' : 'typo-title'} style=${!this.member ? 'color:var(--mdc-theme-text-disabled);font-style:italic' : ''}>
            ${!!this.member ? this.member.fullName : this._i18n('account:addSale.noMember')}
          </div>
        </div>
      </div>
      <div class="flex-layout-flex flex-layout-horizontal flex-layout-center flex-layout-end-justified mbr-act">
        ${!this.topLevel
          ? ''
          : !this.standAlone
            ? html`<insight-button contained unelevated @click=${this.__showHistory}
                >${!this._mobileRes
                  ? this._i18n('account:addSale.viewHist')
                  : html`<insight-icon icon-name="history" title=${this._i18n('account:addSale.viewHist')}></insight-icon>`}</insight-button
              >`
            : !this.member
              ? html`<insight-icon-button id="fnd-mem" no-focus-circle icon-name="search" @click=${this.__findMember}></insight-icon-button>`
              : html`<insight-icon-button id="clr-mem" no-focus-circle icon-name="close" danger @click=${this.__clearMember}></insight-icon-button>
                  <insight-icon-button no-focus-circle icon-name="request_quote" @click=${this.__showHistory}></insight-icon-button>`}
      </div>
    </div>`;
    return html`
      ${unsafeHTML(this.__css)}
      ${this.topLevel
        ? html`<insight-card id="crd-mbr">
            <div slot="cardContent" class="flex-layout-vertical">${cardInnerTpl}</div>
          </insight-card>`
        : html`<div id="crd-outer">${cardInnerTpl}</div>`}
      ${this.topLevel ? html`<pos-history-dialog></pos-history-dialog><pos-member-dialog @member-applied=${this.__applyMember}></pos-member-dialog>` : ''}
    `;
  }

  get __css() {
    return `
    <style>
      :host #crd-mbr {
        height: 64px;
        --card-explicit-height: 100%;
        --card-padding: 0;
        --card-body-padding-top: 0;
      }
      :host #crd-mbr > div {
        height: 100%;
      }
      :host #crd-outer {
        border: 1px solid var(--mdc-theme-divider-color);
        border-radius: 4px;
        position: relative;
        height: 64px;
      }
      :host .card-inner {
        height: 100%;
        padding: 0 12px;
        column-gap: 16px;
      }
      :host .mbr-info {
        min-height: 46px;
        max-height: 46px;
      }
      :host .typo-overline {
        position: absolute;
        top: 12px;
        left: 92px;
        line-height: 12px;
        color: var(--mdc-theme-text-secondary);
      }
      :host .mbr-nme > div {
        margin-top: 16px;
      }
      :host .acct-num {
        font-size: 13px;
        font-weight: 500;
        line-height: 13px;
        margin-left: 8px;
        color: var(--mdc-theme-secondary);
      }
      :host([stand-alone]) .mbr-act {
        --icon-width: 30px;
      }
      @media only screen and (max-width: 600px) {
        :host member-photo {
          display: none;
        }
        :host .typo-overline {
          left: 12px;
        }
      }
    </style>
  `;
  }
}
window.customElements.define('pos-member-header', PosMemberHeader);
