import ky from 'ky';

class CustomerRegisterMockAPI {
  async getMembershipDetails() {
    return new Promise(async resolve => {
      resolve(await ky.get('/mockdata/register/membership-details.json').json());
    });
  }
  async getQuoteDetails() {
    return new Promise(async (resolve, reject) => {
      if (!!new URLSearchParams(location.search).get('no-quote')) reject('quote_not_found');
      resolve(await ky.get('/mockdata/register/quote-details.json').json());
    });
  }
  async verifyUrl() {
    return new Promise(resolve => resolve({success: true}));
  }
  async startQuoteReg() {
    return new Promise(resolve => resolve());
  }
  async completeRegistration(request) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({billed_amount: request.payment_amount, transaction_id: '12345678'});
      }, 2000);
    });
  }
  async postModel(request) {
    return new Promise(resolve => resolve());
  }
}

export default CustomerRegisterMockAPI;
