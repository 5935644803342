import {loadTranslationFiles} from './insight-localization.js';

export class InsightRouter {
  init(routes, handler) {
    try {
      page(
        '/',
        (ctx, next) => this.__handlePreload(routes[0], ctx, next),
        () => handler(routes[0])
      );
      routes.forEach(r =>
        page(
          `/${r.path}`,
          (ctx, next) => this.__handlePreload(r, ctx, next),
          ctx => handler(r, ctx)
        )
      );
      page('*', () => page.redirect('/'));
      page({dispatch: false});
      this.isInitialized = true;
    } catch (e) {
      console.error('Error occurred setting up app router', e);
    }
  }

  navigate(route, replaceHistory) {
    if (route !== window.location.pathname) Insight.previousNav = window.location.pathname.substr(1) + window.location.search;
    page.show(route, null, null, false);
    if (replaceHistory) window.history.replaceState(null, '', route);
    else window.history.pushState(null, '', route);
    Insight.prevUrl = window.location.href;
    document.dispatchEvent(new CustomEvent('url-change', {detail: route}));
  }

  async __handlePreload(route, ctx, next) {
    if (window.location.hash) ctx.originalHash = window.location.hash;
    const langKey = !!route.feature ? route.feature.langKey : route.langKey;
    if (langKey) await loadTranslationFiles(Array.isArray(langKey) ? langKey : [langKey]);
    next();
  }
}
