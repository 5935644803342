import {leftAndMiddleClick, InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {i18nDate} from '@insight/insight-common/i18n/i18n-date.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/button/insight-help-button.js';
import '@insight/insight-common/components/ui/insight-icon.js';
import {AccountUtils} from '../../util/account-utils.js';
import {UserPrefUtils} from '../../util/user-pref-utils.js';
import '../common/member-photo.js';

/* @imgex - none */
class ActivityPanelList extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      activityShown: {attribute: 'activity-shown', type: Boolean},
      __activity: {type: Array},
      __memberToAssoc: {type: String}
    };
  }

  forceRefresh(itemAdded) {
    this.__lastScrollPos = this._getElement('#activity-container').scrollTop - (itemAdded ? 0 : 64);
    this.requestUpdate();
    this._afterRender(() => this.__listRendered());
  }

  showShimmer() {
    this.__activity = new Array(20).fill({shimmer: true, photo: `<img class="img-ph" src="${window._photoPlaceholder}"/>`});
  }

  loadActivity(activity) {
    if (!activity || !activity.length) {
      this.__activity = [];
      return;
    }
    this.__activity = activity.map(i => this.__normalizeActivityItem(i));
    this.__startUpdateInterval();
  }

  addNewActivity(item) {
    this.__activity.forEach(item => this.__updateItemDates(item));
    item = this.__normalizeActivityItem(item);
    this.__activity.unshift(item);
    if (this.__activity.length > 50) this.__activity.pop();
    if (this.activityShown) {
      item.animating = true;
      this.forceRefresh(true);
      this._afterRender(() => {
        const animEl = this._getElement('.activity-item:first-child');
        if (animEl) animEl.classList.add('prepAnimation');
      });
    }
    this.__playChime(item);
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__activity = [];
    document.addEventListener('url-change', this.__checkUrlForMemberView.bind(this));
    this.__checkUrlForMemberView();
  }

  __startUpdateInterval() {
    this.__stopUpdateInterval();
    this.__updateInterval = setInterval(() => {
      if (this.activityShown) {
        this.__activity.forEach(item => this.__updateItemDates(item));
        this.forceRefresh();
      }
    }, 60000);
  }

  __stopUpdateInterval() {
    if (this.__updateInterval) {
      clearInterval(this.__updateInterval);
      this.__updateInterval = null;
    }
  }

  __normalizeActivityItem(item) {
    const newItem = {
      id: item.eventId,
      accountId: item.visitor?.accountId,
      cardholderId: item.visitor?.memberId,
      visitorType: item.visitor?.visitorType,
      visitorLocation:
        !!item.visitor && !!item.visitor.homeLocationName && item.visitor.homeLocationName !== item.locationName ? item.visitor.homeLocationName : null,
      isGuest: item.visitor?.visitorType === 'GUEST',
      noMember: !item.visitor || item.visitor.visitorType === 'UNKNOWN',
      credNum: item.credentialNumber,
      credType: item.credentialType,
      label: this.__getItemLabel(item),
      doorName: item.doorName,
      dateUtc: item.dateUtc,
      photo: item.visitor?.cardholderImageUrl,
      alarm: item.eventType === 'ALARM',
      admitted: item.accessDecision?.startsWith('ADMITTED'),
      denied: item.accessDecision?.startsWith('DENIED'),
      hasPinnedNote: item.visitor?.hasPinnedNote,
      cardholderPrimary: item.visitor?.cardholderPrimary,
      status: item.visitor?.status,
      unknown: item.eventType !== 'ALARM' && !this.__hasCardholder(item),
      invalid: item.accessDecision?.endsWith('INVALID'),
      expired: item.accessDecision?.endsWith('EXPIRED'),
      unassignable: !!item.credentialNumber && ['invalid', 'expired'].includes(item.credentialNumber.toLowerCase())
    };
    this.__updateItemDates(newItem);
    return newItem;
  }

  __hasCardholder(item) {
    return !!item.visitor && !!item.visitor.memberId;
  }

  __getItemLabel(item) {
    if (item.eventType === 'ALARM') {
      return this._i18n('appshell:activity.alarmTriggered');
    } else {
      return this.__hasCardholder(item) && item.visitor.firstName
        ? item.visitor.firstName + ' ' + item.visitor.lastName
        : !!item.credentialNumber
          ? (/^.*[0-9].*$/.exec(item.credentialNumber) ? '#' : '') + item.credentialNumber
          : this._i18n('common:unknown');
    }
  }

  __updateItemDates(item) {
    const dates = this.__getFriendlyDates(item.dateUtc);
    item.fullDate = dates.fullDate;
    item.friendlyDate = dates.friendlyDate;
  }

  __getFriendlyDates(dateVal) {
    const result = {friendlyDate: null, fullDate: null};
    if (!dateVal) return result;
    result.fullDate = i18nDate.formatLongDateTimeInLocale(dateVal, Insight.location.ianaTimezone, true);
    result.friendlyDate = i18nDate.getRelativeDate(dateVal, Insight.location.ianaTimezone);
    return result;
  }

  __handleListClick(e) {
    const hasClass = (el, clazz) => el.classList && el.classList.contains(clazz);
    const path = this._getEventPath(e);
    if (path.some(el => el.id === 'btn-more-activity')) {
      const now = new Date();
      const nowStr = i18nDate.formatDateForPicker(now);
      const prevMonthStr = i18nDate.formatDateForPicker(i18nDate.subtractMonths(now, 1));
      const query = `?start=${prevMonthStr}&end=${nowStr}&col=date&dir=DESC`;
      this._navigateOnClick(e, 'reports/access/show' + query);
      // this._navigateOnClick(e, !this.__doorFilter ? 'search' : 'search?doorName=' + encodeURIComponent(this.__doorFilter.label));
    } else if (path.some(el => hasClass(el, 'photo-banner'))) {
      const bnr = path.find(el => el.classList && el.classList.contains('photo-banner'));
      if (!bnr) return;
      const photo = bnr.parentElement.querySelector('.member-photo');
      if (!photo) return;
      photo.toggleSummary();
    } else if (path.some(el => hasClass(el, 'member-photo'))) {
      // account summary popup
    } else if (path.some(el => el.hasAttribute && (el.hasAttribute('evt-unknown') || el.hasAttribute('evt-alarm')))) {
      // no member
    } else if (path.some(el => hasClass(el, 'activity-item'))) {
      const item = this.__activity[Number(path.find(el => el.classList && el.classList.contains('activity-item')).getAttribute('data-index'))];
      if (item.cardholderId && item.accountId) this._navigateOnClick(e, `accounts/${item.accountId}/view`);
    }
  }

  __createCardholderFromActivity(credEvt) {
    this._dispatchEvent('show-credential-assoc', {
      member: this.__memberToAssoc,
      eventId: credEvt.id,
      credId: credEvt.credNum,
      credDate: credEvt.dateUtc
    });
  }

  __checkUrlForMemberView() {
    let memberGuid;
    if (window.location.pathname.startsWith('/members/')) memberGuid = window.location.pathname.split('/')[2];
    if (memberGuid !== this.__memberToAssoc) this.__memberToAssoc = memberGuid;
  }

  __playChime(item) {
    const muted = UserPrefUtils.get('access-activity-panel', 'mutedChimes') || [];
    if (item.alarm) {
      if (!muted.includes('alarm')) window._notificationSounds.alert.play().catch(e => false);
    } else if (item.denied || item.invalid || item.expired) {
      if (!muted.includes('denied')) window._notificationSounds.alert.play().catch(e => false);
    } else if (['RED', 'COLLECTIONS', 'CANCELLED'].includes(item.status)) {
      if (!muted.includes('red')) window._notificationSounds.alert.play().catch(e => false);
    } else if (item.hasPinnedNote) {
      if (!muted.includes('note')) window._notificationSounds.note.play().catch(e => false);
    } else if (item.status === 'YELLOW') {
      if (!muted.includes('yellow')) window._notificationSounds.dingdong.play().catch(e => false);
    } else {
      if (!muted.includes('green')) window._notificationSounds.bell.play().catch(e => false);
    }
  }

  __listRendered() {
    setTimeout(() => {
      const item = this.__activity
        .filter(item => item.animating)
        .forEach(item => {
          item.animating = false;
          const animEls = this._getElement('.activity-item.prepAnimation', true);
          if (animEls) Array.from(animEls).forEach(el => el.classList.remove('prepAnimation'));
        });
    }, 250);
    this._getElement('#activity-container').scrollTop = this.__lastScrollPos ? this.__lastScrollPos + 64 : 0;
  }

  __buildRows(item, idx) {
    if (item.shimmer) return {};
    const key = this.__getKeyIcon(item);
    const rows = {row1: {text: item.label}};
    if (!item.alarm)
      rows.row2 = {
        text: this.__getStatusText(item),
        icon: key.icon,
        img: key.img,
        ttl: this.__getStatusText(item, key),
        iconTtl: key.title
      };
    rows.row3 = {
      text: this.__getDoorName(item),
      icon: this.__getDoorIcon(item)
    };
    rows.row4 = {
      text: item.friendlyDate,
      ttl: item.fullDate,
      icon: 'schedule',
      iconTtl: item.fullDate
    };
    return rows;
  }

  __getStatusText(item, key) {
    let text;
    if (item.noMember) text = this._i18n('common:unknown');
    else if (item.admitted) text = this._i18n('common:admitted');
    else if (item.denied) text = this._i18n('common:denied');
    else text = this._i18n('common:checkedIn');
    if (item.invalid) text += ' - ' + this._i18n('common:invalid');
    else if (item.expired) text += ' - ' + this._i18n('common:expired');
    return (!!key ? key.title + ' - ' : '') + text;
  }

  __getKeyIcon(item) {
    if (!!item.credNum) {
      switch (item.credType) {
        case 'RFID':
          return {icon: 'sensors', title: AccountUtils.getCredentialLabel(item.credType)};
        case 'MAG':
          return {icon: 'credit_card', title: AccountUtils.getCredentialLabel(item.credType)};
        case 'BARCODE':
          return {img: 'barcode.svg', title: AccountUtils.getCredentialLabel(item.credType)};
        case 'QR_CODE':
          return {icon: 'qr_code_2', title: AccountUtils.getCredentialLabel(item.credType)};
      }
    } else {
      if (item.invalid) return {icon: 'error_outline', title: this._i18n('common:invalid')};
      else if (item.expired) return {icon: 'error_outline', title: this._i18n('common:expired')};
      else return {icon: 'how_to_reg', title: ''};
    }
    return {icon: 'help_outline', title: this._i18n('common:unknownCred')};
  }

  __getDoorName(item) {
    let door = item.doorName;
    return door;
  }

  __getDoorIcon(item) {
    if (item.alarm) return 'notifications_active';
    return 'sensor_door';
  }

  _render() {
    const loadMoreTpl =
      this._hasReportAccess('access:accessReport') && !!this.__activity && !!this.__activity.length
        ? `
      <div style="height:64px;" class="flex-layout-vertical flex-layout-center-center">
        <insight-button id="btn-more-activity" outlined dense>${this._i18n('appshell:activity.seeMore')}</insight-button>
      </div>`
        : `<div></div>`;
    let actRows, banner;
    return html`
      ${unsafeHTML(this.__css)}
      <div id="activity-container" class="flex-layout-flex" ${leftAndMiddleClick(this.__handleListClick.bind(this))}>
        ${this.__activity.map((a, idx) => {
          actRows = this.__buildRows(a, idx);
          banner = !!a.hasPinnedNote
            ? {val: this._i18n('common:alert')}
            : !!a.visitorLocation
              ? {val: this._i18n('common:visitor'), title: a.visitorLocation}
              : a.isGuest
                ? {val: this._i18n('common:guest')}
                : null;
          return html`
            <div
              class="activity-item flex-layout-horizontal"
              data-index=${idx}
              ?show-shimmer=${a.shimmer === true}
              ?evt-invalid=${a.invalid || a.unassignable}
              ?evt-unknown=${a.noMember}
              ?evt-admitted=${a.admitted}
              ?evt-denied=${a.denied || a.invalid}
              ?evt-expired=${a.expired}
              ?evt-alarm=${a.alarm}
            >
              <div class="activity-details flex-layout-flex flex-layout-vertical flex-layout-justified">
                <div class="typo-body2 activity-row activity-row1" title=${actRows.row1?.ttl || actRows.row1?.text}>${actRows.row1?.text}</div>
                ${!!actRows.row2
                  ? html`<div class="flex-layout-horizontal flex-layout-center activity-row activity-row2">
                      ${!!actRows.row2.img
                        ? html`<img
                            class="activity-row-img"
                            src=${'images/' + actRows.row2.img}
                            title=${actRows.row2.iconTtl || actRows.row2.ttl || actRows.row2.text}
                          />`
                        : html`<insight-icon
                            class="activity-row-icon"
                            icon-name=${actRows.row2.icon}
                            title=${actRows.row2.iconTtl || actRows.row2.ttl || actRows.row2.text}
                          ></insight-icon>`}
                      <div class="typo-caption activity-row-lbl" title=${actRows.row2.ttl || actRows.row2.text}>${actRows.row2.text}</div>
                    </div>`
                  : ''}
                <div class="flex-layout-horizontal flex-layout-center activity-row activity-row3">
                  <insight-icon
                    class="activity-row-icon"
                    icon-name=${actRows.row3?.icon}
                    title=${actRows.row3?.iconTtl || actRows.row3?.ttl || actRows.row3?.text}
                  ></insight-icon>
                  <div class="typo-caption activity-row-lbl" title=${actRows.row3?.ttl || actRows.row3?.text}>${actRows.row3?.text}</div>
                </div>
                <div class="flex-layout-horizontal flex-layout-center activity-row activity-row4">
                  <insight-icon
                    class="activity-row-icon"
                    icon-name=${actRows.row4?.icon}
                    title=${actRows.row4?.iconTtl || actRows.row4?.ttl || actRows.row4?.text}
                  ></insight-icon>
                  <div class="typo-caption activity-row-lbl" title=${actRows.row4?.ttl || actRows.row4?.text}>${actRows.row4?.text}</div>
                </div>
              </div>
              ${a.alarm
                ? html`<insight-icon icon-name="error"></insight-icon>`
                : a.credType === 'QR_CODE' && a.noMember
                  ? html`<insight-icon class="item-icon" icon-name="qr_code_2"></insight-icon>`
                  : a.credNum && a.noMember && !a.unassignable && this._hasAccess('MEMBER:KEY:EDIT')
                    ? html`<div class="flex-layout-vertical flex-layout-center-center">
                        <insight-button
                          contained
                          unelevated
                          dense
                          class="unknown-credential"
                          data-id="${a.credNum}"
                          data-date="${a.dateUtc}"
                          @click=${e => this.__createCardholderFromActivity(a)}
                        >
                          ${this._i18n('account:member.assign')}
                        </insight-button>
                      </div>`
                    : html`<div>
                        <member-photo
                          class="member-photo"
                          image-url=${a.photo || window._photoPlaceholder}
                          ?primary-member=${a.cardholderPrimary}
                          ?has-banner=${!!banner}
                          ?has-photo=${!!a.photo}
                          ?no-bottom-border=${!!banner}
                          account-id=${a.accountId}
                          account-status=${a.status || ''}
                        ></member-photo>
                        ${!!banner
                          ? html`<div
                              class="photo-banner"
                              ?is-alert=${!!a.hasPinnedNote}
                              ?is-visitor=${!!a.visitorLocation}
                              title=${banner.title || banner.val}
                            >
                              ${banner.val}
                            </div>`
                          : ''}
                      </div>`}
            </div>
          `;
        })}
        ${unsafeHTML(loadMoreTpl)}
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          height: calc(100% - 58px);
        }
        :host #activity-container {
          height: 100%;
          overflow-y: auto;
        }
        :host .activity-item {
          position: relative;
          opacity: 1;
          transform: none;
          height: 80px;
          background-color: transparent;
          padding: 8px 12px 7px 16px;
          border-bottom: solid 1px var(--mdc-theme-divider-color);
        }
        :host .activity-item:first-child {
          transition: all 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76);
        }
        :host .activity-item[evt-alarm] {
          height: 62px;
        }
        :host .activity-item:not([show-shimmer]) {
          cursor: pointer;
        }
        :host .activity-item.prepAnimation {
          transition: none;
          opacity: 0;
          background-color: var(--mdc-theme-primary);
          transform: rotateY(-90deg);
        }
        @-moz-document url-prefix() {
          :host .activity-item.prepAnimation {
            background-color: var(--mdc-theme-background);
          }
        }
        :host .activity-item:hover {
          padding-left: 18px;
        }
        :host .activity-item[evt-alarm],
        :host .activity-item[evt-unknown] {
          cursor: unset;
          padding-left: 16px;
        }
        :host .activity-item[evt-unknown] {
          background: var(--mdc-theme-dark-bg-4, #f3f3f3);
        }
        :host .activity-item[evt-invalid],
        :host .activity-item[evt-alarm] {
          background: rgba(255, 0, 0, 0.1);
        }
        :host .activity-details {
          overflow: hidden;
        }
        :host member-photo {
          --photo-fit: cover;
          --photo-width: 75px;
          --photo-height: 75px;
          --photo-border-color: var(--mdc-theme-divider-color);
          --photo-border-width: 2px;
        }
        :host member-photo[has-banner] {
          --photo-height: 60px;
          --photo-border-radius: 4px 4px 0 0;
        }
        :host member-photo[has-photo] {
          --photo-border-width: 0;
        }
        :host .activity-row-icon {
          width: 18px;
          height: 18px;
          margin-right: 4px;
          --icon-width: 18px;
          --icon-align: baseline;
        }
        :host .activity-row-img {
          width: 14px;
          height: 18px;
          margin-right: 4px;
          padding: 0 2px;
          object-fit: cover;
        }
        :host .activity-row1 {
          font-size: 13px;
          font-weight: 500;
          color: var(--mdc-theme-text-primary);
          max-height: 26px;
          overflow: hidden;
          word-break: break-word;
          line-height: 13px;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-bottom: 4px;
        }
        :host .activity-item:hover .activity-row1 {
          color: var(--mdc-theme-secondary);
        }
        :host .activity-item[evt-alarm] .activity-row1,
        :host .activity-item[evt-unknown] .activity-row1 {
          color: var(--mdc-theme-text-primary);
        }
        :host .activity-row-lbl {
          font-size: 10px;
          line-height: 10px;
          color: var(--mdc-theme-text-primary);
        }
        :host .activity-item[evt-admitted] .activity-row2 > insight-icon {
          --icon-color: var(--mdc-theme-text-success);
        }
        :host .activity-item[evt-admitted] .activity-row2 > div {
          font-weight: 500;
          color: var(--mdc-theme-text-success);
        }
        :host .activity-item[evt-admitted] .activity-row2 > img {
          filter: invert(40%) sepia(11%) saturate(2195%) hue-rotate(72deg) brightness(93%) contrast(82%);
        }
        :host .activity-item[evt-denied] .activity-row2 > insight-icon,
        :host .activity-item[evt-expired] .activity-row2 > insight-icon {
          --icon-color: var(--mdc-theme-text-error);
        }
        :host .activity-item[evt-denied] .activity-row2 > div,
        :host .activity-item[evt-expired] .activity-row2 > div {
          font-weight: 500;
          color: var(--mdc-theme-text-error);
        }
        :host .activity-item[evt-denied] .activity-row2 > img,
        :host .activity-item[evt-expired] .activity-row2 > img {
          filter: invert(32%) sepia(7%) saturate(4523%) hue-rotate(314deg) brightness(106%) contrast(93%);
        }
        :host .activity-row4 .activity-row-lbl {
          color: var(--mdc-theme-text-secondary);
        }
        :host .activity-item[evt-denied] member-photo,
        :host .activity-item[evt-expired] member-photo,
        :host .activity-item[evt-invalid] member-photo,
        :host .activity-item[evt-unknown] member-photo {
          --photo-border-width: 2px;
          --photo-border-color: var(--mdc-theme-error);
        }
        :host .activity-item[evt-alarm] > insight-icon,
        :host .activity-item > .item-icon {
          margin: -2px 4px 0 0;
          --icon-width: 64px;
        }
        :host .activity-item[evt-alarm] > insight-icon {
          --icon-color: var(--mdc-theme-error);
        }
        :host .activity-item[show-shimmer] .activity-row,
        :host .activity-item[show-shimmer] .activity-row,
        :host .activity-item[show-shimmer] .activity-row {
          background-color: var(--mdc-theme-surface);
          height: 12px;
          opacity: 0.7;
        }
        :host .activity-item[show-shimmer] .activity-row1 {
          width: 70%;
        }
        :host .activity-item[show-shimmer] .activity-row2 {
          width: 50%;
        }
        :host .activity-item[show-shimmer] .activity-row3 {
          width: 80%;
        }
        :host .activity-item[show-shimmer] .activity-row4 {
          width: 70%;
        }
        :host .photo-banner {
          background: var(--mdc-theme-primary);
          text-align: center;
          color: white;
          font-size: 10px;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          font-weight: bold;
          cursor: context-menu;
        }
        :host .photo-banner[is-visitor] {
          background: var(--mdc-theme-warning);
        }
        :host .photo-banner[is-alert] {
          background: var(--mdc-theme-error);
        }
        :host .activity-item[evt-denied] .photo-banner,
        :host .activity-item[evt-expired] .photo-banner,
        :host .activity-item[evt-invalid] .photo-banner {
          border-left: 2px solid var(--mdc-theme-error);
          border-right: 2px solid var(--mdc-theme-error);
          border-bottom: 2px solid var(--mdc-theme-error);
        }
        :host .unknown-credential {
          --button-width: 75px;
          --button-padding: 20px 0;
          --button-font-size: .7rem;
        }
      </style>
    `;
  }
}

window.customElements.define('activity-panel-list', ActivityPanelList);
