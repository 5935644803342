import {InsightComponent, html} from '../../insight-component.js';
import {ifDefined} from 'lit/directives/if-defined.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '../../button/insight-button.js';
import '../../button/insight-icon-button.js';
import '../../form/insight-dropdown-menu.js';
import '../../form/insight-form.js';
import '../../form/insight-input.js';

class InsightPaymentAddACH extends InsightComponent {
  static get properties() {
    return {
      noPayment: {type: Boolean, attribute: 'no-payment'},
      achDetails: {type: Object},
      options: {type: Object}
    };
  }

  /****************************************************  Public Api  ****************************************************/

  constructor() {
    super();
    this.achDetails = {};
  }

  getFocusEl() {
    return this._getElement('#ipt-bank-name');
  }

  /**************************************************  Private Methods  *************************************************/

  updated(changedProperties) {
    if (changedProperties.has('options') && this.options.ach) {
      this.achDetails.accountType = this.options.ach.accountType;
      this.achDetails.bankName = this.options.ach.bankName;
      this.achDetails.routingNumber = this.options.ach.routingNumber;
      this.achDetails.acctNumber = this.options.ach.acctNumber;
      this.achDetails.nameOnAcct = this.options.ach.nameOnAcct;
      this.requestUpdate();
    }
  }

  firstUpdated() {
    this._afterRender(() => {
      this.__setupACHType();
      setTimeout(() => {
        this._getElement('#ipt-bank-name').setFocus(true);
      }, 200);
    });
  }

  __setupACHType() {
    const dd = this._getElement('#dd-account-type');
    const data = [
      {value: 'CHECKING', label: this._i18nComp('payment', 'checking', 'Checking')},
      {value: 'SAVINGS', label: this._i18nComp('payment', 'savings', 'Savings')},
      {value: 'BUS_CHECKING', label: this._i18nComp('payment', 'bizChecking', 'Business Checking')}
    ];
    if (this.achDetails.accountType) {
      data.find(d => d.value === this.achDetails.accountType).selected = true;
    } else {
      data[0].selected = true;
      this.achDetails.accountType = data[0].value;
    }
    dd.data = data;
  }

  __updateAch(e) {
    if (this.__formEl.validate(false, true)) {
      this.options.paymentType = 'insight-ach';
      this.options.ach = {};
      this.options.ach.accountType = this.achDetails.accountType;
      this.options.ach.bankName = this.achDetails.bankName;
      this.options.ach.routingNumber = this.achDetails.routingNumber;
      this.options.ach.acctNumber = this.achDetails.acctNumber;
      this.options.ach.nameOnAcct = this.achDetails.nameOnAcct;
      if (this.noPayment) this._dispatchEvent('finish');
      else this._dispatchEvent('change-view', 0);
    }
  }

  __getTitle(item) {
    if (!!item && !!item.ach) {
      if (!this.achDetails.acctNumber) return this._i18nComp('payment', 'changeAch', 'Change ACH');
      else return this._i18nComp('payment', 'editAch', 'Edit ACH');
    }
    if (!!this.options && this.options.isCharge) return this._i18nComp('payment', 'payAch', 'Pay with ACH');
    return this._i18nComp('payment', 'addAch', 'Add ACH');
  }

  get __formEl() {
    return this._getElement('insight-form');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div class="flex-layout-vertical addach-container">
        <div class="flex-layout-horizontal flex-layout-center">
          ${!this.noPayment
            ? html`
                <insight-icon-button
                  id="btnBack"
                  icon-name="arrow_back"
                  class="addach-back"
                  @click=${() => this._dispatchEvent('change-view', 0)}
                ></insight-icon-button>
              `
            : ''}
          <div class="typo-subhead addach-title">
            <b>${this.__getTitle(this.options.editItem)}</b>
          </div>
        </div>
        <insight-form id="addach-form" class="flex-layout-flex">
          <insight-dropdown-menu
            dense
            class="flex-layout-flex"
            id="dd-account-type"
            label=${this._i18nComp(null, 'accountType', 'Account Type')}
            required
            error-message=${this._i18nComp('common', 'requiredLbl', 'Required')}
            fixed-width
            @change=${e => (this.achDetails.accountType = e.detail.value)}
          ></insight-dropdown-menu>
          <insight-input
            dense
            class="flex-layout-flex"
            id="ipt-bank-name"
            label=${this._i18nComp(null, 'bankName', 'Bank Name')}
            required
            error-message=${this._i18nComp('common', 'requiredLbl', 'Required')}
            initial-value=${ifDefined(this.achDetails.bankName)}
            @change=${e => (this.achDetails.bankName = e.detail)}
          ></insight-input>
          <insight-input
            dense
            class="flex-layout-flex"
            id="ipt-name-on-acct"
            label=${this._i18nComp(null, 'nameOnAccount', 'Name on Account')}
            required
            error-message=${this._i18nComp(null, 'error.nameReq', 'Name is Required')}
            initial-value=${ifDefined(this.achDetails.nameOnAcct)}
            @change=${e => (this.achDetails.nameOnAcct = e.detail)}
          ></insight-input>
          <insight-input
            dense
            class="flex-layout-flex"
            id="ipt-routing-num"
            label=${this._i18nComp(null, 'routingNum', 'Routing Number')}
            required
            minlength="9"
            maxlength="9"
            placeholder="${this._i18nComp('payment', 'routingPlaceholder', '9 digit routing number')}"
            error-message=${this._i18nComp('common', 'requiredLbl', 'Required')}
            initial-value=${ifDefined(this.achDetails.routingNumber)}
            @change=${e => (this.achDetails.routingNumber = e.detail)}
          ></insight-input>
          <insight-input
            dense
            class="flex-layout-flex"
            id="ipt-account-num"
            label=${this._i18nComp(null, 'acctNum', 'Account Number')}
            required
            error-message=${this._i18nComp('common', 'requiredLbl', 'Required')}
            initial-value=${ifDefined(this.achDetails.acctNumber)}
            @change=${e => (this.achDetails.acctNumber = e.detail)}
          ></insight-input>
        </insight-form>
        <div class="flex-layout-horizontal flex-layout-end-justified addach-footer">
          ${(!this.options || !this.options.preventCancel) && this.noPayment
            ? html`
                <insight-button class="flex-layout-flex" outlined @click=${() => this._dispatchEvent('close-dialog')}>
                  ${this.options && this.options.cancelButtonLabel
                    ? this._i18nDirect(this.options.cancelButtonLabel)
                    : this._i18nComp(null, 'cancel', 'Cancel')}
                </insight-button>
              `
            : ''}
          ${!!this.options && this.options.acceptToken
            ? html`
                <insight-button outlined @click=${() => this._dispatchEvent('change-view', 8)}>
                  ${this._i18nComp('payment', 'useToken', 'Use Token')}
                </insight-button>
              `
            : ''}
          ${!!this.options && !!this.options.networks && this.options.networks.length
            ? html`
                <insight-button id="btnCard" outlined @click=${() => this._dispatchEvent('change-view', 1)}>
                  ${this._i18nComp('payment', 'useCard', 'Use Card')}
                </insight-button>
              `
            : ''}
          <insight-button id="btnUpdate" contained @click=${this.__updateAch}>
            ${!!this.options && this.options.isCharge ? this._i18nComp(null, 'pay', 'Pay') : this._i18nComp('payment', 'done', 'Done')}
          </insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host .addach-container {
          height: 100%;
        }
        :host .addach-back {
          margin-left: -16px;
          margin-top: -12px;
        }
        :host .addach-title {
          margin-bottom: 12px;
        }
        :host #addach-form {
          margin-top: 8px;
        }
        :host .form-row > *:not(:last-child) {
          margin-right: 16px;
        }
        :host .addach-footer {
          padding: 8px 0;
          row-gap: 8px;
          column-gap: 8px;
        }
        :host .addach-footer insight-button {
          --button-min-width: 112px;
        }
        @media only screen and (max-width: 500px) {
          :host .addach-footer insight-button {
            --button-min-width: 80px;
          }
        }
        @media only screen and (max-width: 620px) {
          :host .form-row,
          :host .addach-footer {
            flex-wrap: wrap;
          }
          :host .form-row > * {
            min-width: 100% !important;
            max-width: 100% !important;
            margin: 0 !important;
          }
        }
      </style>
    `;
  }
}
window.customElements.define('insight-payment-add-ach', InsightPaymentAddACH);
