import {InsightAppBase, html} from '@insight/insight-common/components/insight-app-base.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {default as initializeAppState} from './app-root/insight-state.js';
import './views/playground/point-of-sale/pos-main-view.js';

class GymInsightPos extends InsightAppBase {
  constructor() {
    super('GymInsightPos', ['components', 'account']);
  }

  async firstUpdated() {
    Insight.user = {
      firstName: 'John',
      lastName: 'Salesman',
      currentLocationGuid: !!window.parent.Insight.user ? window.parent.Insight.user.currentLocationGuid : '63600e89-1eb7-444b-8163-41925110db94'
    };
    Insight.location = {ianaTimezone: 'America/Los_Angeles'};
    await this._configure();
    await this._init(initializeAppState());
    this.__configured = true;
    this.requestUpdate();
  }

  async __viewLoaded({detail: headerCfg}) {
    this._applicationReady();
  }

  __handleError({detail: err}) {
    this._applicationLoadFailure(err);
  }

  get _viewportEl() {
    return this._getElement('.viewport');
  }

  get _viewEl() {
    const vp = this._viewportEl;
    return vp ? vp.firstElementChild : null;
  }

  _getSubclassTemplate() {
    if (!this.__configured) return;
    return html`
      ${unsafeHTML(this.__css)}
      <div class="viewport flex-layout-horizontal flex-layout-center-center" @view-loaded=${this.__viewLoaded}>
        <pos-main-view @app-error=${this.__handleError}></pos-main-view>
      </div>
    `;
  }

  get __css() {
    return `
    <style>
      :host .viewport {
        height: 100%;
        display: flex;
      }
      :host .viewport > *:last-child {
        padding: 0 !important;
      }
    </style>
  `;
  }
}
window.customElements.define('gym-insight-pos', GymInsightPos);
