import {ViewBase, html} from '@insight/insight-common/components/insight-view-base.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {i18nDate} from '@insight/insight-common/i18n/i18n-date.js';
import {i18nMoney} from '@insight/insight-common/i18n/i18n-money.js';
import {i18nNumber} from '@insight/insight-common/i18n/i18n-number.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/container/insight-card.js';
import {AccountUtils} from '../../util/account-utils.js';
import {PricingPlanUtils} from '../../util/pricing-plan-utils.js';
import {StringUtils} from '../../util/string-utils.js';

/* @imgex - desktop/view-quote/9nLGvMUKhvYNzLezgt.jpeg */
class QuotePreviewComponent extends ViewBase {
  static get properties() {
    return {
      quote: {type: Object},
      buttonTpl: {type: Object},
      __isPublic: {type: Boolean, attribute: 'is-public'},
      __canPurchase: {type: Boolean, attribute: 'can-purchase'},
      __hideStatic: {type: Boolean, attribute: 'hide-static'},
      __isSaving: {type: Boolean, attribute: 'is-saving'}
    };
  }

  __complete() {
    if (this.__canPurchase) {
      this._dispatchEvent('complete-quote');
      return;
    }
    if (this._isMock()) window.location.href = '/register.html?qid=' + this.quote.quoteId;
    else window.open('/register.html?qid=' + this.quote.quoteId, '_blank');
  }

  _render() {
    if (!this.quote)
      return html`
        <style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
        <div class="flex-layout-horizontal flex-layout-center-center" style="min-height:568px">
          <div class="insight-loader" style=${window.Testophobia ? 'border-top-color:#ccc;' : ''}></div>
        </div>
      `;
    const isUpgrade = !!this.quote.locationId && !!this.quote.quoteTotal;
    const isDowngrade = !!this.quote.locationId && !this.quote.quoteTotal;
    const priceParts = !!this.quote.costBreakdown ? PricingPlanUtils.getPriceParts(this.quote.costBreakdown) : null;
    return html`
      ${unsafeHTML(this.__css)}
      <div class="qte-main">
        <div class="flex-layout-horizontal flex-layout-justified qte-row">
          <div class="flex-layout-flex">
            <img class="qte-logo" src="images/website-brand-tp.png" />
          </div>
          <div class="flex-layout-flex qte-top-rt">
            <div class="typo-title" style="text-align:right">CUSTOMER QUOTE</div>
          </div>
        </div>
        <div class="flex-layout-horizontal flex-layout-justified qte-row">
          <div>
            <div><b>Gym Insight</b></div>
            <div class="typo-body3">9250 S Rainbow Blvd Suite 170-200</div>
            <div class="typo-body3">Las Vegas, NV 89139</div>
            <div class="typo-body3">1-855-FOR-GYMS (855-367-4967)</div>
            <div class="typo-body2 flex-layout-vertical qte-prep">
              <div>Prepared For:</div>
              <div class="typo-subhead">
                <div class="typo-subhead">${this.quote.prospectName || this.quote.title}</div>
              </div>
            </div>
          </div>
          <div class="qte-top-sum">
            <div class="flex-layout-horizontal flex-layout-end-justified">
              <div class="typo-body2 fld-lbl">Date:</div>
              <div class="typo-body2 fld-val">${i18nDate.formatMediumDay(this.quote.createdUtc, 'America/Los_Angeles')}</div>
            </div>
            <div class="flex-layout-horizontal flex-layout-end-justified">
              <div class="typo-body2 fld-lbl">Valid Until:</div>
              <div class="typo-body2 fld-val">${i18nDate.formatMediumDay(this.quote.expiresUtc, 'America/Los_Angeles')}</div>
            </div>
            <div class="flex-layout-horizontal flex-layout-end-justified fld-row2">
              <div class="fld-lbl2">Quoted Price:</div>
              <div class="fld-val2">${i18nMoney.format(this.quote.quoteTotal)}</div>
            </div>
          </div>
        </div>
        ${this.__hideStatic
          ? ''
          : html`<div id="sct-story" class="qte-section">
                <div class="typo-subtitle story-title">Our Story</div>
                <div class="typo-body2 story-body">
                  <p>
                    Thank you for choosing <b>Gym Insight</b>. We’re excited to help you discover more about our comprehensive and easy-to-use management
                    software and how working with us can benefit your business.
                  </p>
                  <p>
                    Our club management software started back in 2008, when we owned and managed a chain of five gyms.
                    <u>We were frustrated with the software options available</u>: they were expensive and hard to use. Performing basic functions required
                    calling the overseas customer service line. They lacked integration so that canceled members wouldn’t have their access keys disabled, and
                    it was impossible to tell where our money was going at the end of the month because
                    <b>the vendor hid how much of our revenue they were taking</b>!
                  </p>
                  <p>
                    Lacking a better option we built our own software. Over the ensuing years, we tested, grew and refined the system at our own gyms (which in
                    the software industry we call <i>dogfooding</i>). The initial rigorous evaluation occurred in 2010, when we implemented Gym Insight at a gym
                    on the brink of closure. The outcome was <u>astounding</u>: the gym's membership soared from under 300 to more than 1,200 active paying
                    members within a single year!
                  </p>
                  <p>
                    We credit much of this success to our proprietary <b>Sales Guru</b>© tool, which significantly optimizes the enrollment process. This tool
                    was instrumental in transforming even the most unmotivated sales employees into top performers. Sales Guru for tablet or kiosk can also fill
                    in for a front desk or sales position so that walk-in sales aren’t lost after hours.
                  </p>
                  <p>
                    At Gym Insight, we’re proud to provide the best software at the best value on the market. Ours is a software built from the ground up
                    <b>by gym owners</b>, designed specifically for businesses like yours, and geared toward profitability and streamlining at every point. We
                    also provide top-notch responsive customer service based in the United States, and consulting from our industry veterans and take pride in
                    being privately owned and operated by fitness people. We haven’t lost our small business roots.
                  </p>
                  <p>
                    <u>Our ambition is to help businesses like yours</u>. We’re constantly making improvements and adding even more features based on feedback
                    from you. Over the years, we’ve helped many just like you break free from the tyranny of Big Software and regain control of their revenue
                    and their business. We encourage you to read the many testimonials from our customers, and we hope to one day add your name to the list of
                    satisfied operators!
                  </p>
                  <p>Thank you again for your consideration, and we look forward to working with you!</p>
                  <div class="typo-subhead2 story-sig">The Gym Insight Team</div>
                </div>
              </div>
              <div id="sct-test" class="qte-section">
                <div class="typo-subtitle test-title">Customer Testimonials</div>
                <div class="typo-body2 flex-layout-horizontal test-body">
                  <div class="test-img">
                    <div class="test-por"><img src="images/quote-content/test-214-por.png" /></div>
                    <div class="test-logo"><img src="images/quote-content/test-214-logo.png" /></div>
                  </div>
                  <div class="flex-layout-vertical test-panel">
                    <div>
                      <div class="typo-title test-name"><b>Jovon Times</b></div>
                      <div class="typo-body2 test-loc">
                        <b><i>The Gym at 214 Main</i></b>
                      </div>
                    </div>
                    <p>
                      "What I liked most about Gym Insight was its model and how inexpensive it was compared to my other programs. I liked the staff. I felt a
                      connection that made me feel like <b>they were there for me all the time</b> from a start-up to present."
                    </p>
                    <p>
                      "I learn so much every day, getting to learn new things from customer service. I like they listen to my ideas and allow me to be a part of
                      making the program better. They are always wanting to <b>help me grow my business</b>."
                    </p>
                  </div>
                </div>
                <div class="typo-body2 flex-layout-horizontal test-body">
                  <div class="test-img">
                    <div class="test-por"><img src="images/quote-content/test-shpup-por.png" /></div>
                    <div class="test-logo"><img src="images/quote-content/test-shpup-logo.png" /></div>
                  </div>
                  <div class="flex-layout-vertical test-panel tight-ps">
                    <div>
                      <div class="typo-title test-name"><b>Crystal Lotspeich</b></div>
                      <div class="typo-body2 test-loc">
                        <b><i>Shape Up Fitness 580</i></b>
                      </div>
                    </div>
                    <p>"Best Club Management Software out there!!! BY FAR!"</p>
                    <p>
                      "I met with 6 other club management software companies. They were all overpriced and offered a lot of excess stuff I felt wasn't necessary
                      for my fitness facility. If they even offered integrated billing with a credit card processing company, their fees were outrageous and all
                      had negative reviews. Not Gym Insight!"
                    </p>
                    <p>
                      "The customer service - WOW! Let's talk about this companies willingness to answer every call and every question within minutes (not
                      hours). Thank you Anthony! All the <b>knowledge</b> they willingly gave me <b>to help me run my gym</b> to the best of my ability was a
                      bonus. I have <b>NEVER</b>, and I mean <b>NEVER</b>, come across a company with this level of Customer Support. Overall I SAY
                      <b>LOOK NO FURTHER AND GET THIS NOW!"</b>
                    </p>
                  </div>
                </div>
                <div class="typo-body2 flex-layout-horizontal test-body">
                  <div class="test-img">
                    <div class="test-por"><img src="images/quote-content/test-supfit-por.png" /></div>
                    <div class="test-logo"><img src="images/quote-content/test-supfit-logo.png" /></div>
                  </div>
                  <div class="flex-layout-vertical test-panel">
                    <div>
                      <div class="typo-title test-name"><b>Samuel Frederick</b></div>
                      <div class="typo-body2 test-loc">
                        <b><i>Superior Fitness of Granbury</i></b>
                      </div>
                    </div>
                    <p>
                      "We're up to date. We’re part of the <b>state-of-the-art</b> whatever. They sign up on a laptop, on an iPad, or they got the link on my
                      Facebook and Instagram, click on it and sign up there. <b>It's freaking awesome</b>."
                    </p>
                    <p>
                      "It makes us competitive with the big guys, having technology as a source of membership. It's painless, <b>it's really easy</b>. You got
                      no excuse not to, basically."
                    </p>
                  </div>
                </div>
                <div class="typo-body2 flex-layout-horizontal test-body">
                  <div class="test-img">
                    <div class="test-por"><img src="images/quote-content/test-fit247-por.png" /></div>
                    <div class="test-logo"><img src="images/quote-content/test-fit247-logo.png" /></div>
                  </div>
                  <div class="flex-layout-vertical test-panel">
                    <div>
                      <div class="typo-title test-name"><b>Don Roberts</b></div>
                      <div class="typo-body2 test-loc">
                        <b><i>Fit 24/7 Gym</i></b>
                      </div>
                    </div>
                    <p>
                      "Having accidentally found Gym Insight in the spring of 2021, the running of my fitness facility has been forever changed. For 20 years I
                      ran my 24 hour gym facility with pen and paper and it worked. However, I had no idea how much extra time I was spending doing so much
                      needless desk time."
                    </p>
                    <p>
                      "After integrating Gym Insight, each passing week it became apparent - <b>what a great value and timesaver</b> this company is. I highly
                      recommend that any fitness facility of any size take a close look at this software program that is <b>a complete game changer</b>."
                    </p>
                    <p>"It has helped me tremendously. Save time, organization... and <b>I make more money</b>!"</p>
                  </div>
                </div>
              </div> `}
        ${!!this.quote.notes
          ? html`
              <div class="typo-subtitle quote-title">Additional Information</div>
              <div class="qte-notes">${unsafeHTML(this.quote.notes)}</div>
            `
          : ''}
        <div class="typo-subtitle quote-title">Your Quote</div>
        <div class="qte-tbl">
          <div class="flex-layout-horizontal qte-tbl-hdr">
            <div class="flex-layout-flex">Item</div>
            ${!this._mobileRes
              ? html`
                  <div>Amount</div>
                  <div>Shipping</div>
                `
              : ''}
            <div>Total</div>
          </div>
          ${!!this.quote.costBreakdown
            ? html`<div class="flex-layout-horizontal flex-layout-center qte-tbl-ln">
                  <div class="flex-layout-flex">
                    <div class="item-nm">Gym Insight Software Subscription ${isUpgrade ? 'Upgrade (prorated)' : isDowngrade ? 'Downgrade' : ''}</div>
                  </div>
                  ${!this._mobileRes
                    ? html`
                        <div>${i18nMoney.format(isUpgrade || isDowngrade ? this.quote.proratedPrice : this.quote.paymentAmount)}</div>
                        <div>&nbsp;</div>
                      `
                    : ''}
                  <div>${i18nMoney.format(isUpgrade || isDowngrade ? this.quote.proratedPrice : this.quote.paymentAmount)}</div>
                </div>
                <div class="flex-layout-horizontal flex-layout-center qte-tbl-dsc">
                  <div class="typo-body3 item-dsc">
                    Access to the Gym Insight software suite, Member's App on Android/iOS, Sales Guru tablet sales tool, website widgets, and appointment
                    components.
                  </div>
                  ${!this._tabletRes
                    ? html`
                        <div></div>
                        <div></div>
                        <div></div>
                      `
                    : ''}
                </div>
                <div class="flex-layout-horizontal qte-tbl-ln">
                  <div class="flex-layout-flex qte-brk-dwn">
                    <div class="flex-layout-horizontal typo-body2 qte-prc-ln">
                      <div class="prc-lbl">Base Pricing:</div>
                      <div class="prc-val">${i18nMoney.format(priceParts.base)}</div>
                    </div>
                    <div class="flex-layout-horizontal typo-body2 qte-prc-ln">
                      <div class="prc-lbl">Member Accounts:</div>
                      <div class="prc-val">
                        ${!!this.quote.costBreakdown.totalMemberQty && !priceParts.members ? 'Included' : i18nMoney.format(priceParts.members)}
                      </div>
                      ${!this.quote.costBreakdown.totalMemberQty
                        ? html` <div class="typo-body3 prc-note">(not included)</div> `
                        : html` <div class="typo-body3 prc-note">(up to ${i18nNumber.format(this.quote.costBreakdown.totalMemberQty)} members*)</div> `}
                    </div>
                    <div class="flex-layout-horizontal typo-body2 qte-prc-ln">
                      <div class="prc-lbl">Digital Keytags:</div>
                      <div class="prc-val">${!!this.quote.costBreakdown.totalDkQty && !priceParts.dkts ? 'Included' : i18nMoney.format(priceParts.dkts)}</div>
                      ${!this.quote.costBreakdown.totalDkQty
                        ? html` <div class="typo-body3 prc-note">(customer declined)</div> `
                        : html` <div class="typo-body3 prc-note">(up to ${i18nNumber.format(this.quote.costBreakdown.totalDkQty)} keytags*)</div> `}
                    </div>
                    <div class="flex-layout-horizontal typo-body2 qte-prc-ln">
                      <div class="prc-lbl">Member Messages:</div>
                      <div class="prc-val">
                        ${!!this.quote.costBreakdown.totalMessageQty && !priceParts.messages ? 'Included' : i18nMoney.format(priceParts.messages)}
                      </div>
                      ${!this.quote.costBreakdown.totalMessageQty
                        ? html` <div class="typo-body3 prc-note">(customer declined)</div> `
                        : html` <div class="typo-body3 prc-note">(up to ${i18nNumber.format(this.quote.costBreakdown.totalMessageQty)} messages*)</div> `}
                    </div>
                    <div class="typo-body4 prc-warn">
                      * Subscription billed
                      ${AccountUtils.getPaymentInterval2(
                        this.quote.costBreakdown.interval,
                        this.quote.costBreakdown.intervalType,
                        'OPENEND',
                        this._i18n
                      ).toLowerCase()},
                      overage charges apply
                    </div>
                  </div>
                </div>`
            : ''}
          ${!!this.quote.items && this.quote.items.length
            ? html`${this.quote.items.map(i => {
                return html`
                  <div class="flex-layout-horizontal flex-layout-center qte-tbl-ln">
                    <div class="flex-layout-flex">
                      <div class="item-nm">${i.name}</div>
                    </div>
                    ${!this._mobileRes
                      ? html`
                          <div>${i18nMoney.format(i.amount)}</div>
                          <div>${!!i.shipping ? i18nMoney.format(i.shipping) : html`&nbsp;`}</div>
                        `
                      : ''}
                    <div>${i18nMoney.format((i.amount || 0) + (i.shipping || 0))}</div>
                  </div>
                  <div class="flex-layout-horizontal flex-layout-center qte-tbl-dsc">
                    <div class="typo-body3 item-dsc">${i.description}</div>
                    ${!this._tabletRes
                      ? html`
                          <div></div>
                          <div></div>
                          <div></div>
                        `
                      : ''}
                  </div>
                `;
              })}`
            : ''}
        </div>
        <div class="flex-layout-vertical flex-layout-end qte-ttl">
          ${!!this.quote.costBreakdown
            ? html`<div class="flex-layout-horizontal">
                <div class="typo-body2 fld-lbl3">
                  Paying
                  ${StringUtils.capitalize(
                    AccountUtils.getPaymentInterval2(this.quote.costBreakdown.interval, this.quote.costBreakdown.intervalType, 'OPENEND', this._i18n)
                  )}:
                </div>
                <div class="fld-val2">${i18nMoney.format(this.quote.paymentAmount)}</div>
              </div>`
            : ''}
          <div class="flex-layout-horizontal">
            <div class="typo-body2 fld-lbl3"><b>Total Due Today:</b></div>
            <div class="fld-val2"><b>${i18nMoney.format(this.quote.quoteTotal)}</b></div>
          </div>
        </div>
        ${!!this.buttonTpl
          ? this.buttonTpl
          : this.__isPublic && !this.__canPurchase
            ? html`
                <div class="flex-layout-horizontal flex-layout-end-justified qte-btns">
                  <insight-button contained success @click=${this.__complete} ?loading=${this.__isSaving}>${this._i18n('quote:getStarted')}</insight-button>
                </div>
              `
            : ''}
        <div class="typo-body4 qte-terms">
          The prices and terms of any quotation offered by Seller to Buyer are not subject to modification or change unless approved in writing, signed by
          Seller. The terms of any such quotes will supersede any conflicting terms of Buyer’s purchase order or other documentation. All orders and offers are
          subject to acceptance by Seller. Seller reserves the right to correct any errors in pricing in any quotations as well as in this Agreement and all
          other documents related to the sale of products between the parties hereto.
        </div>
      </div>
    `;
  }

  get __css() {
    return `
    <style>
      :host .qte-main {
        max-width: 1600px;
        margin: 0 auto;
      }
      :host .qte-row {
        width: 100%;
        margin-bottom: 24px;
      }
      :host .qte-logo {
        margin-top: 8px;
      }
      :host .qte-top-rt > div:first-child {
        font-weight: 700;
        color: var(--mdc-theme-text-secondary);
      }
      :host .qte-prep {
        margin-top: 32px;
      }
      :host .qte-prep > div:first-child {
        color: var(--mdc-theme-text-secondary);
      }
      :host .qte-prep > div:last-child {
        font-weight: 500;
      }
      :host .qte-top-sum {
        margin: 32px 0 0;
      }
      :host .fld-lbl {
        color: var(--mdc-theme-text-secondary);
      }
      :host .fld-lbl,
      :host .fld-lbl3,
      :host .fld-val {
        line-height: 32px;
        text-align: right;
      }
      :host .fld-lbl2,
      :host .fld-val2 {
        text-align: right;
        line-height: 38px;
      }
      :host .fld-lbl {
        min-width: 80px;
        max-width: 80px;
      }
      :host .fld-val,
      :host .fld-val2 {
        min-width: 120px;
        max-width: 120px;
      }
      :host .fld-row2 {
        display: inline-flex;
        font-weight: 700 !important;
        background: var(--mdc-theme-dark-secondary, #e0f8ff);
        padding: 0 16px;
        border-radius: 4px;
        margin: 8px -16px 0 0;
      }
      :host .prc-lbl,
      :host .prc-val,
      :host .prc-note {
        line-height: 24px;
      }
      :host .prc-lbl {
        min-width: 160px;
        max-width: 160px;
        font-weight: 500;
      }
      :host .prc-val {
        min-width: 90px;
        max-width: 90px;
        text-align: right;
        margin-right: 8px;
        font-weight: 500;
      }
      :host .prc-warn {
        color: var(--mdc-theme-text-secondary);
        font-style: italic;
        margin-top: 8px;
      }
      :host .qte-tbl-hdr {
        color: var(--mdc-theme-primary);
        border-radius: 4px;
        margin-top: 8px;
        font-weight: 700;
        text-decoration: underline;
      }
      :host .qte-tbl-hdr > div,
      :host .qte-tbl-ln > div,
      :host .qte-tbl-dsc > div {
        padding: 0 16px;
        line-height: 32px;
      }
      :host .qte-tbl-hdr > div:not(:first-child),
      :host .qte-tbl-ln > div:not(:first-child),
      :host .qte-tbl-dsc > div:not(:first-child) {
        min-width: 120px;
        max-width: 120px;
        text-align: right;
      }
      :host .qte-tbl-ln {
        margin-top: 12px;
      }
      :host .qte-tbl-hdr > div:not(:first-child) {
        border-left: 1px solid var(--mdc-theme-background);
      }
      :host .item-nm {
        font-weight: 500;
        line-height: 24px;
      }
      :host([mobile-res]) .item-nm {
        font-size: 14px;
        line-height: 18px;
        margin: 7px 0;
      }
      :host .item-dsc {
        padding: 0 16px;
        line-height: 20px !important;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      :host .qte-brk-dwn {
        border: 1px solid var(--mdc-theme-dark-highlight, var(--mdc-theme-divider-color));
        border-radius: 4px;
        margin: 0 16px;
        padding: 8px 16px !important;
      }
      :host .qte-ttl {
        margin-top: 40px;
        padding: 0 16px;
      }
      :host .qte-ttl > div > div {
        line-height: 30px;
      }
      :host .qte-terms {
        margin: 64px 16px 0;
        color: var(--mdc-theme-text-secondary);
        font-style: italic;
      }
      :host .qte-btns {
        padding: 32px 16px 0;
      }
      :host([mobile-res]) .qte-row {
        flex-wrap: wrap;
        padding-left: 24px;
        padding-right: 24px;
        box-sizing: border-box;
      }
      :host([mobile-res]) .qte-row > * {
        min-width: 80vw;
      }
      :host([mobile-res]) .qte-top-rt > div {
        text-align: left !important;
      }
      :host([mobile-res]) .fld-lbl,
      :host([mobile-res]) .fld-lbl2 {
        text-align: left;
      }
      :host([mobile-res]) .qte-tbl {
        padding-left: 4px;
        padding-right: 4px;
      }
      :host([mobile-res]) .qte-top-sum {
        margin: 20px 0 0;
      }
      :host([mobile-res]) .qte-top-sum > div {
        justify-content: flex-start;
      }
      :host([mobile-res]) .qte-top-sum .fld-row2 {
        margin: 24px -16px 0;
      }
      :host([mobile-res]) .qte-prc-ln {
        flex-wrap: wrap;
      }
      :host .qte-notes {
        margin: 12px 0 24px;
        padding: 0 16px;
      }
      :host .qte-notes p,
      :host .qte-notes ul {
        margin: 0;
      }
      :host #sct-story {
        margin-bottom: 24px;
      }
      :host .story-title,
      :host .test-title,
      :host .quote-title {
        background: var(--mdc-theme-primary);
        color: white;
        border-radius: 4px;
        padding: 1px 16px;
        text-shadow: 1px 1px 2px rgba(0,0,0,.8);
      }
      :host .quote-title {
        margin-top: 8px;
      }
      :host .story-body {
        padding: 8px 16px 0;
      }
      :host .qte-section p {
        text-align: justify;
      }
      :host .story-sig {
        font-weight: 700;
        font-style: italic;
        color: var(--mdc-theme-dark-primary-light, var(--mdc-theme-primary-dark));
      }
      :host .test-title {
        margin-bottom: 8px;
      }
      :host .test-body {
        padding: 8px 0;
        column-gap: 16px;
        position: relative;
        min-height: 320px;
        max-height: 320px;
      }
      :host .test-panel {
        background: var(--mdc-theme-dark-bg-8, #e0f8ff);
        border: 2px solid var(--mdc-theme-primary);
        padding: 18px 30px;
        border-radius: 10px;
      }
      :host .test-logo {
        position: absolute;
        bottom: 10px;
      }
      :host .test-logo img {
        min-width: 200px;
        max-width: 200px;
        margin-left: 20px;
      }
      :host .test-panel,
      :host .test-por,
      :host .test-por img {
        min-height: 320px;
        max-height: 320px;
        box-sizing: border-box;
      }
      :host .test-por {
        background: white;
        border: 2px solid var(--mdc-theme-primary);
        border-radius: 10px;
        overflow: hidden;
      }
      :host .test-por img {
        min-width: 240px;
        max-width: 240px;
        mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 50%, rgba(0,0,0,0));
      }
      :host .test-name {
        color: var(--mdc-theme-dark-primary-light, var(--mdc-theme-primary));
      }
      :host .test-loc {
        margin-bottom: 8px;
      }
      :host .tight-ps p {
        margin: 8px 0;
      }
      :host([tablet-res]) .test-body,
      :host([tablet-res]) .test-panel {
        min-height: unset;
        max-height: unset;
      }
      :host([tablet-res]) .test-panel p {
        font-size: 12px;
        line-height: 16px;
      }
      :host([tablet-res]) .test-logo img {
        min-width: 120px;
        max-width: 120px;
        margin-left: 10px;
      }
      :host([tablet-res]) .test-img {
        display: flex;
        flex-direction: column;
      }
      :host([tablet-res]) .test-por,
      :host([tablet-res]) .test-por img {
        min-height: 186px;
        max-height: 186px;
        min-width: 140px;
        max-width: 140px;
        mask-image: unset;
      }
      :host([tablet-res]) .test-logo {
        position: relative;
        margin-top: 24px;
      }
      :host([mobile-res]) .test-logo img {
        min-width: 64px;
        max-width: 64px;
        margin-left: 6px;
      }
      :host([mobile-res]) .test-por,
      :host([mobile-res]) .test-por img {
        min-height: 101px;
        max-height: 101px;
        min-width: 76px;
        max-width: 76px;
      }
      :host([mobile-res]) .test-logo {
        position: relative;
        margin-top: 24px;
      }
      @media (max-width: 500px) {
        :host([mobile-res]) .test-img {
          display: none;
        }
      }
    </style>
  `;
  }
}
window.customElements.define('quote-preview-component', QuotePreviewComponent);
