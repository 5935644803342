import {InsightPermUtils} from '@insight/insight-common/components/util/insight-perm-utils.js';

const ReportRegistry = {
  access: {
    meta: {
      icon: 'meeting_room'
    },
    accessReport: {url: 'reports/access/show'},
    credentials: {url: 'reports/credentials/show'},
    deniedAccess: {url: 'reports/denied-access/show'},
    hourlyCheckins: {url: 'reports/hourly-checkins/show'},
    insuranceVisits: {url: 'reports/insurance-visits/show'},
    insuranceVisitTotals: {url: 'reports/insurance-visits-totals/show'},
    missingCreds: {url: 'reports/missing-credentials/show'}
  },
  accounts: {
    meta: {
      icon: 'person'
    },
    accountDocuments: {url: 'reports/account-documents/show'},
    accountNotes: {url: 'reports/notes/show'},
    accountNotesbyUser: {url: 'reports/notes-by-user/show'},
    accountsWithoutScheduledPayments: {url: 'reports/no-scheduled-payments/show'},
    addons: {url: 'reports/addons-report/show'},
    autoRenewAccounts: {url: 'reports/auto-renew/show'},
    cancelledAccountTags: {url: 'reports/cancelled-account-tags/show'},
    cancelledAccounts: {url: 'reports/cancelled-accounts/show'},
    collectionsAccounts: {url: 'reports/collections-accounts/show'},
    deletedAccounts: {url: 'reports/location-events/show?type=DELETE_ACCOUNT'},
    dupeMembers: {url: 'reports/dupe-members/show'},
    expiringMembers: {url: 'reports/expiring-members/show'},
    expiringPrepaids: {url: 'reports/expiring-prepaid/show'},
    frozenAccounts: {url: 'reports/frozen-accounts/show'},
    guestCreation: {url: 'reports/guest-creation/show'},
    guests: {url: 'reports/guests/show'},
    memberAge: {url: 'reports/member-age/show'},
    memberAnniversaries: {url: 'reports/member-anniversaries/show'},
    memberBirthdays: {url: 'reports/member-birthdays/show'},
    members: {url: 'reports/members/show'},
    memberUsage: {url: 'reports/member-usage/show'},
    membersAppState: {url: 'reports/members-app-state/show'},
    missingEmergencyNum: {url: 'reports/missing-emerg-number/show'},
    missingMemberPictures: {url: 'reports/missing-member-pictures/show'},
    newAccountTags: {url: 'reports/new-account-tags/show'},
    newAccounts: {url: 'reports/new-accounts/show'},
    newMemberUsage: {url: 'reports/new-member-usage/show'},
    oldAccounts: {url: 'reports/old-accounts/show'},
    pendingCancel: {url: 'reports/pending-cancel/show'},
    pendingDelete: {url: 'reports/pending-delete/show'},
    pendingFreeze: {url: 'reports/pending-freeze/show'},
    personalTraining: {url: 'reports/pt-report/show'},
    tagCounts: {url: 'reports/tag-counts/show'},
    weeklyAccounts: {url: 'reports/weekly-accounts/show'},
    yearlyAccounts: {url: 'reports/yearly-accounts/show'}
  },
  contact: {
    meta: {
      icon: 'call'
    },
    emailAddresses: {url: 'reports/email-addresses/show'},
    postalAddresses: {url: 'reports/postal-addresses/show'}
  },
  events: {
    meta: {
      icon: 'event'
    },
    appointments: {url: 'reports/appointment-attendance/show'},
    classAttendance: {url: 'reports/class-attendance/show'},
    eventReservations: {url: 'reports/event-reservations/show'},
    ptAppointmentsByDate: {url: 'reports/pt-appts-by-date/show'},
    reservationsByEventDate: {url: 'reports/event-reservations-by-date/show'}
  },
  financial: {
    meta: {
      icon: 'local_atm'
    },
    accountAdjustments: {url: 'reports/adjustments/show'},
    accountsByPaymentType: {url: 'reports/accounts-by-payment-types/show'},
    addonServicesSold: {url: 'reports/addon-services-sold/show'},
    badPayments: {url: 'reports/bad-payments/show'},
    billingActivity: {url: 'reports/billing-activity/show'},
    chargesByItemType: {url: 'reports/charges-by-item-type/show'},
    chargesByPaymentType: {url: 'reports/charges-by-payment-type/show'},
    dailyDuesChange: {url: 'reports/daily-dues-change/show'},
    declinedCharges: {url: 'reports/declined-charges/show'},
    delinquencyChart: {url: 'reports/delinquency-chart/show'},
    delinquencyRate: {url: 'reports/delinquency/show'},
    delinquentAccounts: {url: 'reports/delinquent-accounts/show'},
    duesChange: {url: 'reports/dues-change/show'},
    duesChangeChart: {url: 'reports/dues-change-chart/show'},
    endOfDay: {url: 'reports/end-of-day/show'},
    ledgerReport: {url: 'reports/ledger-report/show'},
    locationPerformance: {url: 'reports/location-performance/show'},
    memberFees: {url: 'reports/member-fees/show'},
    membershipAutorenewDetail: {url: 'reports/membership-autorenew-detail/show', hidden: true},
    membershipAutorenewSummary: {url: 'reports/membership-autorenew-summary/show', feature: 'PriceChange'},
    membershipPaymentDetail: {url: 'reports/membership-payment-detail/show', hidden: true},
    membershipPaymentSummary: {url: 'reports/membership-payment-summary/show', feature: 'PriceChange'},
    membershipRevenue: {url: 'reports/membership-revenue/show'},
    missingPayments: {url: 'reports/missing-payments/show'},
    moneyYouOwe: {url: 'reports/money-you-owe/show'},
    openBalances: {url: 'reports/open-balances/show'},
    paymentMethodCounts: {url: 'payment-method-counts-report'},
    pendingAccountDues: {url: 'reports/pending-dues/show'},
    pendingCharges: {url: 'reports/pending-charges/show'},
    returnedForCollections: {url: 'reports/returned-for-collections/show'},
    revenue: {url: 'reports/revenue/show'},
    revenueByTag: {url: 'reports/revenue-by-tag/show'},
    salesPerUser: {url: 'reports/sales-by-user/show'},
    salesTaxReport: {url: 'reports/sales-tax-report/show'},
    scheduledPayments: {url: 'reports/scheduled-payments/show'},
    successfulCharges: {url: 'reports/successful-charges/show'},
    trialBalance: {url: 'reports/trial-balance/show'},
    zeroAmounts: {url: 'reports/zero-amount/show'}
  },
  location: {
    meta: {
      icon: 'store'
    },
    locationEvents: {url: 'reports/location-events/show'}
  },
  system: {
    meta: {
      icon: 'local_police'
    },
    chiTrends: {url: 'reports/chi-trends/show'},
    planChanges: {url: 'reports/pricing-plan-changes/show'}
  }
};

const getReportBase = roleGuid => {
  if (
    [
      '1ca12e1e-db50-11ee-af28-0242ac130004', //owner
      'dbd3b74b-7626-43ac-806e-98e55e21165f', //gm
      '098052f1-db50-11ee-af28-0242ac130004', //mgr
      '1336ef83-1f4b-40f5-8102-1c6302435ac6' //acct
    ].includes(roleGuid)
  ) {
    const rpts = [];
    const reg = getAvailableReports(false, true);
    Object.keys(reg).forEach(rk => {
      Object.keys(reg[rk]).forEach(k => rpts.push(`${rk}:${k}`));
    });
    return rpts;
  }
  return [];
};

const getReportRoutes = () => {
  const reg = getAvailableReports(true, false, true);
  let routes = [];
  const kebabize = str => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase());
  Object.keys(reg).forEach(rk => {
    routes = routes.concat(
      Object.keys(reg[rk]).map(k => {
        let tag = kebabize(k);
        if (!tag.endsWith('-report')) tag += '-report';
        return {
          path: reg[rk][k].url,
          view: tag,
          langKey: 'reports',
          importer: async () => await import(`./${rk}/${tag}.js`)
        };
      })
    );
  });
  return routes;
};

const getAvailableReports = (includeSystem, includeAlias, includeHidden, includeMeta) => {
  const reg = JSON.parse(JSON.stringify(ReportRegistry));
  let url, params;
  Object.keys(reg).forEach(cat => {
    if (cat === 'system' && !includeSystem) delete reg[cat];
    else
      Object.keys(reg[cat]).forEach(k => {
        url = reg[cat][k].url;
        params = reg[cat][k];
        if (
          (k === 'meta' && !includeMeta) ||
          (k !== 'meta' && url.includes('?') && !includeAlias) ||
          (!!params &&
            ((params.hidden && !includeHidden) ||
              (params.warmSprings && !InsightPermUtils.isWarmSprings()) ||
              (!!params.feature && !InsightPermUtils.hasFeature(params.feature))))
        ) {
          delete reg[cat][k];
        }
      });
  });
  return reg;
};

export {ReportRegistry, getAvailableReports, getReportBase, getReportRoutes};
