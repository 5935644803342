import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {AccountUtils} from '../../util/account-utils.js';
import '@insight/insight-common/components/form/insight-search-input.js';
import '@insight/insight-common/components/ui/insight-icon.js';
import {UserPrefUtils} from '../../util/user-pref-utils.js';

/* @imgex - desktop/search/E6xLR7HWy9pQQjzzLthw4P3kVENHjR1GLffrUhYzW.jpeg */
class GlobalSearch extends InsightElement {
  /****************************************************  Public Api  ****************************************************/
  static get properties() {
    return {
      modalsEl: {type: HTMLElement},
      __activeRoute: {type: String},
      __searchLabel: {type: String}
    };
  }

  setActiveNavigation(route) {
    this.__activeRoute = route;
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__currentResults = [];
    this.__staticResults = [];
    this.__debouncedSearch = this._debounce(750, e => {
      this.__performSearch(e);
    });
    this._subscribe('search', state => this.__finishSearch(state));
  }

  __performSearch({detail: searchTerm}, crossLoc) {
    this.__searchCrossLoc = crossLoc;
    this.__searchTerm = searchTerm;
    this.__currentResults = [];
    this.__staticResults = [];
    this.__delayedResults = [];
    this.__searchEl.setResults(this.__currentResults, true);
    let searchTypes = [];
    if (Insight.user.administrator && this.__isGuidSearch(this.__searchTerm)) {
      searchTypes = searchTypes.concat(['guid']);
    } else {
      searchTypes = searchTypes.concat(['accounts', 'credentials']);
      if (this.__searchTerm.includes('addr:')) searchTypes.push('addresses');
      if (Insight.user.administrator || this._hasAccess('USER:LIST:EDIT')) searchTypes.push('users');
      if (Insight.user.administrator) searchTypes.push('locations');
      searchTypes.push('global-static-0');
      searchTypes.push('global-static-1');
    }
    this.__searchTypesCount = searchTypes.length;
    this.__includeCancelled = UserPrefUtils.get('search', 'inclXcl');
    Insight.state.dispatch('fetchSearch', {
      searchTerms: this.__searchTerm,
      types: searchTypes,
      includeCancelled: this.__includeCancelled,
      isGlobal: true,
      i18n: this._i18n,
      locations: this.__searchCrossLoc ? Insight.locations : undefined
    });
  }

  __finishSearch(searchResult) {
    if (!searchResult.isGlobal) return;
    this.__searchTypesCount--;
    switch (searchResult.type) {
      case 'users':
        this.__currentResults = this.__currentResults.concat(searchResult.results.map(r => this.__mapUserResult(r, searchResult.type)));
        break;
      case 'accounts':
      case 'addresses':
        this.__currentResults = this.__currentResults.concat(searchResult.results.map(r => this.__mapAccountAddressResult(r, searchResult.type)));
        break;
      case 'credentials':
        this.__currentResults = this.__currentResults.concat(searchResult.results.map(r => this.__mapCredentialResult(r, searchResult.type)));
        break;
      case 'locations':
        this.__currentResults = this.__currentResults.concat(searchResult.results.map(r => this.__mapLocationResult(r, searchResult.type)));
        break;
      case 'guid':
        if (searchResult.results.length > 0) {
          const first = searchResult.results[0];
          switch (first.type.toUpperCase()) {
            case 'USER':
              this._dispatchEvent('navigate-app', Insight.user.administrator ? `admin/clients/${first.clientGuid}/view` : `location/users/edit/${first.guid}`);
              break;
            case 'EVENT':
              this._dispatchEvent('navigate-app', 'events/' + first.guid);
              break;
            case 'LOCATION':
              this._dispatchEvent('navigate-app', `admin/clients/${first.clientGuid}/view`);
              break;
            case 'ACCOUNT':
              this._dispatchEvent('navigate-app', `accounts/${first.accountGuid}/view`);
              break;
            case 'MEMBER':
              this._dispatchEvent('navigate-app', `members/${first.guid}/view`);
              break;
          }
          this.__searchEl.clear(true);
        } else {
          this.__currentResults = [];
        }
        break;
      case 'global-static-0':
      case 'global-static-1':
        if (this.__searchTypesCount === 0) this.__currentResults = this.__currentResults.concat(searchResult.results);
        else this.__delayedResults = this.__delayedResults.concat(searchResult.results);
        break;
    }
    if (this.__searchTypesCount === 0) {
      if (!this.__searchCrossLoc && !this.__currentResults.length && this.__hasMultiLocation()) {
        this.__performSearch({detail: this.__searchTerm}, true);
        return;
      }
      if (this.__delayedResults.length) {
        this.__currentResults = this.__currentResults.concat(this.__delayedResults);
        this.__delayedResults = [];
      }
      if (!this.__searchTerm.includes(':')) {
        const getStaticTpl = text => html`<i style="color:var(--mdc-theme-text-secondary)">${text}<i>`; //prettier-ignore
        this.__staticResults = this.__staticResults.concat([
          {
            titleTpl: getStaticTpl(unsafeHTML(this._i18n(`appshell:search.${this.__includeCancelled ? 'yes' : 'no'}Cancelled`))),
            avatar: {icon: 'no_accounts'},
            toggleXcld: true
          }
        ]);
      }
    }
    this.__searchEl.setResults(this.__currentResults.concat(this.__staticResults), this.__searchTypesCount > 0);
  }

  __mapUserResult(result, searchType) {
    return {
      id: result.userId,
      url: Insight.user.administrator ? `admin/clients/${result.clientId}/view` : `location/users/edit/${result.userId}`,
      button: Insight.user.administrator
        ? {
            label: this._i18n('appshell:search.become'),
            handler: e => {
              e.stopImmediatePropagation();
              this._dispatchEvent('become-user', result.userId);
            }
          }
        : undefined,
      title: `${result.fullName} - ${this._i18n('appshell:search.user')}`,
      titleTpl: html`${result.fullName}<span style="font-weight:400;"> - ${this._i18n('appshell:search.user')}</span>`, //prettier-ignore
      description: `${result.locationName} - ${!!result.roleName ? result.roleName.toLowerCase().replace('_', ' ') : 'no role'} - ${result.username}`,
      avatar: {name: result.fullName, photo: result.imageUrl || undefined, email: result.email}
    };
  }

  __mapAccountAddressResult(result, searchType) {
    return {
      id: result.accountGuid,
      locId: result.locationId,
      locName: result.locationName,
      crossLoc: true,
      url: searchType === 'credentials' ? `members/${result.memberId}/view` : `accounts/${result.accountGuid}/view`,
      iconButton: {
        icon: 'person_search',
        title: this._i18n('common:viewMember'),
        handler: e => {
          e.stopImmediatePropagation();
          this.__searchEl.value = '';
          this.__navigateToAccount(`members/${result.memberId}/view`, result.locationId, result.locationName, true);
        }
      },
      title: `${result.fullName} - ${
        result.accountType === 'GUEST'
          ? result.accountType.toLowerCase()
          : (result.memberCancelled ? '(' + this._i18n('common:cancelled').toLowerCase() + ' ' + this._i18n('common:member').toLowerCase() + ') ' : '') +
            result.accountStatus.toLowerCase() +
            ' ' +
            this._i18n('common:account').toLowerCase()
      }`,
      titleTpl: html`${result.fullName}<span style="font-weight:400;"> - ${result.accountType === 'GUEST' ? result.accountType.toLowerCase() : html`${result.memberCancelled ? html` <i style="text-transform:lowercase;color:var(--mdc-theme-text-error);">(${this._i18n('common:cancelled')} ${this._i18n('common:member')}) </i>` : ''}<span style="color:${AccountUtils.getAccountStatusCssColor(result.accountType, result.accountStatus)};">${result.accountStatus.toLowerCase()}</span> ${this._i18n('common:account').toLowerCase()}`}</span>`, //prettier-ignore
      description:
        searchType === 'credentials'
          ? `🔑 ${result.keyNumber} at ${result.locationName}`
          : `${searchType === 'addresses' ? result.address + ' - ' : ''}${result.accountNumber} at ${result.locationName}`,
      avatar: {name: result.fullName, photo: result.imageUrl || undefined, isPrimary: result.memberPrimary}
    };
  }

  __mapLocationResult(result, searchType) {
    return {
      id: result.locationId,
      url: `admin/clients/${result.clientId}/view`,
      title: result.name,
      description: this._i18n('common:location'),
      avatar: {
        name: result.name,
        icon: 'location_on'
      },
      suffixTpl: html`
        <div style="min-width:168px;min-height:40px;max-width:168px;max-height:40px;">
          <img src="${result.logoUrl}" style="max-width:168px;max-height:40px;" onerror="this.style.display='none';" />
        </div>
      `
    };
  }

  __mapCredentialResult(result, searchType) {
    if (!result.fullName) {
      return {
        id: result.credentialGuid,
        url: `reports/credentials/show`,
        title: this._i18n('appshell:search.notAssigned'),
        description: `🔑 ${result.keyNumber} at ${result.locationName}`,
        avatar: {name: result.fullName, photo: result.imageUrl || undefined, isPrimary: result.memberPrimary}
      };
    } else {
      return this.__mapAccountAddressResult(result, searchType);
    }
  }

  __searchResultSelected({detail: {item, event}}) {
    if (item.toggleXcld) {
      this.__includeCancelled = !this.__includeCancelled;
      UserPrefUtils.store('search', 'inclXcl', this.__includeCancelled);
      this.__searchEl.focus();
      this.__searchEl.__performSearch();
    } else if (item.searchType !== undefined) {
      this.__searchEl.focus();
      this.__searchEl.__performSearch();
    } else {
      let url = item.url;
      if (url.startsWith('/')) url = url.substr(1);
      if (item.onSelect) {
        this.__searchEl.clear(true);
        item.onSelect();
      } else {
        this.__searchEl.clear(true);

        this.__navigateToAccount(url, item.locId, item.locName, item.crossLoc);
      }
    }
  }

  __navigateToAccount(url, locId, locName, crossLoc) {
    if (crossLoc && locId !== Insight.user.currentLocationGuid && !Insight.user.administrator) {
      this._dispatchEvent('show-confirm', {
        title: this._i18n('appshell:advsearch.changeLocTitle'),
        message: this._i18n('appshell:advsearch.changeLocMsg', {loc: locName}),
        confirmBtnText: this._i18n('appshell:advsearch.changeLocBtn'),
        onAccept: closeDialog => {
          closeDialog();
          localStorage.setItem('insight-location', locId);
          Insight.state.sendCommandOneTime('changeLocation', () => {}, {location: locId, newRoute: '/' + url});
        }
      });
    } else {
      this._dispatchEvent('navigate-app', url);
    }
  }

  __addPrefix(key) {
    const el = this.__searchEl;
    el.value = key + '""';
    el.focus();
    const pos = key.length + 1;
    el.__inputEl.__inputEl.setSelectionRange(pos, pos);
  }

  __hasMultiLocation() {
    return !Insight.user.administrator && !!Insight.locations && Insight.locations.length && Insight.locations.length > 1;
  }

  __isGuidSearch(term) {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(term);
  }

  get __searchEl() {
    return this._getElement('insight-search-input');
  }

  __getHelpTplRow(key, label) {
    return html`
      <div class="flex-layout-horizontal flex-layout-center" style="cursor:pointer;margin:8px 0;" @click=${() => this.__addPrefix(key)}>
        <insight-icon icon-name="search" style="--icon-width:18px;"></insight-icon>
        <div class="typo-body2" style="height:20px;background:var(--mdc-theme-surface);border-radius:4px;margin:0 8px;padding: 0 4px;">${key}</div>
        <div class="typo-body2">${label}</div>
      </div>
    `;
  }

  __getHelpTpl() {
    return html`
      <div class="flex-layout-vertical" style="padding:16px 24px;">
        <div class="typo-body2" style="margin-bottom:8px;color:var(--mdc-theme-text-secondary)">${this._i18n('appshell:search.helpTitle')}</div>
        ${this.__getHelpTplRow('name:', this._i18n('appshell:search.helpByName'))}
        ${Insight.user.administrator ? this.__getHelpTplRow('loc:', this._i18n('appshell:search.helpByLoc')) : ''}
        ${this.__getHelpTplRow('acct:', this._i18n('appshell:search.helpByAcct'))} ${this.__getHelpTplRow('phone:', this._i18n('appshell:search.helpByPhone'))}
        ${this.__getHelpTplRow('key:', this._i18n('appshell:search.helpByKey'))} ${this.__getHelpTplRow('addr:', this._i18n('appshell:search.helpByAddr'))}
        ${this.__getHelpTplRow('rpt:', this._i18n('appshell:search.helpByRpt'))}${this.__getHelpTplRow('sw:', this._i18n('appshell:search.helpBySw'))}
      </div>
    `;
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-search-input
        outlined
        .helpTpl=${this.__getHelpTpl()}
        .label=${this.__label}
        ?search-as-overlay=${this._mobileRes}
        @item-select=${this.__searchResultSelected}
        @perform-search=${this.__debouncedSearch}
      ></insight-search-input>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          height: 48px;
          --dropdown-width: 100%;
        }
        :host(:not([mobile-res])) insight-search-input {
          margin-top: 4px;
        }
      </style>
    `;
  }
}
window.customElements.define('global-search', GlobalSearch);
