const InsightExternalUrls = {
  blog: 'https://blog.gyminsight.com',
  delinquencyRates: 'https://help.gyminsight.com/article/56-delinquency-rates',
  guruHelp: 'https://help.gyminsight.com/article/81-introducing-sales-guru',
  knowledgeBase: 'https://help.gyminsight.com',
  memberCalendar: 'https://calendar.gyminsight.com/calendar.html?loc={location}',
  memberEvent: 'https://calendar.gyminsight.com/calendar.html?evt={event}',
  memberHelp: 'https://help.gyminsight.com/article/275-members-app-helping-members-with-issues',
  roleHelp: 'https://help.gyminsight.com/article/137-user-permissions-based-on-user-types'
};

const InsightAppUrls = {
  apptWgtDev: 'http://localhost:9003',
  apptWgtProd: 'https://apptwidget.gyminsight.com',
  guestGuruDev: 'http://localhost:9001/guest',
  guestGuruProd: 'https://guests.gyminsight.com',
  guruDev: 'http://localhost:9001',
  guruProd: 'https://guru.gyminsight.com',
  webGuruDev: 'http://localhost:9001/join',
  webGuruProd: 'https://guru.gyminsight.com/join',
  widgetsDev: 'http://localhost:9003',
  widgetsProd: 'https://widgets.gyminsight.com'
};

export {InsightExternalUrls, InsightAppUrls};
