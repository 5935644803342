import {StringUtils} from './string-utils.js';

const AccountUtils = {
  getFullName(first, last) {
    if (!first && !last) return '';
    return `${!!first ? first + ' ' : ''}${last || ''}`;
  },
  getAccountStatusCssColor(accountType, accountStatus) {
    if (accountType === 'GUEST') return 'var(--mdc-theme-text-secondary)';
    switch (accountStatus) {
      case 'GREEN':
      case 'ACTIVE':
        return 'var(--mdc-theme-text-success)';
      case 'YELLOW':
        return 'var(--mdc-theme-text-warning);text-transform:uppercase';
      case 'CANCELLED':
      case 'COLLECTIONS':
      case 'RED':
      case 'FROZEN':
        return 'var(--mdc-theme-text-error);text-transform:uppercase';
      default:
        return 'var(--mdc-theme-text-secondary)';
    }
  },
  cleanUserRole(role) {
    if (!role) return 'No Role';
    if (!['OWNER', 'MANAGER', 'USER', 'PERSONAL_TRAINER', 'ACCOUNTING', 'SUPERVISOR', 'GENERAL_MANAGER', 'FRONT_DESK'].includes(role)) return role;
    const words = role.toLowerCase().split('_');
    return words.map(word => word[0].toUpperCase() + word.substring(1)).join(' ');
  },
  getPaymentTypes(includeAll) {
    const data = [
      {value: 0, key: 'CASH', label: 'Cash'},
      {value: 3, key: 'VISA', label: 'Visa'},
      {value: 4, key: 'MASTERCARD', label: 'Mastercard'},
      {value: 5, key: 'AMEX', label: 'American Express'},
      {value: 6, key: 'DISCOVER', label: 'Discover'},
      {value: 7, key: 'CHECK', label: 'Check'},
      {value: 8, key: 'DINERS_CLUB', label: 'Diners Club'},
      {value: 9, key: 'JCB', label: 'JCB'},
      {value: 10, key: 'BANK_CARD', label: 'Bank Card'},
      {value: 11, key: 'MOBILE_PAY', label: 'Mobile Payment'},
      {value: 12, key: 'GIFT_CARD', label: 'Gift Card'}
    ];
    if (includeAll) {
      data.splice(1, 0, {value: 1, key: 'CHECKING', label: 'Checking'}, {value: 2, key: 'SAVINGS', label: 'Savings'});
      data.push({value: 99, key: 'UNKNOWN', label: 'Unknown'});
    }
    return data;
  },
  getPaymentTypeLabel(key) {
    const type = AccountUtils.getPaymentTypes(true).find(t => t.key === key);
    return !type ? 'Undetermined' : type.label;
  },
  getIndexForAccountType(type) {
    switch (type) {
      case 'TERM':
        return 0;
      case 'PREPAID':
        return 1;
      case 'OPENEND':
        return 2;
      case 'GUEST':
        return 3;
    }
  },
  getValueForAccountType(index) {
    switch (index) {
      case 0:
        return 'TERM';
      case 1:
        return 'PREPAID';
      case 2:
        return 'OPENEND';
      case 3:
        return 'GUEST';
    }
  },
  getCredentialLabel(type) {
    switch (type) {
      case 'RFID':
        return 'RFID';
      case 'MAG':
        return 'Mag Stripe';
      case 'BARCODE':
        return 'Barcode';
      case 'QR_CODE':
        return 'Digital Keytag';
      default:
        return 'Location Default';
    }
  },
  getItemTypeForNewAccountSale(type) {
    switch (type) {
      case 'TERM':
        return 'TERM_MEMBERSHIP';
      case 'PREPAID':
        return 'PREPAID_MEMBERSHIP';
      case 'OPENEND':
        return 'MONTHLY_MEMBERSHIP';
    }
  },
  getPaymentInterval(type, interval) {
    if (!type || type === 'NONE') return '';
    const typeResult = AccountUtils.getInterval(type);
    if (!typeResult) return '';
    if (interval === 1) return `paying ${typeResult.span}`;
    return `paying every ${interval} ${typeResult.plural}`;
  },
  getPaymentInterval2(interval, intType, type, i18n, abbrev) {
    if (type === 'GUEST') {
      return '';
    } else if (type === 'PREPAID') {
      return AccountUtils.getInterval('ONCE').span;
    } else {
      const typeResult = AccountUtils.getInterval(intType);
      if (abbrev) {
        if (interval !== 1) return `/ ${interval} ${typeResult.abbvPl}`;
        else return '/ ' + typeResult.abbv;
      } else {
        if (interval !== 1) return `${i18n('common:every')} ${interval} ${typeResult.plural}`;
        else return typeResult.span;
      }
    }
  },
  getPaymentInterval3(type, interval, i18n) {
    if (!type || type === 'NONE') return '';
    const typeResult = AccountUtils.getInterval(type);
    if (!typeResult) return '';
    if (interval === 1) return `${StringUtils.capitalizeAll(typeResult.span)}`;
    return `${i18n('common:every')} ${interval} ${StringUtils.capitalizeAll(typeResult.plural)}`;
  },
  getInterval(type) {
    switch (type) {
      case 'ONCE':
      case 'NONE':
        return {
          plural: 'one time',
          singular: 'once',
          span: 'one time',
          abbv: '',
          abbvPl: ''
        };
      case 'DAYS':
      case 'DAILY':
        return {
          plural: 'days',
          singular: 'day',
          span: 'daily',
          abbv: 'day',
          abbvPl: 'days'
        };
      case 'WEEKS':
      case 'WEEKLY':
        return {
          plural: 'weeks',
          singular: 'week',
          span: 'weekly',
          abbv: 'wk',
          abbvPl: 'wks'
        };
      case 'MONTHS':
      case 'MONTHLY':
        return {
          plural: 'months',
          singular: 'month',
          span: 'monthly',
          abbv: 'mo',
          abbvPl: 'mos'
        };
      case 'ANNUALLY':
      case 'YEARS':
        return {
          plural: 'years',
          singular: 'year',
          span: 'yearly',
          abbv: 'yr',
          abbvPl: 'yrs'
        };
    }
    return null;
  },
  getPaymentType(item) {
    if (item.txnType === 'BALANCE_ADJ') return 'adj';
    else return item.paymentType.replace('_', ' ').toLowerCase();
  },
  getCreditCardName(card) {
    if (card) card = card.toUpperCase();
    switch (card) {
      case 'VISA':
        return 'visa';
      case 'MC':
        return 'mastercard';
      case 'AMEX':
        return 'amex';
      case 'DIS':
        return 'discover';
      default:
        return 'NONE';
    }
  },
  getBankAcccountType(type, i18n) {
    switch (type) {
      case 'CHECKING':
        return i18n('components:payment.checking');
      case 'SAVINGS':
        return i18n('components:payment.savings');
      case 'BUS_CHECKING':
        return i18n('components:payment.bizChecking');
      default:
        return i18n('common:unknown');
    }
  },
  getPaymentMethod(pymt, i18n) {
    if (pymt) pymt = pymt.toUpperCase();
    switch (pymt) {
      case 'VISA':
        return 'Visa';
      case 'MC':
        return 'Mastercard';
      case 'AMEX':
        return 'Amex';
      case 'DIS':
        return 'Discover';
      case 'CHECKING':
        return i18n('components:payment.checking');
      case 'SAVINGS':
        return i18n('components:payment.savings');
      case 'BUS_CHECKING':
        return i18n('components:payment.bizChecking');
      case 'CIM':
        return i18n('components:payment.cim');
      default:
        return 'NONE';
    }
  },
  maskValue(value) {
    return '•••••' + value;
  },
  getOwnershipType(type) {
    switch (type) {
      case 'isp':
        return 'Individual / Sole Proprietor';
      case 'llc':
        return 'Limited Liability Company (LLC)';
      case 'ptr':
        return 'Partnership';
      case 'scp':
        return 'Private Corporation';
      case 'ccp':
        return 'Public Corporation';
      default:
        return 'common:unknown';
    }
  },
  getPhoneNumber(nums, type) {
    let num;
    if (!!nums && nums.length) {
      num = nums.find(p => p.title === type);
      return !!num ? num.number : null;
    }
    return null;
  },
  async getAccountDialog(view, dialog, commonDir) {
    let dlg = view._getElement(dialog);
    if (!dlg) {
      if (!customElements.get(dialog)) {
        if (!commonDir) await import(`../views/account/dialogs/${dialog}.js`);
        else await import(`../components/${commonDir}/${dialog}.js`);
      }
      view.shadowRoot.appendChild(document.createElement(dialog));
      await customElements.whenDefined(dialog);
      dlg = view._getElement(dialog);
    }
    return dlg;
  },
  getAccountFilters(type, i18n) {
    switch (type) {
      case 'AccountType':
        return [
          {value: 'OPENEND', label: StringUtils.capitalizeAll(i18n('account:accountType.OPENEND'))},
          {value: 'PREPAID', label: StringUtils.capitalizeAll(i18n('account:accountType.PREPAID'))},
          {value: 'TERM', label: StringUtils.capitalizeAll(i18n('account:accountType.TERM'))},
          {value: 'GUEST', label: StringUtils.capitalizeAll(i18n('account:accountType.GUEST'))}
        ];
      case 'AccountStatus':
        return [
          {value: 'GREEN', label: 'Green'},
          {value: 'YELLOW', label: 'Yellow'},
          {value: 'RED', label: 'Red'},
          {value: 'CANCELLED', label: 'Cancelled'},
          {value: 'COLLECTIONS', label: 'Collections'}
        ];
      case 'TransactionType':
        return [
          {value: 'CHARGE', label: i18n('account:txnType.CHARGE')},
          {value: 'REFUND', label: i18n('account:txnType.REFUND')},
          {value: 'CREDIT', label: i18n('account:txnType.CREDIT')},
          {value: 'BALANCE_ADJ', label: i18n('account:txnType.BALANCE_ADJ')}
        ];
      case 'PaymentTypes':
        return AccountUtils.getPaymentTypes(true).map(o => {
          return {value: o.key, label: o.label};
        });
      case 'ItemCategories':
        return [
          {value: '4052cdcd-9b29-46b3-b9a4-3727520b31ec', label: 'Account fees'},
          {value: '87773f26-927b-11e7-bb33-02be1c8eae80', label: 'Classes'},
          {value: '41c1307a-289a-41cd-8632-fb6225fcb9ed', label: 'Credit'},
          {value: '70392fc9-591c-4efc-ba6f-206027699a65', label: 'Membership dues'},
          {value: 'b93857c5-2a3b-4e5d-93a5-e9169b6c810e', label: 'New memberships'},
          {value: 'b3a8ce9b-b0bf-4150-8ddc-f3dbbf4de98f', label: 'One-time services'},
          {value: '083ac2a5-939f-47a5-9b88-4d87f2749537', label: 'Personal training'},
          {value: 'a26935de-8559-4fb9-9ff7-522bac834f9e', label: 'Retail products'}
        ];
    }
  }
};

export {AccountUtils};
