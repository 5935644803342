import {detect} from 'detect-browser';

const getBrowserInfo = () => {
  try {
    const isMobile =
      !navigator.mediaDevices ||
      /Android|iPad|iPhone|iPod/.test(navigator.platform) ||
      /Android|iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    const info = Object.assign({}, detect(), {
      isMobile: isMobile,
      installed: navigator.standalone || Boolean(window.matchMedia('(display-mode: standalone)').matches)
    });
    info.toString = () => {
      const params = [];
      params.push(info && info.type ? info.type : 'unknown client');
      params.push(info && info.name ? info.name : 'unknown browser');
      params.push(info && info.version ? info.version : 'unknown version');
      params.push(info && info.os ? 'on ' + info.os : 'unknown OS');
      params.push(info && info.isMobile ? '(mobile)' : '');
      params.push(info && info.installed ? '(installed)' : '');
      return params.join(' ');
    };
    return info;
  } catch (e) {
    console.warn('Unable to determine browser info', e);
    return {toString: () => 'Unable to determine browser info - ' + e.message};
  }
};

export default getBrowserInfo;
