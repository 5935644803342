import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import './activity-panel-header.js';
import './activity-panel-list.js';

/* @imgex - desktop/activity-panel/9nLGvMUKhvYNzLezgt.jpeg */
class ActivityPanel extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      activityShown: {attribute: 'activity-shown', type: Boolean},
      __noActivity: {type: Boolean}
    };
  }

  forceRefresh() {
    if (this.__listEl) this.__listEl.forceRefresh();
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__queryActive = false;
    this._subscribe('recentActivity', this.__handleActivity.bind(this));
    this._subscribe('recentActivityUpdates', this.__handleActivityUpdates.bind(this));
    this.addEventListener('keydown', this.__checkKeyDown);
    this.__fetchData();
  }

  firstUpdated() {
    this._afterRender(() => {
      this.__listEl.showShimmer();
      this.__viewReady = true;
      this.__checkReady();
    });
  }

  __checkReady() {
    if (this.__viewReady && this.__dataReady) {
      this.__applyActivity();
    }
  }

  __handleActivity(state, key, type) {
    switch (type) {
      case Insight.state.eventType.FETCH:
        if (state.success === false) return;
        this.__startActivitySubscription();
        this.__recentActivity = state;
        this.__dataReady = true;
        this.__checkReady();
        break;
    }
  }

  __handleActivityUpdates(state, key, type) {
    if (type === Insight.state.eventType.FETCH) {
      if (state.reconnected) {
        this.__subscriptionActive = false;
        this.__fetchData();
      } else {
        if (!!state) this.__noActivity = false;
        this.__listEl.addNewActivity(state);
      }
    }
  }

  __applyActivity() {
    this.__queryActive = false;
    const list = this.__listEl;
    const activity = this.__recentActivity.items;
    if (!activity.length) {
      this.__noActivity = true;
      list.loadActivity(null);
      return;
    }
    this.__noActivity = false;
    list.loadActivity(activity);
  }

  __fetchData() {
    this.__queryActive = true;
    const filters = [{name: 'locationId', value: Insight.user.currentLocationGuid}];
    // filters.push({name: 'doorGroupId', value: doorFilter.id});
    Insight.state.dispatch('fetchRecentActivity', {filters: filters});
  }

  __startActivitySubscription() {
    // const filters = doorFilter ? [{name: 'doorGroupId', value: doorFilter.id}] : [];
    const filters = [];
    if (!this.__subscriptionActive) {
      this.__subscriptionActive = true;
      Insight.state.dispatch('fetchRecentActivityUpdates', {filters: filters});
    }
  }

  __checkKeyDown(e) {
    if (e.key === 'Escape' || e.keyCode === 27) {
      //prevent activity drawer close on esc
      e.stopImmediatePropagation();
    }
  }

  get __headerEl() {
    return this._getElement('activity-panel-header');
  }

  get __listEl() {
    return this._getElement('activity-panel-list');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div id="activity-wrapper" class="flex-layout-vertical">
        <activity-panel-header></activity-panel-header>
        ${this.__noActivity ? html` <div id="no-activity" class="typo-subhead">${this._i18n('components:common.noResults')}</div> ` : ''}
        <activity-panel-list ?activity-shown=${this.activityShown}></activity-panel-list>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          display: block;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          background: var(--mdc-theme-dark-bg-8, var(--mdc-theme-background));
        }
        :host #activity-wrapper {
          height: 100%;
        }
        :host #no-activity {
          color: var(--mdc-theme-text-secondary);
          padding: 16px;
          text-align: center;
        }
      </style>
    `;
  }
}
window.customElements.define('activity-panel', ActivityPanel);
