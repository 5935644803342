import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '@insight/insight-common/components/container/insight-dialog.js';
import {UserPrefUtils} from '../../util/user-pref-utils.js';

/* @imgex - desktop/announcement/U8XeCWFLT8PcZ1o9zmUJxZnEuCPgwrwXUX.jpeg */
class FeatureAnnounce extends InsightElement {
  static get properties() {
    return {
      __forceRead: {type: Boolean, attribute: 'force-read'},
      __feature: {type: Object},
      __isReview: {type: Boolean}
    };
  }
  /****************************************************  Public Api  ****************************************************/

  show(feature, isReview) {
    this.__feature = feature;
    this.__isReview = isReview;
    this.__dialogEl.open();
  }

  close() {
    this.__dialogEl.close();
  }

  /**************************************************  Private Methods  *************************************************/

  __onDismiss() {
    this.__dismissFeature();
    this.close();
  }

  __cleanContent(content) {
    return content.split('&lt;').join('<').split('&gt;').join('>');
  }

  __dialogClosed() {
    if (this.__forceRead) this.__dismissFeature();
    this.__feature = null;
  }

  __dismissFeature() {
    let dismissed = UserPrefUtils.get('general', 'feat-seen');
    if (!dismissed) dismissed = [];
    dismissed.push(this.__feature.key);
    UserPrefUtils.store('general', 'feat-seen', dismissed);
    this._dispatchEvent('dismiss');
  }

  __openMoreInfo(e) {
    if (this.__feature.dismissOnBtn) this.__dismissFeature();
    if (this.__feature.moreInfoNav) this._dispatchEvent('navigate-app', {path: this.__feature.moreInfoNav, event: e});
    else window.open(this.__feature.moreInfoUrl, '_blank');
    this.close();
  }

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  get __delBtnTpl() {
    return html`
      <insight-icon-button
        id="dismiss-btn"
        icon-name="delete"
        style="position:absolute;top:0;right:32px;"
        no-focus-circle
        title=${this._i18n('appshell:featureSeen')}
        @click=${() => this.__onDismiss()}
      ></insight-icon-button>
    `;
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dialog
        id="feature-dialog"
        modal
        border-title
        can-dismiss
        .addlHeaderBtnTpl=${this.__isReview || this.__forceRead ? null : this.__delBtnTpl}
        @closed=${this.__dialogClosed}
      >
        <div slot="modalHeader" style="width:calc(100% - ${!this.__isReview && !this.__forceRead ? '56' : '24'}px);">
          <div>${this.__feature ? this.__feature.title : ''}</div>
        </div>
        <div slot="modalBody">
          ${!!this.__feature
            ? html`
                <div id="feature-body" role="alert" ?data-video=${this.__feature.content.includes('youtube.com')}>
                  ${unsafeHTML(this.__cleanContent(this.__feature.content))}${this.__feature.moreInfoUrl || this.__feature.moreInfoNav
                    ? html`
                        <insight-button contained dense class="more-link" @click=${this.__openMoreInfo}>
                          ${!this.__feature.moreInfoBtn ? 'Learn More' : this._i18n(this.__feature.moreInfoBtn)}
                        </insight-button>
                      `
                    : ''}
                </div>
              `
            : ''}
        </div>
      </insight-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host insight-dialog {
          --dialog-padding-body: 20px 24px;
          --dialog-max-width: 50vw;
          --dialog-justify: left;
          --dialog-align: start;
          --dialog-padding: 32px 72px;
        }
        :host([tablet-res]) insight-dialog {
          --dialog-max-width: 80vw;
        }
        :host([mobile-res]) insight-dialog {
          --dialog-max-width: 95vw;
          --dialog-padding: 2.5vw;
        }
        :host #feature-body {
          min-width: 320px;
          max-width: calc(100vw - 64px);
        }
        :host([mobile-res]) #feature-body {
          min-width: unset;
        }
        :host #feature-body p {
          margin: 0;
        }
        :host #feature-body h1,
        :host #feature-body h2,
        :host #feature-body h3,
        :host #feature-body h4,
        :host #feature-body h5,
        :host #feature-body h6 {
          margin: 0;
          font-weight: normal;
        }
        :host #feature-body:not(:first-child) {
          margin-top: 32px;
        }
        :host #feature-body > div:first-child {
          background: var(--mdc-theme-primary-on-dark);
          color: white;
          padding: 8px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        :host #feature-body > div:last-child {
          border: 2px solid var(--mdc-theme-primary);
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          padding: 16px;
          overflow-x: auto;
        }
        :host #feature-body .hover-img:hover {
          opacity: 0.7;
        }
        :host .more-link {
          display: inline-block;
          margin-top: 16px;
          --button-color: var(--mdc-theme-secondary);
        }
      </style>
    `;
  }
}
window.customElements.define('feature-announce', FeatureAnnounce);
