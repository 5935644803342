import tinycolor from 'tinycolor2';

export function isDarkBackground(bgColor) {
  return tinycolor(bgColor).getBrightness() < 192;
}

export function lighten(color, amt) {
  return tinycolor(color).lighten(amt).toString();
}

export function darken(color, amt) {
  return tinycolor(color).darken(amt).toString();
}

export const ColorPalette = [
  '#F44336', //red
  '#4CAF50', //green
  '#2196F3', //blue
  '#8BC34A', //lightGreen
  '#FF9800', //orange
  '#9C27B0', //purple
  '#E91E63', //pink
  '#009688', //teal
  '#FF5722', //deepOrange
  '#00BCD4', //cyan
  '#795548', //brown
  '#03A9F4', //lightBlue
  '#673AB7', //deepPurple
  '#CDDC39', //lime
  '#3F51B5', //indigo
  '#FFC107', //amber
  '#607D8B', //blueGray
  '#FFEB3B', //yellow
  '#9E9E9E' //gray
];

export const Colors = {
  red: ColorPalette[0],
  green: ColorPalette[1],
  blue: ColorPalette[2],
  lightGreen: ColorPalette[3],
  orange: ColorPalette[4],
  purple: ColorPalette[5],
  pink: ColorPalette[6],
  teal: ColorPalette[7],
  deepOrange: ColorPalette[8],
  cyan: ColorPalette[9],
  brown: ColorPalette[10],
  lightBlue: ColorPalette[11],
  deepPurple: ColorPalette[12],
  lime: ColorPalette[13],
  indigo: ColorPalette[14],
  amber: ColorPalette[15],
  blueGray: ColorPalette[16],
  yellow: ColorPalette[17],
  gray: ColorPalette[18]
};
