import {leftAndMiddleClick, InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {i18nDate} from '@insight/insight-common/i18n/i18n-date.js';
import {i18nMoney} from '@insight/insight-common/i18n/i18n-money.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '@insight/insight-common/components/container/insight-dropdown-container.js';
import '@insight/insight-common/components/container/insight-panel.js';
import '@insight/insight-common/components/form/insight-textarea.js';
import {isDarkBackground, lighten, darken} from '@insight/insight-common/components/util/insight-color-utils.js';
import './member-photo.js';

/* @imgex - desktop/training-home/59U4TdWJWuh7To7MoMc3QgmuW1C5k.jpeg */
class AccountSummary extends InsightElement {
  static get properties() {
    return {
      __loading: {type: Boolean},
      __addNoteShown: {type: Boolean},
      __alertShown: {type: String},
      __summary: {type: Object}
    };
  }

  /****************************************************  Public Api  ****************************************************/

  toggle(accountId, x, y) {
    if (!accountId) return;
    const dd = this.__dropdownEl;
    dd.toggle();
    this.__fetchSummary(accountId);
    this._afterRender(() => {
      //account info max dimensions - 356x524
      let left = x;
      let top = y;
      if (left + 356 > window.innerWidth) left = Math.max(0, window.innerWidth - 356);
      if (top + 524 > window.innerHeight) top = Math.max(0, window.innerHeight - 524);
      dd.style.left = left + 'px';
      dd.style.top = top + 'px';
    });
  }

  close() {
    this.__addNoteShown = false;
    this.__alertShown = null;
    this.__dropdownEl.close();
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__loading = true;
  }

  firstUpdated() {
    this._afterRender(() => {
      this.__dropdownEl.preventCloseOnOutsideClick(true);
    });
  }

  __fetchSummary(accountId) {
    this.__loading = true;
    Insight.state.fetchOneTime('accountSummary', state => this.__applySummary(state), false, {accountId: accountId});
  }

  __applySummary(summary) {
    if (!summary) {
      this.close();
      return;
    }
    switch (summary.intervalType) {
      case 'DAYS':
        summary.intervalStr = this._i18n('common:time.daily');
        break;
      case 'WEEKS':
        summary.intervalStr = this._i18n('common:time.weekly');
        break;
      case 'MONTHS':
        summary.intervalStr = this._i18n('common:time.monthly');
        break;
      case 'YEAR':
        summary.intervalStr = this._i18n('common:time.yearly');
        break;
      default:
        summary.intervalStr = '';
    }
    switch (summary.paymentType) {
      case 'CREDIT':
        summary.payTypeStr = summary.cardType.toLowerCase();
        summary.expStr = summary.cardExpiration;
        summary.last4Str = summary.cardLastFour;
        break;
      case 'TOKEN':
        summary.payTypeStr = 'Token';
        summary.expStr = '';
        summary.last4Str = !!summary.maskedCardNumber ? summary.maskedCardNumber.replaceAll('X', '') : '';
        break;
      case 'ACH':
        summary.payTypeStr = 'ACH';
        summary.expStr = null;
        summary.last4Str = null;
        break;
      default:
        summary.payTypeStr = this._i18n('common:none');
        summary.expStr = null;
        summary.last4Str = null;
    }
    summary.members = summary.members.filter(m => !m.cancelDateUtc);
    this.__summary = summary;
    this.__loading = false;
    if (!this._hasAccess('ACCOUNT:ALERTS:VIEW')) summary.pinnedNote = null;
    if (!!summary.pinnedNote) this.__alertShown = summary.pinnedNote;
    this._afterRender(() => {
      const tagEl = this._getElement('.acct-tags');
      if (tagEl.scrollWidth > tagEl.clientWidth) tagEl.setAttribute('has-scroll', '');
    });
  }

  __getAddressLine2(summary) {
    let addy = '';
    if (summary.city) addy += summary.city;
    if (summary.locale) addy += (addy.length > 0 ? ', ' : '') + summary.locale;
    if (summary.postalCode) addy += (addy.length > 0 ? ' ' : '') + summary.postalCode;
    return addy;
  }

  __getBirthDateStr(summary) {
    if (!summary.birthDate || summary.birthDate === '0001-01-01T00:00:00') return '';
    return i18nDate.formatDateInLocale(summary.birthDate) + ` (${i18nDate.getAge(summary.birthDate)})`;
  }

  __showAddNote() {
    if (!this.__addNoteShown) {
      this.__addNoteShown = true;
      this._afterRender(() => {
        this.__noteEl.setFocus(true);
      });
    } else {
      if (!this.__noteEl.value) {
        this.__noteEl.setAttribute('invalid', '');
        this.__noteEl.setFocus();
        return;
      }
      this.__noteEl.removeAttribute('invalid');
      this.__loading = true;
      Insight.state.createOneTime(
        'accountNote',
        state => {
          this.__loading = false;
          if (state.success) {
            this._dispatchEvent('show-snackbar', this._i18n('account:accountNoteCreated'));
            this._afterRender(() => this.__hideAddNote());
          }
        },
        {
          guid: this.__summary.id,
          note: this.__noteEl.value
        }
      );
    }
  }

  __hideAddNote() {
    this.__noteEl.value = '';
    this.__addNoteShown = false;
  }

  __hideAlert() {
    this.__alertShown = null;
  }

  __navToAccount(e) {
    this.close();
    this._navigateOnClick(e, `accounts/${this.__summary.id}/view`);
  }

  __navToMember(e, id) {
    this.close();
    this._navigateOnClick(e, `members/${id}/view`);
  }

  __mergeGuest(e) {
    if (this.__summary && this.__summary.members && this.__summary.members.length) {
      this.close();
      this._navigateOnClick(e, `members/${this.__summary.members[0].id}/merge`);
    }
  }

  __getPrimaryImage(summ) {
    if (this.__summary && this.__summary.members && this.__summary.members.length) {
      const mem = this.__summary.members.length === 1 ? this.__summary.members[0] : this.__summary.members.find(m => m.primary);
      if (mem) return mem.imageUrl;
    }
    return null;
  }

  get __dropdownEl() {
    return this._getElement('#acct-summary');
  }

  get __noteEl() {
    return this._getElement('insight-textarea');
  }

  _render() {
    let isDark, tagBg, tagFg, tagBdr;
    return html`
      <style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
      ${unsafeHTML(this.__css)}
      <insight-dropdown-container id="acct-summary" fixed>
        ${this.__loading
          ? html`
              <div id="acct-loader" class="flex-layout-horizontal flex-layout-center-center">
                <div class="insight-loader" style="${window.Testophobia ? 'border-top-color:#ccc;' : ''}"></div>
              </div>
            `
          : html`
              <insight-icon-button id="close-btn" icon-name="close" no-focus-circle @click=${() => this.close()}></insight-icon-button>
              <div class="flex-layout-horizontal">
                <member-photo
                  class="acct-photo"
                  image-url=${this.__getPrimaryImage(this.__summary)}
                  primary-member
                  account-status=${this.__summary.status}
                  no-popup
                ></member-photo>
                <div class="acct-header flex-layout-flex flex-layout-vertical">
                  <div class="typo-body2 acct-name" title=${this.__summary.fullName} ${leftAndMiddleClick(this.__navToAccount.bind(this))}>
                    ${this.__summary.fullName}
                  </div>
                  <div class="typo-body2 acct-num" ${leftAndMiddleClick(this.__navToAccount.bind(this))}>
                    ${this.__summary.type === 'GUEST' ? html` <span>(${this._i18n('common:guest')})</span> ` : ''} #${this.__summary.accountNumber}
                  </div>
                  <div class="typo-body2 acct-status" account-status=${this.__summary.status}>
                    <span class="field-lbl">${this._i18n('common:accountStatus')}:</span>
                    <span>${this.__summary.status ? this.__summary.status.toLowerCase() : ''}</span>
                  </div>
                  <div class="typo-body2 acct-join">
                    <span class="field-lbl">${this._i18n('common:joined')}:</span>
                    <span>${i18nDate.formatDateInLocale(this.__summary.createdUtc)}</span>
                  </div>
                </div>
              </div>
              ${this.__summary.locationGuid !== Insight.user.currentLocationGuid
                ? html`<div class="typo-body2 acct-tag vstr-div">${this._i18n('common:visitor')} - ${this.__summary.locationName}</div>`
                : ''}
              <div class="flex-layout-horizontal flex-layout-center acct-tags" ?no-tags=${!this.__summary.tags || this.__summary.tags.length === 0}>
                ${this.__summary.tags.map(t => {
                  isDark = !!t.color ? isDarkBackground(t.color) : true;
                  tagBg = !!t.color ? t.color : 'var(--mdc-theme-dark-highlight, var(--mdc-theme-text-disabled))';
                  tagFg = isDark ? 'white' : 'var(--mdc-theme-dark-bg-2, var(--mdc-theme-text-primary))';
                  tagBdr = isDark ? tagBg : darken(tagBg, 20);
                  return html` <div class="typo-body2 acct-tag" style="color:${tagFg};background:${tagBg};border:1px solid ${tagBdr}">${t.name}</div> `;
                })}
              </div>
              <div>
                <div class="typo-overline acct-section-lbl">${this._i18n('common:contactInfo')}</div>
                <div class="acct-section">
                  <div class="flex-layout-horizontal">
                    <div class="acct-section-inner">
                      <span class="typo-caption field-lbl">${this._i18n('common:email')}:</span>
                      <span class="typo-caption" title=${this.__summary.email || ''}>${this.__summary.email || ''}</span>
                    </div>
                    <div class="acct-section-inner">
                      <span class="typo-caption field-lbl">${this._i18n('common:cell')}:</span>
                      <span class="typo-caption">${!!this.__summary.cellPhone ? this.__summary.cellPhone.number : ''}</span>
                    </div>
                  </div>
                  <div class="flex-layout-horizontal">
                    <div class="acct-section-inner">
                      <span class="typo-caption field-lbl">${this._i18n('common:birthdate')}:</span>
                      <span class="typo-caption">${this.__getBirthDateStr(this.__summary)}</span>
                    </div>
                    <div class="acct-section-inner">
                      <span class="typo-caption field-lbl">${this._i18n('common:home')}:</span>
                      <span class="typo-caption">${!!this.__summary.homePhone ? this.__summary.homePhone.number : ''}</span>
                    </div>
                  </div>
                  <div class="flex-layout-horizontal">
                    <div class="acct-section-inner">
                      <span class="typo-caption field-lbl">${this._i18n('common:address')}:</span>
                      <span class="typo-caption"></span>
                    </div>
                    <div class="acct-section-inner">
                      <span class="typo-caption field-lbl">${this._i18n('common:work')}:</span>
                      <span class="typo-caption">${!!this.__summary.workPhone ? this.__summary.workPhone.number : ''}</span>
                    </div>
                  </div>
                  <div class="flex-layout-horizontal">
                    <div class="acct-section-inner">
                      <span class="typo-caption" title=${this.__summary.address || ''}>${this.__summary.address || ''}</span>
                      <span class="typo-caption"></span>
                    </div>
                    <div class="acct-section-inner">
                      <span class="typo-caption field-lbl">${this._i18n('account:emerg')}:</span>
                      <span class="typo-caption">${!!this.__summary.emergencyPhone ? this.__summary.emergencyPhone.number : ''}</span>
                    </div>
                  </div>
                  <div class="flex-layout-horizontal">
                    <div class="acct-section-inner">
                      <span class="typo-caption" title=${this.__getAddressLine2(this.__summary)}>${this.__getAddressLine2(this.__summary)}</span>
                      <span class="typo-caption"></span>
                    </div>
                    <div class="acct-section-inner">
                      <span class="typo-caption field-lbl">${this._i18n('common:contact')}:</span>
                      <span class="typo-caption" title=${this.__summary.emergencyContact || ''}>${this.__summary.emergencyContact || ''}</span>
                    </div>
                  </div>
                </div>
              </div>
              ${this._hasAccess('ACCOUNT:PAYMENTDETAIL:VIEW') || this._hasAccess('ACCOUNT:BILLINGDETAIL:VIEW')
                ? html`
                    <div>
                      <div class="typo-overline acct-section-lbl">${this._i18n('account:billingPayment')}</div>
                      <div class="acct-section">
                        <div class="flex-layout-horizontal">
                          ${this._hasAccess('ACCOUNT:BILLINGDETAIL:VIEW')
                            ? html`<div class="acct-section-inner">
                                <span class="typo-caption field-lbl">${this._i18n('account:interval')}:</span>
                                <span class="typo-caption">${this.__summary.intervalStr}</span>
                              </div>`
                            : ''}
                          ${this._hasAccess('ACCOUNT:PAYMENTDETAIL:VIEW')
                            ? html`<div class="acct-section-inner">
                                <span class="typo-caption field-lbl">${this._i18n('common:type')}:</span>
                                <span class="typo-caption">${this.__summary.payTypeStr}</span>
                              </div>`
                            : ''}
                        </div>
                        <div class="flex-layout-horizontal">
                          ${this._hasAccess('ACCOUNT:BILLINGDETAIL:VIEW')
                            ? html`<div class="acct-section-inner">
                                <span class="typo-caption field-lbl">${this._i18n('common:amount')}:</span>
                                <span class="typo-caption">${i18nMoney.format(this.__summary.paymentAmount, Insight.location.currencyCode)}</span>
                              </div>`
                            : ''}
                          ${this._hasAccess('ACCOUNT:PAYMENTDETAIL:VIEW') && this.__summary.expStr !== null
                            ? html`<div class="acct-section-inner">
                                <span class="typo-caption field-lbl">${this._i18n('account:exp')}:</span>
                                <span class="typo-caption">${this.__summary.expStr}</span>
                              </div>`
                            : ''}
                        </div>
                        <div class="flex-layout-horizontal">
                          ${this._hasAccess('ACCOUNT:BILLINGDETAIL:VIEW')
                            ? html`<div class="acct-section-inner">
                                <span class="typo-caption field-lbl">${this._i18n('account:nextDue')}:</span>
                                <span class="typo-caption">${i18nDate.formatDateInLocale(this.__summary.nextBillingUtc)}</span>
                              </div>`
                            : ''}
                          ${this._hasAccess('ACCOUNT:PAYMENTDETAIL:VIEW') && this.__summary.last4Str !== null
                            ? html`<div class="acct-section-inner">
                                <span class="typo-caption field-lbl">${this._i18n('account:last4')}:</span>
                                <span class="typo-caption">${this.__summary.last4Str}</span>
                              </div>`
                            : ''}
                        </div>
                      </div>
                    </div>
                  `
                : ''}
              <div>
                <div class="typo-overline acct-section-lbl">${this._i18n('account:members')}</div>
                <div class="acct-section member-section">
                  <div class="flex-layout-horizontal acct-member-header">
                    <div></div>
                    <div class="flex-layout-flex">${this._i18n('common:name')}</div>
                    <div>${this._i18n('common:latestCheckin')}</div>
                  </div>
                  ${this.__summary.members.map(m => {
                    return html`
                      <div class="flex-layout-horizontal flex-layout-center acct-member">
                        <member-photo class="member-photo" image-url=${m.imageUrl} no-popup></member-photo>
                        <div class="typo-caption flex-layout-flex member-name" title=${m.fullName} ${leftAndMiddleClick(e => this.__navToMember(e, m.id))}>
                          ${m.fullName}
                        </div>
                        <div class="typo-caption member-checkin">${i18nDate.formatDateTimeInLocale(m.latestCheckinDateUtc, Insight.location.ianaTimezone)}</div>
                      </div>
                    `;
                  })}
                </div>
              </div>
              ${this.__addNoteShown
                ? html`
                    <div id="add-note-panel">
                      <insight-textarea label=${this._i18n('account:accountNote')}></insight-textarea>
                    </div>
                  `
                : !!this.__alertShown
                  ? html`
                      <div id="alert-panel">
                        <insight-panel panel-title=${this._i18n('account:alertTitle')} error>
                          <div class="typo-subhead2 alert-content">${this.__alertShown}</div>
                          <insight-icon-button icon-name="clear" @click=${this.__hideAlert}></insight-icon-button>
                        </insight-panel>
                      </div>
                    `
                  : ''}
              <div
                class="flex-layout-horizontal ${!this.__addNoteShown && this.__summary.type !== 'GUEST'
                  ? 'flex-layout-end-justified'
                  : 'flex-layout-justified'} acct-footer"
              >
                <insight-button id="cancel-btn" dense @click=${this.__hideAddNote} ?hidden=${!this.__addNoteShown}>
                  ${this._i18n('common:cancel')}
                </insight-button>
                ${this._hasAccess('MEMBER:MERGE:ALL')
                  ? html`<insight-button
                      id="merge-btn"
                      dense
                      contained
                      @click=${this.__mergeGuest}
                      ?hidden=${this.__alertShown || this.__addNoteShown || this.__summary.type !== 'GUEST'}
                    >
                      ${this._i18n('common:merge')}
                    </insight-button>`
                  : ''}
                ${this._hasAccess('MEMBER:ADDNOTE:ALL')
                  ? html`<insight-button id="note-btn" contained dense @click=${this.__showAddNote} ?hidden=${!!this.__alertShown}>
                      ${this._i18n('common:addNote')}
                    </insight-button>`
                  : ''}
              </div>
            `}
      </insight-dropdown-container>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          cursor: initial;
        }
        :host #close-btn {
          position: absolute;
          top: 0;
          right: 0;
        }
        :host #acct-summary {
          --dropdown-width: 352px;
          --dropdown-min-width: 352px;
          --dropdown-max-width: 352px;
          --dropdown-overflow-x: hidden;
          --dropdown-overflow-y: auto;
          --dropdown-padding: 16px 12px 8px;
        }
        :host member-photo {
          cursor: initial;
        }
        :host .acct-photo {
          margin-right: 16px;
          --photo-width: 106px;
          --photo-height: 80px;
          --photo-border-width: 2px;
          --photo-border-color: var(--mdc-theme-primary);
        }
        :host .field-lbl {
          font-weight: 500;
        }
        :host .acct-header {
          color: var(--mdc-theme-text-primary);
          max-width: 190px;
        }
        :host .acct-header > * {
          line-height: 1;
          margin-bottom: 7px;
        }
        :host .acct-header .field-lbl {
          margin-right: 2px;
        }
        :host .acct-name,
        :host .acct-num {
          font-size: 15px;
          color: var(--mdc-theme-primary);
          font-weight: 500;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        :host .acct-num {
          color: var(--mdc-theme-secondary);
        }
        :host .acct-num > span {
          color: var(--mdc-theme-text-success);
          text-transform: lowercase;
        }
        :host .acct-join {
          margin-top: 1px;
          margin-bottom: 0;
        }
        :host .acct-status span:last-child {
          color: var(--mdc-theme-text-success);
        }
        :host .acct-status[account-status="YELLOW"] span:last-child {
          color: var(--mdc-theme-warning);
        }
        :host .acct-status[account-status="RED"] span:last-child,
        :host .acct-status[account-status="COLLECTIONS"] span:last-child,
        :host .acct-status[account-status="CANCELLED"] span:last-child {
          color: var(--mdc-theme-error);
        }
        :host .acct-tags {
          margin-top: 6px;
          overflow-x: auto;
          overflow-y: hidden;
        }
        :host .acct-tags[has-scroll] {
          height: 38px;
        }
        :host .acct-tags[no-tags] {
          margin-top: 0;
          height: 0;
        }
        :host .acct-tag {
          background: #5CB85C;
          color: white;
          border-radius: 4px;
          margin-right: 6px;
          padding: 0 6px;
          font-size: 11px;
          font-weight: 500;
          white-space: nowrap;
        }
        :host .vstr-div {
          background: var(--mdc-theme-warning);
          border-radius: 4px;
          color: white;
          display: inline-block;
          margin-top: 6px;
        }
        :host .acct-section-lbl {
          color: var(--mdc-theme-text-secondary);
          font-size: 8px;
          line-height: 16px;
          margin-top: 6px;
        }
        :host .acct-section {
          padding: 4px 6px;
          color: var(--mdc-theme-text-primary);
          border: 1px solid var(--mdc-theme-divider-color);
          border-radius: 4px;
        }
        :host .acct-section-inner {
          width: 166px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        :host .acct-section-inner:last-child {
          width: 144px;
        }
        :host .acct-section > * {
          max-height: 20px;
        }
        :host .acct-section span {
          vertical-align: top;
        }
        :host .acct-member-header {
          font-size: 9px;
          font-weight: 500;
        }
        :host .acct-member-header > div:first-child {
          width: 48px;
        }
        :host .acct-member:not(:last-child) {
          margin-bottom: 6px;
        }
        :host .member-photo {
          margin-right: 8px;
          --photo-width: 40px;
          --photo-height: 30px;
        }
        :host .member-name {
          color: var(--mdc-theme-secondary);
          font-weight: 500;
          cursor: pointer;
        }
        :host .member-checkin,
        :host .acct-member-header > div:last-child {
          width: 120px;
        }
        :host #acct-summary .member-section {
          padding-top: 2px;
          min-height: 48px;
          max-height: 80px;
          overflow-x: hidden;
          overflow-y: auto;
        }
        :host #acct-summary .member-section > * {
          max-height: 30px;
        }
        :host .acct-footer {
          margin-top: 8px;
        }
        :host #add-note-panel,
        :host #alert-panel {
          background: var(--mdc-theme-dark-bg-8, var(--mdc-theme-background));
          padding: 0 16px;
          box-sizing: border-box;
          position: absolute;
          bottom: 47px;
          left: 0;
          right: 0;
          height: calc(100% - 148px);
        }
        :host #add-note-panel insight-textarea {
          --input-height: 100%;
        }
        :host #alert-panel {
          bottom: 0;
          height: calc(100% - 102px);
          --panel-header-bg: var(--mdc-theme-dark-bg-8, var(--mdc-theme-background));
        }
        :host #alert-panel insight-panel {
          margin-top: 8px;
          height: calc(100% - 24px);
          --panel-height: 100%;
        }
        :host #alert-panel .alert-content {
          margin: 4px 12px 0px;
          overflow-y: auto;
          color: var(--mdc-theme-text-primary);
        }
        :host #alert-panel insight-icon-button {
          position: absolute;
          top: -12px;
          right: -12px;
          width: 28px;
          height: 28px;
          background: var(--mdc-theme-dark-bg-2, var(--mdc-theme-background));
          border: 2px solid var(--mdc-theme-error);
          border-radius: 50%;
          --icon-color: var(--mdc-theme-text-error);
          --icon-width: 20px;
          --icon-padding: 4px;
          --icon-display: flex;
        }
        :host #acct-loader {
          height: 443px;
        }
        :host .insight-loader {
          position: absolute;
          z-index: 1;
        }
      </style>
    `;
  }
}
window.customElements.define('account-summary', AccountSummary);
