import {Directive, directive} from 'lit/directive.js';
import {nothing, noChange} from 'lit';

class LeftAndMiddleClickDirective extends Directive {
  render(fn) {
    return nothing;
  }

  update(part, args) {
    if (!!this.__clickHandler) {
      part.element.removeEventListener('click', this.__clickHandler);
      part.element.removeEventListener('auxclick', this.__clickHandler);
    }
    this.__clickHandler = args[0];
    part.element.addEventListener('click', this.__clickHandler);
    part.element.addEventListener('auxclick', this.__clickHandler);
    return noChange;
  }
}
const leftAndMiddleClick = directive(LeftAndMiddleClickDirective);
export {leftAndMiddleClick};
